import api from "./api";

class HotelService {
	/**************************************
	 *  LOCAL STORAGE
	 **************************************/

	getLocalHotel() {
		const hotel = JSON.parse(localStorage.getItem("hotel"));
		return hotel;
	}

	async updateLocalHotel(id) {
		try {
			var response = await this.getHotel(id);
			let hotel = response.data;
			this.removeLocalHotel();
			localStorage.setItem("hotel", JSON.stringify(hotel));
		} catch (error) {
			console.error(error);
		}
	}

	async setInitialLocalHotel() {
		try {
			var response = await this.getUserHotels({
				activePMS: true,
			});
			let hotels = response.data;
			this.setLocalHotel(hotels[0]);
			return hotels[0];
		} catch (error) {
			console.error(error);
		}
	}

	async setLocalHotel(hotel) {
		this.removeLocalHotel();
		await localStorage.setItem("hotel", JSON.stringify(hotel));
	}

	removeLocalHotel() {
		localStorage.removeItem("hotel");
	}

	/**************************************
	 *  API HOSPEDIUM
	 **************************************/

	async getHotel(id) {
		try {
			const response = await api.get("/hotels/" + id);
			return { status: response.status, data: response.data };
		} catch (error) {
			return {
				status: error.response ? error.response.status : 500,
				data: error.response ? error.response.data : "An error occurred",
			};
		}
	}

	async updateHotel(params) {
		let hotel = this.getLocalHotel();

		try {
			const response = await api.put("/hotels/" + hotel.id, params, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			});
			return { status: response.status, data: response.data };
		} catch (error) {
			return {
				status: error.response ? error.response.status : 500,
				data: error.response ? error.response.data : "An error occurred",
			};
		}
	}

	async getUserHotels(params) {
		try {
			const response = await api.get("/hotels", { params: params });
			return { status: response.status, data: response.data };
		} catch (error) {
			return {
				status: error.response ? error.response.status : 500,
				data: error.response ? error.response.data : "An error occurred",
			};
		}
	}

	async addBuilding(params) {
		try {
			const response = await api.post("/buildings", params);
			return { status: response.status, data: response.data };
		} catch (error) {
			return {
				status: error.response ? error.response.status : 500,
				data: error.response ? error.response.data : "An error occurred",
			};
		}
	}

	async getBuildings() {
		let hotel = await this.getLocalHotel();

		try {
			const response = await api.get("/buildings?hotel=" + hotel.id);
			return { status: response.status, data: response.data };
		} catch (error) {
			return {
				status: error.response ? error.response.status : 500,
				data: error.response ? error.response.data : "An error occurred",
			};
		}
	}

	async getBuilding(id) {
		try {
			const response = await api.get("/buildings/" + id);
			return { status: response.status, data: response.data };
		} catch (error) {
			return {
				status: error.response ? error.response.status : 500,
				data: error.response ? error.response.data : "An error occurred",
			};
		}
	}

	async updateBuilding(id, params) {
		try {
			const response = await api.put("/buildings/" + id, params);
			return { status: response.status, data: response.data };
		} catch (error) {
			return {
				status: error.response ? error.response.status : 500,
				data: error.response ? error.response.data : "An error occurred",
			};
		}
	}

	async deleteBuilding(id) {
		try {
			const response = await api.delete("/buildings/" + id);
			return { status: response.status, data: response.data };
		} catch (error) {
			return {
				status: error.response ? error.response.status : 500,
				data: error.response ? error.response.data : "An error occurred",
			};
		}
	}

	async addFloor(params) {
		try {
			const response = await api.post("/floors", params);
			return { status: response.status, data: response.data };
		} catch (error) {
			return {
				status: error.response ? error.response.status : 500,
				data: error.response ? error.response.data : "An error occurred",
			};
		}
	}

	async getFloors() {
		let hotel = await this.getLocalHotel();

		try {
			const response = await api.get("/floors?hotel=" + hotel.id);
			return { status: response.status, data: response.data };
		} catch (error) {
			return {
				status: error.response ? error.response.status : 500,
				data: error.response ? error.response.data : "An error occurred",
			};
		}
	}

	async getFloor(id) {
		try {
			const response = await api.get("/floors/" + id);
			return { status: response.status, data: response.data };
		} catch (error) {
			return {
				status: error.response ? error.response.status : 500,
				data: error.response ? error.response.data : "An error occurred",
			};
		}
	}

	async updateFloor(id, params) {
		try {
			const response = await api.put("/floors/" + id, params);
			return { status: response.status, data: response.data };
		} catch (error) {
			return {
				status: error.response ? error.response.status : 500,
				data: error.response ? error.response.data : "An error occurred",
			};
		}
	}

	async deleteFloor(id) {
		try {
			const response = await api.delete("/floors/" + id);
			return { status: response.status, data: response.data };
		} catch (error) {
			return {
				status: error.response ? error.response.status : 500,
				data: error.response ? error.response.data : "An error occurred",
			};
		}
	}

	async getHotelModules() {
		let hotel = await this.getLocalHotel();
		try {
			const response = await api.get("/hotels/" + hotel.id + "/modules");
			return { status: response.status, data: response.data };
		} catch (error) {
			return {
				status: error.response ? error.response.status : 500,
				data: error.response ? error.response.data : "An error occurred",
			};
		}
	}

	async getModules() {
		try {
			const response = await api.get("/modules/");
			return { status: response.status, data: response.data };
		} catch (error) {
			return {
				status: error.response ? error.response.status : 500,
				data: error.response ? error.response.data : "An error occurred",
			};
		}
	}

	async addModuleToHotel(hotelID, data) {
		try {
			const response = await api.post("/hotels/" + hotelID + "/modules", data);
			return { status: response.status, data: response.data };
		} catch (error) {
			return {
				status: error.response ? error.response.status : 500,
				data: error.response ? error.response.data : "An error occurred",
			};
		}
	}

	async removeModuleFromHotel(hotelID, data) {
		try {
			const response = await api.post("/hotels/" + hotelID + "/modules/remove/", data);
			return { status: response.status, data: response.data };
		} catch (error) {
			return {
				status: error.response ? error.response.status : 500,
				data: error.response ? error.response.data : "An error occurred",
			};
		}
	}

	async get_hotel_channel_mappings() {
		let hotel = await this.getLocalHotel();
		try {
			const response = await api.get(`/hotels/${hotel.id}/mappings`);
			return { status: response.status, data: response.data };
		} catch (error) {
			return {
				status: error.response ? error.response.status : 500,
				data: error.response ? error.response.data : "An error occurred",
			};
		}
	}

	async update_hotel_channel_mappings(mapping_uuid, data) {
		let hotel = await this.getLocalHotel();
		try {
			const response = await api.put(`/hotels/${hotel.id}/mappings/${mapping_uuid}/`, data);
			return { status: response.status, data: response.data };
		} catch (error) {
			return {
				status: error.response ? error.response.status : 500,
				data: error.response ? error.response.data : "An error occurred",
			};
		}
	}

	async get_hotel_channel_exceptions() {
		let hotel = await this.getLocalHotel();
		try {
			const response = await api.get(`/hotels/${hotel.id}/exceptions`);
			return { status: response.status, data: response.data };
		} catch (error) {
			return {
				status: error.response ? error.response.status : 500,
				data: error.response ? error.response.data : "An error occurred",
			};
		}
	}

	async update_hotel_channel_exceptions(exception_uuid, data) {
		let hotel = await this.getLocalHotel();
		try {
			const response = await api.put(`/hotels/${hotel.id}/exceptions/${exception_uuid}/`, data);
			return { status: response.status, data: response.data };
		} catch (error) {
			return {
				status: error.response ? error.response.status : 500,
				data: error.response ? error.response.data : "An error occurred",
			};
		}
	}
}

export default new HotelService();
