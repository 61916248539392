<template>
	<div class="page-app">
		<div class="col-12 pb-5">
			<div class="card border-0 shadow-sm mb-3 p-3">
				<div v-if="!loading" class="card-header bg-white border-0">
					<div
						class="d-md-flex d-block justify-content-between align-items-center mb-3"
					>
						<div class="fs-4" style="font-weight: 500">
							Facturas
						</div>
						<div class="d-block d-md-flex">
							<div class="me-3">
								<AddInvoice />
							</div>
							<div>
								<ExportInvoicesModal />
							</div>
						</div>
					</div>
					<div class="d-md-flex d-block justify-content-between">
						<div class="dropdown">
							<button
								class="btn btn-primary dropdown-toggle mb-3 mb-md-0"
								type="button"
								id="dropdownFilters"
								data-bs-toggle="dropdown"
								aria-expanded="false"
							>
								<i class="fa-regular fa-filter-list me-2"></i
								>Filtros
							</button>
							<div
								class="dropdown-menu"
								aria-labelledby="dropdownFilters"
								style="min-width: 270px"
							>
								<div class="p-3">
									<h6>Filtros:</h6>
									<MazSelect
										v-model="filterStatusPayment"
										label="Filtrar por estado"
										:options="optionsFilterStatus"
										class="mb-3"
										size="sm"
									/>
									<MazSelect
										v-model="filterClient"
										label="Filtrar por cliente"
										:options="optionsFilterClient"
										class="mb-3"
										size="sm"
									/>
									<MazSelect
										v-model="filterType"
										label="Filtrar por tipo"
										:options="optionsFilterType"
										class="mb-3"
										size="sm"
									/>
									<MazSelect
										v-model="filterTag"
										label="Filtrar por etiquetas"
										:options="optionsFilterTag"
										class="mb-3"
										size="sm"
									/>
								</div>
							</div>
						</div>

						<div class="d-md-flex justify-content-end">
							<div class="searchBar me-3 mb-3 mb-md-0">
								<!-- Filter Search -->
								<div class="input-group">
									<MazInput
										v-model="searchValue"
										label="Buscar..."
										size="sm"
									>
										<template #left-icon>
											<i
												class="fa-regular fa-magnifying-glass ms-2"
											></i>
										</template>
									</MazInput>
								</div>
							</div>
							<div class="mb-1">
								<DatePicker
									v-model.range="range"
									:masks="masks"
									:columns="columns"
									v-if="!loadingCalendar"
								>
									<template
										#default="{ togglePopover, inputValue }"
									>
										<button
											class="btn px-3 py-2 bg-blue-500 text-sm bg-white font-semibold rounded-md shadow-sm"
											@click="togglePopover"
											style="
												padding: 10px;
												border: 1px solid #eeeef1;
												border-radius: 4px;
												width: 270px;
											"
										>
											<i
												class="fa-regular fa-calendar-days me-3 text-primary"
											></i>
											<span>{{ inputValue.start }}</span>
											<i
												class="fa-regular fa-arrow-right mx-2"
											></i>
											<span>{{ inputValue.end }}</span>
										</button>
									</template>
								</DatePicker>
							</div>
						</div>
					</div>
				</div>
				<div class="card-body">
					<TableInvoices
						id="client-table"
						:isClient="true"
						:startDate="range.start"
						:endDate="range.end"
						:filterType="filterType"
						:filterClient="filterClient"
						:filterStatusPayment="filterStatusPayment"
						:filterTag="filterTag"
						:searchValue="searchValue"
					></TableInvoices>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import TableInvoices from "@/components/invoices/TableInvoices.vue";
import ExportInvoicesModal from "@/components/invoices/ExportInvoicesModal.vue";
import AddInvoice from "@/components/invoices/AddInvoice.vue";

import TagService from "../../services/tag.service";

import { useScreens } from "vue-screen-utils";

export default {
	name: "invoices",
	components: {
		TableInvoices,
		ExportInvoicesModal,
		AddInvoice,
	},
	setup() {
		const { mapCurrent } = useScreens({
			xs: "0px",
			sm: "640px",
			md: "768px",
			lg: "1024px",
		});
		const columns = mapCurrent({ lg: 2 }, 1);

		return {
			columns,
		};
	},
	data() {
		return {
			loading: true,
			range: {
				start: new Date(new Date().setDate(new Date().getDate() - 20)),
				end: new Date(),
			},
			masks: {
				input: "YYYY-MM-DD",
			},
			filterStatusPayment: 0,
			filterType: 0,
			filterClient: 0,
			filterTag: 0,
			searchValue: "",
			optionsFilterStatus: [
				{ label: "Todos", value: 0 },
				{ label: "Pagado", value: 1 },
				{ label: "Pendiente", value: 2 },
			],

			optionsFilterType: [
				{ label: "Todos", value: 0 },
				{ label: "Factura", value: 1 },
				{ label: "Factura Rectificativa", value: 2 },
				{ label: "Factura Anticipo", value: 3 },
				{ label: "Factura Proforma", value: 4 },
			],

			optionsFilterClient: [
				{ label: "Todos", value: 0 },
				{ label: "Cliente", value: 1 },
				{ label: "Empresa", value: 2 },
			],

			optionsFilterTag: [{ label: "Todos", value: 0 }],
		};
	},
	computed: {
		currentUser() {
			return this.$store.state.auth.user;
		},
	},
	methods: {
		async start() {
			this.loading = true;
			await this.getTags();
			this.loading = false;
		},

		async getTags() {
			var response = await TagService.listTags({ enabled: true });
			var tags = response.data;

			console.log(tags);

			for (var i = 0; i < tags.length; i++) {
				this.optionsFilterTag.push({
					label: tags[i].name,
					value: tags[i].id,
				});
			}
		},
	},
	created() {
		this.start();
	},
	mounted() {
		if (!this.currentUser) {
			this.$router.push("/login");
		}
	},
};
</script>

