<template>
    <div id="sidebar" class="bg-white fixed-top" v-click-outside="onClickOutside">
        <div class="logo">
            <a href="/" class="">
                <img :src="require('@/assets/img/favicon.png')" class="rounded" />
            </a>
        </div>
        <div style=" overflow: hidden; display: flex;  justify-content: center; align-items: center;">
            <ul class="nav nav-pills nav-flush flex-column mb-auto text-center">
                <li v-if="!isHousekeeping" class="nav-item">
                    <router-link id="menu-planning" to="/planning" class="nav-link">
                        <i class="fa-regular fa-calendar-lines"></i>
                        <div class="item-title">Planning</div>
                    </router-link>
                </li>
                <li v-if="!isHousekeeping" class="nav-item">
                    <router-link id="menu-checkin" to="/checkin-checkout" class="nav-link">
                        <i class="fa-regular fa-right-to-bracket"></i>
                        <div class="item-title">
                            In / Out
                        </div>
                    </router-link>
                </li>
                <li v-if="!isHousekeeping" class="nav-item">
                    <router-link id="menu-bookings" to="/bookings" class="nav-link">
                        <i class="fa-regular fa-rectangle-list"></i>
                        <div class="item-title">Reservas</div>
                    </router-link>
                </li>
                <li class="nav-item">
                    <router-link id="menu-rooms" to="/rooms" class="nav-link">
                        <i class="fa-regular fa-bed"></i>
                        <div class="item-title">Habitaciones</div>
                    </router-link>
                </li>
                <li v-if="!isHousekeeping" class="nav-item">
                    <router-link id="menu-invoices" to="/invoices" class="nav-link">
                        <i class="fa-regular fa-credit-card"></i>
                        <div class="item-title">Facturación</div>
                    </router-link>
                </li>
                <li v-if="!isHousekeeping" class="nav-item">
                    <router-link id="menu-channel" to="/channel" class="nav-link">
                        <i class="fa-regular fa-network-wired"></i>
                        <div class="item-title">Channel</div>
                    </router-link>
                </li>

                <li v-if="!isHousekeeping" class="nav-item">
                    <router-link id="menu-rooms" to="/clients" class="nav-link">
                        <i class="fa-regular fa-users"></i>
                        <div class="item-title">Clientes</div>
                    </router-link>
                </li>
                <li v-if="!isHousekeeping" class="nav-item">
                    <router-link id="menu-reporting" to="/reporting" class="nav-link">
                        <i class="fa-regular fa-chart-line"></i>
                        <div class="item-title">Listados</div>
                    </router-link>
                </li>
                <li v-if="!isHousekeeping" class="nav-item">
                    <router-link id="menu-settings" to="/settings/hotel" class="nav-link">
                        <i class="fa-regular fa-gear"></i>
                        <div class="item-title">Ajustes</div>
                    </router-link>
                </li>
            </ul>
        </div>

        <div class="text-center py-3 version" style="cursor: pointer;">
            <router-link to="/version" class="link"><i class="fa-regular fa-circle-info me-1"></i><span class="num-version">v{{ version }}</span></router-link>
        </div>


    </div>
</template>

<script>
import EventBus from "../common/EventBus";
import vClickOutside from "click-outside-vue3";

export default {
    data() {
        return {
            loading: true,
            version: localStorage.getItem("currentVersion"),
        };
    },
    directives: {
        clickOutside: vClickOutside.directive,
    },
    computed: {
        currentUser() {
            return this.$store.state.user.data;
        },
        userRoles() {
            return this.$store.getters['user/userRoles'];
        },
        isHousekeeping() {
            return this.userRoles.includes('pms_hotel_housekeeping');
        },
    },
    methods: {
        async start() {
            this.loading = true;
            this.loading = false;
        },
        logOut() {
            this.$store.dispatch("auth/logout");
            this.$router.push("/login");
        },

        onClickOutside(event) {
            if (window.$("#sidebar").hasClass("open")) {
                // console.log("Clicked outside. Event: ", event);
                window.$("#sidebar").css("display", "none");
                window.$("#sidebar").removeClass("open");
            }
        },

    },
    created() {
        this.start()
    },
    mounted() {
        EventBus.on("logout", () => {
            this.logOut();
        });
    },
    beforeUnmount() {
        EventBus.remove("logout");
    },
};
</script>

<style scoped>
#sidebar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}

#sidebar li.nav-item {
    color: #181C32;
    text-align: center;
    width: 100%;
    padding: 5px 0;
}

#sidebar li .nav-link {
    width: 80px;
    margin: 0 auto;
    padding: 8px 4px;
}

#sidebar.minified li .nav-link {
    width: 40px;
}

#sidebar .router-link-active {
    color: #fff;
    background-color: var(--bs-primary);
    border-radius: 10px;
}

#sidebar .router-link-active i {
    color: #fff;
}

#sidebar .router-link-active .item-title {
    color: #fff;
}

#sidebar .nav-link:hover {
    color: var(--bs-primary) !important;
    background-color: transparent;
}

#sidebar .router-link-active:hover {
    color: #fff;
    background-color: var(--bs-primary);
    border-radius: 10px;
}


#sidebar .router-link-active:hover i {
    color: #fff;
}

#sidebar .logo {
    align-items: center;
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 20px;
    padding-top: 20px;
}

#sidebar .logo img {
    width: 40px;
}



#sidebar ul.nav-pills {
    overflow-y: scroll;
    position: relative;
    margin: 0 5px;
}

/* Customizing the scrollbar */
::-webkit-scrollbar {
    width: 5px;
    /* or any value you like */
    height: 5px;
    /* For horizontal scrollbars */
}

/* Customizing the track of the scrollbar */
::-webkit-scrollbar-track {
    background: transparent;
    /* or any color/value you like */
    border-radius: 10px;
}

/* Customizing the thumb/handle of the scrollbar */
::-webkit-scrollbar-thumb {
    background: #181C32;
    /* or any color/value you like */
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    opacity: 0.8;
}

#sidebar.minified .logo img {
    width: 30px;
}

.version {
    font-size: 12px;
    color: #181C32;
}

.version .link {
    text-decoration: none;
    color: #181C32;
}

#sidebar .version .router-link-active {
    color: #181C32;
    background-color: transparent;
    border-radius: 0;

}

#sidebar .version .router-link-active i {
    color: #181C32;
    background-color: transparent;
    border-radius: 0;

}

#sidebar.minified .version .num-version {
    display: none;
}

@media (max-width: 992px) {

    #sidebar .logo {
        width: auto;
        margin-left: 1rem;
    }

    #sidebar .logo img {
        width: 30px;
    }
}
</style>