import api from './api';
import HotelService from "./hotel.service";

class CreditCardService { 
    

    /**************************************
     *  API HOSPEDIUM
     **************************************/
    
    async createCreditCard(data) {
        let hotel = HotelService.getLocalHotel();
        var params = {
            hotel: hotel.id
        }
        return await api.post('/cards/paynopain/create', data, {params: params})
        .then((response) => {
            return {status: response.status, data: response.data};
        })
        .catch(function (error) {
            return {status: error.response.status, data: error.response.data};
        });
    }

    async deleteCreditCard(data) {
        let hotel = HotelService.getLocalHotel();
        var params = {
            hotel: hotel.id
        }
        return await api.post('/cards/paynopain/delete', data, {params: params})
        .then((response) => {
            return {status: response.status, data: response.data};
        })
        .catch(function (error) {
            return {status: error.response.status, data: error.response.data};
        });
    }


}

export default new CreditCardService();