<template>
    <div class="page-app">
        <div class="row pt-2">
            <div class="col-md-4 col-lg-3 col-xl-2 px-3">
                <SidebarMenu />
            </div>
            <div class="col-md-8 col-lg-9 col-xl-10">
                <div class="d-lg-flex justify-content-between align-items-center mb-3">
                    <h1>Cobros</h1>
                    <div class="d-lg-flex">
                        <MazSelect v-model="paymentTypeSelected" label="Tipo de pago" :options="paymentTypeOptions" class="me-md-2 mb-2 mb-md-0" />
                        <MazSelect v-model="userSelected" label="Usuario" :options="userOptions" class="me-md-2 mb-2 mb-md-0" />
                        <DatePicker v-model="range" mode="date" :masks="masks" is-range>
                            <template v-slot="{ inputValue, inputEvents, isDragging }">
                                <div class="custom-calendar-input d-flex justify-content-center align-items-center bg-white btn me-2">
                                    <div class="input-group flex-grow-0" style="width: auto">
                                        <span class="input-group-text border-0 bg-white text-primary ps-0"><i class="far fa-calendar-week"></i></span>
                                        <input class="flex-grow-0 border-0" :class="isDragging
                            ? 'text-gray-600'
                            : 'text-gray-900'
                            " :value="inputValue.start" v-on="inputEvents.start" style="
												width: 105px;
												cursor: pointer;
											" />
                                    </div>
                                    <span class="me-2 flex-grow-0">
                                        <i class="fas fa-long-arrow-alt-right stroke-current text-gray-600"></i>
                                    </span>
                                    <div class="input-group flex-grow-0" style="width: auto">
                                        <span class="input-group-text border-0 bg-white text-primary"><i class="far fa-calendar-week"></i></span>
                                        <input class="flex-grow-0 border-0" :class="isDragging
                            ? 'text-gray-600'
                            : 'text-gray-900'
                            " :value="inputValue.end" v-on="inputEvents.end" style="
												width: 105px;
												cursor: pointer;
											" />
                                    </div>
                                </div>
                            </template>
                        </DatePicker>

                        <div class="" data-bs-toggle="modal" data-bs-target="#modalReportingCheckin">
                            <button class="btn bg-white text-primary custom-export-btn h-100" data-bs-toggle="tooltip" data-bs-placement="top" title="Exportar" @click="exportReport()">
                                <i class="fa-regular fa-arrow-down-to-line"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="card border-0 shadow-sm mb-3" style="min-height: 100px;">
                    <div class="card-body p-3">
                        <div v-if="!loading" class="table-responsive scrollbar">
                            <h6 class="px-3">
                                {{ `Listado de cobros realizados entre las fechas: ${formatDate(range.start)} y ${formatDate(range.end)}` }}
                            </h6>
                            <h6 class="px-3">
                                Total: {{ formatCurrency(data.total, defaultCurrency) }}
                            </h6>
                            <EasyDataTable v-if="items.length > 0" :headers="headers" :items="items" :server-items-length="serverItemsLength" v-model:server-options="serverOptions" :rows-per-page="10"
                                :loading="loadingData" rows-per-page-message="resultados por página" rows-of-page-separator-message="de" :rows-items="[10, 25, 50, 100]"
                                table-class-name="customize-table report-table">

                                <template #item-operation="item">
                                    <div class="operation-wrapper">
                                        <div class="d-flex align-items-center">
                                            <RouterLink :to="'/invoices/' + item.invoice.id" class="btn btn-primary me-2">
                                                <i class="fa-regular fa-eye"></i>
                                            </RouterLink>
                                        </div>
                                    </div>
                                </template>

                                <template #item-date="item">
                                    <div style="min-width: 90px">
                                        {{ item.date }}
                                    </div>
                                </template>

                                <template #item-invoice="item">
                                    <div>
                                        {{ item.invoice.num }}
                                    </div>
                                </template>

                                <template #item-booking="item">
                                    <RouterLink v-if="item.booking" :to="'/bookings/view/' + item.booking.id"> <span class="badge badge-secondary">{{ `#${item.booking.id}` }}</span>
                                    </RouterLink>
                                </template>

                                <template #item-quantity="item">
                                    <div>
                                        {{ formatCurrency(item.quantity) }}
                                    </div>
                                </template>
                            </EasyDataTable>
                            <div v-if="loadingData" id="spinner-search" class="col-12 text-center py-5">
                                <div class="spinner-border text-primary" role="status" style="width: 2rem; height: 2rem; visibility: visble;">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                                <div>Cargando información...</div>
                            </div>

                            <div v-else-if="items.length == 0" class="text-center py-5">
                                <div>
                                    <i class="fa-regular fa-circle-info fa-2x text-primary mb-3"></i>
                                </div>
                                No hay información para la fecha seleccionada

                            </div>
                        </div>
                        <div v-else id="spinner-search" class="col-12 text-center py-5">
                            <div class="spinner-border text-primary" role="status" style="
									width: 2rem;
									height: 2rem;
									visibility: visble;
								">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                            <div>Cargando información...</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal -->
        <div class="modal fade" id="modalReportingCheckin" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Listado de cobros</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <embed v-if="pdf != null" :src="pdf" frameborder="0" width="100%" height="550px" />
                        <div v-else id="spinner-search" class="col-12 text-center py-5">
                            <div class="spinner-border text-primary" role="status" style="
									width: 2rem;
									height: 2rem;
									visibility: visble;
								">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                            <div>Cargando información...</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SidebarMenu from "../../components/reporting/SidebarMenu.vue";
import PaymentMethodService from "@/services/paymentmethod.service";
import ReportingService from "../../services/reporting.service";
import UserService from "@/services/user.service";

export default {
    name: "ReportingPayments",
    components: {
        SidebarMenu: SidebarMenu,
    },
    data() {
        return {
            loading: true,
            loadingData: true,
            pdf: null,
            data: [],
            range: {
                start: new Date(new Date().setDate(new Date().getDate() - 15)),
                end: new Date(),
            },
            masks: {
                input: "YYYY-MM-DD",
            },
            serverOptions: {
                page: 1,
                rowsPerPage: 10,
                sortBy: "date",
                sortType: "asc",
            },
            headers: [],
            items: [],
            serverItemsLength: 0,
            userSelected: null,
            userOptions: [],
            paymentTypeSelected: null,
            paymentTypeOptions: [],
        };
    },
    watch: {
        range: {
            handler: function () {
                this.start();
            },
            deep: true,
        },
        serverOptions(value) {
            localStorage.setItem("report-payments", JSON.stringify(this.serverOptions))
            this.getData();
        },
        paymentTypeSelected(value) {
            this.getData();
        },
        userSelected(value) {
            this.getData();
        },
    },
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },
    },
    methods: {
        async start() {
            this.loading = true;
            if (localStorage.getItem("report-payments")) {
                this.serverOptions = JSON.parse(localStorage.getItem("report-payments"))
            }
            await this.getAllPaymentTypes();
            await this.getAllHotelUsers();
            await this.getData();

            this.headers = [
                { text: "", value: "operation" },
                { text: "Día", value: "date", sortable: true },
                { text: "Tipo de pago", value: "paymentMethod", sortable: true },
                {
                    text: "Factura",
                    value: "invoice",
                    sortable: true,
                },
                { text: "Reserva", value: "booking", },
                { text: "Cantidad", value: "quantity", sortable: true },
                { text: "Creado por", value: "createdBy", sortable: true },
                { text: "Última modificación por", value: "updatedBy", sortable: true },
            ];
            this.loading = false;
        },

        async getData() {
            this.loadingData = true;
            this.data = []
            this.items = []
            var response = await ReportingService.getPaymemtsReportingByDate({
                startDate: this.formatDate(this.range.start),
                endDate: this.formatDate(this.range.end),
                page: this.serverOptions.page,
                rowsPerPage: this.serverOptions.rowsPerPage,
                sortBy: this.serverOptions.sortBy,
                sortType: this.serverOptions.sortType,
                paymentType: this.paymentTypeSelected,
                user: this.userSelected
            });
            this.data = response.data;
            this.serverItemsLength = response.data.serverItemsLength;
            this.items = response.data.data;
            console.log(this.items, "items");
            this.loadingData = false;
        },



        async getAllPaymentTypes() {
            const response = await PaymentMethodService.getAllPaymentMethod();
            this.paymentTypeOptions = [
                { label: 'Todos', value: null },
            ]
            if (response.status == 200) {
                var data = response.data;
                data.forEach(paymentType => {
                    this.paymentTypeOptions.push({
                        label: paymentType.name,
                        value: paymentType.id
                    });
                });
            }
        },

        async getAllHotelUsers() {
            const response = await UserService.getHotelUsers();
            this.userOptions = [
                { label: 'Todos', value: null },
            ]
            if (response.status == 200) {
                var data = response.data;
                data.forEach(user => {
                    this.userOptions.push({
                        label: user.username,
                        value: user.id
                    });
                });
            }
        },



        async exportReport() {
            this.pdf = null;
            var response = await ReportingService.getPaymentsReportingByDatePDF(
                {
                    startDate: this.formatDate(this.range.start),
                    endDate: this.formatDate(this.range.end),
                    sortBy: this.serverOptions.sortBy,
                    sortType: this.serverOptions.sortType,
                    paymentType: this.paymentTypeSelected,
                    user: this.userSelected
                }
            );
            this.pdf = response.data;
            console.log(this.pdf, "PDF");
        },

        formatDate(date, show) {
            var d = new Date(date),
                month = "" + (d.getMonth() + 1),
                day = "" + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2) month = "0" + month;
            if (day.length < 2) day = "0" + day;

            if (show) {
                return [day, month, year].join("/");
            }

            return [year, month, day].join("-");
        },

    },
    created() {
        this.start();
    },
    mounted() {
        if (!this.currentUser) {
            this.$router.push("/login");
        }
    },
};
</script>

<style>
#sales-chart {
    display: block;
    box-sizing: unset;
    max-height: 250px;
    min-width: 100%;
    width: auto;
    aspect-ratio: auto !important;
    overflow: auto !important;
    overflow-clip-margin: unset;
    height: auto;
}

.listings-menu-item {
    cursor: pointer;
}

.listings-menu-item:hover {
    background-color: #fff;
    border-radius: 4px;
}

.table-responsive.scrollbar {
    overflow-x: auto;
}

.table-responsive.scrollbar::-webkit-scrollbar {
    -webkit-appearance: none;
}

.table-responsive.scrollbar::-webkit-scrollbar:vertical {
    width: 12px;
}

.table-responsive.scrollbar::-webkit-scrollbar:horizontal {
    height: 12px;
}

.table-responsive.scrollbar::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    border: 2px solid #ffffff;
}

.table-responsive.scrollbar::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #ffffff;
}

.custom-calendar-input {
    width: auto;
    border: 0.063rem solid rgb(229 231 235);
    border-radius: 0.7rem;
    min-width: 320px;
}
</style>