<template>
    <div v-if="!loading">
        <button class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#modalAddInvoice"><i class="fas fa-plus me-2"></i>Nueva factura no alojado</button>

        <!-- Modal Add Type Invoice -->
        <div class="modal fade" id="modalAddInvoice" tabindex="-1" aria-labelledby="modalAddInvoice" aria-hidden="true">
            <div class="modal-dialog modal-xl  modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Añadir factura no alojado</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>

                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="mb-3 d-md-flex justify-content-between align-items-center">

                                    <h6 class="mb-3">1. Datos de facturación</h6>
                                    <div>
                                        <label>Este contacto es...</label>
                                        <div class="d-flex">
                                            <button type="button" class="btn btn-secondary" :class="{ 'active btn-primary': clientActive }" @click="changeClientType('client')"
                                                style="border-radius: 4px 0 0 4px;">
                                                <i class="fa-solid fa-person me-2"></i>
                                                <span>Cliente</span>
                                            </button>
                                            <button type="button" class="btn btn-secondary" :class="{ 'active btn-primary': companyActive }" @click="changeClientType('company')"
                                                style="border-radius: 0 4px 4px 0;">
                                                <i class="fa-solid fa-building me-2"></i>
                                                <span>Empresa</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="clientActive" class="mb-5">
                                    <div class="row mb-3 mx-0" style="background-color: #e3ebf7; color: #285192; padding: 10px; border-radius: 5px;">
                                        <div class="col-12 col-md-7">
                                            <input class="form-control" placeholder="Buscar..." v-model="search" @keyup="searchClient(search)" />

                                        </div>
                                        <div class="col-12 col-md-5">
                                            <button class="btn btn-sm btn-primary" @click.stop="reset('client')"><i class="fa-regular fa-user-plus me-2"></i>Nuevo
                                                cliente</button>
                                        </div>
                                        <div class="col-12">
                                            <ul v-if="suggestedClients != ''" class="invoice-suggested-clients shadow-sm" v-click-outside="onClickOutside">
                                                <li :key="c" v-for="c in suggestedClients" @click.stop="selectClient(c)">
                                                    <div class="d-flex">
                                                        <div v-if="c.id == addInvoice.client.id" class="clientSelected"><i class="fa-solid fa-user-check"></i>
                                                        </div>
                                                        <div>
                                                            <div>
                                                                {{ c.firstName + ' ' + c.lastName1 + ' '
        + c.lastName2 + ' (' + c.nif + ')' }}
                                                            </div>
                                                            <small>{{ c.email }}</small>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>

                                        <div v-if="addInvoice.client.id > 0" class="mt-3" style="font-weight: 500;">
                                            Se ha seleccionado el cliente <span class="text-uppercase">
                                                {{ addInvoice.client.firstName + ' ' +
        addInvoice.client.lastName1 + ' ' +
        addInvoice.client.lastName2 }}</span>. <span style="color: #0091ff; text-decoration: underline; cursor: pointer;" @click.stop="reset('client')">Resetear
                                                campos</span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="row g-3 align-items-center mb-2">
                                                <div class="col-12">
                                                    <MazInput id="client-firstName" v-model="addInvoice.client.firstName" label="Nombre" required class="text-uppercase" :error=errors.client.firstName>
                                                        <template #left-icon>
                                                            <MazIcon name="user" />
                                                        </template>
                                                    </MazInput>
                                                </div>
                                                <div class="col-md-6">
                                                    <MazInput id="client-lastName1" v-model="addInvoice.client.lastName1" label="Primer Apellido" required class="text-uppercase"
                                                        :error=errors.client.lastName1>
                                                        <template #left-icon>
                                                            <MazIcon name="user" />
                                                        </template>
                                                    </MazInput>
                                                </div>

                                                <div class="col-md-6">
                                                    <MazInput id="client-lastName2" v-model="addInvoice.client.lastName2" label="Segundo Apellido" class="text-uppercase">
                                                        <template #left-icon>
                                                            <MazIcon name="user" />
                                                        </template>
                                                    </MazInput>
                                                </div>
                                                <div class="col-12">
                                                    <MazInput id="client-email" v-model="addInvoice.client.email" label="Email" class="text-uppercase" type="email">
                                                        <template #left-icon>
                                                            <MazIcon name="mail" />
                                                        </template>
                                                    </MazInput>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="row g-3 align-items-center mb-2">
                                                <div class="col-12">
                                                    <MazInput id="client-nif" v-model="addInvoice.client.nif" label="NIF del contacto" class="text-uppercase" />
                                                </div>
                                                <div class="col-12">
                                                    <MazInput id="client-address" v-model="addInvoice.client.tax_address" label="Dirección" class="text-uppercase">
                                                        <template #left-icon>
                                                            <MazIcon name="map" />
                                                        </template>
                                                    </MazInput>
                                                </div>
                                                <div class="col-md-6">
                                                    <MazInput id="client-locality" v-model="addInvoice.client.tax_locality" label="Población" class="text-uppercase" />
                                                </div>
                                                <div class="col-md-6">
                                                    <MazInput id="client-tax_postal_code" v-model="addInvoice.client.tax_postal_code" label="Código Postal" class="text-uppercase" />
                                                </div>
                                                <div class="col-md-12">
                                                    <MazSelect id="client-tax_country" v-model="addInvoice.client.tax_country" label="Seleccionar país" :options="countries" option-value-key="id"
                                                        option-label-key="name" option-input-value-key="name" search search-placeholder="Buscar..." class="text-uppercase" required
                                                        :error=errors.client.tax_country>
                                                        <template #left-icon>
                                                            <MazIcon name="flag" />
                                                        </template>
                                                    </MazSelect>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="companyActive" class="mb-5">
                                    <div class="row mb-3 mx-0" style="background-color: #e3ebf7; color: #285192; padding: 10px; border-radius: 5px;">
                                        <div class="col-12 col-md-7">
                                            <input class="form-control" placeholder="Buscar..." v-model="search" @keyup="searchCompany(search)" />

                                        </div>
                                        <div class="col-12 col-md-5">
                                            <button class="btn btn-sm btn-primary" @click.stop="reset('company')"><i class="fa-regular fa-plus me-2"></i>Nueva
                                                empresa</button>
                                        </div>
                                        <div class="col-12">
                                            <ul v-if="suggestedCompanies != ''" class="invoice-suggested-clients shadow-sm" v-click-outside="onClickOutside">
                                                <li :key="c" v-for="c in suggestedCompanies" @click.stop="selectCompany(c)">
                                                    <div class="d-flex">
                                                        <div v-if="c.id == addInvoice.company.id" class="clientSelected"><i class="fa-solid fa-building-circle-check"></i>
                                                        </div>
                                                        <div>
                                                            <div>{{ c.social_reason + ' (' + c.cif +
        ')' }}</div>
                                                            <small>{{ c.email }}</small>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>

                                        <!-- <div v-if="addInvoice.company.id > 0" class="mt-3" style="font-weight: 500;">
                                            Se ha seleccionado la empresa <span class="text-uppercase">{{addInvoice.company.social_reason + ' - ' + addInvoice.company.cif }}</span>. <span style="color: #0091ff; text-decoration: underline; cursor: pointer;" @click.stop="reset('company')">Resetear campos</span>
                                        </div> -->
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="row g-3 align-items-center mb-2">
                                                <div class="col-12">
                                                    <MazInput id="company-social_reason" v-model="addInvoice.company.social_reason" label="Razón Social" class="text-uppercase" required
                                                        :error=errors.company.social_reason />
                                                </div>
                                                <div class="col-12">
                                                    <MazInput id="company-cif" v-model="addInvoice.company.cif" label="CIF" class="text-uppercase" required :error=errors.company.cif />
                                                </div>
                                                <div class="col-12">
                                                    <MazInput id="company-email" v-model="addInvoice.company.email" label="Email" class="text-uppercase" type="email">
                                                        <template #left-icon>
                                                            <MazIcon name="mail" />
                                                        </template>
                                                    </MazInput>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="row g-3 align-items-center mb-2">
                                                <div class="col-12">
                                                    <MazInput id="company-tax_address" v-model="addInvoice.company.tax_address" label="Dirección" class="text-uppercase">
                                                        <template #left-icon>
                                                            <MazIcon name="map" />
                                                        </template>
                                                    </MazInput>
                                                </div>
                                                <div class="col-md-6">
                                                    <MazInput id="company-locality" v-model="addInvoice.company.tax_locality" label="Población" class="text-uppercase" />
                                                </div>
                                                <div class="col-md-6">
                                                    <MazInput id="company-tax_postal_code" v-model="addInvoice.company.tax_postal_code" label="Código Postal" class="text-uppercase" />
                                                </div>
                                                <div class="col-md-12">
                                                    <MazSelect id="company-country" v-model="addInvoice.company.tax_country" label="Seleccionar país" :options="countries" option-value-key="id"
                                                        option-label-key="name" option-input-value-key="name" search search-placeholder="Buscar..." class="text-uppercase" required
                                                        :error=errors.company.tax_country>
                                                        <template #left-icon>
                                                            <MazIcon name="flag" />
                                                        </template>
                                                    </MazSelect>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group mb-4">

                                    <div class="mb-3">
                                        <h6 class="mb-3">2. Seleccionar tipo factura</h6>
                                        <div>
                                            <div class="d-flex">
                                                <button type="button" class="btn btn-secondary" :class="{ 'active btn-primary': invoiceNormalActive }" @click="changeInvoiceType('normal')"
                                                    style="border-radius: 4px 0 0 4px;">
                                                    <i class="fa-regular fa-file-invoice me-2"></i>
                                                    <span>Factura normal</span>
                                                </button>
                                                <button type="button" class="btn btn-secondary" :class="{ 'active btn-primary': invoiceProformaActive }" @click="changeInvoiceType('proforma')"
                                                    style="border-radius: 0 4px 4px 0;">
                                                    <i class="fa-solid fa-file-invoice-dollar me-2"></i>
                                                    <span>Factura proforma</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-link" data-bs-dismiss="modal">Cerrar</button>
                        <button id="submitAddInvoice" class="btn btn-primary" @click.prevent.stop="createInvoice()">Crear</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ClientService from "../../services/client.service"
import CompanyService from "../../services/company.service"
import CountryService from "../../services/country.service"
import InvoiceService from "../../services/invoice.service"



import Swal from 'sweetalert2'

export default {
    name: 'AddInvoice',
    data() {
        return {
            clientActive: true,
            companyActive: false,
            invoiceNormalActive: true,
            invoiceProformaActive: false,
            addInvoice: {},
            search: null,
            suggestedClients: [],
            suggestedCompanies: [],
            countries: [],
            clients: [],
            companies: [],
            countriesOptions: [],
            errors: [],
            loading: true,
            defaultCurrency: ''
        }
    },
    methods: {
        async start() {
            this.loading = true
            this.clientActive = true;
            this.companyActive = false;
            await this.resetModal();
            await this.getCountries();

            this.resetErrors();

            this.loading = false;
            // console.log(this.addInvoice)
        },
        onClickOutside(event) {
            // console.log('Clicked outside. Event: ', event)
            this.suggestedClients = []
            this.suggestedCompanies = []
            this.search = null;
        },
        changeClientType(key) {
            this.search = null;
            if (key == 'company') {
                this.companyActive = true;
                this.addInvoice.client_type = 'company';
                this.clientActive = false;
                this.reset("company")
            } else {
                this.clientActive = true;
                this.addInvoice.client_type = 'client';
                this.companyActive = false;
                this.reset("client")
            }
        },
        changeInvoiceType(key) {
            if (key == 'proforma') {
                this.invoiceNormalActive = false;

                this.invoiceProformaActive = true;
                this.addInvoice.isProforma = true;

                this.addInvoice.total_price = 0;
            } else {
                this.invoiceNormalActive = true;

                this.addInvoice.isProforma = false;
                this.invoiceProformaActive = false;

                this.addInvoice.total_price = 0
            }
        },
        async checkErrors() {
            var error = false

            // RESETAMOS LOS ERRORES
            this.resetErrors();

            if (this.addInvoice.client_type == 'client') {
                // COMPROBAMOS EL CAMPO firstName
                if (this.addInvoice.client.firstName == undefined || this.addInvoice.client.firstName == '') {
                    console.log("HOLA")
                    this.errors.client.firstName = true
                    error = true
                }

                // COMPROBAMOS EL CAMPO lastName1
                if (this.addInvoice.client.lastName1 == undefined || this.addInvoice.client.lastName1 == '') {
                    this.errors.client.lastName1 = true
                    error = true
                }

                // COMPROBAMOS EL CAMPO email
                if (this.addInvoice.client.email != undefined && this.addInvoice.client.email != '') {
                    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                    if (!emailPattern.test(this.addInvoice.client.email)) {
                        this.errors.client.email = true;
                        error = true;
                    }
                }

                // COMPROBAMOS EL CAMPO country
                if (this.addInvoice.client.tax_country == undefined || this.addInvoice.client.tax_country == '') {
                    this.errors.client.tax_country = true
                    error = true
                }
            } else if (this.addInvoice.client_type == 'company') {
                // COMPROBAMOS EL CAMPO social_reason
                if (this.addInvoice.company.social_reason == undefined || this.addInvoice.company.social_reason == '') {
                    this.errors.company.social_reason = true
                    error = true
                }

                // COMPROBAMOS EL CAMPO email
                if (this.addInvoice.company.email != undefined && this.addInvoice.company.email != '') {
                    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                    if (!emailPattern.test(this.addInvoice.company.email)) {
                        this.errors.company.email = true;
                        error = true;
                    }
                }


                // COMPROBAMOS EL CAMPO country
                if (this.addInvoice.company.tax_country == undefined || this.addInvoice.company.tax_country == '') {
                    this.errors.company.tax_country = true
                    error = true
                }
            }


            if (this.addInvoice.isAdvancePayment) {
                // COMPROBAMOS EL CAMPO advancePaymentPrice
                if (this.addInvoice.total_price == 0) {
                    this.errors.advancePaymentPrice = true
                    error = true
                }
            }

            return error;
        },

        resetErrors() {
            // RESETAMOS LOS ERRORES
            this.errors = {
                client: {
                    firstName: false,
                    lastName1: false,
                    tax_country: false
                },
                company: {
                    social_reason: false,
                    cif: false,
                    tax_country: false
                },
                advancePaymentPrice: false
            }
        },

        async createInvoice() {

            var error = await this.checkErrors();

            if (!error) {

                window.$("#submitAddInvoice").empty();
                window.$("#submitAddInvoice").append('<span class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>Guardando');


                var response = await InvoiceService.addInvoice(this.addInvoice)

                if (response.status == 201) {
                    window.$('#modalAddInvoice').hide()

                    Swal.fire({
                        icon: 'success',
                        title: 'Factura creada',
                        confirmButtonColor: '#0091ff'
                    }).then((result) => {
                        this.$router.push('/invoices/' + response.data.id);
                    })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Ha ocurrido un error. Vuelve a intentarlo. Si el error persiste ponte en contacto con soporte@hospedium.com',
                        confirmButtonColor: '#0091ff'
                    });
                }

                window.$("#submitAddInvoice").empty();
                window.$("#submitAddInvoice").append('Guardar');
            }
        },


        formatDate(date) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            return [year, month, day].join('-');
        },


        async getAllClients(search) {
            var response = await ClientService.searchClients({ search: search });
            this.clients = response.data;
            // console.log(this.clients, "clients")
        },

        async searchClient(search) {
            if (search != null) {
                await this.getAllClients(search)
                this.suggestedClients = this.clients;
            }
        },
        selectClient(client) {
            this.addInvoice.client = client;
            this.search = null;
            this.suggestedClients = []
        },

        async getAllCompanies(search) {
            var response = await CompanyService.searchCompanies({ search: search });
            this.companies = response.data;
            // console.log(this.companies, "companies")
        },
        async searchCompany(search) {
            if (search != null) {
                await this.getAllCompanies(search)
                this.suggestedCompanies = this.companies;
            }
        },
        selectCompany(company) {
            this.addInvoice.company = company;
            this.search = null;
            this.suggestedCompanies = []
        },
        resetModal() {
            this.clientActive = true;
            this.companyActive = false;
            this.addInvoice = {
                client_type: 'client',
                client: {
                    firstName: '',
                    lastName1: '',
                    lastName2: '',
                    email: '',
                    tax_address: '',
                    tax_locality: '',
                    tax_postal_code: '',
                    tax_country: '',
                    nif: '',
                },
                company: {
                    social_reason: '',
                    cif: '',
                    email: '',
                    tax_address: '',
                    tax_locality: '',
                    tax_postal_code: '',
                    tax_country: '',
                },
                charges: [],
                total_price: 0,
            }
        },

        reset(key) {
            this.search = null;
            if (key == 'client') {
                this.addInvoice.client = {
                    id: 0,
                    firstName: '',
                    lastName1: '',
                    lastName2: '',
                    email: '',
                    tax_address: '',
                    tax_locality: '',
                    tax_postal_code: '',
                    tax_country: '',
                    nif: '',
                }
            }

            if (key == 'company') {
                this.addInvoice.company = {
                    id: 0,
                    social_reason: '',
                    cif: '',
                    email: '',
                    tax_address: '',
                    tax_locality: '',
                    tax_postal_code: '',
                    tax_country: '',
                }
            }
        },

        async getCountries() {
            var response = await CountryService.getAllCountries();
            this.countries = response;
            // console.log(this.countries, "countries")
        },


    },
    mounted() {
        this.defaultCurrency = localStorage.getItem('currency') || 'EUR';
        this.start();
    }
}
</script>