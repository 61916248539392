<template>
    <div v-if="!loading">
        <button class="btn btn btn-primary" style="width: max-content" data-bs-toggle="modal" data-bs-target="#modalAddInvoice">
            <i class="far fa-plus" aria-hidden="true"></i><span class="d-none d-md-inline-block ms-1">Añadir Factura</span>
        </button>

        <!-- Modal Add Invoice -->
        <div class="modal fade" id="modalAddInvoice" tabindex="-1" aria-labelledby="modalAddInvoice" aria-hidden="true">
            <div class="modal-dialog modal-xl modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-header border-0">
                        <h5 class="modal-title">Crear nueva factura</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body px-4 pt-0">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="mb-3 d-md-flex justify-content-between align-items-center">
                                    <h6 class="mb-3">
                                        1. Datos de facturación
                                    </h6>
                                    <div>
                                        <label>Este contacto es...</label>
                                        <div class="d-flex">
                                            <button type="button" class="btn btn-secondary" :class="{ 'active btn-primary': clientActive }" @click=" changeClientType('client')"
                                                style="border-radius: 4px 0 0 4px;">
                                                <i class="fa-solid fa-person me-2"></i>
                                                <span>Cliente</span>
                                            </button>
                                            <button type="button" class="btn btn-secondary" :class="{ 'active btn-primary': companyActive }" @click="changeClientType('company')" style="
													border-radius: 0 4px 4px 0;
												">
                                                <i class="fa-solid fa-building me-2"></i>
                                                <span>Empresa</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="clientActive" class="mb-5">
                                    <div class="row mb-3 mx-0" style="
											background-color: #e3ebf7;
											color: #285192;
											padding: 10px;
											border-radius: 5px;
										">
                                        <div class="col-12 col-md-7">
                                            <input class="form-control" placeholder="Buscar..." v-model="search" @keyup="searchClient(search)" />
                                        </div>
                                        <div class="col-12 col-md-5">
                                            <button class="btn btn-sm btn-primary me-2" @click.stop="searchGuests()">
                                                <i class="fa-regular fa-users-viewfinder me-2"></i>Huéspedes
                                            </button>
                                            <button class="btn btn-sm btn-primary" @click.stop="reset('client')">
                                                <i class="fa-regular fa-user-plus me-2"></i>Nuevo
                                                cliente
                                            </button>
                                        </div>
                                        <div class="col-12">
                                            <ul v-if="suggestedClients != ''" class="invoice-suggested-clients shadow-sm" v-click-outside="onClickOutside">
                                                <li :key="c" v-for="c in suggestedClients" @click.stop="
        selectClient(c)
        ">
                                                    <div class="d-flex">
                                                        <div v-if="c.id ==
        addInvoice
            .client.id
        " class="clientSelected">
                                                            <i class="fa-solid fa-user-check"></i>
                                                        </div>
                                                        <div>
                                                            <div>
                                                                {{
        c.firstName +
        " " +
        c.lastName1 +
        " " +
        c.lastName2 +
        " (" +
        c.nif +
        ")"
    }}
                                                            </div>
                                                            <small>{{
            c.email
        }}</small>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>

                                        <div v-if="addInvoice.client.id > 0" class="mt-3" style="font-weight: 500">
                                            Se ha seleccionado el cliente
                                            <span class="text-uppercase">{{
        addInvoice.client.firstName +
        " " +
        addInvoice.client.lastName1 +
        " " +
        addInvoice.client.lastName2
    }}</span>.
                                            <span style="
													color: #0091ff;
													text-decoration: underline;
													cursor: pointer;
												" @click.stop="reset('client')">Resetear campos</span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="row g-3 align-items-center mb-2">
                                                <div class="col-12">
                                                    <MazInput id="client-firstName" v-model="addInvoice.client
        .firstName
        " label="Nombre" required class="text-uppercase" :error="errors.client
        .firstName
        ">
                                                        <template #left-icon>
                                                            <MazIcon name="user" />
                                                        </template>
                                                    </MazInput>
                                                </div>
                                                <div class="col-md-6">
                                                    <MazInput id="client-lastName1" v-model="addInvoice.client
        .lastName1
        " label="Primer Apellido" required class="text-uppercase" :error="errors.client
        .lastName1
        ">
                                                        <template #left-icon>
                                                            <MazIcon name="user" />
                                                        </template>
                                                    </MazInput>
                                                </div>

                                                <div class="col-md-6">
                                                    <MazInput id="client-lastName2" v-model="addInvoice.client
        .lastName2
        " label="Segundo Apellido" class="text-uppercase">
                                                        <template #left-icon>
                                                            <MazIcon name="user" />
                                                        </template>
                                                    </MazInput>
                                                </div>
                                                <div class="col-12">
                                                    <MazInput id="client-email" v-model="addInvoice.client
        .email
        " label="Email" class="text-uppercase" type="email" :error="errors.client.email">
                                                        <template #left-icon>
                                                            <MazIcon name="mail" />
                                                        </template>
                                                    </MazInput>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="row g-3 align-items-center mb-2">
                                                <div class="col-12">
                                                    <MazInput id="client-nif" v-model="addInvoice.client
        .nif
        " label="NIF del contacto" class="text-uppercase" />
                                                </div>
                                                <div class="col-12">
                                                    <MazInput id="client-address" v-model="addInvoice.client
        .tax_address
        " label="Dirección" class="text-uppercase">
                                                        <template #left-icon>
                                                            <MazIcon name="map" />
                                                        </template>
                                                    </MazInput>
                                                </div>
                                                <div class="col-md-6">
                                                    <MazInput id="client-locality" v-model="addInvoice.client
        .tax_locality
        " label="Población" class="text-uppercase" />
                                                </div>
                                                <div class="col-md-6">
                                                    <MazInput id="client-tax_postal_code" v-model="addInvoice.client
        .tax_postal_code
        " label="Código Postal" class="text-uppercase" />
                                                </div>
                                                <div class="col-md-12">
                                                    <MazSelect id="client-tax_country" v-model="addInvoice.client
        .tax_country
        " label="Seleccionar país" :options="countries" option-value-key="id" option-label-key="name" option-input-value-key="name" search search-placeholder="Buscar..." class="text-uppercase"
                                                        required :error="errors.client
        .tax_country
        ">
                                                        <template #left-icon>
                                                            <MazIcon name="flag" />
                                                        </template>
                                                    </MazSelect>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="companyActive" class="mb-5">
                                    <div class="row mb-3 mx-0" style="
											background-color: #e3ebf7;
											color: #285192;
											padding: 10px;
											border-radius: 5px;
										">
                                        <div class="col-12 col-md-7">
                                            <input class="form-control" placeholder="Buscar..." v-model="search" @keyup="searchCompany(search)" />
                                        </div>
                                        <div class="col-12 col-md-5">
                                            <button class="btn btn-sm btn-primary" @click.stop="reset('company')">
                                                <i class="fa-regular fa-plus me-2"></i>Nueva empresa
                                            </button>
                                        </div>
                                        <div class="col-12">
                                            <ul v-if="suggestedCompanies != ''" class="invoice-suggested-clients shadow-sm" v-click-outside="onClickOutside">
                                                <li :key="c" v-for="c in suggestedCompanies" @click.stop="
        selectCompany(c)
        ">
                                                    <div class="d-flex">
                                                        <div v-if="c.id ==
        addInvoice
            .company.id
        " class="clientSelected">
                                                            <i class="fa-solid fa-building-circle-check"></i>
                                                        </div>
                                                        <div>
                                                            <div>
                                                                {{
        c.social_reason +
        " (" +
        c.cif +
        ")"
    }}
                                                            </div>
                                                            <small>{{
            c.email
        }}</small>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>

                                        <div v-if="addInvoice.company.id > 0" class="mt-3" style="font-weight: 500">
                                            Se ha seleccionado la empresa
                                            <span class="text-uppercase">{{
        addInvoice.company
            .social_reason +
        " - " +
        addInvoice.company.cif
    }}</span>.
                                            <span style="
													color: #0091ff;
													text-decoration: underline;
													cursor: pointer;
												" @click.stop="reset('company')">Resetear campos</span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="row g-3 align-items-center mb-2">
                                                <div class="col-12">
                                                    <MazInput id="company-social_reason" v-model="addInvoice.company
        .social_reason
        " label="Razón Social" class="text-uppercase" required :error="errors.company
        .social_reason
        " />
                                                </div>
                                                <div class="col-12">
                                                    <MazInput id="company-cif" v-model="addInvoice.company
        .cif
        " label="CIF" class="text-uppercase" required :error="errors.company.cif
        " />
                                                </div>
                                                <div class="col-12">
                                                    <MazInput id="company-email" v-model="addInvoice.company
        .email
        " label="Email" class="text-uppercase" type="email" :error="errors.company.email">
                                                        <template #left-icon>
                                                            <MazIcon name="mail" />
                                                        </template>
                                                    </MazInput>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="row g-3 align-items-center mb-2">
                                                <div class="col-12">
                                                    <MazInput id="company-tax_address" v-model="addInvoice.company
        .tax_address
        " label="Dirección" class="text-uppercase">
                                                        <template #left-icon>
                                                            <MazIcon name="map" />
                                                        </template>
                                                    </MazInput>
                                                </div>
                                                <div class="col-md-6">
                                                    <MazInput id="company-locality" v-model="addInvoice.company
        .tax_locality
        " label="Población" class="text-uppercase" />
                                                </div>
                                                <div class="col-md-6">
                                                    <MazInput id="company-tax_postal_code" v-model="addInvoice.company
        .tax_postal_code
        " label="Código Postal" class="text-uppercase" />
                                                </div>
                                                <div class="col-md-12">
                                                    <MazSelect id="company-country" v-model="addInvoice.company
        .tax_country
        " label="Seleccionar país" :options="countries" option-value-key="id" option-label-key="name" option-input-value-key="name" search search-placeholder="Buscar..." class="text-uppercase"
                                                        required :error="errors.company
        .tax_country
        ">
                                                        <template #left-icon>
                                                            <MazIcon name="flag" />
                                                        </template>
                                                    </MazSelect>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group mb-4">
                                    <div class="mb-3 d-md-flex justify-content-between align-items-center">
                                        <h6 class="mb-3">
                                            2. Seleccionar cargos de la factura
                                        </h6>
                                        <div>
                                            <label>Esta factura es...</label>
                                            <div class="d-flex">
                                                <button type="button" class="btn btn-secondary" :class="{
        'active btn-primary':
            invoiceNormalActive,
    }" @click="
        changeInvoiceType(
            'normal'
        )
        " style="
														border-radius: 4px 0 0
															4px;
													">
                                                    <i class="fa-regular fa-file-invoice me-2"></i>
                                                    <span>Factura normal</span>
                                                </button>
                                                <button type="button" class="btn btn-secondary" :class="{
        'active btn-primary':
            invoiceAdvancePaymentActive,
    }" @click="
        changeInvoiceType(
            'advancePayment'
        )
        " style="
														border-radius: 0 4px 4px
															0;
													">
                                                    <i class="fa-solid fa-file-invoice-dollar me-2"></i>
                                                    <span>Factura anticipo</span>
                                                </button>
                                                <button type="button" class="btn btn-secondary" :class="{
        'active btn-primary':
            invoiceProformaActive,
    }" @click="
        changeInvoiceType(
            'proforma'
        )
        " style="
														border-radius: 0 4px 4px
															0;
													">
                                                    <i class="fa-solid fa-file-invoice-dollar me-2"></i>
                                                    <span>Factura proforma</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <div v-if="addInvoice.isAdvancePayment" class="col-12 row">
                                        <div class="col-6">
                                            <MazInput v-model="addInvoice.advancePaymentDescription
        " label="Descripción anticipo" class="text-uppercase">
                                            </MazInput>
                                        </div>

                                        <div class="col-6">
                                            <MazInputPrice id="advancePaymentPrice" v-model="addInvoice.total_price" class="text-uppercase" label="Introduce una cantidad" :currency="defaultCurrency"
                                                locale="es-ES" :error="errors.advancePaymentPrice
        " />
                                        </div>
                                    </div>
                                    <div v-else>
                                        <p>
                                            <small>Selecciona los cargos de la
                                                reserva que quieras añadir a la
                                                factura</small>
                                        </p>
                                        <div class="list-group mb-4">
                                            <label id="selectAllCharges" class="list-group-item">
                                                <div class="d-flex align-items-center">
                                                    <input class="form-check-input m-0" type="checkbox" style="font-size: 1.3rem;" @change="selectAllCharges()" />
                                                    <div class="d-flex align-items-center justify-content-between ms-3">
                                                        Seleccionar todos
                                                    </div>
                                                </div>
                                            </label>
                                        </div>
                                        <div :key="BR" v-for="BR in charges" class="mb-4 invoice-add-charges">
                                            <h6 class="mb-3">
                                                {{ `${BR.roomType_name}: ${BR.room_name} (${formatCurrency(BR.totalPrice, defaultCurrency)})` }}
                                                <span v-if="BR.status === 7" class="badge badge-danger ms-2">Habitación cancelada</span>
                                            </h6>
                                            <div id="addInvoiceChargesTable" class="table-responsive">
                                                <table class="table table-row-dashed">
                                                    <thead>
                                                        <tr>
                                                            <th></th>
                                                            <th>Fecha</th>
                                                            <th>Tipo de cargo</th>
                                                            <th>Descripción</th>
                                                            <th>Subtotal</th>
                                                            <th>Impuestos</th>
                                                            <th>Total</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr :id="`charge-${charge.id}`" :disabled="charge.availableToInvoice === false && !invoiceProformaActive" :key="charge"
                                                            v-for="charge in BR.charges">
                                                            <td style="">
                                                                <input class="form-check-input m-0" type="checkbox" style="font-size: 1.3rem;" @change="addCharge(charge)"
                                                                    :disabled="charge.availableToInvoice === false && !invoiceProformaActive"
                                                                    :checked="charge.availableToInvoice === false && !invoiceProformaActive" />
                                                            </td>
                                                            <td>{{ charge.date }}</td>
                                                            <td>
                                                                <span v-if="charge.isAccommodation">Alojamiento</span>
                                                                <span v-else>Extra</span>
                                                            </td>
                                                            <td>{{ charge.description }}</td>
                                                            <td>{{ formatCurrency(charge.baserate_amount, defaultCurrency) }}</td>
                                                            <td>
                                                                <span v-if="booking.show_taxes">{{ formatCurrency(charge.tax_amount, defaultCurrency) }}</span>
                                                                <span v-else><em>Sin impuestos</em></span>

                                                            </td>
                                                            <td>
                                                                <span v-if="booking.show_taxes">{{ formatCurrency(charge.total_amount, defaultCurrency) }}</span>
                                                                <span v-else>{{ formatCurrency(charge.baserate_amount, defaultCurrency) }}</span>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div v-if="chargesTaxes.length > 0" class="invoice-add-charges">
                                            <p>
                                                <small>Selecciona los impuestos
                                                    que quieras añadir a la
                                                    factura</small>
                                            </p>
                                            <div class="list-group mb-2">
                                                <label :id="'charge-' + charge.id" class="list-group-item" :disabled="charge.chargesTaxes ==
        false &&
        !invoiceProformaActive
        " :key="charge" v-for="charge in chargesTaxes">
                                                    <div class="d-flex align-items-center">
                                                        <input class="form-check-input m-0" type="checkbox" style="
																font-size: 1.3rem;
															" @change="
        addCharge(
            charge
        )
        " :disabled="charge.availableToInvoice ==
        false &&
        !invoiceProformaActive
        " :checked="charge.availableToInvoice ==
        false &&
        !invoiceProformaActive
        " />
                                                        <div class="d-flex align-items-center justify-content-between ms-3">
                                                            <div style="
																	width: 80px;
																">
                                                                {{
        charge.date
    }}
                                                            </div>
                                                            <i class="fa-regular fa-arrow-right-long mx-2"></i>
                                                            <span>{{
            charge.description +
                                                                " (" +
                                                                formatCurrency(
                                                                charge.price,
                                                                defaultCurrency
                                                                ) +
                                                                ")"
                                                                }}</span>
                                                        </div>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div v-if="chargesAdvancePayments.length >
        0
        " class="invoice-add-charges">
                                            <p>
                                                <small>Selecciona los anticipos
                                                    que quieras añadir a la
                                                    factura</small>
                                            </p>
                                            <div class="list-group mb-2">
                                                <label :id="'charge-' + charge.id" class="list-group-item" :disabled="charge.availableToInvoice ==
        false &&
        !invoiceProformaActive
        " :key="charge" v-for="charge in chargesAdvancePayments">
                                                    <div class="d-flex align-items-center">
                                                        <input class="form-check-input m-0" type="checkbox" style="
																font-size: 1.3rem;
															" @change="
        addCharge(
            charge
        )
        " :disabled="charge.availableToInvoice ==
        false &&
        !invoiceProformaActive
        " :checked="charge.availableToInvoice ==
        false &&
        !invoiceProformaActive
        " />
                                                        <div class="d-flex align-items-center justify-content-between ms-3">
                                                            <div style="
																	width: 80px;
																">
                                                                {{
                                                                charge.date
                                                                }}
                                                            </div>
                                                            <i class="fa-regular fa-arrow-right-long mx-2"></i>
                                                            <span>{{
                                                                "ANTICIPO: " +
                                                                charge.description +
                                                                " (" +
                                                                formatCurrency(
                                                                charge.price,
                                                                defaultCurrency
                                                                ) +
                                                                ")"
                                                                }}</span>
                                                        </div>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer d-flex align-items-center justify-content-between">
                        <h4>
                            Total:
                            {{ formatCurrency(addInvoice.total_price, defaultCurrency) }}
                        </h4>
                        <button id="submitAddInvoice" type="submit" class="btn btn-primary" @click.stop.prevent="createInvoice">
                            Crear factura
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
.invoice-suggested-clients {
    position: relative;
    text-transform: uppercase;
    width: 100%;
    height: auto;
    max-height: 150px;
    overflow: auto;
    background: white;
    padding: 5px;
}

.invoice-suggested-clients li {
    cursor: pointer;
    border-bottom: 1px solid #eee;
    padding: 5px;
    font-weight: 500;
}

.invoice-suggested-clients li:hover {
    background: #f3f3f3;
}

.invoice-suggested-clients .clientSelected {
    width: 35px;
    height: 35px;
    text-align: center;
    background: #0091ff;
    line-height: 35px;
    border-radius: 50px;
    color: white;
    margin-right: 10px;
}

.invoice-alert .link {
    cursor: pointer;
    text-decoration: underline;
}

.invoice-alert .link:hover {
    opacity: 0.8;
}

.invoice-add-charges label.list-group-item {
    cursor: pointer;
}

.invoice-add-charges label[disabled="true"] {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff;
    cursor: default;
}


#addInvoiceChargesTable th {
    text-transform: uppercase;
    font-weight: 500 !important;
    font-size: 12px;
}

#addInvoiceChargesTable td {
    border: none;
    font-size: 12px;
}

#addInvoiceChargesTable .table:not(.table-bordered).table-row-dashed tr {
    border-bottom-width: 1px;
    border-bottom-style: dashed;
    border-bottom-color: var(--bs-border-color);
}
</style>

<script>
import ClientService from "../../services/client.service";
import CompanyService from "../../services/company.service";
import ChargeService from "../../services/charge.service";
import BookingService from "../../services/booking.service";
import InvoiceService from "../../services/invoice.service";

import Swal from "sweetalert2";

export default {
    name: "AddInvoice",
    props: {
        booking: Object,
        countries: Array,
        guests: Array,
    },
    components: {},
    data() {
        return {
            clientActive: true,
            companyActive: false,
            invoiceNormalActive: true,
            invoiceAdvancePaymentActive: false,
            invoiceProformaActive: false,
            addInvoice: {},
            bookingRooms: [],
            charges: [],
            chargesTaxes: [],
            chargesAdvancePayments: [],
            search: null,
            suggestedClients: [],
            suggestedCompanies: [],
            clients: [],
            companies: [],
            errors: [],
            loading: true,
            defaultCurrency: ''
        };
    },
    methods: {
        async start() {
            this.loading = true;
            this.clientActive = true;
            this.companyActive = false;
            await this.resetModal();
            await this.getBookingCharges();
            await this.getBookingChargesTaxes();
            await this.getBookingChargesAdvancePayment();
            if (
                typeof this.guests !== "undefined" &&
                this.guests != "" &&
                this.guests.length > 0
            ) {
                this.addInvoice.client = {
                    id: this.guests[0].client_hotel.id,
                    firstName: this.guests[0].client_hotel.firstName,
                    lastName1: this.guests[0].client_hotel.lastName1,
                    lastName2: this.guests[0].client_hotel.lastName2,
                    email: this.guests[0].client_hotel.email,
                    tax_address: this.guests[0].client_hotel.tax_address,
                    tax_locality: this.guests[0].client_hotel.tax_locality,
                    tax_postal_code: this.guests[0].client_hotel.tax_postal_code,
                    tax_country: this.guests[0].client_hotel.tax_country,
                    nif: this.guests[0].client_hotel.nif,
                };
            }

            this.resetErrors();

            this.loading = false;
            // console.log(this.addInvoice)
        },

        onClickOutside(event) {
            // console.log('Clicked outside. Event: ', event)
            this.suggestedClients = [];
            this.suggestedCompanies = [];
            this.search = null;
        },

        changeClientType(key) {
            this.search = null;
            if (key == "company") {
                this.companyActive = true;
                this.addInvoice.client_type = "company";
                this.clientActive = false;
                this.reset("company");
            } else {
                this.clientActive = true;
                this.addInvoice.client_type = "client";
                this.companyActive = false;
                this.reset("client");
            }
        },

        changeInvoiceType(key) {
            this.addInvoice.total_price = 0;
            this.addInvoice.charges = [];
            // Reseteamos cargos
            this.resetSelectedCharges();

            if (key == "advancePayment") {
                this.addInvoice.isAdvancePayment = true;
                this.invoiceAdvancePaymentActive = true;

                this.invoiceNormalActive = false;

                this.invoiceProformaActive = false;
                this.addInvoice.isProforma = false;
            } else if (key == "proforma") {
                this.addInvoice.isAdvancePayment = false;
                this.invoiceAdvancePaymentActive = false;

                this.invoiceNormalActive = false;

                this.invoiceProformaActive = true;
                this.addInvoice.isProforma = true;
            } else {
                this.addInvoice.isAdvancePayment = false;
                this.invoiceAdvancePaymentActive = false;

                this.invoiceNormalActive = true;

                this.addInvoice.isProforma = false;
                this.invoiceProformaActive = false;
            }
        },

        async getBookingCharges() {
            var response = await BookingService.getBookingCharges({
                booking: this.booking.id,
            });
            this.charges = response.data;
            // console.log(this.charges, "charges")
        },

        async getBookingChargesTaxes() {
            var response = await BookingService.getBookingChargesTaxes({
                booking: this.booking.id,
            });
            this.chargesTaxes = response.data;
            // console.log(this.chargesTaxes, "chargesTaxes")
        },

        async getBookingChargesAdvancePayment() {
            var response = await BookingService.getBookingChargesAdvancePayment(
                { booking: this.booking.id }
            );
            this.chargesAdvancePayments = response.data;
            // console.log(this.chargesAdvancePayments, "chargesAdvancePayments")
        },

        resetSelectedCharges() {
            this.addInvoice.total_price = 0;
            this.addInvoice.charges = [];
            window.$("#selectAllCharges input").prop("checked", false);
            for (var i = 0; i < this.charges.length; i++) {
                for (var j = 0; j < this.charges[i].charges.length; j++) {
                    var item = this.charges[i].charges[j];
                    if (
                        item.availableToInvoice == true ||
                        this.invoiceProformaActive
                    ) {
                        window
                            .$("#charge-" + item.id + " input")
                            .prop("checked", false);
                    }
                }
            }
        },

        selectAllCharges() {
            var input = window.$("#selectAllCharges input");
            if (input.is(":checked")) {
                this.addInvoice.total_price = 0;
                this.addInvoice.charges = [];
                for (var i = 0; i < this.charges.length; i++) {
                    for (var j = 0; j < this.charges[i].charges.length; j++) {
                        var item = this.charges[i].charges[j];
                        if (
                            item.availableToInvoice == true ||
                            this.invoiceProformaActive
                        ) {
                            window
                                .$("#charge-" + item.id + " input")
                                .prop("checked", true);
                            this.addCharge(item);
                        }
                    }
                }
            } else {
                this.resetSelectedCharges();
            }
        },

        addCharge(item) {
            var input = window.$("#charge-" + item.id + " input");
            if (input.is(":checked")) {
                this.addInvoice.charges.push(item.id);
                if (item.isAdvancePayment) {
                    this.addInvoice.total_price -= item.price;
                } else {
                    this.addInvoice.total_price += item.price;
                }
                window.$("#charge-" + item.id).addClass("active");
            } else {
                window.$("#selectAllCharges input").prop("checked", false);
                const indexOfObject = this.addInvoice.charges.findIndex(
                    (object) => {
                        return object.id === item.id;
                    }
                );
                this.addInvoice.charges.splice(indexOfObject, 1);

                if (item.isAdvancePayment) {
                    this.addInvoice.total_price += item.price;
                } else {
                    this.addInvoice.total_price -= item.price;
                }

                window.$("#charge-" + item.id).removeClass("active");
            }

            if (this.addInvoice.total_price < 0) {
                this.addInvoice.total_price = 0;
            }

            // console.log(this.addInvoice.charges)
        },

        async createInvoice() {
            var error = await this.checkErrors();

            if (!error) {
                window.$("#submitAddInvoice").empty();
                window.$("#submitAddInvoice").attr("disabled", "true");
                window
                    .$("#submitAddInvoice")
                    .append(
                        '<span class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>Guardando'
                    );

                if (
                    this.addInvoice.charges == "" &&
                    !this.addInvoice.isAdvancePayment
                ) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Debes seleccionar al menos un cargo para poder generar la factura",
                        confirmButtonColor: "#0091ff",
                    });
                } else {
                    var response = await InvoiceService.addInvoice(
                        this.addInvoice
                    );

                    if (response.status == 201) {
                        window.$("#modalAddInvoice").hide();
                        Swal.fire({
                            icon: "success",
                            title: "Factura creada",
                            confirmButtonColor: "#0091ff",
                        }).then((result) => {
                            this.$router.go();
                        });
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Ha ocurrido un error. Vuelve a intentarlo. Si el error persiste ponte en contacto con soporte@hospedium.com",
                            confirmButtonColor: "#0091ff",
                        });
                    }
                }
                window.$("#submitAddInvoice").empty();
                window.$("#submitAddInvoice").append("Guardar");
                window.$("#submitAddInvoice").removeAttr("disabled");
            }
        },

        async checkErrors() {
            var error = false;

            // RESETAMOS LOS ERRORES
            this.resetErrors();

            if (this.addInvoice.client_type == "client") {
                // COMPROBAMOS EL CAMPO firstName
                if (
                    this.addInvoice.client.firstName == undefined ||
                    this.addInvoice.client.firstName == ""
                ) {
                    this.errors.client.firstName = true;
                    error = true;
                }

                // COMPROBAMOS EL CAMPO lastName1
                if (
                    this.addInvoice.client.lastName1 == undefined ||
                    this.addInvoice.client.lastName1 == ""
                ) {
                    this.errors.client.lastName1 = true;
                    error = true;
                }

                // COMPROBAMOS EL CAMPO email
                if (this.addInvoice.client.email != undefined && this.addInvoice.client.email != '') {
                    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                    if (!emailPattern.test(this.addInvoice.client.email)) {
                        this.errors.client.email = true;
                        error = true;
                    }
                }

                // COMPROBAMOS EL CAMPO country
                if (
                    this.addInvoice.client.tax_country == undefined ||
                    this.addInvoice.client.tax_country == ""
                ) {
                    this.errors.client.tax_country = true;
                    error = true;
                }
            } else if (this.addInvoice.client_type == "company") {
                // COMPROBAMOS EL CAMPO social_reason
                if (
                    this.addInvoice.company.social_reason == undefined ||
                    this.addInvoice.company.social_reason == ""
                ) {
                    this.errors.company.social_reason = true;
                    error = true;
                }

                // COMPROBAMOS EL CAMPO email
                if (this.addInvoice.company.email != undefined && this.addInvoice.company.email != '') {
                    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                    if (!emailPattern.test(this.addInvoice.company.email)) {
                        this.errors.company.email = true;
                        error = true;
                    }
                }

                // COMPROBAMOS EL CAMPO country
                if (
                    this.addInvoice.company.tax_country == undefined ||
                    this.addInvoice.company.tax_country == ""
                ) {
                    this.errors.company.tax_country = true;
                    error = true;
                }
            }

            if (this.addInvoice.isAdvancePayment) {
                // COMPROBAMOS EL CAMPO advancePaymentPrice
                if (this.addInvoice.total_price == 0) {
                    this.errors.advancePaymentPrice = true;
                    error = true;
                }
            }

            return error;
        },

        resetErrors() {
            // RESETAMOS LOS ERRORES
            this.errors = {
                client: {
                    firstName: false,
                    lastName1: false,
                    tax_country: false,
                },
                company: {
                    social_reason: false,
                    cif: false,
                    tax_country: false,
                },
                advancePaymentPrice: false,
            };
        },

        formatDate(date) {
            var d = new Date(date),
                month = "" + (d.getMonth() + 1),
                day = "" + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2) month = "0" + month;
            if (day.length < 2) day = "0" + day;

            return [year, month, day].join("-");
        },

        async getAllClients(search) {
            var response = await ClientService.searchClients({
                search: search,
            });
            this.clients = response.data;
            // console.log(this.clients, "clients")
        },

        async searchClient(search) {
            if (search != null) {
                await this.getAllClients(search);
                this.suggestedClients = this.clients;
            }
        },

        searchGuests() {
            this.suggestedClients = [];
            for (var i = 0; i < this.guests.length; i++) {
                this.suggestedClients.push(this.guests[i].client_hotel);
            }
            // console.log(this.suggestedClients, "suggestedClients");
        },
        selectClient(client) {
            this.addInvoice.client = client;
            this.search = null;
            this.suggestedClients = [];
        },

        async getAllCompanies(search) {
            var response = await CompanyService.searchCompanies({
                search: search,
            });
            this.companies = response.data;
            // console.log(this.companies, "companies")
        },
        async searchCompany(search) {
            if (search != null) {
                await this.getAllCompanies(search);
                this.suggestedCompanies = this.companies;
            }
        },
        selectCompany(company) {
            this.addInvoice.company = company;
            this.search = null;
            this.suggestedCompanies = [];
        },

        resetModal() {
            this.clientActive = true;
            this.companyActive = false;
            this.addInvoice = {
                booking: this.booking.id,
                client_type: "client",
                isAdvancePayment: false,
                advancePaymentDescription: "",
                client: {
                    firstName: "",
                    lastName1: "",
                    lastName2: "",
                    email: "",
                    tax_address: "",
                    tax_locality: "",
                    tax_postal_code: "",
                    tax_country: "",
                    nif: "",
                },
                company: {
                    social_reason: "",
                    cif: "",
                    email: "",
                    tax_address: "",
                    tax_locality: "",
                    tax_postal_code: "",
                    tax_country: "",
                },
                charges: [],
                total_price: 0,
            };
        },

        reset(key) {
            this.search = null;
            if (key == "client") {
                this.addInvoice.client = {
                    id: 0,
                    firstName: "",
                    lastName1: "",
                    lastName2: "",
                    email: "",
                    tax_address: "",
                    tax_locality: "",
                    tax_postal_code: "",
                    tax_country: "",
                    nif: "",
                };
            }

            if (key == "company") {
                this.addInvoice.company = {
                    id: 0,
                    social_reason: "",
                    cif: "",
                    email: "",
                    tax_address: "",
                    tax_locality: "",
                    tax_postal_code: "",
                    tax_country: "",
                };
            }
        },


    },
    created() {
        this.defaultCurrency = localStorage.getItem('currency') || 'EUR';
        this.start();
    },
    mounted() {
        window.$("#modalAddInvoice").on("hidden.bs.modal", this.resetModal);
    },
};
</script>