<template>
    <div>
        <button v-if="!isCompany" class="btn btn-link text-primary me-2 px-0" data-bs-toggle="modal" data-bs-target="#modalChangeClient"><i class="fas fa-edit me-1"></i>Cambiar cliente</button>
        <button v-else class="btn btn-link text-primary me-2 px-0" data-bs-toggle="modal" data-bs-target="#modalChangeClient"><i class="fas fa-edit me-1"></i>Cambiar empresa</button>

        <div class="modal fade" id="modalChangeClient" tabindex="-1" aria-labelledby="modalChangeClient" aria-hidden="true">
            <div class="modal-dialog modal-xl">
                <div class="modal-content p-3">
                    <div class="modal-header">
                        <h5 v-if="!isCompany" class="modal-title">Cambiar cliente</h5>
                        <h5 v-else class="modal-title">Cambiar empresa</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div v-if="!loading" class="row">
                            <div class="col-md-12">
                                <div v-if="!isCompany" class="mb-5">
                                    <div class="row mb-3 mx-0" style="background-color: #e3ebf7; color: #285192; padding: 10px; border-radius: 5px;">
                                        <div class="col-12 col-md-7">
                                            <input class="form-control" placeholder="Buscar..." v-model="search" @keyup="searchClient(search)" />

                                        </div>
                                        <div class="col-12 col-md-5">
                                            <button v-if="hasGuests" class="btn btn-sm btn-primary me-2" @click.stop="searchGuests()"><i
                                                    class="fa-regular fa-users-viewfinder me-2"></i>Huéspedes</button>
                                            <button class="btn btn-sm btn-primary" @click.stop="reset('client')"><i class="fa-regular fa-user-plus me-2"></i>Nuevo
                                                cliente</button>
                                        </div>
                                        <div class="col-12">
                                            <ul v-if="suggestedClients.length > 0" class="invoice-suggested-clients shadow-sm" v-click-outside="onClickOutside">
                                                <li :key="c" v-for="c in suggestedClients" @click.stop="selectClient(c)">
                                                    <div class="d-flex">
                                                        <div v-if="c.id == addInvoice.client.id" class="clientSelected"><i class="fa-solid fa-user-check"></i>
                                                        </div>
                                                        <div>
                                                            <div>{{
            c.firstName + ' ' +
            c.lastName1 + ' ' +
            c.lastName2 + ' (' + c.nif + ')' }}
                                                            </div>
                                                            <small>{{ c.email }}</small>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>

                                        <div v-if="addInvoice.client.id > 0" class="mt-3" style="font-weight: 500;">
                                            Se ha seleccionado el cliente
                                            <span class="text-uppercase me-1">{{
            addInvoice.client.firstName + ' ' +
            addInvoice.client.lastName1 + ' ' +
            addInvoice.client.lastName2 + '.' }}</span>
                                            <span style="color: #0091ff; text-decoration: underline; cursor: pointer;" @click.stop="reset('client')">Resetear campos</span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="row g-3 align-items-center mb-2">
                                                <div class="col-12">
                                                    <MazInput id="client-firstName" v-model="addInvoice.client.firstName" label="Nombre" required class="text-uppercase" hint="Indica un nombre"
                                                        :error=errors.client.firstName>
                                                        <template #left-icon>
                                                            <MazIcon name="user" />
                                                        </template>
                                                    </MazInput>
                                                </div>
                                                <div class="col-md-6">
                                                    <MazInput id="client-lastName1" v-model="addInvoice.client.lastName1" label="Primer Apellido" required class="text-uppercase"
                                                        hint="Indica un apellido" :error=errors.client.lastName1>
                                                        <template #left-icon>
                                                            <MazIcon name="user" />
                                                        </template>
                                                    </MazInput>
                                                </div>

                                                <div class="col-md-6">
                                                    <MazInput id="client-lastName2" v-model="addInvoice.client.lastName2" label="Segundo Apellido" class="text-uppercase">
                                                        <template #left-icon>
                                                            <MazIcon name="user" />
                                                        </template>
                                                    </MazInput>
                                                </div>
                                                <div class="col-12">
                                                    <MazInput id="client-email" v-model="addInvoice.client.email" label="Email" class="text-uppercase" type="email">
                                                        <template #left-icon>
                                                            <MazIcon name="mail" />
                                                        </template>
                                                    </MazInput>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="row g-3 align-items-center mb-2">
                                                <div class="col-12">
                                                    <MazInput id="client-nif" v-model="addInvoice.client.nif" label="NIF del contacto" class="text-uppercase" />
                                                </div>
                                                <div class="col-12">
                                                    <MazInput id="client-address" v-model="addInvoice.client.tax_address" label="Dirección" class="text-uppercase">
                                                        <template #left-icon>
                                                            <MazIcon name="map" />
                                                        </template>
                                                    </MazInput>
                                                </div>
                                                <div class="col-md-6">
                                                    <MazInput id="client-locality" v-model="addInvoice.client.tax_locality" label="Población" class="text-uppercase" />
                                                </div>
                                                <div class="col-md-6">
                                                    <MazInput id="client-tax_postal_code" v-model="addInvoice.client.tax_postal_code" label="Código Postal" class="text-uppercase" />
                                                </div>
                                                <div class="col-md-12">
                                                    <MazSelect id="client-tax_country" v-model="addInvoice.client.tax_country" label="Seleccionar país" :options="countries" option-value-key="id"
                                                        option-label-key="name" option-input-value-key="name" search search-placeholder="Buscar..." class="text-uppercase" required
                                                        hint="Indica un país" :error=errors.client.tax_country>
                                                        <template #left-icon>
                                                            <MazIcon name="flag" />
                                                        </template>
                                                    </MazSelect>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-else class="mb-5">
                                    <div class="row mb-3 mx-0" style="background-color: #e3ebf7; color: #285192; padding: 10px; border-radius: 5px;">
                                        <div class="col-12 col-md-7">
                                            <input class="form-control" placeholder="Buscar..." v-model="search" @keyup="searchCompany(search)" />

                                        </div>
                                        <div class="col-12 col-md-5">
                                            <button class="btn btn-sm btn-primary" @click.stop="reset('company')"><i class="fa-regular fa-plus me-2"></i>Nueva
                                                empresa</button>
                                        </div>
                                        <div class="col-12">
                                            <ul v-if="suggestedCompanies != ''" class="invoice-suggested-clients shadow-sm" v-click-outside="onClickOutside">
                                                <li :key="c" v-for="c in suggestedCompanies" @click.stop="selectCompany(c)">
                                                    <div class="d-flex">
                                                        <div v-if="c.id == addInvoice.company.id" class="clientSelected"><i class="fa-solid fa-building-circle-check"></i>
                                                        </div>
                                                        <div>
                                                            <div>{{ c.social_reason + ' (' + c.cif +
            ')' }}</div>
                                                            <small>{{ c.email }}</small>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>

                                        <div v-if="addInvoice.company.id > 0" class="mt-3" style="font-weight: 500;">
                                            Se ha seleccionado la empresa <span class="text-uppercase">{{
            addInvoice.company.social_reason
            + ' - ' + addInvoice.company.cif }}</span>. <span style="color: #0091ff; text-decoration: underline; cursor: pointer;"
                                                @click.stop="reset('company')">Resetear campos</span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="row g-3 align-items-center mb-2">
                                                <div class="col-12">
                                                    <MazInput id="company-social_reason" v-model="addInvoice.company.social_reason" label="Razón Social" class="text-uppercase" required
                                                        hint="Indica un nombre" :error=errors.company.social_reason />
                                                </div>
                                                <div class="col-12">
                                                    <MazInput id="company-cif" v-model="addInvoice.company.cif" label="CIF" class="text-uppercase" required hint="Indica un CIF"
                                                        :error=errors.company.cif />
                                                </div>
                                                <div class="col-12">
                                                    <MazInput id="company-email" v-model="addInvoice.company.email" label="Email" class="text-uppercase" type="email">
                                                        <template #left-icon>
                                                            <MazIcon name="mail" />
                                                        </template>
                                                    </MazInput>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="row g-3 align-items-center mb-2">
                                                <div class="col-12">
                                                    <MazInput id="company-tax_address" v-model="addInvoice.company.tax_address" label="Dirección" class="text-uppercase">
                                                        <template #left-icon>
                                                            <MazIcon name="map" />
                                                        </template>
                                                    </MazInput>
                                                </div>
                                                <div class="col-md-6">
                                                    <MazInput id="company-locality" v-model="addInvoice.company.tax_locality" label="Población" class="text-uppercase" />
                                                </div>
                                                <div class="col-md-6">
                                                    <MazInput id="company-tax_postal_code" v-model="addInvoice.company.tax_postal_code" label="Código Postal" class="text-uppercase" />
                                                </div>
                                                <div class="col-md-12">
                                                    <MazSelect id="company-country" v-model="addInvoice.company.tax_country" label="Seleccionar país" :options="countries" option-value-key="id"
                                                        option-label-key="name" option-input-value-key="name" search search-placeholder="Buscar..." class="text-uppercase" required
                                                        hint="Indica un país" :error=errors.company.tax_country>
                                                        <template #left-icon>
                                                            <MazIcon name="flag" />
                                                        </template>
                                                    </MazSelect>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group text-center py-4">
                                    <div class="d-grid gap-2 col-6 mx-auto">
                                        <button id="submitChangeClient" class="btn btn-primary" @click.stop.prevent="changeClient">Guardar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
.invoice-suggested-clients {
    position: relative;
    text-transform: uppercase;
    width: 100%;
    height: auto;
    max-height: 150px;
    overflow: auto;
    background: white;
    padding: 5px;
}

.invoice-suggested-clients li {
    cursor: pointer;
    border-bottom: 1px solid #eee;
    padding: 5px;
    font-weight: 500;
}

.invoice-suggested-clients li:hover {
    background: #f3f3f3;
}

.invoice-suggested-clients .clientSelected {
    width: 35px;
    height: 35px;
    text-align: center;
    background: #0091ff;
    line-height: 35px;
    border-radius: 50px;
    color: white;
    margin-right: 10px;
}
</style>

<script>
import InvoiceService from '../../services/invoice.service'
import ClientService from "../../services/client.service"
import CompanyService from "../../services/company.service"
import CountryService from "../../services/country.service"
import BookingService from "../../services/booking.service";

import Swal from 'sweetalert2'

export default {
    name: 'ChangeClientInvoice',
    props: {
        invoiceId: Number,
        hasGuests: Boolean
    },
    emits: [],
    data() {
        return {
            loading: true,
            invoice: {},
            addInvoice: {},
            countries: [],
            clients: [],
            companies: [],
            errors: [],
            search: null,
            suggestedClients: [],
            suggestedCompanies: [],
            guests: [],
            isCompany: false
        }
    },
    methods: {
        async start() {
            this.loading = true;
            await this.resetClient()
            await this.resetCompany()
            await this.getInvoice()
            await this.getAlllCountries()
            if (this.hasGuests) {
                await this.getBookingGuests()
            }
            await this.resetErrors()

            if (this.invoice.company > 0) {
                this.isCompany = true;
            }
            this.loading = false;
        },

        async getInvoice() {
            var response = await InvoiceService.getInvoice(this.invoiceId)
            this.invoice = response.data;
        },

        async getAlllCountries() {
            var response = await CountryService.getAllCountries()
            this.countries = response;
        },

        async getAllClients(search) {
            var response = await ClientService.searchClients({ search: search });
            this.clients = response.data;
            // console.log(this.clients, "clients")
        },

        async getAllCompanies(search) {
            var response = await CompanyService.searchCompanies({ search: search });
            this.companies = response.data;
            // console.log(this.companies, "companies")
        },

        async getBookingGuests() {
            var response = await BookingService.getBookingGuests({ booking: this.invoice.booking });
            this.guests = response.data;
            // console.log(this.guests, "guests")
        },

        async checkErrors() {
            var error = false

            // RESETAMOS LOS ERRORES
            this.resetErrors();

            if (!this.isCompany) {
                // COMPROBAMOS EL CAMPO firstName
                if (this.addInvoice.client.firstName == undefined || this.addInvoice.client.firstName == '') {
                    this.errors.client.firstName = true
                    error = true
                }

                // COMPROBAMOS EL CAMPO lastName1
                if (this.addInvoice.client.lastName1 == undefined || this.addInvoice.client.lastName1 == '') {
                    this.errors.client.lastName1 = true
                    error = true
                }

                // COMPROBAMOS EL CAMPO country
                if (this.addInvoice.client.tax_country == undefined || this.addInvoice.client.tax_country == '') {
                    this.errors.client.tax_country = true
                    error = true
                }
            } else {
                // COMPROBAMOS EL CAMPO social_reason
                if (this.addInvoice.company.social_reason == undefined || this.addInvoice.company.social_reason == '') {
                    this.errors.company.social_reason = true
                    error = true
                }

                // COMPROBAMOS EL CAMPO country
                if (this.addInvoice.company.tax_country == undefined || this.addInvoice.company.tax_country == '') {
                    this.errors.company.tax_country = true
                    error = true
                }
            }

            return error;
        },

        resetErrors() {
            // RESETAMOS LOS ERRORES
            this.errors = {
                client: {
                    firstName: false,
                    lastName1: false,
                    tax_country: false
                },
                company: {
                    social_reason: false,
                    cif: false,
                    tax_country: false
                }
            }
        },



        async changeClient() {
            var error = await this.checkErrors();

            if (!error) {

                window.$("#submitChangeClient").empty();
                window.$("#submitChangeClient").append('<span class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>Guardando');

                if (!this.isCompany) {
                    var response = await InvoiceService.updateInvoiceClient({
                        invoice: this.invoiceId,
                        client: this.addInvoice.client
                    })
                } else {
                    var response = await InvoiceService.updateInvoiceCompany({
                        invoice: this.invoiceId,
                        company: this.addInvoice.company
                    })
                }


                if (response.status == 200) {
                    window.$('#modalChangeClient').hide()
                    Swal.fire({
                        icon: 'success',
                        title: 'Cliente actualizado',
                        position: 'top',
                        showConfirmButton: false,
                        timer: 1500, backdrop: false
                    })
                    this.$router.go();
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Ha ocurrido un error. Vuelve a intentarlo. Si el error persiste ponte en contacto con soporte@hospedium.com',
                        confirmButtonColor: '#0091ff'
                    });
                }


                window.$("#submitChangeClient").empty();
                window.$("#submitChangeClient").append('Guardar');
            }
        },

        async addClient() {
            var response = '';
            if (this.addInvoice.client.id > 0) {
                response = await ClientService.updateClient(
                    this.addInvoice.client.id,
                    this.addInvoice.client
                );
            } else {
                response = await ClientService.addClient(this.addInvoice.client);
            }
            if (response.status == 201 || response.status == 200) {
                return response.data.id
            }
            return false
        },

        async addCompany() {
            var response = '';
            if (this.addInvoice.company.id > 0) {
                response = await CompanyService.updateCompany(
                    this.addInvoice.company.id,
                    this.addInvoice.company
                );
            } else {
                response = await CompanyService.addCompany(this.addInvoice.company);
            }
            if (response.status == 201 || response.status == 200) {
                return response.data.id
            }
            return false
        },

        searchGuests() {
            this.suggestedClients = []
            for (var i = 0; i < this.guests.length; i++) {
                this.suggestedClients.push(this.guests[i].client_hotel);
            }
        },

        async searchClient(search) {
            if (search != null) {
                await this.getAllClients(search)
                this.suggestedClients = this.clients;
            }
        },

        async searchCompany(search) {
            if (search != null) {
                await this.getAllCompanies(search)
                this.suggestedCompanies = this.companies;
            }
        },

        selectClient(client) {
            this.addInvoice.client = client;
            this.search = null;
            this.suggestedClients = []
        },

        selectCompany(company) {
            this.addInvoice.company = company;
            this.search = null;
            this.suggestedCompanies = []
        },

        resetClient() {
            this.addInvoice.client = {
                id: 0,
                firstName: '',
                lastName1: '',
                lastName2: '',
                tax_address: '',
                tax_locality: '',
                tax_postal_code: '',
                tax_country: '',
                nif: '',
            }
        },

        resetCompany() {
            this.addInvoice.company = {
                id: 0,
                social_reason: '',
                cif: '',
                email: '',
                tax_address: '',
                tax_locality: '',
                tax_postal_code: '',
                tax_country: '',
            }
        },


        onClickOutside(event) {
            this.suggestedClients = []
            this.suggestedCompanies = []
        },

        reset(key) {
            this.search = null;
            if (key == 'client') {
                this.addInvoice.client = {
                    id: 0,
                    firstName: '',
                    lastName1: '',
                    lastName2: '',
                    email: '',
                    tax_address: '',
                    tax_locality: '',
                    tax_postal_code: '',
                    tax_country: '',
                    nif: '',
                }
            }

            if (key == 'company') {
                this.addInvoice.company = {
                    id: 0,
                    social_reason: '',
                    cif: '',
                    email: '',
                    tax_address: '',
                    tax_locality: '',
                    tax_postal_code: '',
                    tax_country: '',
                }
            }
        },


    },
    created() {
        this.start()
    }
}
</script>