<template>
    <div class="page-app">
        <div id="planning-inventory" class="card mb-3 border-0 shadow-sm p-3">
            <div class="card-body p-0">
                <div class="d-md-flex d-block justify-content-between align-items-center mb-2">
                    <div class="d-flex  align-items-center">
                        <DatePicker v-model.range="range" :masks="masks" :columns="columns" v-if="!loadingCalendar">
                            <template #default="{ togglePopover, inputValue }">
                                <button class="btn px-3 py-2 bg-blue-500 text-sm bg-white font-semibold rounded-md shadow-sm" @click="togglePopover" style="
                                        padding: 10px;
                                        border: 0.125rem solid var(--bs-primary);
                                        border-radius: 0.5rem;
                                        width: 270px;
                                    ">
                                    <i class="fa-regular fa-calendar-days me-3 text-primary"></i>
                                    <span>{{ inputValue.start }}</span>
                                    <i class="fa-regular fa-arrow-right mx-2"></i>
                                    <span>{{ inputValue.end }}</span>
                                </button>
                            </template>
                        </DatePicker>
                        <button v-if="!loadingCalendar" class="btn btn-outline-primary ms-3 ms-md-3 my-3 my-md-0" @click="todayPlanning()">
                            Hoy
                        </button>
                    </div>
                    <div class="mb-md-0 mb-3">
                        <span class="badge badge-primary me-1 mb-2">Confirmado</span>
                        <span class="badge badge-success me-1 mb-2">Checkin</span>
                        <span class="badge badge-secondary me-1 mb-2">Checkout</span>
                        <span class="badge badge-warning me-1 mb-2">Modificado</span>
                        <span class="badge badge-dark me-1 mb-2">No Show</span>
                        <span class="badge text-dark me-1 mb-2" style="background: orange !important"><i class="fa-regular fa-triangle-exclamation me-1"></i>Tipo
                            de hab. incorrecto</span>
                        <!-- <span class="badge badge-info text-dark me-1">Info</span>
                        <span class="badge badge-light text-dark me-1">Light</span>
                        <span class="badge badge-dark me-1">Dark</span> -->
                    </div>
                    <!-- <div class="dropdown">
                        <button class="btn btn-secondary d-flex align-items-center" data-bs-toggle="dropdown" aria-expanded="false">
                            <i class="fa-regular fa-palette me-2" style="font-size: 16px; cursor: pointer;"></i>
                            Guía colores
                        </button>
                        <ul class="dropdown-menu">
                            <li><button class="dropdown-item" type="button">Action</button></li>
                            <li><button class="dropdown-item" type="button">Another action</button></li>
                            <li><button class="dropdown-item" type="button">Something else here</button></li>
                        </ul>
                    </div> -->
                </div>
                <div v-if="!loadingPlanning" class="table-responsive">
                    <table id="planningTable" class="table table-striped table-bordered table-fixed mb-0">
                        <thead>
                            <tr>
                                <th>
                                    <!-- <select class="forn-control">
                                        <option>Filtrar hab.</option>
                                    </select> -->
                                </th>
                                <th class="text-center" :key="item" v-for="(item, index) in dates" :class="'th-' + item.planning.class" @click="availabilityByDay.length > 0 && openModal(index)">
                                    <div class="month">
                                        <small class="text-uppercase">{{ item.planning.month }}</small>
                                    </div>
                                    <div class="day">{{ item.planning.day }}</div>
                                    <div class="week"><small>{{ item.planning.week }}</small></div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr :key="item" v-for="(item, row) in planning" :class="'status-' + item.room.status" :row="row">
                                <td class="px-2" :style="'border-left: 4px solid ' +
                            item.room.roomType.color + '!important;'
                            ">
                                    <div class="d-md-flex justify-content-between align-items-center">
                                        {{ item.room.name }}
                                        <div class="d-flex align-items-center ps-2">
                                            <span v-if="item.room.dontDisturb" class="badge rounded-pill badge-warning ms-2 d-flex align-items-center justify-content-center"
                                                style="width: 24px; height: 24px;" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-custom-class="custom-tooltip" data-bs-title="No molestar">
                                                <i class="fa-regular fa-ban"></i>
                                            </span>
                                            <span v-if="item.room.situation == 1" class="badge rounded-pill badge-success ms-2 d-flex align-items-center justify-content-center"
                                                style="width: 24px; height: 24px;" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-custom-class="custom-tooltip" data-bs-title="Limpia">
                                                <i class="fa-solid fa-bed"></i>
                                            </span>
                                            <span v-else class="badge rounded-pill badge-danger ms-2 d-flex align-items-center justify-content-center" style="width: 24px; height: 24px;"
                                                data-bs-toggle="tooltip" data-bs-placement="top" data-bs-custom-class="custom-tooltip" data-bs-title="Sucia">
                                                <i class="fa-solid fa-bed"></i>
                                            </span>
                                        </div>
                                    </div>
                                </td>
                                <td :key="c" v-for="(c, column) in item.td" :class="{ isDisabled: c.isDisabled }" class="p-0" :row="row" :column="column" :room="item.room.id" :id="row + '_' + column"
                                    @mouseover="mouseover">
                                    <div v-if="c.booking.id && c.colspan > 0" :id="item.room.id + '_' + c.booking.id" :booking="c.booking.id" :bookingroom="c.bookingroom.id" class="td-booked" :class="{
                            customColor:
                                c.bookingroom.hasCustomColor,
                        }" :status="c.bookingroom.status" @click="updateOffCanvas(c.booking.id)" :style="' --booking-color:' +
                            c.bookingroom.color +
                            '; width: ' +
                            (c.colspan * TD_size - 10) +
                            'px;'
                            " :colspan="c.colspan">
                                        <!-- v-drag="{ axis: 'all' }"
                                        @v-drag-moving="dragMoving"
                                        @v-drag-end.stop.prevent="dragEnd" -->

                                        <div style="
												position: absolute;
												right: 2px;
												font-size: 12px;
                                                  display: grid;
                                                grid-template-columns: repeat(2, auto);
                                                gap: 1px;
											">
                                            <div v-if="c.booking.isPaid" style="color: greenyellow" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-custom-class="custom-tooltip"
                                                data-bs-title="Reserva pagada"><i class="fa-solid fa-circle-euro"></i></div>

                                            <div v-else-if="c.booking.total_paid > 0 && c.booking.left_to_pay > 0" style="color: darkorange" data-bs-toggle="tooltip" data-bs-placement="top"
                                                data-bs-custom-class="custom-tooltip" data-bs-title="Reserva pagada parcialmente"><i class="fa-solid fa-circle-euro"></i></div>


                                            <div v-if="c.booking.isCheckinOnlineSend" style="color: #7239ea;" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-custom-class="custom-tooltip"
                                                data-bs-title="Check-in online enviado"><i class="fa-solid fa-circle-location-arrow"></i></div>
                                            <div v-if="c.booking.isPaymentLinkSent" style="color: deeppink" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-custom-class="custom-tooltip"
                                                data-bs-title="Enlace de pago enviado"><i class="fa-sharp fa-solid fa-circle-bolt"></i></div>
                                            <span v-if="c.bookingroom
                            .roomTypeWarning
                            " style="
													color: orange !important;
													margin-left: 1px;
												" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-custom-class="custom-tooltip" data-bs-title="El tipo de habitación no corresponde con la habitación asignada"><i
                                                    class="fa-solid fa-circle-exclamation"></i></span>
                                        </div>
                                        <div class="booking-info" :c="c.colspan">
                                            <span v-if="c.colspan == 1" class="text-uppercase badge badge-light-primary rounded-pill" style="padding: 0 4px" data-bs-toggle="tooltip"
                                                data-bs-placement="top" data-bs-custom-class="custom-tooltip" :data-bs-title="c.booking.firstName +
                            ' ' +
                            c.booking.lastName
                            ">{{ c.booking.firstName[0] }}
                                                <span v-if="c.booking.lastName !=
                            null
                            ">{{
                            c.booking.lastName[0]
                        }}</span>
                                            </span>
                                            <span v-else>{{
                                c.booking.firstName +
                                " " +
                                c.booking.lastName
                            }}</span>
                                            <span>{{
                                " (" + c.booking.id + ")"
                            }}</span>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot v-if="!loading">
                            <tr>
                                <td class="p-1"></td>
                                <td class="text-center p-1" :key="item" v-for="(item, index) in dates" :class="'th-' + item.planning.class" @click="availabilityByDay.length > 0 && openModal(index)"
                                    style="cursor: pointer">
                                    <div v-if="availabilityByDay[index] && availabilityByDay[index].total_roomsAvailable < 0" class="badge badge-light-danger d-flex flex-column"
                                        style="line-height: 14px">
                                        <div class="text-uppercase" style="font-size: 12px"><small>Libres</small></div>
                                        <div>{{ availabilityByDay[index].total_roomsAvailable }}</div>
                                    </div>
                                    <div v-else-if="availabilityByDay[index].total_roomsAvailable == 0" class="badge badge-light-warning d-flex flex-column" style="line-height: 14px">
                                        <div class="text-uppercase" style="font-size: 12px"><small>Libres</small></div>
                                        <div>{{ availabilityByDay[index].total_roomsAvailable }}</div>
                                    </div>
                                    <div v-else style="line-height: 14px" class="badge badge-light-success d-flex flex-column">
                                        <div class="text-uppercase" style="font-size: 12px"><small>Libres</small></div>
                                        <div>{{ availabilityByDay[index].total_roomsAvailable }}</div>
                                    </div>
                                    <div v-if="isOverbookingActive || isSuperadmin || isAdmin">
                                        <div v-if="availabilityByDay[index].overbooking_total" class="overbooking-icon text-danger" data-bs-toggle="tooltip" data-bs-placement="top"
                                            data-bs-custom-class="custom-tooltip" data-bs-title="Overbooking Total">
                                            <i class="fa-solid fa-circle-exclamation"></i>
                                        </div>
                                        <div v-else-if="availabilityByDay[index].overbooking_partial" class="overbooking-icon text-warning" data-bs-toggle="tooltip" data-bs-placement="top"
                                            data-bs-custom-class="custom-tooltip" data-bs-title="Overbooking Parcial">
                                            <i class="fa-solid fa-circle-exclamation"></i>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tfoot>


                    </table>
                </div>
                <div v-else id="spinner-search" class="col-12 text-center py-5">
                    <div class="spinner-border text-primary" role="status" style="width: 2rem; height: 2rem; visibility: visble">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                    <div>Cargando información...</div>
                </div>
            </div>
        </div>

        <div class="card border-0 shadow-sm p-3">
            <div class="card-header bg-white border-0">
                <div class="modal-title">
                    <div class="fs-4" style="font-weight: 500">
                        Reservas sin asignar
                    </div>
                </div>
            </div>
            <div class="card-body p-0">
                <div v-if="!loading" class="table-responsive w-100">
                    <table id="table-bookings" class="table table-row-dashed">
                        <thead>
                            <tr class="">
                                <th></th>
                                <th>PMS ID</th>
                                <th>Canal</th>
                                <th>Checkin</th>
                                <th>Checkout</th>
                                <th>Personas</th>
                                <th>Habitación</th>
                                <th>Precio total</th>
                                <th>Cliente</th>
                                <th>Fecha de reserva</th>
                                <th>Estado</th>
                            </tr>
                        </thead>
                        <tbody v-if="!loading">
                            <tr :key="item" v-for="item in notAssignedRooms" class="align-middle" @click="
                            handleRowNotAssignedBookingRoomClick(item)
                            " style="cursor: pointer">
                                <PlanningNotAssignedRoomRow :bookingroom="item">
                                </PlanningNotAssignedRoomRow>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div v-else id="spinner-search" class="col-12 text-center py-5">
                    <div class="spinner-border text-primary" role="status" style="width: 2rem; height: 2rem; visibility: visble">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                    <div>Cargando información...</div>
                </div>
            </div>
        </div>

        <div v-if="!loading">
            <PlanningAvailabilityPerDayModal :currentItem="currentItem" :currentIndex="currentIndex" :availabilityByDay="availabilityByDay" @showPreviousDay="showPreviousDay"
                @showNextDay="showNextDay" />
        </div>




        <PlanningBookingRoomsListOffCanvas :bookingId="bookingSelected" @updateOffCanvas="updateOffCanvas" @updatePlanning="updatePlanning" v-if="open" />


    </div>
</template>




<script>
import BookingService from "@/services/booking.service";

import PlanningNotAssignedRoomRow from "@/components/planning/PlanningNotAssignedRoomRow";
import PlanningBookingRoomsListOffCanvas from "@/components/planning/PlanningBookingRoomsListOffCanvas";
import PlanningAvailabilityPerDayModal from "@/components/planning/PlanningAvailabilityPerDayModal";

import Swal from "sweetalert2";

import { useScreens } from "vue-screen-utils";
import { Tooltip } from "bootstrap";

export default {
    name: "Planning",
    components: {
        PlanningNotAssignedRoomRow,
        PlanningBookingRoomsListOffCanvas,
        PlanningAvailabilityPerDayModal
    },
    setup() {
        const { mapCurrent } = useScreens({
            xs: "0px",
            sm: "640px",
            md: "768px",
            lg: "1024px",
        });
        const columns = mapCurrent({ lg: 2 }, 1);

        return {
            columns,
        };
    },
    data: function () {
        return {
            intervalId: null,
            dates: [],
            map: [],
            range: {
                start: null,
                end: null,
            },
            masks: {
                input: "YYYY-MM-DD",
            },
            startDate: null,
            endDate: null,
            planning: [],
            notAssignedRooms: [],
            availabilityByDay: [],
            currentItem: {},
            currentIndex: 0,
            bookingSelected: null,
            open: false,
            loading: true,
            loadingPlanning: true,
            loadingCalendar: true,
            TD_size: 80,
        };
    },
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },
        isOverbookingActive() {
            return this.$store.state.hotel.hotelModules.some(
                (module) => module.key === "module_overbooking"
            );
        },
        userRoles() {
            return this.$store.getters['user/userRoles'];
        },
        isSuperadmin() {
            return this.userRoles.includes('pms_superadmin');
        },
        isAdmin() {
            return this.userRoles.includes('pms_admin');
        },
    },
    watch: {
        range: {
            handler: function () {
                this.onDateRangeChange();
            },
            deep: true,
        },
    },
    methods: {
        onDateRangeChange() {
            this.loading = true;
            this.loadingPlanning = true;
            this.planning = [];

            this.startDate = this.formatDate(this.range.start);
            this.endDate = this.formatDate(this.range.end);

            // Actualizamos fechas en localStorage
            localStorage.setItem(
                "planning",
                JSON.stringify({
                    startDate: this.startDate,
                    endDate: this.endDate,
                    data: this.planning,
                })
            );
            if (!this.loadingCalendar) {
                this.start();
            }
        },
        async start() {
            // Obtenemos fechas guardadas
            this.loadingCalendar = true;

            await this.getPlanning();
            await this.getDates();
            this.loadingPlanning = false;
            this.loadingCalendar = false;

            await this.getAvailabilityByDay();
            if (this.availabilityByDay.length > 0) {
                this.currentItem = this.availabilityByDay[0];
            }
            await this.getBookingRoomErrors();
            this.loading = false;
        },

        async getAvailabilityByDay() {
            var response = await BookingService.getAvailabilityByDay({
                startDate: this.startDate,
                endDate: this.endDate,
            });
            this.availabilityByDay = response.data;
            console.log(this.availabilityByDay, "Availability By Day");
        },

        getDates() {
            // Returns an array of dates between the two dates
            var parts = this.startDate.split('-');
            var startDate = new Date(+parts[0], +parts[1] - 1, +parts[2]);

            parts = this.endDate.split('-');
            var endDate = new Date(+parts[0], +parts[1] - 1, +parts[2]);

            // Ajustar las fechas al mismo valor UTC
            // startDate.setUTCHours(0, 0, 0, 0);
            // endDate.setUTCHours(23, 59, 59, 999);

            // console.log(startDate, "startDate");
            // console.log(endDate, "endDate");

            const dates = [];
            let currentDate = startDate;

            var today = new Date();

            var days = [
                "Domingo",
                "Lunes",
                "Martes",
                "Miércoles",
                "Jueves",
                "Viernes",
                "Sábado",
            ];
            var months = [
                "Enero",
                "Febrero",
                "Marzo",
                "Abril",
                "Mayo",
                "Junio",
                "Julio",
                "Agosto",
                "Septiembre",
                "Octubre",
                "Noviembre",
                "Diciembre",
            ];

            while (currentDate <= endDate) {
                var options = {
                    weekday: "short",
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                };
                var date1 = currentDate.toLocaleDateString("es-ES", options);
                var date2 = this.formatDate(currentDate);

                var className = currentDate.getDay();

                if (
                    currentDate.getDate() == today.getDate() &&
                    currentDate.getMonth() == today.getMonth()
                ) {
                    className = currentDate.getDay() + " today";
                }
                var plannig_date = {
                    month: months[currentDate.getMonth()],
                    day: currentDate.getDate(),
                    week: days[currentDate.getDay()],
                    class: className,
                };
                dates.push({
                    long: date1,
                    short: date2,
                    planning: plannig_date,
                });
                currentDate = new Date(
                    currentDate.setDate(currentDate.getDate() + 1)
                );
            }

            this.dates = dates;
            // console.log(this.dates, "dates");
        },

        formatDate(dateInput) {
            let d;
            if (typeof dateInput === 'string') {
                // Si la entrada es una cadena, se asume formato 'YYYY-MM-DD' y se crea un objeto Date en UTC
                const [year, month, day] = dateInput.split('-').map(num => parseInt(num, 10));
                d = new Date(Date.UTC(year, month - 1, day));
            } else if (dateInput instanceof Date) {
                // Si la entrada ya es un objeto Date, se usa directamente
                d = new Date(Date.UTC(dateInput.getFullYear(), dateInput.getMonth(), dateInput.getDate()));

            } else {
                // Si la entrada no es ni cadena ni objeto Date, retorna un error o un valor por defecto
                console.error('Invalid date input');
                return null; // O maneja este caso como creas conveniente
            }

            var result = `${d.getUTCFullYear()}-${(d.getUTCMonth() + 1).toString().padStart(2, '0')}-${d.getUTCDate().toString().padStart(2, '0')}`;

            return result;
        },

        async todayPlanning() {
            this.range = {
                start: new Date(new Date().setDate(new Date().getDate() - 3)),
                end: new Date(new Date().setDate(new Date().getDate() + 21)),
            };
        },

        async getPlanning() {
            var response = await BookingService.getPlanning({
                startDate: this.startDate,
                endDate: this.endDate,
            });
            this.planning = response.data;
            //console.log(this.planning, "Planning");

            localStorage.setItem(
                "planning",
                JSON.stringify({
                    startDate: this.startDate,
                    endDate: this.endDate,
                    data: this.planning,
                })
            );
        },

        async getBookingRoomErrors() {
            const response = await BookingService.getBookingRoomErrors();
            this.notAssignedRooms = Array.isArray(response.data) ? response.data : (response.data ? [response.data] : []);
        },


        updateOffCanvas(bookingId) {
            if (bookingId != this.bookingSelected) {
                this.bookingSelected = bookingId;
                this.open = true;
            } else {
                this.bookingSelected = null;
                this.open = false;
            }

            // console.log(this.bookingSelected, "this.bookingSelected")
        },

        updatePlanning() {
            this.start();
        },

        handleRowNotAssignedBookingRoomClick(item) {
            // this.$router.push(`/bookings/view/${item.booking}`);
            this.updateOffCanvas(item.booking)
        },

        showPreviousDay(index) {
            if (index > 0) {
                this.currentIndex = index - 1;
                this.currentItem = this.availabilityByDay[this.currentIndex];
                this.showDayModal(this.currentIndex);
            }
        },
        showNextDay(index) {
            if (index < this.availabilityByDay.length - 1) {
                this.currentIndex = index + 1;
                this.currentItem = this.availabilityByDay[this.currentIndex];
                this.showDayModal(this.currentIndex);
            }
        },
        showDayModal(index) {
            this.removeBackdrops(); // Remove any existing backdrops
            const modal = new this.$bootstrap.Modal(document.getElementById('availabilityModal'));
            modal.show();
        },
        openModal(index) {
            if (this.availabilityByDay.length > 0) {
                this.currentIndex = index;
                this.currentItem = this.availabilityByDay[index];
                this.showDayModal(this.currentIndex);
            }
        },
        removeBackdrops() {
            const backdrops = document.querySelectorAll('.modal-backdrop');
            backdrops.forEach(backdrop => backdrop.parentNode.removeChild(backdrop));
        },

    },
    created() {
        const planning = JSON.parse(localStorage.getItem("planning"));
        if (planning) {
            this.range.start = new Date(planning.startDate);
            this.range.end = new Date(planning.endDate);
            this.startDate = this.formatDate(this.range.start);
            this.endDate = this.formatDate(this.range.end);
        } else {
            this.range.start = new Date(
                new Date().setDate(new Date().getDate() - 3)
            );
            this.range.end = new Date(
                new Date().setDate(new Date().getDate() + 21)
            );
            this.startDate = this.formatDate(this.range.start);
            this.endDate = this.formatDate(this.range.end);
        }
    },
    async mounted() {
        if (!this.currentUser) {
            this.$router.push("/login");
        }
        new Tooltip(document.body, {
            selector: "[data-bs-toggle='tooltip']",
        });

        await this.start();

        this.intervalId = window.setInterval(() => {
            this.getPlanning();
            this.getBookingRoomErrors();
        }, 30000);
    },
    beforeUnmount() {
        // console.warn("beforeUnmount");
        clearInterval(this.intervalId);
    },
};
</script>

<style>
.overbooking-icon {
    color: red;
    /* Puedes cambiar el color según tus necesidades */
    font-size: 14px;
    /* Ajusta el tamaño del ícono */
    margin-top: 5px;
    /* Ajusta el margen según tus necesidades */
}

#planning-inventory .table-responsive {
    height: calc(100vh - 150px);
    max-height: 650px;
    overflow: auto;
}


#planningTable {
    border-collapse: separate;
    border-spacing: 0;
    width: 80px;
}

#planningTable.is-dragging tbody td:hover {
    border-color: red;
    border-width: 2px;
}

#planningTable.is-dragging tbody .td-booked {
    opacity: 0.5;
}

#planningTable tr.status-false,
#planningTable tr.status-false td:first-child {
    background: #f8d7da !important;
}

#planningTable td:first-child {
    position: sticky;
    left: 0px;
    z-index: 2;
    background-color: #fff !important;
    font-size: 12px;
    font-weight: 400;
    color: #1f496a;
    box-shadow: none;
    border-left: 4px solid #0091ff;
    border-right: 1px solid #f3f3f3;
    border-top: 0.01px solid #f3f3f3;
    min-width: 140px;
    white-space: nowrap;
    overflow: auto;
}

#planningTable th,
#planningTable td {
    min-width: 80px;
}

#planningTable tbody tr {
    position: relative;
}

#planningTable tbody td {
    position: relative;
}

#planning-inventory .table-bordered> :not(caption)>*>* {
    border-width: 0 0.1px;
    border-color: #f9f9f9;
}

#planningTable tbody tr .td-booked {
    background: #0091ff;
    color: white;
    padding: 4px 2px;
    border-radius: 4px;
    font-size: 11px;
    text-transform: uppercase;
    font-weight: 300;
    margin-left: 40px;
    height: 85%;
    display: flex;
    align-items: center;
}

#planningTable tbody tr .td-booked .booking-info {
    width: 75%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

#planningTable tbody tr .td-booked[status="4"] {
    background: var(--bs-primary);
}

#planningTable tbody tr .td-booked[status="6"] {
    background: #000;
    opacity: 0.6;
}

#planningTable tbody tr .td-booked[status="8"] {
    background: var(--bs-warning);
}

#planningTable tbody tr .td-booked[status="9"] {
    background: var(--bs-success);
}

#planningTable tbody tr .td-booked[status="10"] {
    background: var(--bs-secondary);
    color: var(--bs-secondary-inverse);
    opacity: 0.8;
    font-weight: 500;
}

#planningTable tbody tr .td-booked[status="4"].customColor {
    background: repeating-linear-gradient(45deg,
            #0091ff,
            #0091ff 10px,
            var(--booking-color) 10px,
            var(--booking-color) 20px);
}

#planningTable tbody tr .td-booked[status="6"].customColor {
    background: repeating-linear-gradient(45deg,
            #000,
            #000 10px,
            var(--booking-color) 10px,
            var(--booking-color) 20px);
}

#planningTable tbody tr .td-booked[status="8"].customColor {
    background: repeating-linear-gradient(45deg,
            #ffc107,
            #ffc107 10px,
            var(--booking-color) 10px,
            var(--booking-color) 20px);
}

#planningTable tbody tr .td-booked[status="9"].customColor {
    background: repeating-linear-gradient(45deg,
            #198754,
            #198754 10px,
            var(--booking-color) 10px,
            var(--booking-color) 20px);
}

#planningTable tbody tr .td-booked[status="10"].customColor {
    background: repeating-linear-gradient(45deg,
            #6c757d,
            #6c757d 10px,
            var(--booking-color) 10px,
            var(--booking-color) 20px);
}

#planningTable thead th {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background: #1f496a;
    cursor: pointer;
    border: 0;
    padding: 2px;
    line-height: 17px;
    z-index: 2;
    color: #fff;
}

#planningTable thead th:hover {
    background: #214a6a;
}

#planningTable thead th:first-child {
    background-color: #fff !important;
    left: 0px;
    z-index: 3;
}

#planningTable thead th.th-0,
#planningTable thead th.th-6 {
    background-color: #2d6795;
}

#planningTable thead th.today {
    background: white !important;
    color: #2d6795 !important;
}

#planningTable thead th .month {
    font-size: 12px;
    font-weight: 300;
}

#planningTable thead th .day {
    font-size: 16px;
    font-weight: 700;
}

#planningTable thead th .week {
    font-size: 12px;
    font-weight: 400;
}

#planningTable.table> :not(:first-child) {
    border-top: none;
}

#planningTable .td-booked {
    cursor: pointer;
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
}

#planningTable .td-booked:hover {
    opacity: 0.8;
}

#planning-inventory .table-responsive::-webkit-scrollbar {
    -webkit-appearance: none;
    background-color: transparent;
}

#planning-inventory .table-responsive::-webkit-scrollbar:vertical {
    width: 10px;
}

#planning-inventory .table-responsive::-webkit-scrollbar:horizontal {
    height: 10px;
}

#planning-inventory .table-responsive::-webkit-scrollbar-thumb {
    background-color: rgba(24, 28, 50, 0.7);
    border-radius: 10px;
}

#planning-inventory .table-responsive::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
}

#planning-inventory td.isDisabled {
    background: #f5c2c7;
}

#planningTable tfoot {
    position: sticky;
    bottom: 0;
    background: #fff;
    z-index: 9;
    color: #1f496a;
    font-weight: 500;
}

#table-bookings th {
    text-transform: uppercase;
    font-weight: 500 !important;
}

#table-bookings td {
    border: none;
}

#table-bookings .table:not(.table-bordered).table-row-dashed tr {
    border-bottom-width: 1px;
    border-bottom-style: dashed;
    border-bottom-color: var(--bs-border-color);
}

@media screen and (max-width: 768px) {
    #planningTable thead th {
        position: relative;
    }

    #planningTable td:first-child {
        position: relative;
    }

}
</style>