import api from "./api";
import HotelService from "./hotel.service";

class ClientService {
	/**************************************
	 *  API HOSPEDIUM
	 **************************************/
	async addClient(data) {
		let hotel = HotelService.getLocalHotel();
		return await api
			.post("/clients/hotel/create/" + hotel.id + "/", data)
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async updateClient(id, params) {
		return await api
			.put("/clients/hotel/update/" + id, params)
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async getAllClients(params) {
		let hotel = HotelService.getLocalHotel();
		return await api
			.get("/clients/hotel/list/" + hotel.id, { params: params })
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async searchClients(params) {
		let hotel = HotelService.getLocalHotel();
		return await api
			.get("/clients/hotel/list/" + hotel.id, { params: params })
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async getClient(id) {
		return await api
			.get("/clients/hotel/single/" + id)
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async exportClientsCSV() {
		let hotel = HotelService.getLocalHotel();
		var params = { hotel: hotel.id };
		return api
			.get("clients/hotel/export/csv", {
				params: params,
				responseType: "blob",
			})
			.then((response) => {
				var date = new Date();
				var filename = "clients" + date.getFullYear() + date.getMonth() + date.getDate() + "_" + date.getHours() + date.getMinutes() + date.getSeconds();

				// create file link in browser's memory
				const url = window.URL.createObjectURL(
					new Blob([response.data], {
						type: "application/vnd.ms-excel",
					})
				);

				// create "a" HTML element with href to file & click
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", filename + ".xls"); //or any other extension
				document.body.appendChild(link);
				link.click();
				// clean up "a" element & remove ObjectURL
				document.body.removeChild(link);
				URL.revokeObjectURL(href);
			})
			.catch(function (error) {
				return { error };
			});
	}
}

export default new ClientService();
