<template>
    <div id="login-page" class="d-flex h-100 flex-column">
        <div class="container px-4 py-5 mx-auto">
            <div class="card border-0 shadow-sm">
                <div class="d-flex flex-md-row flex-column-reverse">
                    <div class="card card1 border-0">
                        <div class="row justify-content-center my-auto">
                            <div class="col-md-8 col-10 my-3">
                                <div class="row justify-content-center px-3 mb-3 text-center">
                                    <a href="https://hospedium.com" target="_blank"><img
                                            src="assets/img/favicon-circle.png"
                                            class="img-login-logo" /></a>
                                </div>
                                <h3 class="mb-5 text-center heading">
                                    Hospedium <span>PMS</span>
                                </h3>

                                <h6 class="msg-info">
                                    Iniciar sesión en tu cuenta
                                </h6>

                                <Form @submit="handleLogin" :validation-schema="schema">
                                    <div class="form-group mb-3">
                                        <label class="form-control-label text-muted">Usuario</label>
                                        <Field name="username" type="text"
                                            class="form-control form-control-lg form-control-solid" />
                                        <ErrorMessage name="username"
                                            class="error-feedback text-danger" />
                                    </div>

                                    <div class="form-group">
                                        <label class="form-control-label text-muted">Contraseña</label>
                                        <Field name="password" type="password"
                                            class="form-control form-control-lg form-control-solid"
                                            autocomplete />
                                        <div class="mt-2">
                                            <ErrorMessage name="password"
                                                class="error-feedback text-danger mt-2" />
                                        </div>
                                    </div>

                                    <div class="row justify-content-center mb-3 px-3">
                                        <button class="btn btn-block btn-color" :disabled="loading">
                                            <span v-show="loading"
                                                class="spinner-border spinner-border-sm me-2"></span>
                                            <span>Iniciar sesión</span>
                                        </button>
                                    </div>
                                </Form>

                                <div class="row justify-content-center my-2">
                                    <router-link to="/password/reset"><small class="text-muted">¿Has
                                            olvidado tu contraseña?</small></router-link>
                                </div>
                                <div class="form-group mt-3">
                                    <div v-if="message" class="alert alert-danger border-0 shadow-sm"
                                        role="alert">
                                        {{ message }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="bottom text-center mb-5">
                            <div class="copyright">
                                <small>©{{ currentYear }}
                                    <a href="https://hospedium.com" target="_blank">HOSPEDIUM HOTEL
                                        GROUP</a>.<br />Powered by
                                    <a href="https://wejyc.com" target="_blank">WEJYC</a>.</small>
                            </div>
                        </div>
                    </div>
                    <div class="card card2 border-0">
                        <div class="my-auto mx-md-5 px-md-5 right">
                            <h3 class="text-white mb-5">
                                Cadena de
                                <strong>hoteles independiente</strong>
                            </h3>
                            <div class="text-white mb-3">¿NECESITAS AYUDA?</div>
                            <div class="text-white mb-3">
                                <i class="fa-regular fa-phone me-3"></i>+34 912
                                90 69 05
                            </div>
                            <div class="text-white mb-3">
                                <i class="fa-regular fa-envelope me-3"></i>info@hospedium.com
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
#login-page {
    justify-content: center;
}

#login-page .error-feedback {
    font-size: 14px;
}

#login-page .img-login-logo {
    width: 100%;
    max-width: 50px;
    margin: 0 auto;
}

#login-page .copyright {
    font-size: 12px;
    text-align: center;
}

#login-page .copyright a {
    color: #000;
    text-decoration: none;
}

@media screen and (max-width: 768px) {
    #login-page {
        justify-content: normal;
    }

    #login-page .copyright {
        position: relative;
        padding: 50px 10px !important;
    }
}

#login-page input #login-page {
    background-color: #f3e5f5;
    border-radius: 50px !important;
    padding: 12px 15px 12px 15px !important;
    width: 100%;
    box-sizing: border-box;
    border: none !important;
    border: 1px solid #f3e5f5 !important;
    font-size: 16px !important;
    color: #000 !important;
    font-weight: 400;
}

#login-page input:focus {
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border: 1px solid #0091ff !important;
    outline-width: 0;
    font-weight: 400;
}

#login-page button:focus {
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    outline-width: 0;
}

#login-page .card {
    border: none;
}

#login-page .card1.card {
    width: 50%;
    padding: 40px 30px 10px 30px;
    border-radius: 0;
}

#login-page .card2.card {
    width: 50%;
    background-image: linear-gradient(to right, #0091ff, #275cb2);
    border-radius: 0;
}

#login-page .heading {
    margin-bottom: 60px !important;
}

#login-page ::placeholder {
    color: #000 !important;
    opacity: 1;
}

#login-page :-ms-input-placeholder {
    color: #000 !important;
}

#login-page ::-ms-input-placeholder {
    color: #000 !important;
}

#login-page .form-control-label {
    font-size: 14px;
    margin-left: 15px;
}

#login-page .msg-info {
    padding-left: 16px;
    margin-bottom: 30px;
}

#login-page .btn-color {
    color: #fff;
    background-image: linear-gradient(to right, #0091ff, #275cb2);
    padding: 15px;
    cursor: pointer;
    border: none !important;
    margin-top: 40px;
}

#login-page .btn-color:hover {
    color: #fff;
    background-image: linear-gradient(to right, #275cb2, #0091ff);
}

#login-page .bottom {
    width: 100%;
    margin-top: 50px !important;
}

#login-page .sm-text {
    font-size: 15px;
}

@media screen and (max-width: 992px) {
    #login-page .card1.card {
        width: 100%;
        padding: 40px 30px 10px 30px;
    }

    #login-page .card2.card {
        width: 100%;
    }

    #login-page .right {
        margin-top: 100px !important;
        margin-bottom: 100px !important;
    }
}

@media screen and (max-width: 768px) {
    #login-page .container {
        padding: 10px !important;
    }

    #login-page .card2.card {
        padding: 50px;
    }

    #login-page .right {
        margin-top: 50px !important;
        margin-bottom: 50px !important;
    }
}
</style>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";

import HotelService from "@/services/hotel.service";

export default {
    name: "Login",
    components: {
        Form,
        Field,
        ErrorMessage,
    },
    data() {
        const schema = yup.object().shape({
            username: yup.string().required("El campo usuario es obligatorio."),
            password: yup
                .string()
                .required("El campo contraseña es obligatorio."),
        });

        return {
            loading: false,
            currentYear: new Date().getFullYear(),
            message: "",
            schema,
        };
    },
    computed: {
        loggedIn() {
            return this.$store.state.auth.status.loggedIn;
        },
    },
    created() {
        if (this.loggedIn) {
            this.$router.push("/");
        }
    },
    methods: {
        async handleLogin(user) {
            this.loading = true;

            await this.$store.dispatch("auth/login", user).then(
                async () => {
                    await this.$store.dispatch("initializeStore");
                    await this.$store.dispatch("user/fetchUserRoles");
                    this.$router.push("/");
                },
                (error) => {
                    this.loading = false;
                    console.log("STATUS CODE: " + error);

                    this.message = "El usuario o contraseña son incorrectos.";
                }
            );
        },
    },
};
</script>

<style scoped></style>