<template>
    <div>
        <button class="btn  btn-primary" data-bs-toggle="modal" data-bs-target="#exportInvoicesModal"><i class="fa-regular fa-file-export me-2"></i>Exportar facturas</button>

        <!-- Modal Export Invoices-->
        <div class="modal fade" id="exportInvoicesModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="exportInvoicesModal" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Exportar facturas</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="col-md-12 mb-3">
                            <div class="mb-2">Selecciona el rango de fechas que quieres exportar</div>
                            <DatePicker
                                v-model="range"
                                mode="date"
                                :masks="masks"
                                is-range
                            >
                                <template v-slot="{ inputValue, inputEvents, isDragging }">
                                    <div class="d-md-flex d-block justify-content-start align-items-center">
                                        <div class="input-group  flex-grow-0" style="width: auto;">
                                            <span class="input-group-text" id="basic-addon1"><i class="far fa-calendar-week"></i></span>
                                            <input
                                                class="flex-grow-0 ps-3 pe-2 py-1 bg-gray-100 border rounded"
                                                :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                                                :value="inputValue.start"
                                                v-on="inputEvents.start"
                                                style="width: 110px"
                                            />
                                        </div>
                                        <span class="nput-group mx-2 flex-grow-0">
                                            <i class="fas fa-long-arrow-alt-right stroke-current text-gray-600"></i>
                                        </span>
                                        <div class="input-group flex-grow-0" style="width: auto;">
                                            <span class="input-group-text" id="basic-addon1"><i class="far fa-calendar-week"></i></span>
                                            <input
                                                class="flex-grow-0 ps-3 pe-2 py-1 bg-gray-100 border rounded"
                                                :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                                                :value="inputValue.end"
                                                v-on="inputEvents.end"
                                                style="width: 110px"
                                            
                                            />
                                        </div>
                                    </div>
                                </template>
                            </DatePicker>
                        </div>
                    </div>

                    <div class="modal-footer">
                        <button id="exportBtn" type="button" class="btn btn-primary" @click="exportInvoices()">Exportar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import InvoiceService from "../../services/invoice.service"

    import Swal from 'sweetalert2'
    
    export default {
        name: 'ExportInvoicesModal',
        props: {},
        data () {
            return {
                exporting: false,
                range: {
                    start: new Date(new Date().setDate(new Date().getDate() -20)),
                    end: new Date(),
                },
                masks: {
                    input: 'YYYY-MM-DD',
                },
            }
        },
        methods: {
            async exportInvoices() {
                window.$("#exportBtn").attr("disabled","");
                window.$("#exportBtn").empty();
                window.$("#exportBtn").append('<span class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>Exportando');

                var response = await InvoiceService.exportInvoices({
                    startDate: this.formatDate(this.range.start),
                    endDate: this.formatDate(this.range.end),
                });

                window.$("#exportBtn").removeAttr("disabled");
                window.$("#exportBtn").empty();
                window.$("#exportBtn").append('Exportar');
                window.$('#exportInvoicesModal').hide();
                window.$('body').removeClass('modal-open');
                window.$('.modal-backdrop').remove();

                if (response.status && response.status == 200) {

                    Swal.fire({
                        icon: 'success',
                        title: 'Exportación de facturas en curso',
                        text: 'Este proceso puede tardar varios minutos. Cuando finalice la exportación recibirás un email con el enlace de descarga de los archivos.',
                        showCancelButton: false,
                        confirmButtonColor: '#0091ff',
                        confirmButtonText: 'OK'
                    })

                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Ha ocurrido un error. Vuelve a intentarlo. Si el error persiste ponte en contacto con soporte@hospedium.com',
                        confirmButtonColor: '#0091ff'
                    });
                }

            },
            formatDate(date) {
                var d = new Date(date),
                    month = '' + (d.getMonth() + 1),
                    day = '' + d.getDate(),
                    year = d.getFullYear();

                if (month.length < 2) 
                    month = '0' + month;
                if (day.length < 2) 
                    day = '0' + day;

                return [year, month, day].join('-');
            }, 
        },
        mounted() {
        }
    }
</script>