import api from "./api";
import HotelService from "./hotel.service";

class PaymentService {
	/**************************************
	 *  API HOSPEDIUM
	 **************************************/

	async addPayment(data) {
		let hotel = HotelService.getLocalHotel();
		var params = { hotel: hotel.id };
		return await api
			.post("/payments/create", data, { params: params })
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return { status: error.response.status, data: error.response.data };
			});
	}

	async getPayment(id) {
		return await api
			.get("/payments/" + id)
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return { status: error.response.status, data: error.response.data };
			});
	}

	async updatePayment(data) {
		let hotel = HotelService.getLocalHotel();
		var params = { hotel: hotel.id };
		return await api
			.post("/payments/edit", data, { params: params })
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return { status: error.response.status, data: error.response.data };
			});
	}

	async deletePayment(data) {
		let hotel = HotelService.getLocalHotel();
		var params = { hotel: hotel.id };
		return await api
			.post("/payments/delete", data, { params: params })
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return { status: error.response.status, data: error.response.data };
			});
	}

	async getAllPayments(params) {
		let hotel = HotelService.getLocalHotel();
		params.hotel = hotel.id;
		return await api
			.get("/payments", { params: params })
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return { status: error.response.status, data: error.response.data };
			});
	}

	async getPaymentMethod(type) {
		return await api
			.get("/paymentmethod?type=" + type)
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return { status: error.response.status, data: error.response.data };
			});
	}

	async getInvoicePayments(id) {
		return await api
			.get("/paymentsinvoice?invoice=" + id)
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return { status: error.response.status, data: error.response.data };
			});
	}

	async getBookingPayments(id) {
		return await api
			.get("/paymentsbooking?booking=" + id)
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return { status: error.response.status, data: error.response.data };
			});
	}

	async getClientPayments(id) {
		return await api
			.get("/paymentsclient?client=" + id)
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return { status: error.response.status, data: error.response.data };
			});
	}

	async getPaymentListByMethodPayment(params) {
		let hotel = HotelService.getLocalHotel();
		params.hotel = hotel.id;
		return await api
			.get("/payments/list/day", { params })
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return { status: error.response.status, data: error.response.data };
			});
	}

	async createPaymentMoto(params, data) {
		let hotel = HotelService.getLocalHotel();
		params.hotel = hotel.id;
		return await api
			.post("payments/paynopain/moto/create", data, { params: params })
			.then((response) => {
				return Object.freeze({ status: response.status, data: response.data });
			})
			.catch(function (error) {
				return { status: error.response.status, data: error.response.data };
			});
	}

	async sendPainopainEmailLink(data) {
		let hotel = HotelService.getLocalHotel();
		let params = {};
		params.hotel = hotel.id;
		return await api
			.post("paynopain/payment/email/", data, { params: params })
			.then((response) => {
				return Object.freeze({ status: response.status, data: response.data });
			})
			.catch(function (error) {
				return { status: error.response.status, data: error.response.data };
			});
	}

	async assignPaymentToInvoice(payment_pk, data) {
		let hotel = HotelService.getLocalHotel();
		let params = {};
		params.hotel = hotel.id;
		return await api
			.put(`payments/${payment_pk}/invoice/`, data, { params: params })
			.then((response) => {
				return Object.freeze({ status: response.status, data: response.data });
			})
			.catch(function (error) {
				return { status: error.response.status, data: error.response.data };
			});
	}
}

export default new PaymentService();
