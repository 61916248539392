<template>
    <div>
        <div>
            <h6 class="mb-3">Exportar excel</h6>
            <label class="form-label">Seleccionar fechas</label>
            <DatePicker
                v-model="range"
                mode="date"
                :masks="masks"
                is-range
            >
                <template v-slot="{ inputValue, inputEvents, isDragging }">
                    <div class="d-flex justify-content-center align-items-center">
                        <div class="input-group  flex-grow-0" style="width: auto;">
                            <span class="input-group-text" id="basic-addon1"><i class="far fa-calendar-week"></i></span>
                            <input
                                class="flex-grow-0 ps-2 pe-2 py-1 bg-gray-100 border rounded"
                                :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                                :value="inputValue.start"
                                v-on="inputEvents.start"
                                style="width: 105px;"
                            />
                        </div>
                        <span class="nput-group mx-2 flex-grow-0">
                            <i class="fas fa-long-arrow-alt-right stroke-current text-gray-600"></i>
                        </span>
                        <div class="input-group flex-grow-0" style="width: auto;">
                            <span class="input-group-text" id="basic-addon1"><i class="far fa-calendar-week"></i></span>
                            <input
                                class="flex-grow-0 ps-2 pe-2 py-1 bg-gray-100 border rounded"
                                :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                                :value="inputValue.end"
                                v-on="inputEvents.end"
                                style="width: 105px;"
                            
                            />
                        </div>
                    </div>
                </template>
            </DatePicker>

            <div class="col-12 mt-4 pt-3">
                <button id="exportBtn" class="btn btn-primary" @click="exportExcel">Exportar</button>
            </div>
        </div>
    </div>
</template>


<style>
  .invoices-pdf .table>thead {
      vertical-align: bottom;
      background: transparent;
      color: #000;
      font-weight: 600;
  }

</style>


<script>
    import InvoiceService from "../../services/invoice.service";

    export default {
        name: 'InvoicesExportExcel',
        data () {
            return {
                masks: {
                    input: 'YYYY-MM-DD',
                },
                range: {
                    start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
                    end: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
                }
            }
        },
        methods: {

            async exportExcel() {
                
                window.$("#exportBtn").empty();
                window.$("#exportBtn").attr("disabled", "true");
                window.$("#exportBtn").append('<span class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>Exportando');

                await InvoiceService.exportInvoicesExcel({
                    startDate: this.formatDate(this.range.start),
                    endDate: this.formatDate(this.range.end)
                })

                window.$("#exportBtn").empty();
                window.$("#exportBtn").append('Exportar');
                window.$("#exportBtn").removeAttr("disabled");
            },

            formatDate(date) {
                var d = new Date(date),
                    month = '' + (d.getMonth() + 1),
                    day = '' + d.getDate(),
                    year = d.getFullYear();

                if (month.length < 2) 
                    month = '0' + month;
                if (day.length < 2) 
                    day = '0' + day;

                return [year, month, day].join('-');
            },
        },

        created () {
        }
    }
</script>