<template>
    <div class="modal fade" id="chargesAccommodationModal" tabindex="-1" aria-labelledby="chargesAccommodationModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="chargesAccommodationModalLabel">Precio tarifa por días</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="form-check form-switch mb-3">
                        <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" v-model="toggleTaxes">
                        <label class="form-check-label" for="flexSwitchCheckDefault">Mostrar precios con impuestos</label>
                    </div>
                    <div class="mb-3">
                        <label class="form-check-label me-2">Actualizar precio:</label>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="updateOption" id="updateByDay" value="day" v-model="updateMode">
                            <label class="form-check-label" for="updateByDay">Día por día</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="updateOption" id="updateTotal" value="total" v-model="updateMode">
                            <label class="form-check-label" for="updateTotal">Total</label>
                        </div>
                    </div>
                    <div v-if="updateMode === 'day'" class="table-responsive">
                        <table class="table table-row-dashed">
                            <thead>
                                <tr>
                                    <th scope="col">Día</th>
                                    <th scope="col">Tarifa</th>
                                    <th scope="col">Precio Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-if="charges && charges.length > 0">
                                    <template v-for="(charge, index) in charges" :key="index">
                                        <tr v-if="charge.isAccommodation" class="align-middle">
                                            <td>{{ charge.date }}</td>
                                            <td>{{ charge.description }}</td>
                                            <td>
                                                <MazInputPrice v-if="toggleTaxes" v-model="charge.total_amount" :currency="defaultCurrency" label="Precio con impuestos" locale="es-ES" :min="0"
                                                    @update:model-value="updateChargeValue(charge)" />
                                                <MazInputPrice v-else v-model="charge.baserate_amount" :currency="defaultCurrency" label="Precio sin impuestos" locale="es-ES" :min="0"
                                                    @update:model-value="updateChargeValue(charge)" />
                                            </td>
                                        </tr>
                                    </template>
                                </template>
                                <tr v-else>
                                    <td colspan="3">No hay cargos disponibles.</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div v-else>
                        <MazInputPrice v-if="toggleTaxes" v-model="totalAmount" :currency="defaultCurrency" label="Precio total con impuestos" locale="es-ES" :min="0" />
                        <MazInputPrice v-else v-model="baseRateAmount" :currency="defaultCurrency" label="Precio total sin impuestos" locale="es-ES" :min="0" />
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                    <button type="button" class="btn btn-primary" :disabled="!hasChanges" @click="saveChanges">Guardar cambios</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BookingService from '@/services/booking.service.js';

export default {
    name: 'BookingroomAccommodationChargesModal',
    emits: ["reloadView"],
    props: {
        bookingroom: {
            type: Object,
            required: true,
        },
        charges: {
            type: Array,
            required: true,
            default: () => []
        },
        show_taxes: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            defaultCurrency: localStorage.getItem('currency') || 'EUR',
            updatedCharges: [],
            toggleTaxes: this.show_taxes,
            updateMode: 'day', // 'day' or 'total'
            totalAmount: 0,
            baseRateAmount: 0
        };
    },
    computed: {
        hasChanges() {
            return this.updatedCharges.length > 0 || this.updateMode === 'total';
        }
    },
    watch: {
        charges: {
            handler(newCharges) {
                this.calculateTotalAmounts(newCharges);
            },
            deep: true
        },
        toggleTaxes(newValue) {
            this.$emit('update:show_taxes', newValue);
        }
    },
    methods: {
        calculateTotalAmounts(charges) {
            let total = 0;
            let baseRate = 0;
            charges.forEach(charge => {
                if (charge.isAccommodation) {
                    total += charge.total_amount || 0;
                    baseRate += charge.baserate_amount || 0;
                }
            });
            this.totalAmount = total;
            this.baseRateAmount = baseRate;
        },
        updateChargeValue(charge) {
            const chargeIndex = this.updatedCharges.findIndex(c => c.id === charge.id);
            const price = this.toggleTaxes ? charge.total_amount : charge.baserate_amount;
            if (chargeIndex === -1) {
                this.updatedCharges.push({ id: charge.id, price, show_taxes: this.toggleTaxes });
            } else {
                this.updatedCharges[chargeIndex].price = price;
                this.updatedCharges[chargeIndex].show_taxes = this.toggleTaxes;
            }
        },
        async saveChanges() {
            if (this.updateMode === 'total') {
                // Lógica para guardar el total amount en lugar de día por día
                this.$swal.fire({
                    title: 'Guardando...',
                    text: 'Por favor espera mientras se guardan los cambios.',
                    icon: 'info',
                    allowOutsideClick: false,
                    showConfirmButton: false,
                });
                this.$swal.showLoading();

                try {
                    const response = await BookingService.updateBookingroomRatePrice(this.bookingroom.id, {
                        price: this.toggleTaxes ? this.totalAmount : this.baseRateAmount,
                        show_taxes: this.toggleTaxes
                    });

                    if (response.status === 200) {
                        this.$swal.fire({
                            title: '¡Éxito!',
                            text: 'Los cambios se han guardado correctamente.',
                            icon: 'success',
                            confirmButtonColor: "#0091ff",
                            showConfirmButton: true
                        }).then(() => {
                            this.$emit('reloadView');
                        });
                    } else {
                        this.$swal.fire({
                            title: 'Error',
                            text: 'Hubo un problema al guardar los cambios.',
                            icon: 'error',
                            confirmButtonColor: "#0091ff",
                            showConfirmButton: true
                        });
                    }
                } catch (error) {
                    this.$swal.fire({
                        title: 'Error',
                        text: 'Hubo un problema al guardar los cambios.',
                        icon: 'error',
                        confirmButtonColor: "#0091ff",
                        showConfirmButton: true
                    });
                }
            } else {
                // Lógica para guardar día por día
                this.$swal.fire({
                    title: 'Guardando...',
                    text: 'Por favor espera mientras se guardan los cambios.',
                    icon: 'info',
                    allowOutsideClick: false,
                    showConfirmButton: false,
                });
                this.$swal.showLoading();

                try {
                    const response = await BookingService.updateBookingroomChargesPrices(this.bookingroom.id, this.updatedCharges);

                    if (response.status === 200) {
                        this.$swal.fire({
                            title: '¡Éxito!',
                            text: 'Los cambios se han guardado correctamente.',
                            icon: 'success',
                            confirmButtonColor: "#0091ff",
                            showConfirmButton: true
                        }).then(() => {
                            this.$emit('reloadView');
                        });
                    } else {
                        this.$swal.fire({
                            title: 'Error',
                            text: 'Hubo un problema al guardar los cambios.',
                            icon: 'error',
                            confirmButtonColor: "#0091ff",
                            showConfirmButton: true
                        });
                    }
                } catch (error) {
                    this.$swal.fire({
                        title: 'Error',
                        text: 'Hubo un problema al guardar los cambios.',
                        icon: 'error',
                        confirmButtonColor: "#0091ff",
                        showConfirmButton: true
                    });
                }
            }
        }
    },
    mounted() {
        this.calculateTotalAmounts(this.charges);
    }
};
</script>

<style scoped>
th {
    text-transform: uppercase;
    font-weight: 500 !important;
}

td {
    border: none;
}

.table:not(.table-bordered).table-row-dashed tr {
    border-bottom-width: 1px;
    border-bottom-style: dashed;
    border-bottom-color: var(--bs-border-color);
}
</style>
