<template>
    <div class="page-app">
        <div class="row pt-2">
            <div class="col-md-4 col-lg-3 col-xl-2 px-3">
                <SidebarMenu />
            </div>
            <div class="col-md-8 col-lg-9 col-xl-10">
                <div class="d-md-flex justify-content-between align-items-center mb-3">
                    <h1>Parking</h1>
                    <div class="d-md-flex">
                        <DatePicker v-model="range" mode="date" :masks="masks" is-range class="me-2">
                            <template v-slot="{ inputValue, inputEvents, isDragging }">
                                <div class="d-flex justify-content-center align-items-center bg-white shadow-sm btn">
                                    <div class="input-group flex-grow-0" style="width: auto">
                                        <span class="input-group-text border-0 bg-white text-primary"><i class="far fa-calendar-week"></i></span>
                                        <input class="flex-grow-0 border-0" :class="isDragging
                            ? 'text-gray-600'
                            : 'text-gray-900'
                            " :value="inputValue.start" v-on="inputEvents.start" style="
												width: 105px;
												cursor: pointer;
											" />
                                    </div>
                                    <span class="me-2 flex-grow-0">
                                        <i class="fas fa-long-arrow-alt-right stroke-current text-gray-600"></i>
                                    </span>
                                    <div class="input-group flex-grow-0" style="width: auto">
                                        <span class="input-group-text border-0 bg-white text-primary"><i class="far fa-calendar-week"></i></span>
                                        <input class="flex-grow-0 border-0" :class="isDragging
                            ? 'text-gray-600'
                            : 'text-gray-900'
                            " :value="inputValue.end" v-on="inputEvents.end" style="
												width: 105px;
												cursor: pointer;
											" />
                                    </div>
                                </div>
                            </template>
                        </DatePicker>

                        <div data-bs-toggle="modal" data-bs-target="#modalReportingCheckin">
                            <button class="btn bg-white text-primary shadow-sm ms-2" data-bs-toggle="tooltip" data-bs-placement="top" title="Exportar" @click="exportReport()">
                                <i class="fa-regular fa-arrow-down-to-line"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="card border-0 shadow-sm mb-3" style="min-height: 100px; max-height: 500px; overflow: auto">
                    <div class="card-body p-3">
                        <h3>Listado parking</h3>
                        <p>
                            Los datos que se muestran a continuación son
                            estimaciones.
                        </p>
                        <h6 v-if="!loading">
                            Fechas:
                            {{ data.startDate + " a " + data.endDate }}
                        </h6>
                        <h6 v-if="!loading">
                            Nº total de plazas de parking:
                            {{ data.total_num_parkings }}
                        </h6>
                        <div v-if="!loading" class="table-responsive scrollbar">
                            <table class="table table-hover table-bordered mb-0" style="font-size: 0.8em">
                                <thead class="bg-white text-black">
                                    <tr>
                                        <th class="text-center" style="
												min-width: 200px;
												max-width: 200px;
											"></th>
                                        <th :key="item" v-for="item in data.days" class="text-center" style="
												min-width: 100px;
												max-width: 100px;
											">
                                            {{ formatDate(item, true) }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="text-end" style="font-weight: 500">
                                            Nº Plazas Parking
                                        </td>
                                        <td class="text-center" :key="item" v-for="item in data.parking_list">
                                            {{ item.num_parking }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div v-else id="spinner-search" class="col-12 text-center py-5">
                            <div class="spinner-border text-primary" role="status" style="
									width: 2rem;
									height: 2rem;
									visibility: visble;
								">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                            <div>Cargando información...</div>
                        </div>
                    </div>
                </div>
                <div class="card border-0 shadow-sm mb-3" style="min-height: 100px; max-height: 500px; overflow: auto">
                    <div class="card-body p-3">
                        <div v-if="!loading" class="table-responsive scrollbar mb-5" :key="item" v-for="item in data.parking_list">
                            <h5>
                                Parking día: {{ formatDate(item.day, true) }}
                            </h5>
                            <table class="table table-hover table-bordered mb-0" style="font-size: 0.8em">
                                <thead class="bg-white text-black">
                                    <tr>
                                        <th class="text-center" style="
												min-width: 200px;
												max-width: 200px;
											">
                                            Reserva
                                        </th>
                                        <th class="text-center" style="
												min-width: 200px;
												max-width: 200px;
											">
                                            Cliente
                                        </th>
                                        <th class="text-center" style="
												min-width: 200px;
												max-width: 200px;
											">
                                            Habitación
                                        </th>
                                        <th class="text-center" style="
												min-width: 200px;
												max-width: 200px;
											">
                                            Nº Plazas Parking
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr :key="BR" v-for="BR in item.bookingrooms">
                                        <td class="text-center">
                                            <router-link v-if="BR.booking_id" class="btn btn-sm btn-outline-primary" :to="'/bookings/view/' +
                            BR.booking_id
                            "><i class="far fa-eye me-2"></i>
                                                {{ BR.booking_id }}
                                            </router-link>
                                        </td>
                                        <td class="text-center">
                                            {{ BR.client_name }}
                                        </td>
                                        <td class="text-center">
                                            <span v-if="BR.room_name != null">{{
                            BR.room_name
                        }}</span>
                                            <span v-else>Sin asignar</span>
                                        </td>
                                        <td class="text-center">
                                            {{ BR.num_parking }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div v-else id="spinner-search" class="col-12 text-center py-5">
                            <div class="spinner-border text-primary" role="status" style="
									width: 2rem;
									height: 2rem;
									visibility: visble;
								">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                            <div>Cargando información...</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal -->
        <div class="modal fade" id="modalReportingCheckin" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Listado de parking</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <embed v-if="pdf != null" :src="pdf" frameborder="0" width="100%" height="550px" />
                        <div v-else id="spinner-search" class="col-12 text-center py-5">
                            <div class="spinner-border text-primary" role="status" style="
									width: 2rem;
									height: 2rem;
									visibility: visble;
								">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                            <div>Cargando información...</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
#sales-chart {
    display: block;
    box-sizing: unset;
    max-height: 250px;
    min-width: 100%;
    width: auto;
    aspect-ratio: auto !important;
    overflow: auto !important;
    overflow-clip-margin: unset;
    height: auto;
}

.listings-menu-item {
    cursor: pointer;
}

.listings-menu-item:hover {
    background-color: #fff;
    border-radius: 4px;
}

.table-responsive.scrollbar {
    overflow-x: auto;
}

.table-responsive.scrollbar::-webkit-scrollbar {
    -webkit-appearance: none;
}

.table-responsive.scrollbar::-webkit-scrollbar:vertical {
    width: 12px;
}

.table-responsive.scrollbar::-webkit-scrollbar:horizontal {
    height: 12px;
}

.table-responsive.scrollbar::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    border: 2px solid #ffffff;
}

.table-responsive.scrollbar::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #ffffff;
}
</style>

<script>
import SidebarMenu from "../../components/reporting/SidebarMenu.vue";
import ReportingService from "../../services/reporting.service";

export default {
    name: "ReportingParking",
    components: {
        SidebarMenu: SidebarMenu,
    },
    data() {
        return {
            loading: true,
            pdf: null,
            data: [],
            range: {
                start: new Date(),
                end: new Date(new Date().setDate(new Date().getDate() + 3)),
            },
            masks: {
                input: "YYYY-MM-DD",
            },
            lineChart: null,
            defaultCurrency: ''
        };
    },
    watch: {
        range: {
            handler: function () {
                this.start();
            },
            deep: true,
        },
    },
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },
    },
    methods: {
        async start() {
            this.loading = true;
            await this.getParkingReporting();

            this.loading = false;
        },

        async getParkingReporting() {
            var response = await ReportingService.getParkingReporting({
                startDate: this.formatDate(this.range.start),
                endDate: this.formatDate(this.range.end),
            });
            this.data = response.data;
            console.log(this.data, "data");
        },

        async exportReport() {
            this.pdf = null;
            var response = await ReportingService.getParkingReportingByDatePDF({
                startDate: this.formatDate(this.range.start),
                endDate: this.formatDate(this.range.end),
            });
            this.pdf = response.data;
            console.log(this.pdf, "PDF");
        },

        formatDate(date, show) {
            var d = new Date(date),
                month = "" + (d.getMonth() + 1),
                day = "" + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2) month = "0" + month;
            if (day.length < 2) day = "0" + day;

            if (show) {
                return [day, month, year].join("/");
            }

            return [year, month, day].join("-");
        },

    },
    created() {
        this.start();
    },
    mounted() {
        if (!this.currentUser) {
            this.$router.push("/login");
        }
    },
};
</script>