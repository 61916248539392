import api from "./api";
import HotelService from "./hotel.service";

class TaxService {
    async createTag(data) {
        let hotel = HotelService.getLocalHotel();
        var params = { hotel: hotel.id };
        data.hotel = hotel.id;
        return await api
            .post("/tags", data, { params: params })
            .then((response) => {
                return { status: response.status, data: response.data };
            })
            .catch(function (error) {
                return {
                    status: error.response.status,
                    data: error.response.data,
                };
            });
    }
    async listTags(params) {
        let hotel = HotelService.getLocalHotel();
        params.hotel = hotel.id;
        return await api
            .get("/tags", { params: params })
            .then((response) => {
                return { status: response.status, data: response.data };
            })
            .catch(function (error) {
                return {
                    status: error.response.status,
                    data: error.response.data,
                };
            });
    }
    async getTag(id) {
        let hotel = HotelService.getLocalHotel();
        var params = { hotel: hotel.id };
        return await api
            .get("/tags/" + id, { params: params })
            .then((response) => {
                return { status: response.status, data: response.data };
            })
            .catch(function (error) {
                return {
                    status: error.response.status,
                    data: error.response.data,
                };
            });
    }

    async updateTag(id, data) {
        let hotel = HotelService.getLocalHotel();
        var params = { hotel: hotel.id };
        return await api
            .put("/tags/" + id, data, { params: params })
            .then((response) => {
                return { status: response.status, data: response.data };
            })
            .catch(function (error) {
                return {
                    status: error.response.status,
                    data: error.response.data,
                };
            });
    }

    async deleteTag(id) {
        let hotel = HotelService.getLocalHotel();
        var params = { hotel: hotel.id };
        return await api
            .delete("/tags/" + id, { params: params })
            .then((response) => {
                return { status: response.status, data: response.data };
            })
            .catch(function (error) {
                return {
                    status: error.response.status,
                    data: error.response.data,
                };
            });
    }
}

export default new TaxService();
