<template>
    <div class="listings-menu" style="position: sticky; top: 60px; overflow-y: auto; max-height: 90vh">
        <router-link to="/reporting" class="btn btn-light bg-white mb-3"><i class="fa-solid fa-grid-2 me-2"></i>Explorar todos</router-link>

        <div class="mb-5">
            <div class="text-muted text-uppercase ps-2 mb-2" style="font-weight: 500">
                Reservas
            </div>
            <ul style="list-style: none; font-weight: 500" class="ps-0">
                <router-link to="/reporting/checkin" class="">
                    <li class="p-2 listings-menu-item">Entradas</li>
                </router-link>
                <router-link to="/reporting/checkout" class="">
                    <li class="p-2 listings-menu-item">Salidas</li>
                </router-link>

                <router-link to="/reporting/breakfast" class="">
                    <li class="p-2 listings-menu-item">Desayunos</li>
                </router-link>
                <router-link to="/reporting/parking" class="">
                    <li class="p-2 listings-menu-item">Parking</li>
                </router-link>
                <router-link to="/reporting/stays" class="">
                    <li class="p-2 listings-menu-item">Estancias</li>
                </router-link>
                <router-link to="/reporting/forecast_occupancy" class="">
                    <li class="p-2 listings-menu-item">Previsión de ocupación</li>
                </router-link>
            </ul>
        </div>

        <div class="mb-5">
            <div class="text-muted text-uppercase ps-2 mb-2" style="font-weight: 500">
                Facturación
            </div>
            <ul style="list-style: none; font-weight: 500" class="ps-0">
                <router-link to="/reporting/sales" class="">
                    <li class="p-2 listings-menu-item">Ventas</li>
                </router-link>
                <router-link to="/reporting/sales_by_payment" class="">
                    <li class="p-2 listings-menu-item">
                        Ventas por forma de pago
                    </li>
                </router-link>
                <router-link to="/reporting/payments" class="">
                    <li class="p-2 listings-menu-item">Cobros</li>
                </router-link>
                <router-link v-if="isPaynoPainActive" to="/reporting/payment_links" class="">
                    <li class="p-2 listings-menu-item">Enlaces de pago</li>
                </router-link>
                <!-- <router-link  to="/reporting/checkin" class="">
                    <li class="p-2 listings-menu-item">Ventas por habitación</li>
                </router-link>

                <router-link  to="/reporting/checkin" class="">
                    <li class="p-2 listings-menu-item">Pendientes de cobro</li>
                </router-link> -->
            </ul>
        </div>

        <div class="mb-5">
            <div class="text-muted text-uppercase ps-2 mb-2" style="font-weight: 500">
                Producción
            </div>
            <ul style="list-style: none; font-weight: 500" class="ps-0">
                <router-link to="/reporting/production" class="">
                    <li class="p-2 listings-menu-item">Producción diaria</li>
                </router-link>
                <router-link to="/reporting/production/tags" class="">
                    <li class="p-2 listings-menu-item">
                        Producción por etiquetas
                    </li>
                </router-link>
            </ul>
        </div>

        <div class="mb-5">
            <div class="text-muted text-uppercase ps-2 mb-2" style="font-weight: 500">
                Otros
            </div>
            <ul style="list-style: none; font-weight: 500" class="ps-0">
                <router-link to="/reporting/ine" class="">
                    <li class="p-2 listings-menu-item">INE mensual</li>
                </router-link>
                <router-link to="/reporting/guests" class="">
                    <li class="p-2 listings-menu-item">Registro Huéspedes</li>
                </router-link>
                <router-link to="/reporting/housekeeping" class="">
                    <li class="p-2 listings-menu-item">Housekeeping</li>
                </router-link>
            </ul>
        </div>

        <!-- <div class="mb-5">
            <div class="text-muted text-uppercase ps-2 mb-2" style="font-weight: 500;">Policía</div>
            <ul style="list-style: none; font-weight: 500;" class="ps-0">
                <router-link  to="/reporting/checkin" class="">
                    <li class="p-2 listings-menu-item">Fichero Guardia Civil</li>
                </router-link>
            </ul>
        </div> -->
    </div>
</template>

<style>
.listings-menu-item {
    cursor: pointer;
}

.listings-menu-item:hover {
    background-color: #fff;
    border-radius: 4px;
}

.listings-menu a {
    text-decoration: none;
    color: black;
}

.listings-menu a.router-link-active li {
    color: #0091ff;
    background-color: white;
}
</style>

<script>
export default {
    name: "SidebarMenu",
    data() {
        return {};
    },
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },
        isPaynoPainActive() {
            // console.log(this.$store.state.hotel.hotelModules);
            return this.$store.state.hotel.hotelModules.some(
                (module) => module.key === "module_paynopain"
            );
        },
    },
    methods: {},
    created() { },
    mounted() {
        if (!this.currentUser) {
            this.$router.push("/login");
        }
    },
};
</script>