import api from './api';
import HotelService from "./hotel.service";

class SegmentService { 


    /**************************************
     *  API HOSPEDIUM
     **************************************/
    async addSegment(params) {
        return await api.post('/segments', params)
        .then((response) => {
            return {status: response.status, data: response.data};
        })
        .catch(function (error) {
            return {status: error.response.status, data: error.response.data};
        });
    }    

    async updateSegment(id, params) {
        return await api.put('/segments/'+id, params)
        .then((response) => {
            return {status: response.status, data: response.data};
        })
        .catch(function (error) {
            return {status: error.response.status, data: error.response.data};
        });
    }

    async getAllSegments() {
        let hotel = HotelService.getLocalHotel();
        return await api.get('/segments?hotel=' + hotel.id)
        .then((response) => {
            return {status: response.status, data: response.data};
        })
        .catch(function (error) {
            return {status: error.response.status, data: error.response.data};
        });
    }

    async getSegment(id) {
        return await api.get('/segments/' + id)
        .then((response) => {
            return {status: response.status, data: response.data};
        })
        .catch(function (error) {
            return {status: error.response.status, data: error.response.data};
        });
    }
}

export default new SegmentService();