<template>
    <div class="h-100">
        <div id="submitCancelBookingInfo" class="booking-footer-button" data-bs-toggle="modal" data-bs-target="#modalCancelBooking">
            <i class="fa-regular fa-rectangle-xmark text-danger"></i>
            <div class="title text-danger">Cancelar reserva</div>
        </div>

        <!-- Modal Cancelar reserva -->
        <div v-if="!loading" class="modal fade" id="modalCancelBooking" tabindex="-1" aria-labelledby="modalCancelBooking" aria-hidden="true">
            <div class="modal-dialog modal-xl modal-fullscreen">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            Cancelar reserva {{ "#" + booking.id }}
                        </h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="alert alert-warning" role="alert">
                            La cancelación de la reserva supone la cancelación
                            de todas las habitaciones. Una vez se cancele la
                            reserva no habrá vuelta a atrás.
                        </div>
                        <div class="col-md-12 mb-3">
                            <label class="form-label">Email que recibe el correo de
                                cancelación</label>
                            <input type="email" class="form-control" v-model="cancel.email" />
                        </div>
                        <div class="form-check form-switch mb-3">
                            <input class="form-check-input" type="checkbox" v-model="cancel.sendEmail" />
                            <label class="form-check-label">Mandar Email de cancelación</label>
                        </div>

                        <div v-if="!loading && cancel.sendEmail">
                            <button type="button" class="btn btn-primary" v-on:click="saveDesign">
                                Guardar
                            </button>
                            <EmailEditor ref="emailEditor" v-on:load="editorLoaded" v-on:ready="editorReady" />
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-link text-primary" data-bs-dismiss="modal">
                            Cerrar
                        </button>
                        <button id="cancelBookingBtn" type="submit" class="btn btn-primary" @click.stop.prevent="cancelBooking()">
                            Continuar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Swal from "sweetalert2";
import InfoService from "../../services/info.service";
import BookingService from "../../services/booking.service";
import { EmailEditor } from "vue-email-editor";

export default {
    name: "CancelBooking",
    props: {
        booking: Object,
    },
    components: {
        EmailEditor,
    },
    data() {
        return {
            loading: true,
            info: null,
            cancel: {
                email: this.booking.email,
                sendEmail: false,
                htmlEmail: "",
            },
        };
    },
    methods: {
        async start() {
            this.loading = true;

            await this.getInfo();
            var content = JSON.parse(this.info.content);
            this.cancel.htmlEmail = content["html"];

            this.loading = false;
        },

        async cancelBooking() {
            window.$("#cancelBookingBtn").attr("disabled", "true");
            Swal.fire({
                position: "top",
                icon: "question",
                title: "¿Estás seguro que quieres cancelar esta reserva?",
                text: "La reserva figurará como cancelada en el PMS. Si es una reserva hecha por otro canal se deberá cancelar desde el propio canal.",
                showConfirmButton: true,
                confirmButtonText: "Confirmar cancelación",
                confirmButtonColor: "#dc3545",
                showCancelButton: true,
                cancelButtonText: "Cerrar",
            }).then(async (result) => {
                if (result.isConfirmed) {

                    Swal.fire({
                        title: 'Cancelando...',
                        text: 'Por favor, espera mientras se cancela la reserva.',
                        allowOutsideClick: false,
                        didOpen: () => {
                            Swal.showLoading();
                        }
                    });

                    try {
                        if (this.booking.origin_country != 66) {
                            this.booking.origin_region = null;
                        }
                        var response = await BookingService.cancelBooking({
                            booking: this.booking.id,
                            sendEmail: this.cancel.sendEmail,
                            email: this.cancel.email,
                            htmlEmail: this.cancel.htmlEmail,
                        });

                        if (response.status == 200) {
                            Swal.fire({
                                title: "¡Reserva cancelada!",
                                text: "Se ha cancelado correctamente.",
                                icon: "success",
                                confirmButtonColor: "#0091ff",
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    this.$router.go();
                                }
                            });
                        } else {
                            throw new Error('Error al cancelar la reserva.');
                        }
                    } catch (error) {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Ha ocurrido un error. Vuelve a intentarlo. Si el error persiste ponte en contacto con soporte@hospedium.com",
                            confirmButtonColor: "#0091ff",
                        });
                    }
                }
            });

            window.$("#cancelBookingBtn").removeAttr("disabled");
        },

        async getInfo() {
            var response = await InfoService.getInfo({
                key_name: "email-canceled",
            });
            this.info = response.data;
            // console.log(this.info, "Info")
        },

        editorLoaded() {
            // console.log("editorLoaded");
            // Pass the template JSON here
            var content = JSON.parse(this.info.content);
            this.$refs.emailEditor.editor.loadDesign(content["design"]);
        },
        // called when the editor has finished loading
        editorReady() {
            // console.log("editorReady");
        },

        async saveDesign() {
            this.$refs.emailEditor.editor.exportHtml((data) => {
                Swal.fire({
                    position: "top-end",
                    icon: "success",
                    title: "Se ha actualizado correctamente.",
                    showConfirmButton: false,
                    timer: 1500,
                    backdrop: false,
                });

                this.cancel.htmlEmail = data.html;
            });
        },
    },
    created() {
        this.start();
    },
};
</script>