import api from "./api";
import HotelService from "./hotel.service";

class BookingService {
	/**************************************
	 *  API HOSPEDIUM
	 **************************************/
	async addBooking(params) {
		let hotel = HotelService.getLocalHotel();
		params.hotel = hotel.id;
		return await api
			.post("/bookings", params)
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async updateBooking(id, params) {
		return await api
			.put("/bookings/" + id, params)
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async getAllBookings(params) {
		let hotel = HotelService.getLocalHotel();
		params.hotel = hotel.id;

		return await api
			.get("/bookings", { params: params })
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async getAllBookingRooms(params) {
		let hotel = HotelService.getLocalHotel();
		params.hotel = hotel.id;

		return await api
			.get("/bookings/rooms", { params: params })
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async getBookingRoomsSelected(bookingID) {
		return await api
			.get("/bookings/rooms/selected/" + bookingID)
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async assignRoomToGuest(guestID, data) {
		return await api
			.put("/guests/assign_room/" + guestID + "/", data)
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async getBooking(id) {
		return await api
			.get("/bookings/" + id)
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async getBookingsClient(id) {
		return await api
			.get("/bookingsclient?client=" + id)
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async getBookingsCompany(id) {
		return await api
			.get("/bookingscompany?company=" + id)
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async getBookingsDay(params) {
		let hotel = HotelService.getLocalHotel();
		params.hotel = hotel.id;
		return await api
			.get("/bookingsday", { params: params })
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async getBookingsConfirmed(params) {
		return await api
			.get("/bookingsconfirmed", { params: params })
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async getPlanning(params) {
		let hotel = HotelService.getLocalHotel();
		params.hotel = hotel.id;

		return await api
			.get("/planning", { params: params })
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	formatDate(date) {
		var d = new Date(date),
			month = "" + (d.getMonth() + 1),
			day = "" + d.getDate(),
			year = d.getFullYear();

		if (month.length < 2) month = "0" + month;
		if (day.length < 2) day = "0" + day;

		return [year, month, day].join("-");
	}

	async setInitialPlanning() {
		localStorage.removeItem("planning");
		var startDate = new Date(new Date().setDate(new Date().getDate() - 3));
		var endDate = new Date(new Date().setDate(new Date().getDate() + 21));

		var params = {
			startDate: this.formatDate(startDate),
			endDate: this.formatDate(endDate),
		};
		return await this.getPlanning(params).then((response) => {
			localStorage.setItem(
				"planning",
				JSON.stringify({
					startDate: params.startDate,
					endDate: params.endDate,
					data: response.data,
				})
			);
		});
	}

	async getAvailability(params) {
		let hotel = HotelService.getLocalHotel();
		params.hotel = hotel.id;
		params.availability = 1;

		return await api
			.get("/availability/searchRooms", { params: params })
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async isRoomTypeAvailable(params) {
		let hotel = HotelService.getLocalHotel();
		params.hotel = hotel.id;

		return await api
			.get("/availability/roomtype", { params: params })
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async getAvailabilityByDay(params) {
		let hotel = HotelService.getLocalHotel();
		params.hotel = hotel.id;

		return await api
			.get("/availability", { params: params })
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async updateChannelInventory(params, data) {
		let hotel = HotelService.getLocalHotel();
		params.hotel = hotel.id;

		params = Object.keys(params)
			.map((key) => key + "=" + params[key])
			.join("&");

		return await api
			.post("/channel/inventory/update?" + params, data)
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async getBookingRoom(id) {
		return await api
			.get("/bookings/rooms/" + id)
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async addBookingRoom(params) {
		let hotel = HotelService.getLocalHotel();
		params.hotel = hotel.id;
		return await api
			.post("/bookings/rooms", params)
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async updateBookingRoom(id, params) {
		return await api
			.put("/bookings/rooms/" + id, params)
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async cancelBookingRoom(data) {
		let hotel = HotelService.getLocalHotel();
		var params = { hotel: hotel.id };
		return await api
			.post("/bookings/rooms/cancel", data, { params: params })
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async checkinOnlineBooking(data) {
		let hotel = HotelService.getLocalHotel();
		var params = { hotel: hotel.id };
		return await api
			.post("/bookings/checkin_online/email", data, { params: params })
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async getBookingRoomErrors() {
		let hotel = HotelService.getLocalHotel();

		return await api
			.get("/bookings/rooms/error", { params: { hotel: hotel.id } })
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async addGuest(params) {
		return api
			.post("/guests", params)
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async updateGuest(id, params) {
		return api
			.put("/guests/" + id, params)
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async getGuest(id) {
		return api
			.get("/guests/" + id)
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async deleteGuest(id) {
		return api
			.delete("/guests/" + id)
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async getPortal(id) {
		return api
			.get("/portals/" + id)
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async getBookingsCheckinListDay(params) {
		let hotel = HotelService.getLocalHotel();
		params.hotel = hotel.id;
		return api
			.get("/bookings/list/checkin", { params: params })
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async getBookingsCheckoutListDay(params) {
		let hotel = HotelService.getLocalHotel();
		params.hotel = hotel.id;
		return api
			.get("/bookings/list/checkout", { params: params })
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async getBookingGuestsReporting(params) {
		let hotel = HotelService.getLocalHotel();
		params.hotel = hotel.id;
		return api
			.get("/bookings/reporting/guests", { params: params })
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async getBookingBreakfastReporting(params) {
		let hotel = HotelService.getLocalHotel();
		params.hotel = hotel.id;
		return api
			.get("/bookings/reporting/breakfast", { params: params })
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async getBreakfastReportingByDatePDF(params) {
		let hotel = HotelService.getLocalHotel();
		params.hotel = hotel.id;
		return api
			.get("/bookings/reporting/breakfast/pdf", {
				params: params,
				responseType: "blob",
			})
			.then((response) => {
				var date = new Date();
				var filename = "breakfast_" + date.getFullYear() + date.getMonth() + date.getDate() + "_" + date.getHours() + date.getMinutes() + date.getSeconds();
				const url = window.URL.createObjectURL(new Blob([response.data], { type: "application/pdf" }));
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", filename + ".pdf"); //or any other extension
				document.body.appendChild(link);
				// link.click(function(){
				//     window.$("#dialog").dialog();
				// });
				return { status: response.status, data: link.href };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async getBookingCheckinListDayReporting(params) {
		let hotel = HotelService.getLocalHotel();
		params.hotel = hotel.id;
		return api
			.get("/bookings/list/checkin/reporting", { params: params })
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async getBookingCheckoutListDayReporting(params) {
		let hotel = HotelService.getLocalHotel();
		params.hotel = hotel.id;
		return api
			.get("/bookings/list/checkout/reporting", { params: params })
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async getBookingCheckinListDayReportingPDF(params) {
		let hotel = HotelService.getLocalHotel();
		params.hotel = hotel.id;
		return api
			.get("/bookings/list/checkin/reporting/pdf", {
				params: params,
				responseType: "blob",
			})
			.then((response) => {
				var date = new Date();
				var filename = "entradas_" + date.getFullYear() + date.getMonth() + date.getDate() + "_" + date.getHours() + date.getMinutes() + date.getSeconds();
				const url = window.URL.createObjectURL(new Blob([response.data], { type: "application/pdf" }));
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", filename + ".pdf"); //or any other extension
				document.body.appendChild(link);
				// link.click(function(){
				//     window.$("#dialog").dialog();
				// });
				return { status: response.status, data: link.href };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async getBookingCheckoutListDayReportingPDF(params) {
		let hotel = HotelService.getLocalHotel();
		params.hotel = hotel.id;
		return api
			.get("/bookings/list/checkout/reporting/pdf", {
				params: params,
				responseType: "blob",
			})
			.then((response) => {
				var date = new Date();
				var filename = "salidas_" + date.getFullYear() + date.getMonth() + date.getDate() + "_" + date.getHours() + date.getMinutes() + date.getSeconds();
				const url = window.URL.createObjectURL(new Blob([response.data], { type: "application/pdf" }));
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", filename + ".pdf"); //or any other extension
				document.body.appendChild(link);
				// link.click(function(){
				//     window.$("#dialog").dialog();
				// });
				return { status: response.status, data: link.href };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async getBookingRoomListDayCost(params) {
		let hotel = HotelService.getLocalHotel();
		params.hotel = hotel.id;
		return api
			.get("/bookings/list/bookingroom_day_cost", { params: params })
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async getProductionByPortals(params) {
		let hotel = HotelService.getLocalHotel();
		params.hotel = hotel.id;
		return api
			.get("/bookings/list/booking_production_by_portals", {
				params: params,
			})
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async getParteGuardiaCivil(params) {
		let hotel = HotelService.getLocalHotel();
		params.hotel = hotel.id;
		return api
			.get("/bookings/list/guardia_civil", { params: params })
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async getGuestsByClient(params) {
		return api
			.get("/guests/client", { params: params })
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async getBookingLogs(params) {
		let hotel = HotelService.getLocalHotel();
		params.hotel = hotel.id;
		return api
			.get("/bookings/logs", { params: params })
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async getBookingGuests(params) {
		let hotel = HotelService.getLocalHotel();
		params.hotel = hotel.id;
		return api
			.get("/bookings/guests", { params: params })
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async getBookingroomGuests(bookingroomID) {
		return api
			.get("bookings/bookingroom/guests/" + bookingroomID)
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async getBookingCharges(params) {
		let hotel = HotelService.getLocalHotel();
		params.hotel = hotel.id;
		return api
			.get("/bookings/charges", { params: params })
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async getBookingChargesTaxes(params) {
		let hotel = HotelService.getLocalHotel();
		params.hotel = hotel.id;
		return api
			.get("/bookings/charges/taxes", { params: params })
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async getBookingChargesAdvancePayment(params) {
		let hotel = HotelService.getLocalHotel();
		params.hotel = hotel.id;
		return api
			.get("/bookings/charges/advance_payment", { params: params })
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async changeBookingDates(data) {
		let hotel = HotelService.getLocalHotel();
		var params = {
			hotel: hotel.id,
		};
		return api
			.post("/bookings/change/dates", data, { params: params })
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async changeBookinRoomgDates(data) {
		let hotel = HotelService.getLocalHotel();
		var params = {
			hotel: hotel.id,
		};
		return api
			.post("/bookings/rooms/change/dates", data, { params: params })
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async setBookingNoshow(data) {
		let hotel = HotelService.getLocalHotel();
		var params = {
			hotel: hotel.id,
		};
		return api
			.post("/bookings/noshow", data, { params: params })
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async cancelBooking(data) {
		let hotel = HotelService.getLocalHotel();
		var params = {
			hotel: hotel.id,
		};
		return api
			.post("/bookings/cancel", data, { params: params })
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async checkin(data) {
		let hotel = HotelService.getLocalHotel();
		var params = { hotel: hotel.id };
		return api
			.post("/bookings/checkin", data, { params: params })
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}
	async checkout(data) {
		let hotel = HotelService.getLocalHotel();
		var params = { hotel: hotel.id };
		return api
			.post("/bookings/checkout", data, { params: params })
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async updateBookingColor(booking_id, data) {
		let hotel = HotelService.getLocalHotel();
		var params = { hotel: hotel.id };
		return api
			.put(`/bookings/${booking_id}/color/`, data, { params: params })
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}
	async changeColorBookingRoom(data) {
		let hotel = HotelService.getLocalHotel();
		var params = { hotel: hotel.id };
		return api
			.put("/bookings/bookingroom/color/", data, { params: params })
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async addGuestToBookingRoom(data) {
		let hotel = HotelService.getLocalHotel();
		var params = { hotel: hotel.id };
		return api
			.post("bookings/bookingroom/guest/add", data, { params: params })
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async getParteViajero(id) {
		let hotel = HotelService.getLocalHotel();
		var params = {
			hotel: hotel.id,
		};
		return api
			.get("bookings/parte_viajero/" + id + "/pdf", {
				params: params,
				responseType: "blob",
			})
			.then((response) => {
				var date = new Date();
				var filename = "parte_viajero_" + date.getFullYear() + date.getMonth() + date.getDate() + "_" + date.getHours() + date.getMinutes() + date.getSeconds();
				const url = window.URL.createObjectURL(new Blob([response.data], { type: "application/pdf" }));
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", filename + ".pdf"); //or any other extension
				document.body.appendChild(link);
				// link.click(function(){
				//     window.$("#dialog").dialog();
				// });
				return { status: response.status, data: link.href };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async bookingPDF(id) {
		let hotel = HotelService.getLocalHotel();
		var params = {
			hotel: hotel.id,
		};
		return api
			.get("bookings/" + id + "/pdf", {
				params: params,
				responseType: "blob",
			})
			.then((response) => {
				var date = new Date();
				var filename = "booking_" + date.getFullYear() + date.getMonth() + date.getDate() + "_" + date.getHours() + date.getMinutes() + date.getSeconds();
				const url = window.URL.createObjectURL(new Blob([response.data], { type: "application/pdf" }));
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", filename + ".pdf"); //or any other extension
				document.body.appendChild(link);
				return { status: response.status, data: link.href };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async sendEmailBooking(bookingID, data) {
		return await api
			.post("bookings/send/email/" + bookingID + "/", data)
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async addBookingRoomToBooking(data) {
		let hotel = HotelService.getLocalHotel();
		var params = { hotel: hotel.id };
		return api
			.post("bookings/bookingroom/add/", data, { params: params })
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async downloadChannelBooking(booking_pk) {
		let hotel = HotelService.getLocalHotel();
		var params = { hotel: hotel.id };
		return api
			.get(`bookings/${booking_pk}/channel/download`, { params: params })
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async recalculateBookingPrices(id) {
		let hotel = HotelService.getLocalHotel();
		var params = { hotel: hotel.id };
		return api
			.post(`/bookings/${id}/prices/calculate`, {}, { params: params })
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async checkinAllRooms(booking_pk) {
		let hotel = HotelService.getLocalHotel();
		var params = { hotel: hotel.id };
		return api
			.post(`/bookings/${booking_pk}/checkin_all_rooms`, {}, { params: params })
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async checkoutAllRooms(booking_pk) {
		let hotel = HotelService.getLocalHotel();
		var params = { hotel: hotel.id };
		return api
			.post(`/bookings/${booking_pk}/checkout_all_rooms`, {}, { params: params })
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async uncheckinAllRooms(booking_pk) {
		let hotel = HotelService.getLocalHotel();
		var params = { hotel: hotel.id };
		return api
			.post(`/bookings/${booking_pk}/uncheckin_all_rooms`, {}, { params: params })
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async uncheckoutAllRooms(booking_pk) {
		let hotel = HotelService.getLocalHotel();
		var params = { hotel: hotel.id };
		return api
			.post(`/bookings/${booking_pk}/uncheckout_all_rooms`, {}, { params: params })
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async recalculateBookingPrices(booking_pk) {
		let hotel = HotelService.getLocalHotel();
		var params = { hotel: hotel.id };
		return api
			.get(`bookings/${booking_pk}/prices/calculate`, { params: params })
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async getBookingroomCharges(bookingroom_pk) {
		let hotel = HotelService.getLocalHotel();
		var params = { hotel: hotel.id };
		return api
			.get(`charges/bookingroom/${bookingroom_pk}`, { params: params })
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async updateBookingroomChargesPrices(bookingroom_pk, data) {
		let hotel = HotelService.getLocalHotel();
		var params = { hotel: hotel.id };
		return api
			.put(`charges/bookingroom/${bookingroom_pk}/prices/update/`, data, { params: params })
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async updateBookingroomRatePrice(bookingroom_pk, data) {
		let hotel = HotelService.getLocalHotel();
		var params = { hotel: hotel.id };
		return api
			.put(`/bookingroom/${bookingroom_pk}/rate/price/update/`, data, { params: params })
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}
}

export default new BookingService();
