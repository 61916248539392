<template>
	<div>
		<!-- Trigger the modal with a button -->
		<button
			class="btn btn-sm btn-light btn-active-light-primary"
			@click="generateParteViajero()"
			data-bs-toggle="modal"
			:data-bs-target="'#modalParteViajero' + guestID"
		>
			<i class="fa-solid fa-download me-2"></i>Descargar
		</button>

		<!-- Modal -->
		<div
			class="modal fade"
			:id="'modalParteViajero' + guestID"
			tabindex="-1"
			aria-labelledby="exampleModalLabel"
			aria-hidden="true"
		>
			<div class="modal-dialog modal-xl">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title">Parte de viajero</h5>
						<button
							type="button"
							class="btn-close"
							data-bs-dismiss="modal"
							aria-label="Close"
						></button>
					</div>
					<div class="modal-body">
						<embed
							v-if="!loading"
							:src="pdf"
							frameborder="0"
							width="100%"
							height="550px"
						/>
						<div
							v-else
							id="spinner-search"
							class="col-12 text-center py-5"
						>
							<div
								class="spinner-border text-primary"
								role="status"
								style="
									width: 2rem;
									height: 2rem;
									visibility: visble;
								"
							>
								<span class="visually-hidden">Loading...</span>
							</div>
							<div>Cargando información...</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>


<script>
import BookingService from "../../services/booking.service";

export default {
	name: "ParteViajero",
	props: {
		guestID: Number,
	},
	components: {},
	data() {
		return {
			pdf: "",
			parte: [],
			loading: true,
		};
	},
	methods: {
		async generateParteViajero() {
			this.loading = true;
			var response = await BookingService.getParteViajero(this.guestID);
			this.pdf = response.data;
			console.log(this.pdf, "PDF");
			this.loading = false;
		},
	},
	created() {},
};
</script>