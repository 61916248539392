import api from './api';
class RoomcloudService { 
    

    /**************************************
     *  API HOSPEDIUM
     **************************************/

     async getInventory(params) {
        return await api.get('/channel/inventory', {params: params})
        .then((response) => {
            return Object.freeze({status: response.status, data: response.data});
        })
        .catch(function (error) {
            return {status: error.response.status, data: error.response.data};
        });
    }

    async getRates(params) {
        return await api.get('/channel/rates', {params: params})
        .then((response) => {
            return Object.freeze({status: response.status, data: response.data});
        })
        .catch(function (error) {
            return {status: error.response.status, data: error.response.data};
        });
    }

    
 

}

export default new RoomcloudService();