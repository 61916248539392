const data = JSON.parse(localStorage.getItem("user"));
import UserService from "@/services/user.service.js";

const initialState = data
	? {
			status: { loggedIn: true },
			user: data,
			roles: [], // Estado para almacenar los roles del usuario
	  }
	: {
			status: { loggedIn: false },
			user: null,
			roles: [], // Estado para almacenar los roles del usuario
	  };

const mutations = {
	SET_ROLES(state, roles) {
		state.roles = roles;
	},
};

const actions = {
	// Acción para obtener y almacenar los roles del usuario
	fetchUserRoles({ commit }) {
		return UserService.getCurrentUserRoles().then(
			(response) => {
				commit("SET_ROLES", response.data);
				return Promise.resolve(response.data);
			},
			(error) => {
				return Promise.reject(error);
			}
		);
	},
};

const getters = {
	// ... otros getters ...
	userRoles: (state) => state.roles, // Getter para acceder a los roles
};

export const user = {
	namespaced: true,
	state: initialState,
	mutations,
	actions,
	getters,
};
