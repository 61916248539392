<template>
    <div>
        <button id="test" class="btn btn-primary" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvas-planning" aria-controls="offcanvas-planning" style="display: none">
            Toggle top offcanvas
        </button>

        <div v-if="bookingId" id="offcanvas-planning" class="offcanvas offcanvas-end show" tabindex="-1">
            <div class="offcanvas-header">
                <h5 id="offcanvasRightLabel" class="mb-0">
                    Reserva #{{ bookingId }}
                </h5>
                <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div v-if="!loading" class="offcanvas-body">
                <div class="card mb-2">
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table fw-semibold table-sm mb-0">
                                <tbody>
                                    <tr>
                                        <td class="text-gray-500">ID reserva:</td>
                                        <td class="text-gray-800">{{ booking.bookingId }}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-gray-500">Canal:</td>
                                        <td class="text-gray-800" v-if="booking.portal.name">{{ booking.portal.name }}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-gray-500">Fecha de llegada:</td>
                                        <td class="text-gray-800">{{ booking.checkin }}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-gray-500">Fecha de salida:</td>
                                        <td class="text-gray-800">{{ booking.checkout }}</td>
                                    </tr>

                                    <tr>
                                        <td class="text-gray-500">Precio total:</td>
                                        <td class="text-gray-800">
                                            <span v-if="booking.show_taxes">{{ formatCurrency(booking.total_amount) }}<br><small class="text-muted">Impuestos incluidos</small></span>
                                            <span v-else>{{ formatCurrency(booking.baserate_amount) }}<br><small class="text-muted">Impuestos no incluidos</small></span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="text-gray-500">Estado</td>
                                        <td class="text-gray-800">
                                            <div>
                                                <span v-if="booking.status == 4" class="badge badge-primary">Confirmada</span>
                                                <span v-else-if="booking.status == 8" class="badge badge-warning text-dark">Modificada</span>
                                                <span v-else-if="booking.status == 9" class="badge badge-success">Checkin</span>
                                                <span v-else-if="booking.status == 10" class="badge badge-secondary">Checkout</span>
                                                <span v-else-if="booking.status == 7" class="badge badge-danger">Cancelada</span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="text-gray-500">Cliente:</td>
                                        <td class="text-gray-800">
                                            <ul class="ps-0 mb-0" style="list-style: none">
                                                <li class="text-uppercase mb-1">
                                                    <small><i class="fa-solid fa-user me-2"></i>{{ `${booking.firstName} ${booking.lastName}` }}</small>
                                                </li>
                                                <li v-if="booking.email" class="text-uppercase mb-1">
                                                    <small><i class="fa-solid fa-envelope me-2"></i>{{ booking.email }}</small>
                                                </li>
                                                <li v-if="booking.telephone" class="text-uppercase mb-1">
                                                    <small><i class="fa-solid fa-phone me-2"></i>{{ booking.telephone }}</small>
                                                </li>
                                            </ul>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="text-gray-500">Personas:</td>
                                        <td class="text-gray-800">
                                            <div class="d-flex">
                                                <small class="me-5"><i class="fa-solid fa-person me-2" aria-hidden="true"></i>{{ `Adultos: ${booking.adults}` }}</small>
                                                <small><i class="fa-solid fa-baby me-2" aria-hidden="true"></i>{{ `Niños: ${booking.children}` }}</small>
                                            </div>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="text-gray-500">Habitaciones:</td>
                                        <td class="text-gray-800">
                                            <small class="me-5"><i class="fa-regular fa-bed  me-2" aria-hidden="true"></i>{{ `${booking.rooms.length}` }}</small>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="d-grid gap-2 col-8 mx-auto mt-3 mb-5">
                    <router-link class="btn btn-primary text-white text-uppercase" :to="{ name: 'ViewBooking', params: { bookingId: booking.id } }">Ver reserva</router-link>
                </div>




                <div class="row mx-0">
                    <div class="col-md-12 mb-3">
                        <div class="d-flex align-items-center form-check form-switch mt-3 mb-2 ps-0">
                            <input class="form-check-input me-3 mt-0" type="checkbox" @change="handleCheckinAllRooms" v-model="checkinAllChecked" :disabled="booking.statusCheckout !== 1"
                                style="font-size: 24px; cursor: pointer; margin-left: 0rem;" />
                            <strong>Realizar Check-in en todas las habitaciones</strong>
                        </div>
                        <div class="d-flex align-items-center form-check form-switch mb-2 mt-3 ps-0">
                            <input class="form-check-input me-3 mt-0" type="checkbox" @change="handleCheckoutAllRooms" v-model="checkoutAllChecked" :disabled="booking.statusCheckin !== 3"
                                style="font-size: 24px; cursor: pointer; margin-left: 0rem;" />
                            <strong>Realizar Check-out en todas las habitaciones</strong>
                        </div>
                        <PlanningChangeColorBooking :bookingId="bookingId" @updateColor="updateBookingColor" :colorSelected="booking.color" class="mb-2 mt-3" />

                    </div>
                    <div class="col-md-12 mb-3">
                        <label class="mb-2">Habitaciones</label>

                        <PlanningBookingRoomOffCanvas v-for="BR in bookingrooms" :key="BR" :bookingroom="BR" :show_taxes="booking.show_taxes" @updatePlanning="updatePlanning" />
                    </div>
                    <div class="col-md-12 mb-3">
                        <label class="mb-2">Húespedes</label>
                        <div v-if="!guests || !guests.length">
                            <small>No hay huéspedes registrados</small>
                        </div>
                        <div v-else class="table-responsive">
                            <table class="table table-sm table-row-dashed">
                                <tbody>
                                    <tr :key="g" v-for="g in guests" class="align-middle">
                                        <td>
                                            <router-link class="btn btn-secondary" :to="'/guests/' + g.id"><i class="fa-regular fa-user"></i></router-link>
                                        </td>
                                        <td>
                                            <span>{{
            g.client_hotel.firstName +
            " " +
            g.client_hotel.lastName1 +
            " " +
            g.client_hotel.lastName2
                                                }}</span>
                                        </td>
                                        <td>
                                            <div>
                                                <div>Fecha registro</div>
                                                {{ formatDate(g.dateCreated) }}
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="col-md-12 mb-3">
                        <label class="mb-2">Observaciones cliente</label>
                        <p>
                            <small>{{ booking.notes }}</small>
                        </p>
                    </div>
                    <div class="col-md-12 mb-3">
                        <label class="mb-2">Observaciones hotel</label>
                        <p>
                            <small>{{ booking.notesHotel }}</small>
                        </p>
                    </div>

                </div>
            </div>
            <div v-else id="spinner-search" class="col-12 text-center py-5">
                <div class="spinner-border text-primary" role="status" style="width: 2rem; height: 2rem; visibility: visble">
                    <span class="visually-hidden">Loading...</span>
                </div>
                <div><small>Cargando información...</small></div>
            </div>
        </div>
    </div>
</template>

<script>
import BookingService from "@/services/booking.service";

import PlanningBookingRoomOffCanvas from "@/components/planning/PlanningBookingRoomOffCanvas";
import PlanningChangeColorBooking from "@/components/planning/PlanningChangeColorBooking";

import { format, isValid } from 'date-fns';
import { es } from 'date-fns/locale';

export default {
    name: "PlanningBookingRoomsListOffCanvas",
    props: {
        bookingId: Number,
    },
    emits: ["updateOffCanvas", "updatePlanning"],
    components: {
        PlanningBookingRoomOffCanvas,
        PlanningChangeColorBooking
    },
    data() {
        return {
            booking: null,
            bookingrooms: null,
            guests: null,
            loading: true,
            defaultCurrency: '',
            checkinAllChecked: false,
            checkoutAllChecked: false,
        };
    },
    watch: {
        bookingId: function () {
            // watch it
            this.start();
        },
    },
    methods: {
        async start() {
            // window.$("#offcanvas-planning").addClass("show")
            // window.$("#offcanvas-planning").css("visibility", "visible")

            window.$("#test").click();
            this.loading = true;
            await this.getBooking();
            await this.getBookingGuests();

            this.loading = false;

            var myOffcanvas = document.getElementById("offcanvas-planning");
            myOffcanvas.addEventListener(
                "hidden.bs.offcanvas",
                this.updateOffCanvas
            );
        },

        async getBooking() {
            var response = await BookingService.getBooking(this.bookingId);
            if (response.status == 200) {
                this.booking = response.data;
                this.bookingrooms = response.data.rooms;
                this.checkinAllChecked = (this.booking.statusCheckin === 3);
                this.checkoutAllChecked = (this.booking.statusCheckout === 3);
            }

            // console.log("Booking", this.booking)
        },

        async getBookingGuests() {
            var response = await BookingService.getBookingGuests({
                booking: this.booking.id,
            });
            this.guests = response.data;
            // console.log(this.guests, "guests")
        },



        formatDate(date) {
            const dateObject = new Date(date);
            if (isValid(dateObject)) {
                return format(dateObject, 'dd MMM yyyy HH:mm', { locale: es });
            } else {
                console.error('Invalid date:', date);
                return ''; // or some fallback representation of an invalid date
            }
        },

        goToBookingRoom(id) {
            this.$router.push("/bookings/checkin/" + id);
        },

        updateOffCanvas() {
            this.$emit("updateOffCanvas", this.bookingId);
        },

        updatePlanning() {
            this.$emit("updatePlanning");
        },

        async updateBookingColor() {
            this.loading = true;
            await this.getBooking();
            await this.getBookingGuests();
            this.updatePlanning()
            this.loading = false;
        },

        async formCheckinAllRooms() {
            this.$swal.fire({
                title: 'Guardando...',
                allowOutsideClick: false,
                didOpen: () => {
                    this.$swal.showLoading();
                }
            });

            let response;
            if (!this.checkinAllChecked) {
                response = await BookingService.uncheckinAllRooms(this.booking.id);
            } else {
                response = await BookingService.checkinAllRooms(this.booking.id);
            }


            if (response.status === 200) {
                this.booking.statusCheckin = this.checkinAllChecked ? 1 : 3; // Toggle check-in status
                this.$swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: `Check-in ${!this.checkinAllChecked ? ' revertido' : 'completado'} para todas las estancias.`,
                    showConfirmButton: false,
                    timer: 3000,
                    toast: true
                });

                this.loading = true;
                await this.getBooking();
                await this.getBookingGuests();
                this.updatePlanning()
                this.loading = false;

            } else {
                console.error("Failed to update check-in status for all rooms: ", response.data);
                this.$swal.fire({
                    icon: 'error',
                    title: `Error`,
                    text: response.data.message,
                    showConfirmButton: false,
                });
            }

        },

        async formCheckoutAllRooms() {

            this.$swal.fire({
                title: 'Guardando...',
                allowOutsideClick: false,
                didOpen: () => {
                    this.$swal.showLoading();
                }
            });

            let response;
            if (!this.checkoutAllChecked) {
                response = await BookingService.uncheckoutAllRooms(this.booking.id);
            } else {
                response = await BookingService.checkoutAllRooms(this.booking.id);
            }

            if (response.status === 200) {
                this.booking.statusCheckout = this.checkoutAllChecked ? 1 : 3; // Toggle check-out status
                this.$swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: `Check-out ${!this.checkoutAllChecked ? 'revertido' : 'completado'} para todas las estancias.`,
                    showConfirmButton: false,
                    timer: 3000,
                    toast: true
                });
                this.loading = true;
                await this.getBooking();
                await this.getBookingGuests();
                this.updatePlanning()
                this.loading = false;
            } else {
                console.error("Failed to update check-out status for all rooms: ", response.data);
                this.$swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: `Failed to ${this.checkoutAllChecked ? 'revert' : 'complete'} check-out for all rooms.`,
                    showConfirmButton: false,
                });
            }
        },

        handleCheckinAllRooms(event) {
            this.checkinAllChecked = event.target.checked;
            this.formCheckinAllRooms();
        },

        handleCheckoutAllRooms(event) {
            this.checkoutAllChecked = event.target.checked;
            this.formCheckoutAllRooms();
        }

    },
    created() {
    },
    mounted() {
        this.start();
    },
};
</script>

<style scoped>
#offcanvas-planning {
    z-index: 1051;
}

#offcanvas-planning .vue-swatches__trigger {
    width: 20px !important;
    height: 20px !important;
    border-radius: 4px !important;
}

.text-gray-500 {
    color: #99A1B7 !important;
}

.text-gray-800 {
    color: #252F4A !important;
}

.card {
    --bs-card-box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.03);
    --bs-card-border-color: #F1F1F4;
    border: 1px solid var(--bs-card-border-color);
    border-radius: 1rem;
}

.table:not(.table-bordered).table-row-dashed tr {
    border-bottom-width: 1px;
    border-bottom-style: dashed;
    border-bottom-color: var(--bs-border-color);
}
</style>
