<template>
    <div>
        <div class="d-grid gap-2">
            <button type="button" class="btn btn-primary mb-2" data-bs-toggle="modal" data-bs-target="#modalSendPaymentLink">
                <i class="fa-regular fa-paper-plane-top me-2"></i>Enviar enlace de pago
            </button>
        </div>

        <!-- Modal Enlace de Pago -->
        <div class="modal fade" id="modalSendPaymentLink" tabindex="-1" aria-labelledby="modalSendPaymentLink" aria-hidden="true">
            <div class="modal-dialog modal-xl ">
                <div class="modal-content">
                    <form @submit.prevent.stop="sendPainopainEmailLink">
                        <div class="modal-header">
                            <h5 class="modal-title">Enviar enlace de pago</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div v-if="!loading" class="modal-body">
                            <div class="row mb-5">
                                <!-- <div class="form-group mb-3 col-md-4">
                                    <MazSelect v-model="sendPaymentObj.operative" label="Operativa de la Orden" :options="['AUTHORIZATION', 'PAYOUT']" />
                                </div> -->
                                <div class="form-group mb-3 col-md-4">
                                    <MazInputNumber v-model="sendPaymentObj.quantity_percent" label="Porcentaje a cobrar (%)" :min="0" :max="100" class="w-100" no-buttons
                                        @update:model-value="updateQuantityPercent" />
                                </div>
                                <div class="form-group mb-3 col-md-4">
                                    <MazInputPrice label="Cantidad a cobrar" :currency="defaultCurrency" locale="es-ES" :min="0" :step="0.01" v-model="sendPaymentObj.quantity_amount"
                                        @update:model-value="updateQuantityAmount" />
                                </div>
                                <div class="form-group mb-3 col-md-4">
                                    <MazPicker v-model="sendPaymentObj.expires_at" label="Fecha de expiración del enlace de pago" class="w-100" time :minDate="minDate" format="YYYY-MM-DD HH:mm:ss" />
                                </div>
                            </div>
                            <!-- <div class="mb-5 alert alert-primary" role="alert">
                                <h6>Tipos de operativa</h6>
                                <div class="mb-1">
                                    <small>
                                        <strong>AUTHORIZATION:</strong> Bloquea el monto especificado en la cuenta del pagador sin realizar el cobro inmediato. Utilizado para verificar los fondos y la
                                        validez de la tarjeta.</small>
                                </div>
                                <div class="mb-1">
                                    <small><strong>PAYOUT:</strong> Transfiere el monto especificado desde la cuenta del receptor a la cuenta del destinatario.</small>
                                </div>
                            </div> -->
                            <h5 class="mb-3">Configuración del email</h5>
                            <div class="form-group mb-3 col-md-4">
                                <MazSelect v-model="email_template" @update:model-value="changeEmailTemplate()" label="Plantilla de email" :options="options_email_template" />
                            </div>
                            <div class="form-group mb-1" id="recipientInputTo">
                                <MazInputTags ref="recipientInputTo" v-model="sendPaymentObj.recipient_list_to" label="Para:" color="primary" size="sm" :error="errors.recipient_list_to"
                                    @update:model-value="check_recipient_list_to(sendPaymentObj.recipient_list_to)" class="w-100" @blur="handleBlur('to', $event)" />
                            </div>
                            <div class="form-group mb-1">
                                <MazInputTags ref="recipientInputCc" v-model="sendPaymentObj.recipient_list_cc" label="CC:" color="primary" size="sm"
                                    @update:model-value="check_recipient_list_cc(sendPaymentObj.recipient_list_cc)" class="w-100" @blur="handleBlur('cc', $event)" />
                            </div>
                            <div class="form-group mb-1">
                                <MazInputTags ref="recipientInputBcc" v-model="sendPaymentObj.recipient_list_bcc" label="BCC:" color="primary" size="sm"
                                    @update:model-value="check_recipient_list_bcc(sendPaymentObj.recipient_list_bcc)" class="w-100" @blur="handleBlur('bcc', $event)" />
                            </div>
                            <div class="form-group mb-1">
                                <MazInput v-model="sendPaymentObj.subject" label="Asunto:" color="primary" />
                            </div>

                            <EmailEditor v-if="email_template == 'email_template_1'" ref="emailEditor1" v-on:load="editorLoaded1" v-on:ready="editorReady1" />
                            <EmailEditor v-if="email_template == 'email_template_2'" ref="emailEditor2" v-on:load="editorLoaded2" v-on:ready="editorReady2" />


                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-link text-primary" data-bs-dismiss="modal">
                                Cerrar
                            </button>
                            <button id="submitSendPayment" type="submit" class="btn btn-primary">
                                Enviar
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import HotelService from "@/services/hotel.service";
import InfoService from "@/services/info.service";
import PaymentService from "@/services/payment.service";
import { EmailEditor } from "vue-email-editor";
import Swal from "sweetalert2";

export default {
    name: "SendPaymentLinkBooking",
    props: {
        booking: Object,
    },
    data() {
        return {
            defaultCurrency: '',
            loading: true,
            hotel: HotelService.getLocalHotel(),
            email_info_1: null,
            error: false,
            myModal: null,
            errors: {},
            minDate: '',
            sendPaymentObj: {
                subject: null,
                html_content: null,
                recipient_list_to: [],
                recipient_list_cc: [],
                recipient_list_bcc: [],
                booking: this.booking.id,
                quantity_percent: 0,
                quantity_amount: 0,
                expires_at: null,
                operative: 'AUTHORIZATION',
                type: 'MAIL',
                reference: this.booking.id,
                description: "Booking #" + this.booking.id,
                customer_ext_id: this.booking.id
            },
            email_template: 'email_template_1',
            options_email_template: [
                { label: '1. Envío Enlace de pago', value: 'email_template_1' },
                { label: '2. Recordatorio Enlace de pago', value: 'email_template_2' }
            ],
            isEditingQuantityPercent: false,
            isEditingQuantityAmount: false
        };
    },
    components: {
        EmailEditor,
    },
    methods: {
        async start() {
            this.loading = true;
            await this.getInfo();
            await this.getDefaultExpiryDate()
            await this.getCurrentDateTime()
            await this.calculateDefaultAmount();

            var content_default = JSON.parse(this.email_info_1.content);
            this.sendPaymentObj.html_content = content_default["html"];

            if (this.booking && this.booking.email) {
                this.sendPaymentObj.recipient_list_to.push(this.booking.email);
            }

            this.sendPaymentObj.subject = this.hotel.name + " ~ Enlace de pago para confirmar tu reserva";

            this.loading = false;
        },

        async getInfo() {
            var response1 = await InfoService.getInfo({
                key_name: "email-paynopain-link-1",
            });
            this.email_info_1 = response1.data;

            var response2 = await InfoService.getInfo({
                key_name: "email-paynopain-link-2",
            });
            this.email_info_2 = response2.data;

            // console.log("this.email_info_2", this.email_info_2)
        },

        updateQuantityPercent(newVal) {
            // Actualiza el valor de porcentaje
            this.sendPaymentObj.quantity_percent = newVal;

            // Si la reserva y el monto total son válidos, calcula la cantidad
            if (this.booking && this.booking.total_amount > 0) {
                this.sendPaymentObj.quantity_amount = parseFloat(((newVal / 100) * this.booking.total_amount).toFixed(2));
            }
        },
        updateQuantityAmount(newVal) {
            // Actualiza el valor de la cantidad
            this.sendPaymentObj.quantity_amount = newVal;

            // Si la reserva y el monto total son válidos, calcula el porcentaje
            if (this.booking && this.booking.total_amount > 0) {
                // Calcula el porcentaje y redondea al número entero más cercano
                this.sendPaymentObj.quantity_percent = Math.round((newVal / this.booking.total_amount) * 100);
            }
        },

        handleBlur(type, event) {
            // Define la expresión regular para validar el formato de correo electrónico
            const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            const newVal = event.target.value.trim();

            // Si se hace clic fuera del input y el valor es un email válido
            if (emailRegex.test(newVal)) {
                this.addEmail(type, newVal);
                event.target.value = ""
            }
        },
        addEmail(type, email) {
            if (type === 'to') {
                if (!this.sendPaymentObj.recipient_list_to.includes(email)) {
                    this.sendPaymentObj.recipient_list_to.push(email);
                }
            } else if (type === 'cc') {
                if (!this.sendPaymentObj.recipient_list_cc.includes(email)) {
                    this.sendPaymentObj.recipient_list_cc.push(email);
                }
            } else if (type === 'bcc') {
                if (!this.sendPaymentObj.recipient_list_bcc.includes(email)) {
                    this.sendPaymentObj.recipient_list_bcc.push(email);
                }
            }
        },

        changeEmailTemplate() {
            if (this.email_template == 'email_template_1') {
                this.sendPaymentObj.subject = this.hotel.name + " ~ Enlace de pago para confirmar tu reserva";
            } else {
                this.sendPaymentObj.subject = this.hotel.name + " ~ Último aviso para confirmar tu reserva";
            }
        },

        editorLoaded1() {
            // console.log("editorLoaded");
            // Pass the template JSON here
            var content = JSON.parse(this.email_info_1.content);
            this.$refs.emailEditor1.editor.loadDesign(content["design"]);

            var meta_tags = {
                hotel_name: {
                    name: "Hotel Name",
                    value: "{{hotel_name}}",
                },
                booking_number: {
                    name: "Booking Number",
                    value: "{{booking_number}}",
                },
                first_name: {
                    name: "First Name",
                    value: "{{first_name}}",
                },
                hotel_logo: {
                    name: "Hotel Logo",
                    value: "{{hotel_logo}}",
                },
                checkin_date: {
                    name: "Checkin Date",
                    value: "{{checkin_date}}",
                },
                checkout_date: {
                    name: "Checkout Date",
                    value: "{{checkout_date}}",
                },

                booking_price: {
                    name: "Booking Price",
                    value: "{{booking_price}}",
                },

                link_payment: {
                    name: "Link Payment",
                    value: "{{link_payment}}",
                },

                quantity_percent: {
                    name: "Quantity Percent",
                    value: "{{quantity_percent}}",
                },
                quantity_amount: {
                    name: "Quantity Amount",
                    value: "{{quantity_amount}}",
                },
            }

            this.$refs.emailEditor1.editor.setMergeTags(meta_tags);
        },
        editorLoaded2() {
            // console.log("editorLoaded");
            // Pass the template JSON here
            var content = JSON.parse(this.email_info_2.content);
            this.$refs.emailEditor2.editor.loadDesign(content["design"]);

            var meta_tags = {
                hotel_name: {
                    name: "Hotel Name",
                    value: "{{hotel_name}}",
                },
                booking_number: {
                    name: "Booking Number",
                    value: "{{booking_number}}",
                },
                first_name: {
                    name: "First Name",
                    value: "{{first_name}}",
                },
                hotel_logo: {
                    name: "Hotel Logo",
                    value: "{{hotel_logo}}",
                },
                checkin_date: {
                    name: "Checkin Date",
                    value: "{{checkin_date}}",
                },
                checkout_date: {
                    name: "Checkout Date",
                    value: "{{checkout_date}}",
                },

                booking_price: {
                    name: "Booking Price",
                    value: "{{booking_price}}",
                },

                link_payment: {
                    name: "Link Payment",
                    value: "{{link_payment}}",
                },

                quantity_percent: {
                    name: "Quantity Percent",
                    value: "{{quantity_percent}}",
                },
                quantity_amount: {
                    name: "Quantity Amount",
                    value: "{{quantity_amount}}",
                },
            }

            this.$refs.emailEditor2.editor.setMergeTags(meta_tags);
        },
        editorReady1() {
            // console.log("editorReady");
        },
        editorReady2() {
            // console.log("editorReady");
        },

        check_recipient_list_to(newEmails) {
            // Define la expresión regular para validar el formato de correo electrónico
            const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            let invalidEmailsFound = false;

            // Filtra la lista para mantener solo los elementos que cumplen con el formato de correo electrónico
            // y detecta si hay correos inválidos
            this.sendPaymentObj.recipient_list_to = newEmails.filter(email => {
                const isValid = emailRegex.test(email);
                if (!isValid) invalidEmailsFound = true;
                return isValid;
            });

            // Muestra un toast si se encontró al menos un correo inválido
            if (invalidEmailsFound) {
                Swal.fire({
                    toast: true,
                    icon: 'error',
                    title: 'El email introducido no es válido.',
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                });
            }
        },

        check_recipient_list_cc(newEmails) {
            // Define la expresión regular para validar el formato de correo electrónico
            const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            let invalidEmailsFound = false;

            // Filtra la lista para mantener solo los elementos que cumplen con el formato de correo electrónico
            // y detecta si hay correos inválidos
            this.sendPaymentObj.recipient_list_cc = newEmails.filter(email => {
                const isValid = emailRegex.test(email);
                if (!isValid) invalidEmailsFound = true;
                return isValid;
            });

            // Muestra un toast si se encontró al menos un correo inválido
            if (invalidEmailsFound) {
                Swal.fire({
                    toast: true,
                    icon: 'error',
                    title: 'El email introducido no es válido.',
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                });
            }
        },

        check_recipient_list_bcc(newEmails) {
            // Define la expresión regular para validar el formato de correo electrónico
            const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            let invalidEmailsFound = false;

            // Filtra la lista para mantener solo los elementos que cumplen con el formato de correo electrónico
            // y detecta si hay correos inválidos
            this.sendPaymentObj.recipient_list_bcc = newEmails.filter(email => {
                const isValid = emailRegex.test(email);
                if (!isValid) invalidEmailsFound = true;
                return isValid;
            });

            // Muestra un toast si se encontró al menos un correo inválido
            if (invalidEmailsFound) {
                Swal.fire({
                    toast: true,
                    icon: 'error',
                    title: 'El email introducido no es válido.',
                    position: 'top-right',
                    showConfirmButton: false, timer: 3000,
                    timerProgressBar: true,
                });
            }
        },

        async sendPainopainEmailLink() {
            console.log("HELLO")
            this.error = false;
            this.errors = {};

            // Verificar la lista de destinatarios
            if (this.sendPaymentObj.recipient_list_to.length === 0) {
                this.errors.recipient_list_to = true;
                this.error = true;
            }

            // Verificar el campo subject
            if (!this.sendPaymentObj.subject || this.sendPaymentObj.subject.trim() === "") {
                this.errors.subject = true;
                this.error = true;
            }

            if (this.error) {
                return;
            } else {
                this.$swal.fire({
                    title: 'Enviando...',
                    didOpen: () => {
                        this.$swal.showLoading();
                    },
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    showConfirmButton: false
                });


                try {
                    await this.exportHtml();

                    console.log(this.sendPaymentObj)
                    const response = await PaymentService.sendPainopainEmailLink(
                        this.sendPaymentObj
                    );

                    this.$swal.close();
                    const modal = document.getElementById("modalSendPaymentLink");
                    const modalBackdrop = document.querySelector(".modal-backdrop");
                    modal.style.display = "none";
                    document.body.classList.remove("modal-open");
                    modalBackdrop.remove();

                    if (response.status == 201) {
                        this.$swal.fire({
                            icon: "success",
                            title: "La solicitud de pago se ha enviado correctamente.",
                            confirmButtonColor: "#0091ff",
                        }).then(() => {
                            this.$router.go();
                        });
                    } else {
                        this.$swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Ha ocurrido un error. Vuelve a intentarlo. Si el error persiste ponte en contacto con soporte@hospedium.com",
                            confirmButtonColor: "#0091ff",
                        });
                    }
                } catch (error) {
                    // If an error occurs, we need to delete the created payment
                    try {
                        await PaymentService.deletePayment(this.sendPaymentObj.payment_id);
                    } catch (deleteError) {
                        console.error('Failed to delete payment after error:', deleteError);
                    }

                    this.$swal.close();
                    this.$swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Ha ocurrido un error. Vuelve a intentarlo.",
                        confirmButtonColor: "#0091ff",
                    });
                }
            }
        },

        async calculateDefaultAmount() {
            if (this.booking.show_taxes) {
                this.sendPaymentObj.quantity_amount = this.booking.total_amount - this.booking.total_paid;
                this.sendPaymentObj.quantity_percent = (this.sendPaymentObj.quantity_amount / this.booking.total_amount) * 100;
            } else {
                this.sendPaymentObj.quantity_amount = this.booking.baserate_amount - this.booking.total_paid;
                this.sendPaymentObj.quantity_percent = (this.sendPaymentObj.quantity_amount / this.booking.baserate_amount) * 100;
            }
        },

        async getDefaultExpiryDate() {
            const tomorrow = new Date();
            tomorrow.setDate(tomorrow.getDate() + 1);
            // Set to the current hour, minute, second
            const hours = tomorrow.getHours();
            const minutes = tomorrow.getMinutes();
            const seconds = tomorrow.getSeconds();
            tomorrow.setHours(hours, minutes, seconds, 0); // Preserve current time
            this.sendPaymentObj.expires_at = tomorrow.toISOString().slice(0, 19).replace('T', ' '); // Returns date in YYYY-MM-DD HH:MM:SS format
        },

        async getCurrentDateTime() {
            const now = new Date();
            const hours = now.getHours();
            const minutes = now.getMinutes();
            const seconds = now.getSeconds();
            now.setHours(hours, minutes, seconds, 0); // Preserve current time
            this.minDate = now.toISOString().slice(0, 19).replace('T', ' '); // Returns date in YYYY-MM-DD HH:MM:SS format
        },

        async exportHtml() {
            return new Promise((resolve, reject) => {
                const selectedEditor = this.email_template === 'email_template_1' ? this.$refs.emailEditor1 : this.$refs.emailEditor2;

                selectedEditor.editor.exportHtml(
                    (data) => {
                        this.sendPaymentObj.html_content = data.html;
                        resolve(); // Resolviendo la promesa aquí
                    },
                    (error) => {
                        reject(error); // En caso de error, rechazamos la promesa
                    }
                );
            });
        }
    },
    created() {
        this.defaultCurrency = localStorage.getItem('currency') || 'EUR';
        this.start();
    },
    mounted() {
        this.myModal = new this.$bootstrap.Modal(document.getElementById('modalSendPaymentLink'));
    }
};
</script>
<style scoped>
/* Add any specific styles for the new component here */
</style>
