<template>
    <div class="h-100">
        <Navbar v-if="isLoggedIn && !isLoginPage" />
        <Sidebar v-if="isLoggedIn && !isLoginPage" />

        <div class="h-100">
            <router-view />
        </div>

        <div id="loader-hotel" style="
				position: fixed;
				z-index: 9999999999999;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				background: rgb(255 255 255 / 98%);
				display: none;
			">
            <div class="text-center text-primary" style="
					height: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
					flex-wrap: wrap;
					flex-direction: column;
				">
                <div class="spinner-border" style="width: 3rem; height: 3rem" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
                <div class="mt-3">Cargando información del hotel</div>
            </div>
        </div>
    </div>
</template>

<script>
import EventBus from "./common/EventBus";

import Navbar from "./components/Navbar.vue";
import Sidebar from "./components/Sidebar.vue";

import pmsService from "./services/pms.service";

export default {
    data() {
        return {
            updateInterval: null,  // Almacenar la referencia al intervalo
        };
    },
    components: {
        Navbar: Navbar,
        Sidebar: Sidebar,
    },
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },
        isLoggedIn() {
            return !!this.currentUser;
        },
        isLoginPage() {
            return this.$route.path === '/login';
        },
    },
    methods: {
        logOut() {
            this.$store.dispatch("auth/logout");
            this.$router.push("/login");
        },

        async getPMSVersion() {
            var response = await pmsService.getCurrentPMSVersion()
            return response.data.version
        },
        async checkForUpdates() {
            const currentVersion = localStorage.getItem("currentVersion");
            const serverVersion = await this.getPMSVersion()

            if (currentVersion !== serverVersion) {
                this.refreshApp();
                localStorage.setItem("currentVersion", serverVersion);
            }
        },
        refreshApp() {
            console.log("Nueva versión")
            window.location.reload(); // Recarga la aplicación
        }
    },

    mounted() {
        EventBus.on("logout", () => {
            this.logOut();
        });
        this.$store.dispatch("initializeStore");

        this.checkForUpdates();  // Comprueba por actualizaciones inmediatamente al montar

        // Configura el intervalo para comprobar actualizaciones cada minuto
        this.updateInterval = setInterval(this.checkForUpdates, 1000 * 60 * 30);
    },

    beforeUnmount() {
        EventBus.remove("logout");
        // Limpia el intervalo cuando el componente se vaya a destruir
        if (this.updateInterval) {
            clearInterval(this.updateInterval);
        }

    }
};
</script>

<style lang="scss">
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>