<template>
    <div class="page-app viewBooking">
        <div class="row mx-0 h-100">
            <div class="col-xxl-9 col-xl-8">
                <div class="row">
                    <div class="col-xxl-6">
                        <div class="card mb-3 border-0 shadow-sm p-3">
                            <div class="card-header d-flex align-items-center bg-white border-0">
                                <div v-if="booking != null" class="modal-title me-3">
                                    <div class="fs-4" style="font-weight: 500">
                                        Reserva #{{ booking.id }}
                                    </div>
                                </div>
                                <div v-if="booking != null">
                                    <span v-if="booking.status == 2" class="badge badge-primary me-1 text-uppercase">Submitted</span>
                                    <span v-else-if="booking.status == 4" class="badge badge-primary me-1 text-uppercase">Confirmado</span>
                                    <span v-else-if="booking.status == 6" class="badge badge-dark me-1 text-uppercase">No Show</span>
                                    <span v-else-if="booking.status == 7" class="badge badge-danger me-1 text-uppercase">Cancelado</span>
                                    <span v-else-if="booking.status == 5" class="badge badge-danger me-1 text-uppercase">Rechazado</span>
                                    <span v-else-if="booking.status == 8" class="badge badge-warning me-1 text-uppercase">Modificado</span>
                                    <span v-else-if="booking.status == 1" class="badge badge-success me-1 text-uppercase">En
                                        hotel</span>

                                    <span v-if="booking.isCheckinOnlineSend" class="badge badge-info me-1 text-uppercase"><i class="fa-regular fa-paper-plane-top me-2"></i>Check-in
                                        enviado</span>
                                </div>
                            </div>
                            <div class="card-body">
                                <div v-if="booking != null" class="mb-3">
                                    <div>
                                        <div class="row g-3 align-items-center mb-2">
                                            <div class="col-6">
                                                <label class="form-label">Llegada</label>
                                                <div class="fs-5">
                                                    {{ checkin }}
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <label class="form-label">Salida</label>
                                                <div class="fs-5">
                                                    {{ checkout }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="">
                                            <small>Fecha creación:
                                                {{ creation_date }}</small>
                                        </div>
                                        <div class="mb-3">
                                            <small>Descarga PMS:
                                                {{ download_date }}</small>
                                        </div>
                                    </div>

                                    <div class="mb-3">
                                        <div class="form-label mb-0">{{ `Canal: ${portal}` }}</div>
                                    </div>
                                    <div class="d-block d-lg-flex">
                                        <div class="me-lg-5">
                                            <div class="form-label mb-0"><i class="fa-solid fa-person me-2" aria-hidden="true"></i>{{ `Adultos: ${booking.adults}` }}</div>
                                        </div class="me-lg-5">
                                        <div class="me-lg-5">
                                            <div class="form-label mb-0"><i class="fa-solid fa-baby me-2" aria-hidden="true"></i>{{ `Niños: ${booking.children}` }}</div>
                                        </div>
                                        <div class="me-lg-5">
                                            <div class="form-label mb-0"><i class="fa-regular fa-bed  me-2" aria-hidden="true"></i>{{ `Habitaciones: ${booking.rooms.length}` }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xxl-6">
                        <div class="card mb-3 border-0 shadow-sm p-3">
                            <div class="card-header d-flex align-items-center bg-white border-0">
                                <div class="modal-title me-3 d-flex align-items-center">
                                    <i class="fa-regular fa-clipboard-user fs-3 me-2 text-primary"></i>
                                    <div class="fs-4" style="font-weight: 500">
                                        Información cliente
                                    </div>
                                </div>
                            </div>
                            <div v-if="booking != null && !this.loadingBooking" class="card-body">
                                <div class="row g-3 align-items-center mb-2">
                                    <div class="col-3">
                                        <label class="form-label">Nombre</label>
                                    </div>
                                    <div class="col-9">
                                        <input type="text" class="form-control form-control-sm form-control-solid text-uppercase" v-model="booking.firstName" @keyup="changeBooking" />
                                    </div>
                                </div>
                                <div class="row g-3 align-items-center mb-2">
                                    <div class="col-3">
                                        <label class="form-label">Apellidos</label>
                                    </div>
                                    <div class="col-9">
                                        <input type="text" class="form-control form-control-sm form-control-solid text-uppercase" v-model="booking.lastName" @keyup="changeBooking" />
                                    </div>
                                </div>
                                <div class="row g-3 align-items-center mb-2">
                                    <div class="col-3">
                                        <label class="form-label">Email</label>
                                    </div>
                                    <div class="col-9">
                                        <input type="email" class="form-control form-control-sm form-control-solid text-uppercase" v-model="booking.email" @keyup="changeBooking" />
                                    </div>
                                </div>
                                <div class="row g-3 align-items-center mb-2">
                                    <MazPhoneNumberInput v-model="booking.telephone" @update="changeBooking" :translations="{
                                    countrySelector: {
                                        placeholder: 'Código país',
                                        error: 'Elige país',
                                    },
                                    phoneInput: {
                                        placeholder: 'Nº teléfono',
                                        example: 'Ej:',
                                    },
                                }" :no-example="true" @update:model-value="testPhone" />

                                </div>
                                <div class="row g-3 align-items-center mb-2">
                                    <MazSelect id="client-tax_country" v-model="booking.country" label="Seleccionar país" :options="countries" option-value-key="isoCode2" option-label-key="name"
                                        option-input-value-key="name" search search-placeholder="Buscar..." @update:model-value="changeBooking">
                                        <template #left-icon>
                                            <MazIcon name="flag" />
                                        </template>
                                    </MazSelect>
                                </div>

                                <!-- <div class="row g-3 align-items-center mb-2">
                                    <div class="col-3">
                                        <label for="inputAddress" class="form-label">Dirección</label>
                                    </div>
                                    <div class="col-9">
                                        <input type="text" class="form-control form-control-sm form-control-solid text-uppercase" v-model="booking.address">
                                    </div>
                                </div> -->
                                <!-- <div class="row g-3 align-items-center mb-2">
                                    <div class="col-3">
                                        <label for="inputCity" class="form-label">Ciudad y CP</label>
                                    </div>
                                    <div class="col-5">
                                        <input type="text" class="form-control form-control-sm form-control-solid text-uppercase" v-model="booking.city" placeholder="Ciudad">
                                    </div>
                                    <div class="col-4">
                                        <input type="text" class="form-control form-control-sm form-control-solid text-uppercase" v-model="booking.zipCode" placeholder="Código Postal">
                                    </div>
                                </div> -->
                                <!-- <div class="row g-3 align-items-center mb-2">
                                    <div class="col-3">
                                        <label for="inputCity" class="form-label">País</label>
                                    </div>
                                    <div class="col-9">
                                        <input type="text" class="form-control form-control-sm form-control-solid text-uppercase" v-model="booking.country" @keyup="changeBooking">
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </div>

                    <div class="col-md-12">
                        <div class="card mb-3 border-0 shadow-sm p-3">
                            <div class="card-header bg-white d-block d-md-flex justify-content-between border-0">
                                <div class="modal-title mb-0 d-flex align-items-center mb-3 mb-md-0 me-md-5 me-0">
                                    <i class="fa-regular fa-bed fs-3 me-3 text-primary"></i>
                                    <div class="fs-4" style="font-weight: 500">
                                        Habitaciones
                                    </div>
                                </div>
                                <div v-if="booking != null" class="d-block d-md-flex align-items-center">
                                    <div>
                                        <!-- CHECKIN -->
                                        <span v-if="booking.statusCheckin == 1" class="badge badge-secondary me-1 text-uppercase my-1">Checkin
                                            Pendiente</span>
                                        <span v-else-if="booking.statusCheckin == 2" class="badge badge-warning me-1 text-uppercase my-1">Checkin
                                            Parcial</span>
                                        <span v-else-if="booking.statusCheckin == 3" class="badge badge-success me-1 text-uppercase my-1">Checkin
                                            Completado</span>
                                        <!-- CHECKOUT -->
                                        <span v-if="booking.statusCheckout == 1" class="badge badge-secondary me-1 text-uppercase my-1">Checkout
                                            Pendiente</span>
                                        <span v-else-if="booking.statusCheckout == 2" class="badge badge-warning me-1 text-uppercase my-1">Checkout
                                            Parcial</span>
                                        <span v-else-if="booking.statusCheckout == 3" class="badge badge-success me-1 text-uppercase my-1">Checkout
                                            Completado</span>
                                    </div>
                                    <AddBookingRoom v-if="booking.portal.id == 22 || booking.portal.id == 13 && booking.status != 7" :booking="booking" class="ms-md-3" />
                                </div>
                            </div>
                            <div class="card-body">
                                <div class="alert alert-warning" role="alert">
                                    <strong><i class="fa-regular fa-circle-info me-2"></i>No olvides
                                        completar el chekin/checkout
                                        para cada habitación.</strong>
                                </div>
                                <div v-if="booking" class="col-md-12 mb-3">
                                    <div class="d-block d-md-flex">
                                        <div class="d-flex align-items-center form-check form-switch mb-2 ps-0 me-5">
                                            <input class="form-check-input me-2 mt-0" type="checkbox" @change="handleCheckinAllRooms" v-model="checkinAllChecked"
                                                :disabled="booking.statusCheckout !== 1" style="cursor: pointer; margin-left: 0rem; font-size: 18px;" />
                                            <strong>{{ checkinAllChecked ? 'Deshacer Check-in en todas' : 'Realizar Check-in en todas' }}</strong>
                                        </div>
                                        <div class="d-flex align-items-center form-check form-switch mb-2 ps-0">
                                            <input class="form-check-input me-2 mt-0" type="checkbox" @change="handleCheckoutAllRooms" v-model="checkoutAllChecked"
                                                :disabled="booking.statusCheckin !== 3" style="cursor: pointer; margin-left: 0rem; font-size: 18px;" />
                                            <strong>{{ checkoutAllChecked ? 'Deshacer Check-out en todas' : 'Realizar Check-out en todas' }}</strong>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="booking != null" class="table-responsive">
                                    <table id="table-booking-view-rooms" class="table table-row-dashed">
                                        <thead>
                                            <tr>
                                                <th>Habitación</th>
                                                <th>Tarifa</th>
                                                <th>Total</th>
                                                <th>Estado</th>
                                                <th>Huéspedes</th>
                                                <th>Entrada</th>
                                                <th>Salida</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr :key="item" v-for="(
													item, index
												) in booking.rooms" class="align-middle" @click="
                                    handleRowBookingRoomClick(
                                        item
                                    )
                                    " style="cursor: pointer">
                                                <ViewBookingRoomRow :bookingroom="item" :index="index" :show_taxes="booking.show_taxes" />
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div v-else id="spinner-search" class="col-12 text-center py-5">
                                    <div class="spinner-border text-primary" role="status" style="
											width: 2rem;
											height: 2rem;
											visibility: visble;
										">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                    <div>Cargando información...</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="card mb-3 border-0 shadow-sm p-3">
                            <div class="card-header bg-white border-0">
                                <div class="d-flex justify-content-between">
                                    <div class="modal-title mb-0 d-flex align-items-center">
                                        <i class="fa-regular fa-file-lines fs-3 me-3 text-primary"></i>
                                        <div class="fs-4" style="font-weight: 500">
                                            Facturas
                                        </div>
                                    </div>
                                    <AddInvoice v-if="!loading" :booking="booking" :countries="countries" :guests="guests" />
                                </div>
                            </div>
                            <div class="card-body">
                                <div v-if="invoices != null" class="table-responsive">
                                    <table id="table-booking-view-rooms" class="table table-row-dashed">
                                        <thead>
                                            <tr class="text-start text-gray-400">
                                                <th>Número</th>
                                                <th>Subtotal</th>
                                                <th>Impuestos</th>
                                                <th>Total</th>
                                                <th>Estado</th>
                                                <th>Fecha</th>
                                                <th>Cliente</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr :key="item" v-for="item in invoices" @click="
                                    handleRowInvoiceClick(item)
                                    " style="cursor: pointer" class="align-middle">
                                                <ViewBookingInvoiceRow :invoice="item" />
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div v-else id="spinner-search" class="col-12 text-center py-5">
                                    <div class="spinner-border text-primary" role="status" style="
											width: 2rem;
											height: 2rem;
											visibility: visble;
										">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                    <div>Cargando información...</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 mb-2">
                        <div class="card mb-3 border-0 shadow-sm p-3">
                            <div class="card-header bg-white border-0">
                                <div class="d-flex justify-content-between">
                                    <div class="modal-title mb-0 d-flex align-items-center">
                                        <i class="fa-regular fa-money-check-dollar-pen me-2 text-primary fs-3"></i>
                                        <div class="fs-4" style="font-weight: 500">
                                            Pagos
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">
                                <div v-if="payments != null && invoices" class="table-responsive">
                                    <table id="table-booking-view-rooms" class="table table-row-dashed">
                                        <thead>
                                            <tr>
                                                <th>Fecha de pago</th>
                                                <th>Total</th>
                                                <th>Estado</th>
                                                <th>Tipo de Pago</th>
                                                <th>Factura</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <ViewBookingPaymentRow v-for="item in payments" :key="item.id" :bookingpayment="item" :invoices="invoices" />
                                        </tbody>
                                    </table>
                                </div>
                                <div v-else id="spinner-search" class="col-12 text-center py-5">
                                    <div class="spinner-border text-primary" role="status" style="
											width: 2rem;
											height: 2rem;
											visibility: visble;
										">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                    <div>Cargando información...</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xxl-3 col-xl-4">
                <div>
                    <div class="card shadow-sm border-0 p-3 mb-3">
                        <div class="card-body">
                            <div v-if="booking != null && booking.show_taxes" class="d-flex flex-column mb-3">
                                <label class="form-label mb-0 text-uppercase">Subtotal de la reserva</label>
                                <div disabled class="" style="font-size: 1.3em; font-weight: 500">
                                    <span>{{
                                    formatCurrency(booking.baserate_amount
                                        , defaultCurrency)
                                }}</span>

                                </div>
                            </div>
                            <div v-if="booking != null && booking.show_taxes" class="d-flex flex-column mb-3">
                                <label class="form-label mb-0 text-uppercase">Impuestos de la reserva</label>
                                <div disabled class="" style="font-size: 1.3em; font-weight: 500">
                                    <span>{{
                                    formatCurrency(booking.tax_amount, defaultCurrency)
                                }}</span>
                                </div>
                            </div>
                            <div class="d-flex flex-column">
                                <label class="form-label mb-0 text-uppercase">Total de la reserva</label>
                                <div v-if="booking" disabled class="" style="font-size: 1.3em; font-weight: 500">
                                    <span v-if="booking.show_taxes">{{
                                    formatCurrency(booking.total_amount, defaultCurrency) }}</span>
                                    <span v-else>{{ formatCurrency(booking.baserate_amount, defaultCurrency) }}<br><small class="text-muted" style="font-size: 12px;">Impuestos no
                                            incluidos</small></span>

                                </div>
                            </div>

                            <div v-if="roles.includes('pms_superadmin') || roles.includes('pms_admin')" class=" mt-3">
                                <div v-if="booking != null" class="form-check form-switch">
                                    <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" v-model="booking.show_taxes" @change="updateBooking">
                                    <label class="form-check-label" for="flexSwitchCheckDefault" style="font-size: 14px;">Mostrar impuestos</label>
                                </div>
                            </div>
                            <div class="d-flex flex-column mt-3">
                                <label class="form-label mb-0 text-uppercase">Pendiente de
                                    cobro</label>
                                <div v-if="booking" disabled class="text-danger" style="font-size: 1.3em; font-weight: 500">
                                    <span v-if="booking.left_to_pay > 0" class="text-warning">{{
                                    formatCurrency(booking.left_to_pay, defaultCurrency) }}
                                    </span>
                                    <span v-else-if="booking.left_to_pay == 0" class="text-dark">{{
                                    formatCurrency(booking.left_to_pay, defaultCurrency) }}
                                    </span>
                                    <span v-else-if="booking.left_to_pay < 0" class="text-danger">{{
                                    formatCurrency(booking.left_to_pay, defaultCurrency) }}
                                    </span>
                                </div>
                            </div>
                            <div class="d-flex flex-column mt-3">
                                <label class="form-label mb-0 text-uppercase">Total pagado</label>
                                <div v-if="booking" disabled class="text-success" style="font-size: 1.3em; font-weight: 500">
                                    {{ formatCurrency(booking.total_paid, defaultCurrency) }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mb-3">
                        <PaymentGatewayBooking v-if="!loading && isPaynoPainActive" :booking="booking" />

                        <div v-if="!loading" class="d-grid gap-2">
                            <!-- BOTÓN NO SHOW RESERVA -->
                            <button v-if="booking.status != 7 && booking.status != 6
                                    " type="button" id="submitCancelBookingInfo" class="btn btn-sm btn-secondary mb-2" @click="noShowBooking(true)">
                                Activar No Show
                            </button>

                            <!-- BOTÓN NO SHOW RESERVA -->
                            <button v-if="booking.status != 7 && booking.status == 6
                                    " type="button" id="submitCancelBookingInfo" class="btn btn-sm btn-secondary mb-2" @click="noShowBooking(false)">
                                Desactivar No Show
                            </button>
                        </div>
                    </div>
                    <div class="card mb-3 border-0 shadow-sm">
                        <div class="card-body">
                            <div class="form-group col-12 mb-3">
                                <label class="form-label"><i class="fa-solid fa-comment-pen me-2 text-primary"></i>Observaciones
                                    del cliente</label>
                                <textarea v-if="booking != null" rows="4" class="form-control form-control-sm form-control-solid" v-model="booking.notes" @keyup="changeBooking"></textarea>
                            </div>

                            <div class="form-group col-12 mb-3">
                                <label class="form-label"><i class="fa-solid fa-comment-pen me-2 text-primary"></i>Observaciones
                                    del hotel</label>
                                <textarea v-if="booking != null" rows="4" class="form-control form-control-sm form-control-solid" v-model="booking.notesHotel" @keyup="changeBooking"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 pb-5" style="margin-bottom: 100px">
                <div class="card mb-3 border-0 shadow-sm p-3">
                    <div class="card-header bg-white border-0">
                        <div class="d-flex justify-content-between">
                            <div class="modal-title mb-0 d-flex align-items-center">
                                <i class="fa-regular fa-list-timeline fs-3 me-2 text-primary"></i>
                                <div class="fs-4" style="font-weight: 500">
                                    Historial de cambios
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body" style="max-height: 300px; overflow: auto">
                        <div v-if="logs != null" class="table-responsive">
                            <table class="table table table-row-dashed">
                                <thead>
                                    <tr>
                                        <th>Fecha</th>
                                        <th>Usuario</th>
                                        <th>Acción</th>
                                        <th>Descripción</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr :key="item" v-for="item in logs" class="align-middle">
                                        <td>
                                            {{
                                    formatDate(
                                        item.dateCreated,
                                        true
                                    )
                                }}
                                        </td>
                                        <td>
                                            <span v-if="item.user">{{
                                    item.user.username
                                }}</span>
                                            <span v-else>PMS</span>
                                        </td>
                                        <td>
                                            <span v-if="item.action_type == 1" class="badge badge-light-primary">Create</span>
                                            <span v-else-if="item.action_type == 2
                                    " class="badge badge-light-info">Read</span>
                                            <span v-else-if="item.action_type == 3
                                    " class="badge badge-light-warning">Update</span>
                                            <span v-else-if="item.action_type == 4
                                    " class="badge badge-light-danger">Delete</span>
                                        </td>
                                        <td v-html="item.action_message"></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div v-else id="spinner-search" class="col-12 text-center py-5">
                            <div class="spinner-border text-primary" role="status" style="
									width: 2rem;
									height: 2rem;
									visibility: visble;
								">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                            <div>Cargando información...</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="footer-booking">
            <div class="booking-footer-buttons">
                <div v-if="!loading" class="booking-footer-button-container">
                    <!-- BOTÓN DESCARGAR RESERVA -->
                    <BookingDownload :bookingID="booking.id" />
                </div>
                <div v-if="!loading" class="booking-footer-button-container">
                    <div class="booking-footer-button" @click="sendWhatsapp">
                        <i class="fa-brands fa-whatsapp"></i>
                        <div class="title">WhatsApp</div>
                    </div>
                </div>

                <div v-if="!loading" class="booking-footer-button-container">
                    <EmailsBooking :booking="booking" />
                </div>

                <div v-if="!loading" class="booking-footer-button-container">
                    <!-- BOTÓN MODIFICAR RESERVA -->
                    <div v-if="booking.status != 7 &&
                                    (booking.portal.id == 22 ||
                                        booking.portal.id == 13) &&
                                    booking.statusCheckin == 1 &&
                                    booking.statusCheckout == 1
                                    " class="booking-footer-button" data-bs-toggle="modal" data-bs-target="#modalEditDates">
                        <i class="fa-regular fa-calendar-pen"></i>
                        <div class="title">Modificar fechas</div>
                    </div>
                </div>

                <div v-if="!loading && isCheckinOnlineModuleActive" class="booking-footer-button-container">
                    <!-- BOTÓN CHECKIN ONLINE -->
                    <CheckinOnlineBooking :booking="booking" />
                </div>

                <div v-if="!loading" class="booking-footer-button-container">
                    <ModalGuestsBooking :booking="booking" :guests="guests" />
                </div>
                <div v-if="!loading" class="booking-footer-button-container">
                    <!-- BOTÓN DESCARGAR RESERVA DEL CHANNEL (Admin) -->
                    <div v-if="(roles.includes('pms_superadmin') ||
                                    roles.includes('pms_admin')) && booking.portal.id != 22
                                    " class="booking-footer-button" @click="downloadChannelBooking">
                        <i class="fa-solid fa-user-shield"></i>
                        <div class="title">Descargar reserva</div>
                    </div>

                </div>

                <div v-if="!loading" class="booking-footer-button-container">
                    <!-- BOTÓN DESCARGAR RESERVA DEL CHANNEL (Admin) -->
                    <div v-if="(roles.includes('pms_superadmin') ||
                                    roles.includes('pms_admin'))
                                    " class="booking-footer-button" @click="recalculateBookingPrices">
                        <i class="fa-solid fa-user-shield"></i>
                        <div class="title">Actualizar precios</div>
                    </div>

                </div>

                <div v-if="!loading" class="booking-footer-button-container">
                    <!-- BOTÓN CANCELAR RESERVA -->
                    <CancelBooking v-if="booking.status != 7 && booking.status != 6" :booking="booking" />
                </div>
            </div>
            <div v-if="!loading" class="booking-footer-button-container">
                <button id="btnSaveBooking" class="btn btn-primary text-uppercase" disabled @click="updateBooking">
                    Guardar
                </button>
            </div>
        </div>



        <!-- Modal Edit Dates -->
        <div v-if="!loading" class="modal fade" id="modalEditDates" tabindex="-1" aria-labelledby="modalEditDates" aria-hidden="true">
            <div class="modal-dialog modal-md">
                <div class="modal-content">
                    <form @submit="changeBookingDates">
                        <div class="modal-header">
                            <h5 class="modal-title">
                                Editar fechas de la reserva
                            </h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="alert alert-primary" role="alert">
                                <i class="fa-solid fa-circle-info me-1"></i>
                                Las nuevas fechas seleccionadas se aplicarán a
                                todas las habitaciones de la reserva.
                                <strong>Si no quierfes que afecte a todas la
                                    habitaciones tendrás que realizar el cambio
                                    dentro de cada habitación.</strong>
                            </div>
                            <label class="mb-2">Seleccionar fecha de check-in y
                                check-out</label>
                            <DatePicker v-model="range" mode="date" :masks="masks" :min-date="new Date()" is-range>
                                <template v-slot="{
                                    inputValue,
                                    inputEvents,
                                    isDragging,
                                }">
                                    <div class="d-flex align-items-center">
                                        <div class="input-group flex-grow-0" style="width: auto">
                                            <span class="input-group-text" id="basic-addon1"><i class="far fa-calendar-week"></i></span>
                                            <input class="flex-grow-0 ps-2 pe-2 py-1 bg-gray-100 border rounded" :class="isDragging
                                    ? 'text-gray-600'
                                    : 'text-gray-900'
                                    " :value="inputValue.start" v-on="inputEvents.start" style="width: 105px" />
                                        </div>
                                        <span class="nput-group mx-2 flex-grow-0">
                                            <i class="fas fa-long-arrow-alt-right stroke-current text-gray-600"></i>
                                        </span>
                                        <div class="input-group flex-grow-0" style="width: auto">
                                            <span class="input-group-text" id="basic-addon1"><i class="far fa-calendar-week"></i></span>
                                            <input class="flex-grow-0 ps-2 pe-2 py-1 bg-gray-100 border rounded" :class="isDragging
                                    ? 'text-gray-600'
                                    : 'text-gray-900'
                                    " :value="inputValue.end" v-on="inputEvents.end" style="width: 105px" />
                                        </div>
                                    </div>
                                </template>
                            </DatePicker>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-link text-primary" data-bs-dismiss="modal">
                                Cerrar
                            </button>
                            <button type="submit" id="submitChangeBookingDates" class="btn btn-primary">
                                Actualizar
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

    </div>
</template>



<script>
import BookingService from "../../services/booking.service";
import CountryService from "../../services/country.service";
import InvoiceService from "../../services/invoice.service";
import PaymentService from "../../services/payment.service";
import RegionService from "../../services/region.service";
import HotelService from "../../services/hotel.service";

import Swal from "sweetalert2";

import ViewBookingRoomRow from "../../components/bookings/ViewBookingRoomRow.vue";
import ViewBookingPaymentRow from "../../components/bookings/ViewBookingPaymentRow.vue";
import ViewBookingInvoiceRow from "../../components/bookings/ViewBookingInvoiceRow.vue";
import AddInvoice from "../../components/bookings/AddInvoice.vue";
import AddBookingRoom from "@/components/bookings/AddBookingRoom.vue";
import Table from "../../components/common/Table.vue";
import BookingDownload from "../../components/bookings/BookingDownload.vue";
import CreditCardInfo from "../../components/bookings/CreditCardInfo.vue";
import AddCreditCard from "../../components/bookings/AddCreditCard.vue";
import CancelBooking from "../../components/bookings/CancelBooking.vue";
import ModalGuestsBooking from "@/components/bookings/ModalGuestsBooking.vue";
import CheckinOnlineBooking from "@/components/bookings/CheckinOnlineBooking.vue";
import EmailsBooking from "@/components/bookings/EmailsBooking.vue";
import PaymentGatewayBooking from "@/components/bookings/PaymentGatewayBooking.vue";

import userRoles from "@/mixins/user-roles.js";

export default {
    name: "ViewBooking",
    mixins: [userRoles],
    components: {
        ViewBookingRoomRow,
        ViewBookingPaymentRow,
        ViewBookingInvoiceRow,
        AddInvoice,
        Table,
        BookingDownload,
        CreditCardInfo,
        AddCreditCard,
        CancelBooking,
        ModalGuestsBooking,
        CheckinOnlineBooking,
        EmailsBooking,
        AddBookingRoom,
        PaymentGatewayBooking
    },

    data() {
        return {
            bookingId: this.$route.params.bookingId,
            hotel: null,
            hotelId: HotelService.getLocalHotel().id,
            booking: null,
            payments: null,
            invoices: null,
            availability: [],
            loading: true,
            loadingBooking: true,
            checkin: "",
            checkout: "",
            creation_date: "",
            download_date: "",
            portal: "",
            bookingGuests: [],
            countries: [],
            regions: [],
            origin_country: 0,
            origin_region: 0,
            logs: null,
            guests: [],
            range: {
                start: new Date(
                    new Date().getFullYear(),
                    new Date().getMonth(),
                    1
                ),
                end: new Date(
                    new Date().getFullYear(),
                    new Date().getMonth() + 1,
                    0
                ),
            },
            masks: {
                input: "YYYY-MM-DD",
                data: "DD/MM/YYYY",
            },
            defaultCurrency: '',
            checkinAllChecked: false,
            checkoutAllChecked: false,
        };
    },

    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },
        isCheckinOnlineModuleActive() {
            // console.log(this.$store.state, "hotelModules");
            return this.$store.state.hotel.hotelModules.some(
                (module) => module.key === "module_checkin_online"
            );
        },

        isPaynoPainActive() {
            // console.log(this.$store.state.hotel.hotelModules);
            return this.$store.state.hotel.hotelModules.some(
                (module) => module.key === "module_paynopain"
            );
        },
    },
    methods: {
        async start() {
            this.loading = true;
            this.loadingBooking = true;

            await this.getBooking();

            if (!this.booking) {
                this.$router.push("/bookings");
                return;  // Asegúrate de detener la ejecución aquí.
            }


            console.log("BEFORE", this.booking.telephone)
            this.booking.telephone = this.booking.telephone
                ? await this.modifyTelephone(this.booking.telephone)
                : null;



            this.creation_date = this.formatDate(this.booking.creation_date, true);
            this.download_date = this.formatDate(this.booking.dateCreated, true);
            this.checkin = this.formatDate(this.booking.checkin);
            this.checkout = this.formatDate(this.booking.checkout);
            this.portal = this.booking.portal ? this.booking.portal.name : null;
            this.range.start = new Date(this.booking.checkin);
            this.range.end = new Date(this.booking.checkout);

            this.hotel = await HotelService.getLocalHotel();

            await this.getPayments();
            await this.getInvoices();
            await this.getCountries();
            await this.getRegions();
            await this.getBookingGuests();
            await this.getBookingLogs();
            // await this.getAvailability();

            this.loadingBooking = false;

            console.log("AFTER", this.booking.telephone)

            this.loading = false;
        },

        testPhone() {
            console.log("HELLO", this.booking.telephone)
        },

        async modifyTelephone(telephone) {
            // Elimina espacios en blanco iniciales y finales
            telephone = telephone.trim().replace(/\s+/g, '');

            // Si el teléfono ya tiene un código de país que empieza con "+", lo dejamos igual
            if (telephone.startsWith("+")) {
                return telephone;
            }

            // Si el teléfono comienza con "00", identificamos el código de país
            if (telephone.startsWith("00")) {
                // Verificamos si después de "00" ya tiene un código de país correcto
                // Aquí asumimos que "34" es el código de España
                const possibleCountryCode = telephone.slice(2, 4); // Extrae los dígitos después de "00"

                // Si el código de país es correcto, dejamos el número tal cual
                if (possibleCountryCode === "34") {
                    return telephone;
                }

                // Si el código no es "34", pero empieza con "00", asumimos que está mal y lo corregimos
                // Eliminamos el código "00" duplicado y lo reemplazamos por "0034"
                return "+34" + telephone.slice(2);
            }

            // Si el teléfono no tiene un código de país, agregamos "0034" (España) por defecto
            return "+34" + telephone;
        },

        async downloadChannelBooking() {
            const result = await this.$swal.fire({
                title: '¿Quieres volver a descargar la información de la reserva del Channel Manager?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Sí, descargar',
                confirmButtonColor: '#0091ff',
                cancelButtonText: 'No, cancelar'
            });

            if (result.isConfirmed) {
                this.$swal.fire({
                    title: 'Descargando...',
                    text: 'Por favor espera mientras se descarga la información.',
                    allowOutsideClick: false,
                    didOpen: () => {
                        this.$swal.showLoading();
                    }
                });


                const response = await BookingService.downloadChannelBooking(this.booking.id)

                if (response.status == 200) {

                    this.$swal.fire({
                        title: '¡Descarga completada!',
                        text: 'La información de la reserva se ha descargado correctamente.',
                        icon: 'success',
                        confirmButtonColor: '#0091ff',
                    });
                    this.$router.go();
                } else {
                    this.$swal.fire({
                        title: 'Error',
                        text: 'Hubo un problema al descargar la información.',
                        icon: 'error',
                        confirmButtonColor: '#0091ff',
                    });
                }
            }
        },

        async changeBookingDates(evt) {
            evt.preventDefault();
            window.$("#submitChangeBookingDates").empty();
            window
                .$("#submitChangeBookingDates")
                .append(
                    '<span class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>Guardando'
                );

            // console.log(this.formatDate(this.range.start, false, true),"checkin");
            // console.log(
            // 	this.formatDate(this.range.end, false, true),
            // 	"checkout"
            // );

            var response = await BookingService.changeBookingDates({
                checkin: this.formatDate(this.range.start, false, true),
                checkout: this.formatDate(this.range.end, false, true),
                booking: this.booking.id,
            });

            window.$("#submitChangeBookingDates").empty();
            window.$("#submitChangeBookingDates").append("Actualizar");

            if (response.status == 200) {
                window.$("#modalEditDates").hide();
                window.$("body").removeClass("modal-open");
                window.$(".modal-backdrop").remove();

                Swal.fire({
                    icon: "success",
                    title: "Las fechas de la reserva se han actualizado correctamente.",
                    confirmButtonColor: "#0091ff",
                }).then(() => {
                    this.start();
                });
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Ha ocurrido un error. Vuelve a intentarlo. Si el error persiste ponte en contacto con soporte@hospedium.com",
                    confirmButtonColor: "#0091ff",
                });
            }
        },

        async getBooking() {
            var response = await BookingService.getBooking(this.bookingId);
            if (response.status == 404 || this.hotelId != response.data.hotel) {
                this.$router.push("/bookings");
                return null;
            } else {
                this.booking = response.data;
                this.checkinAllChecked = (this.booking.statusCheckin === 3);
                this.checkoutAllChecked = (this.booking.statusCheckout === 3);
                // console.log(this.booking, "Booking");
            }
        },


        async getPayments() {
            var response = await PaymentService.getBookingPayments(
                this.$route.params.bookingId
            );
            this.payments = response.data;
            // console.log(this.payments, "Payments")
        },

        async getInvoices() {
            var response = await InvoiceService.getBookingInvoices(
                this.$route.params.bookingId
            );
            this.invoices = response.data;
            // console.log(this.invoices, "Invoices")
        },

        async getCountries() {
            var response = await CountryService.getAllCountries();
            this.countries = response;
            // console.log(this.countries, "countries")
        },

        async getRegions() {
            var response = await RegionService.getAllRegions();
            this.regions = response.data;
            // console.log(this.regions, "regions")
        },

        async getBookingLogs() {
            var response = await BookingService.getBookingLogs({
                booking: this.booking.id,
            });
            this.logs = response.data;
            // console.log(this.logs, "logs")
        },

        async getBookingGuests() {
            var response = await BookingService.getBookingGuests({
                booking: this.booking.id,
            });
            this.guests = response.data;
            // console.log(this.guests, "guests")
        },

        async updateBooking(evt) {
            evt.preventDefault();
            // console.log(this.booking)
            window.$("#btnSaveBooking").empty();
            window
                .$("#btnSaveBooking")
                .append(
                    '<span class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>Guardando'
                );

            if (this.booking.origin_country != 66) {
                this.booking.origin_region = null;
            }
            var response = await BookingService.updateBooking(this.bookingId, {
                checkin: this.booking.checkin,
                checkout: this.booking.checkout,
                firstName: this.booking.firstName,
                lastName: this.booking.lastName,
                email: this.booking.email,
                telephone: this.booking.telephone,
                address: this.booking.address,
                city: this.booking.city,
                zipCode: this.booking.zipCode,
                country: this.booking.country,
                notes: this.booking.notes,
                notesHotel: this.booking.notesHotel,
                origin_country: this.booking.origin_country,
                origin_region: this.booking.origin_region,
                show_taxes: this.booking.show_taxes,
            });

            window.$("#btnSaveBooking").empty();
            window.$("#btnSaveBooking").append("Guardar");
            window.$("#btnSaveBooking").attr("disabled", "true");

            if (response.status == 200) {
                Swal.fire({
                    position: "top",
                    icon: "success",
                    title: "Se ha guardado correctamente.",
                    showConfirmButton: false,
                    timer: 1500,
                    backdrop: false,
                    toast: false,
                });
            } else {
                Swal.fire({
                    position: "top",
                    icon: "error",
                    title: "Ha ocurrido un error.",
                    showConfirmButton: false,
                    timer: 1500,
                    backdrop: false,
                    toast: false,
                });
            }
        },


        async noShowBooking(boolean = true) {
            var title =
                "¿Estás seguro que quieres marcar como No Show esta reserva?";
            var text =
                "Esto afectará a todas las habitaciones de la reserva. Figurará como No Show solamente en el PMS.";
            if (boolean == false) {
                var title = "¿Estás seguro que quieres desactivar el No Show?";
                var text =
                    "Esto afectará a todas las habitaciones de la reserva. El cambio figurará solamente en el PMS.";
            }

            Swal.fire({
                position: "top",
                icon: "question",
                title: title,
                text: text,
                showConfirmButton: true,
                confirmButtonText: "Confirmar",
                confirmButtonColor: "#0091ff",
                showCancelButton: true,
                cancelButtonText: "Cerrar",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    var response = await BookingService.setBookingNoshow({
                        booking: this.booking.id,
                        noShow: boolean,
                    });

                    if (response.status == 200) {
                        Swal.fire({
                            title: "Se ha actualizado correctamente",
                            icon: "success",
                            confirmButtonColor: "#0091ff",
                        }).then(() => {
                            this.start();
                        });
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Ha ocurrido un error. Vuelve a intentarlo. Si el error persiste ponte en contacto con soporte@hospedium.com",
                            confirmButtonColor: "#0091ff",
                        });
                    }
                }
            });
        },

        async updateChannelInventory(startDate, endDate, roomType, quantity) {
            await BookingService.updateChannelInventory(
                {
                    startDate: startDate,
                    endDate: endDate,
                    add: 1, //Sumamos
                },
                [{ roomType: roomType, quantity: quantity }]
            );
        },


        changeBooking() {
            window.$("#btnSaveBooking").removeAttr("disabled");
        },

        sendWhatsapp() {
            if (this.booking.telephone) {
                Swal.fire({
                    inputLabel:
                        "Escribe un mensaje para enviar por WhatsApp(opcional)",
                    input: "textarea",
                    confirmButtonText: "Abrir WhatsApp",
                    confirmButtonColor: "#0091ff",
                }).then((result) => {
                    var text = result["value"];
                    text.replace("+", "");
                    text.replace(" ", "");
                    var url =
                        "https://wa.me/" +
                        this.booking.telephone +
                        "?text=" +
                        encodeURIComponent(text);
                    // console.log(url);
                    window.open(url, "_blank").focus();
                });
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "No hay ningún teléfono asociado a la reserva",
                    confirmButtonColor: "#0091ff",
                });
            }
        },

        async formCheckinAllRooms() {
            this.$swal.fire({
                title: 'Guardando...',
                allowOutsideClick: false,
                didOpen: () => {
                    this.$swal.showLoading();
                }
            });

            let response;
            if (!this.checkinAllChecked) {
                response = await BookingService.uncheckinAllRooms(this.booking.id);
            } else {
                response = await BookingService.checkinAllRooms(this.booking.id);
            }

            // console.log(response)

            if (response.status === 200) {
                this.booking.statusCheckin = this.checkinAllChecked ? 1 : 3; // Toggle check-in status
                this.$swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: `Check-in ${!this.checkinAllChecked ? ' revertido' : 'completado'} para todas las estancias.`,
                    showConfirmButton: false,
                    timer: 3000,
                    toast: true
                });

                this.loading = true;
                this.loadingBooking = true;
                await this.getBooking();
                this.loadingBooking = false;
                this.loading = false;

            } else {
                // console.error("Failed to update check-in status for all rooms: ", response.data);
                this.$swal.fire({
                    icon: 'error',
                    title: `Error`,
                    text: response.data.message,
                    showConfirmButton: false,
                });
            }

        },

        async formCheckoutAllRooms() {

            this.$swal.fire({
                title: 'Guardando...',
                allowOutsideClick: false,
                didOpen: () => {
                    this.$swal.showLoading();
                }
            });

            let response;
            if (!this.checkoutAllChecked) {
                response = await BookingService.uncheckoutAllRooms(this.booking.id);
            } else {
                response = await BookingService.checkoutAllRooms(this.booking.id);
            }

            if (response.status === 200) {
                this.booking.statusCheckout = this.checkoutAllChecked ? 1 : 3; // Toggle check-out status
                this.$swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: `Check-out ${!this.checkoutAllChecked ? 'revertido' : 'completado'} para todas las estancias.`,
                    showConfirmButton: false,
                    timer: 3000,
                    toast: true
                });
                this.loading = true;
                this.loadingBooking = true;
                await this.getBooking();
                this.loadingBooking = false;
                this.loading = false;
            } else {
                // console.error("Failed to update check-out status for all rooms: ", response.data);
                this.$swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: `Failed to ${this.checkoutAllChecked ? 'revert' : 'complete'} check-out for all rooms.`,
                    showConfirmButton: false,
                });
            }
        },


        handleCheckinAllRooms(event) {
            this.checkinAllChecked = event.target.checked;
            this.formCheckinAllRooms();
        },

        handleCheckoutAllRooms(event) {
            this.checkoutAllChecked = event.target.checked;
            this.formCheckoutAllRooms();
        },

        async recalculateBookingPrices() {
            // Mostrar swal de carga
            const loadingSwal = this.$swal.fire({
                title: 'Actualizando precios...',
                text: 'Por favor espera',
                icon: 'info',
                allowOutsideClick: false,
                showConfirmButton: false,
                onBeforeOpen: () => {
                    this.$swal.showLoading()
                }
            });

            try {
                const response = await BookingService.recalculateBookingPrices(this.booking.id);

                // Cerrar swal de carga
                this.$swal.close();

                if (response.status) {
                    this.$swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: `Precios actualizados.`,
                        showConfirmButton: false,
                        timer: 3000,
                        toast: true
                    });
                } else {
                    this.$swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: `Ha ocurrido un error.`,
                        showConfirmButton: false,
                        timer: 3000,
                        toast: true
                    });
                }
            } catch (error) {
                // Cerrar swal de carga
                this.$swal.close();

                this.$swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: `Ha ocurrido un error.`,
                    showConfirmButton: false,
                    timer: 3000,
                    toast: true
                });
            }
        },


        formatDate(date, hour = false, api = false) {
            var months = [
                "enero", "febrero", "marzo", "abril", "mayo", "junio",
                "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre",
            ];
            // Crear la fecha basándonos en la entrada
            const d = new Date(date);

            let result = "";
            if (api) {
                // Ajuste para cuando se necesite el formato API
                result = `${d.getUTCFullYear()}-${(d.getUTCMonth() + 1).toString().padStart(2, '0')}-${d.getUTCDate().toString().padStart(2, '0')}`;
            } else if (hour) {
                // Ajuste para mostrar la fecha con hora en la zona horaria local
                result = `${d.getDate()} ${months[d.getMonth()]} ${d.getFullYear()} - ${d.getHours()}:${(d.getMinutes() < 10 ? '0' : '')}${d.getMinutes()}:${(d.getSeconds() < 10 ? '0' : '')}${d.getSeconds()}`;
            } else {
                // Solo fecha, sin hora
                result = `${d.getUTCDate()} ${months[d.getUTCMonth()]} ${d.getUTCFullYear()}`;
            }

            return result;
        },



        handleRowInvoiceClick(item) {
            this.$router.push(`/invoices/${item.id}`);
        },
        handleRowBookingRoomClick(item) {
            this.$router.push(`/bookings/checkin/${item.id}`);
        },
    },
    created() {
        // console.log('Componente creado');
        this.defaultCurrency = localStorage.getItem('currency') || 'EUR';
        this.start();
    },
    mounted() {
        // console.log('Componente montado');

        if (!this.currentUser) {
            this.$router.push("/login");
        }
    },
    updated() {
        // console.log('Componente actualizado');
    }
};
</script>

<style scoped>
th {
    text-transform: uppercase;
    font-weight: 500 !important;
}

td {
    border: none;
}

.table:not(.table-bordered).table-row-dashed tr {
    border-bottom-width: 1px;
    border-bottom-style: dashed;
    border-bottom-color: var(--bs-border-color);
}

.page-app.viewBooking {
    margin-bottom: 3.5rem;
}

#table-booking-view-rooms td.min {
    min-width: 200px;
    white-space: nowrap;
}

#footer-booking {
    position: absolute;
    background: #fff;
    bottom: 0;
    right: 0;
    left: 0;
    /* border-top: var(--bs-border-width) var(--bs-border-style)
		var(--bs-border-color) !important; */
    padding: 0 20px 0 8em;
    width: 100%;
}

#footer-booking .border-right {
    border-right: 2px solid var(--bs-border-color) !important;
}

i.ccard {
    font-size: 3.5em;
    color: #0091ff;
}

@media screen and (max-width: 992px) {
    #footer-booking {
        margin-left: 0em;
    }
}

@media screen and (max-width: 768px) {
    #footer-booking {
        margin-left: 0em;
        position: relative;
        flex-direction: column;
        height: auto;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    #footer-booking .border-right {
        border-right: 0;
    }

    #footer-booking .booking-footer-buttons {
        flex-direction: column;
        height: auto;
    }

    #footer-booking .booking-footer-button-container {
        margin-bottom: 20px;
    }

    .page-app.viewBooking {
        margin-bottom: 0;
    }
}
</style>