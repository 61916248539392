<template>
    <tr :key="bookingpayment.id" style="cursor: pointer;" data-bs-toggle="modal" :data-bs-target="'#paymentModal' + bookingpayment.id">
        <td class="align-items-center">
            <div v-if="bookingpayment.date">
                {{ convertDate(bookingpayment.date) }}
            </div>
            <div v-else-if="bookingpayment.isPaymentGateway">
                <div><small>Enlace de pago enviado</small></div>
                <div><small><em>(Identificador de pago: #{{ bookingpayment.id }})</em></small></div>
            </div>
        </td>
        <td class="align-items-center">
            <div v-if="bookingpayment.quantity >= 0">
                <span class="text-success">{{
        formatCurrency(bookingpayment.quantity, bookingpayment.currency)
    }}</span>
            </div>
            <div v-else>
                <span class="text-danger">{{
            formatCurrency(bookingpayment.quantity, bookingpayment.currency)
        }}</span>
            </div>
        </td>
        <td>
            <span v-if="bookingpayment.isPaid" class="badge badge-light-success">Pagado</span>
            <span v-if="bookingpayment.isPending" class="badge badge-light-warning">Pendiente</span>
            <span v-if="bookingpayment.isError" class="badge badge-light-danger">Error</span>
        </td>
        <td class="align-items-center">
            <div v-if="bookingpayment.paymentMethod">{{ bookingpayment.paymentMethod.name }}</div>
            <div v-else>-</div>
        </td>
        <td class="align-items-center">
            <div v-if="bookingpayment.invoice" class="badge badge-secondary">
                {{ bookingpayment.invoice.code + bookingpayment.invoice.number }}
                <i v-if="bookingpayment.invoice.isAdvancePayment" class="fa-solid fa-square-a text-primary ms-2"></i>
            </div>
            <div v-else>
                <span class="badge badge-light-danger">SIN ASIGNAR</span>
            </div>
        </td>
    </tr>

    <!-- Modal -->
    <div class="modal fade" :id="'paymentModal' + bookingpayment.id" tabindex="-1" aria-labelledby="modalPayment" aria-hidden="true" @click.stop>
        <div class="modal-dialog modal-md">
            <div class="modal-content border-0">
                <div class="modal-header">
                    <h5 class="modal-title">Detalles del pago</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="form-group">
                        <div class="mb-3">
                            <label>Fecha del pago</label>
                            <div v-if="bookingpayment.date">
                                {{ convertDate(bookingpayment.date) }}
                            </div>
                            <div v-else-if="bookingpayment.isPaymentGateway">
                                <div><small>Enlace de pago enviado</small></div>
                                <div><small><em>(Identificador de pago: #{{ bookingpayment.id }})</em></small></div>
                            </div>
                        </div>
                        <div class="mb-3">
                            <label>Cantidad</label>
                            <div v-if="bookingpayment.quantity >= 0">
                                <span class="text-success">{{
        formatCurrency(bookingpayment.quantity, bookingpayment.currency)
    }}</span>
                            </div>
                            <div v-else>
                                <span class="text-danger">{{
            formatCurrency(bookingpayment.quantity, bookingpayment.currency)
        }}</span>
                            </div>
                        </div>
                        <div class="mb-3">
                            <label>Estado del pago</label>
                            <div>
                                <span v-if="bookingpayment.isPaid" class="badge badge-light-success">Pagado</span>
                                <span v-if="bookingpayment.isPending" class="badge badge-light-warning">Pendiente</span>
                                <span v-if="bookingpayment.isError" class="badge badge-light-danger">Error</span>
                            </div>
                        </div>

                        <div v-if="invoicesOptions.length === 0" class="alert alert-warning" role="alert">
                            No hay facturas creadas en la reserva.
                        </div>

                        <div v-else>
                            <div class="mb-3">
                                <label for="invoiceSelect">Asignar Factura</label>
                                <select class="form-control" v-model="selectedInvoice">
                                    <option :value="null">Sin asignar</option>
                                    <option v-for="item in invoicesOptions" :key="item.id" :value="item.id">{{ item.label }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                    <button type="button" class="btn btn-primary" @click.prevent="assignInvoice" :disabled="selectedInvoice === bookingpayment.invoice?.id">Guardar</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PaymentService from "@/services/payment.service";

export default {
    name: "ViewBookingPaymentRow",
    props: {
        bookingpayment: Object,
        invoices: {
            type: Array,
            default: () => [] // Establece un valor predeterminado vacío para evitar errores
        }
    },
    data() {
        return {
            selectedInvoice: this.bookingpayment.invoice ? this.bookingpayment.invoice.id : null,
            invoicesOptions: []
        };
    },
    methods: {
        async start() {
            await this.formattedInvoices();
        },

        async formattedInvoices() {
            this.invoicesOptions = this.invoices.map(invoice => ({
                ...invoice,
                id: invoice.id,
                label: `${invoice.code}${invoice.number} - ${invoice.tax_contact_name}`
            }));
        },

        async assignInvoice() {
            this.$swal.fire({
                title: 'Guardando...',
                text: 'Por favor espera mientras se guarda la asignación.',
                icon: 'info',
                allowOutsideClick: false,
                showConfirmButton: false,
                willOpen: () => {
                    this.$swal.showLoading();
                }
            });

            try {
                const response = await PaymentService.assignPaymentToInvoice(
                    this.bookingpayment.id, {
                    invoice_id: this.selectedInvoice
                });

                if (response.status == 200) {
                    this.$swal.fire({
                        title: 'Éxito',
                        text: 'La factura ha sido asignada correctamente.',
                        icon: 'success',
                        confirmButtonText: 'OK',
                        confirmButtonColor: '#0091ff',
                    }).then(() => {
                        this.$router.go(0); // Recarga la ruta actual
                    });
                } else {
                    this.$swal.fire({
                        title: 'Error',
                        text: 'Ocurrió un error al asignar la factura. Por favor, intenta nuevamente.',
                        icon: 'error',
                        confirmButtonText: 'OK',
                        confirmButtonColor: '#0091ff',
                    });
                }
            } catch (error) {
                console.error('Error assigning invoice:', error);
                this.$swal.fire({
                    title: 'Error',
                    text: 'Ocurrió un error al asignar la factura. Por favor, intenta nuevamente.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#0091ff',
                });
            }
        },

        convertDate(dateStr) {
            if (!dateStr) {
                return;
            }

            const dateParts = dateStr.split("-");
            if (dateParts.length !== 3) {
                return;
            }

            const year = dateParts[0];
            const month = dateParts[1];
            const day = dateParts[2];

            return this.formatDate(year, month, day);
        },
        formatDate(year, month, day) {
            const months = [
                "Ene",
                "Feb",
                "Mar",
                "Abr",
                "May",
                "Jun",
                "Jul",
                "Ago",
                "Sep",
                "Oct",
                "Nov",
                "Dic",
            ];

            const monthName = months[parseInt(month, 10) - 1]; // Restamos 1 porque los meses son 0-indexados en JavaScript

            return `${monthName} ${day}, ${year}`;
        },
    },
    created() {
        this.defaultCurrency = localStorage.getItem('currency') || 'EUR';
        this.start();
    },
};
</script>

<style scoped>
td {
    border: none !important;
}
</style>