export const globalMixins = {
	methods: {
		formatCurrency(num, currency) {
			if (isNaN(num)) {
				return "N/A"; // Devuelve 'N/A' si el número no es válido
			}
			if (!currency) {
				this.defaultCurrency = localStorage.getItem("currency") || "EUR";
				currency = this.defaultCurrency || "EUR";
			}
			var formatter = new Intl.NumberFormat("es-ES", {
				style: "currency",
				currency: currency,
			});

			return formatter.format(num);
		},
	},
};
