<template>
    <div>
        <div>
            <h6 class="mb-3">Generar listado de coste de habitaciones por fecha</h6>
            <label class="form-label">Seleccionar fecha</label>
            <DatePicker v-model="date" is-required :masks="masks" :max-date='new Date(new Date().setDate(new Date().getDate() - 1))' class="d-flex justify-content-center align-items-center">
                <template v-slot="{ inputValue, inputEvents }">
                    <div class="input-group  flex-grow-0" style="width: auto;">
                        <span class="input-group-text" id="basic-addon1"><i class="far fa-calendar-week"></i></span>
                        <input class="flex-grow-0 ps-2 pe-2 py-1 bg-gray-100 border rounded" :value="inputValue" v-on="inputEvents" />
                    </div>
                </template>
            </DatePicker>

            <div class="col-12 mt-4 pt-3">
                <button class="btn btn-primary" @click="generateReport()">Generar reporte</button>
            </div>
        </div>
    </div>
</template>


<script>
import BookingService from "../../services/booking.service";
import HotelService from "../../services/hotel.service";


export default {
    name: 'BookingsByDayList',
    components: {

    },
    data() {
        return {
            masks: {
                input: 'YYYY-MM-DD',
            },
            file_name: "",
            data: null,
            date: new Date(new Date().setDate(new Date().getDate() - 1)),
            content: "",
            loading: true,
        }
    },
    methods: {
        async start() {
            this.loading = true
            this.content = ''
            this.data = null
            await this.getData()
            this.loading = false
        },
        async getData() {
            var response = await BookingService.getParteGuardiaCivil({
                date: this.formatDate(this.date),
            })
            this.data = response.data;
            this.file_name = this.data['file_name']

            // console.log(this.data, "Data")
            // console.log(this.file_name, "file_name")

            this.content += this.data['hotel']['register'] + "\n"

            for (var i = 0; i < this.data['guests'].length; i++) {
                // console.log(this.data['guests'][i]['register'])
                this.content += this.data['guests'][i]['register'] + "\n"
            }
        },

        async generateReport() {
            await this.start()

            const a = document.createElement("a");
            const archivo = new Blob([this.content], { type: 'text/plain' });
            const url = URL.createObjectURL(archivo);
            a.href = url;
            a.download = this.file_name;
            a.click();
            URL.revokeObjectURL(url);
        },

        formatDate(date) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            return [year, month, day].join('-');
        },


    },
    created() {
    }
}
</script>