import axiosInstance from "./api";
import TokenService from "./token.service";
import router from "@/router/router";

const setup = (store) => {
    axiosInstance.interceptors.request.use(
        (config) => {
            const token = TokenService.getLocalAccessToken();
            if (token) {
                config.headers["Authorization"] = "Bearer " + token; // for Spring Boot back-end
                // config.headers["x-access-token"] = token; // for Node.js Express back-end
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    axiosInstance.interceptors.response.use(
        (res) => {
            return res;
        },
        async (err) => {
            const originalConfig = err.config;
            if (
                err.response.status === 401 &&
                originalConfig.url === "/token/refresh/"
            ) {
                console.log(originalConfig.url);
                store.dispatch("auth/logout");
                router.push("/login");
                return Promise.reject(err);
            }

            if (originalConfig.url !== "/token" && err.response) {
                // Access Token was expired
                if (err.response.status === 401 && !originalConfig._retry) {
                    originalConfig._retry = true;

                    try {
                        const rs = await axiosInstance.post("/token/refresh/", {
                            refresh: TokenService.getLocalRefreshToken(),
                        });
                        const accessToken = rs.data.access;

                        console.log(rs.status);
                        if (rs.status === 401) {
                            store.dispatch("auth/logout");
                        }

                        store.dispatch("auth/refreshToken", accessToken);
                        TokenService.updateLocalAccessToken(accessToken);

                        return axiosInstance(originalConfig);
                    } catch (_error) {
                        return Promise.reject(_error);
                    }
                }
            }

            return Promise.reject(err);
        }
    );
};

export default setup;
