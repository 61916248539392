import UserService from "../services/user.service"

const userRoles = {
    data() {
      return { roles: [] }
    },
    methods: {
        async getCurrentUserRoles() {
            var response = await UserService.getCurrentUserRoles();
            if (response.status == 200) {
                this.roles = response.data;
                // console.log( this.roles, "userRoles")
            }
        }
    },
    created () {
        this.getCurrentUserRoles();
    },
  }
  
  export default userRoles