<template>
    <div class="d-grid gap-2">
        <div class="btn btn-outline-primary mb-3" data-bs-toggle="modal"
            :data-bs-target="'#modalEditCharge' + index + '-' + position">
            <div>{{ mutationExtra.description }}</div>
            <div>{{ 'Fechas: ' + mutationExtra.dates }}</div>
            <div>{{ 'Precio total: ' + mutationExtra.price + ' ' + defaultCurrency }}</div>
        </div>
        <!-- Modal Edit Charge -->
        <div ref="modal" class="modal fade" :id="'modalEditCharge' + index + '-' + position"
            tabindex="-1">
            <div class="modal-dialog modal-xl">
                <div class="modal-content p-2">
                    <form @submit.prevent="editExtra(index)" @change="updatePrice">
                        <div class="modal-header border-0">
                            <h5 class="modal-title" id="exampleModalLabel">Editar Extra</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal"
                                aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="form-group mb-3">
                                <label class="form-label">Descripción</label>
                                <input type="text" class="form-control"
                                    v-model="mutationExtra.description" required disabled>
                            </div>
                            <div class="row">
                                <div class="form-group mb-3 col-6">
                                    <label class="form-label">Segmentación</label>
                                    <select class="form-control" v-model="mutationExtra.segment"
                                        required>
                                        <option :key="item" v-for="item in segments"
                                            :value="item.id">{{
                item.name }}</option>
                                    </select>
                                </div>
                                <div class="form-group mb-3 col-6">
                                    <label class="form-label">Cantidad por día</label>
                                    <input type="number" class="form-control"
                                        v-model="mutationExtra.quantity" required>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group mb-3 col-6">
                                    <label class="form-label">Precio unitario ({{ defaultCurrency
                                        }})</label>
                                    <input type="text" class="form-control"
                                        v-model="mutationExtra.price_unit" required>
                                </div>
                                <div class="form-group mb-3 col-6">
                                    <label class="form-label">IVA</label>
                                    <select class="form-control" v-model="mutationExtra.iva"
                                        required>
                                        <option value="0">0%</option>
                                        <option value="4">4%</option>
                                        <option value="6">6%</option>
                                        <option value="8">8%</option>
                                        <option value="10">10%</option>
                                        <option value="19">19%</option>
                                        <option value="21">21%</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group mb-3">
                                <label class="form-label">Seleccionar Fechas</label>
                                <Calendar :attributes="attributes" :min-page="minPage"
                                    :max-page="maxPage" :is-required="true" @dayclick="onDayClick"
                                    :available-dates='{ start: new Date(checkin), end: new Date(checkout) }'
                                    style="width: 100%" />
                            </div>
                            <div class="form-group mb-3 text-center">
                                <h4><strong>Precio total: {{ mutationExtra.price + ' ' +
                defaultCurrency }} </strong></h4>
                            </div>
                            <div v-if="error_message" class="alert alert-danger" role="alert">
                                {{ error_message }}
                            </div>
                        </div>
                        <div class="modal-footer border-0 justify-content-between">
                            <a type="button" class="btn btn-link text-decoration-none text-danger"
                                @click="removeCharge"><i
                                    class="fa-regular fa-trash me-2"></i>Eliminar</a>
                            <button id="submitEditCharge" type="submit"
                                class="btn btn-primary">Guardar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
export default {
    name: 'CreateBookingEditExtraRow',
    emits: ["editExtra", "removeExtra"],
    props: {
        extra: Object,
        index: Number,
        position: Number,
        segments: Array,
        checkin: String,
        checkout: String
    },
    data() {
        return {
            mutationExtra: null,
            error_message: null,
            oldPrice: 0,
            loading: true,
            attributes: [],
            minPage: {
                month: parseInt((new Date(this.checkin)).getMonth()) + 1,
                year: (new Date(this.checkin)).getFullYear()
            },
            maxPage: {
                month: parseInt((new Date(this.checkout)).getMonth()) + 1,
                year: (new Date(this.checkout)).getFullYear()
            },
            defaultCurrency: ''

        }
    },
    computed: {
        dates() {
            return this.days.map(day => day.date);
        },
    },
    methods: {
        async start() {
            this.loading = true;
            this.mutationExtra = this.extra;
            this.oldPrice = this.extra.price;
            var arr = []
            for (var j = 0; j < this.mutationExtra.dates.length; j++) {
                arr.push(this.mutationExtra.dates[j])
            }
            this.attributes.push({
                highlight: true,
                dates: arr
            })
            this.loading = false;
        },

        async editExtra(index) {
            this.error_message = null;
            if (this.attributes[0].dates.length < 1) {
                this.error_message = "Debes seleccionar al menos una fecha."
            } else {
                this.mutationExtra.dates = this.attributes[0].dates

                this.$emit("editExtra", this.index, this.position, this.mutationExtra, this.oldPrice);

                window.$('#modalEditCharge' + index + '-' + this.position).hide();
                window.$('body').removeClass('modal-open');
                window.$('.modal-backdrop').remove();

            }
        },

        async updatePrice() {
            var price = this.mutationExtra.price_unit;
            price = price * this.mutationExtra.quantity;
            price = price * this.attributes[0].dates.length;
            this.mutationExtra.price = Math.round(price * 100) / 100;
        },

        async removeCharge() {
            // console.log("DELETE")
            this.$emit("removeExtra", this.index, this.position);
            window.$('#modalEditCharge' + this.index + '-' + this.position).hide();
            window.$('body').removeClass('modal-open');
            window.$('.modal-backdrop').remove();

        },

        onDayClick(day) {
            const idx = this.attributes[0].dates.findIndex(d => d === day.id);
            if (idx >= 0) {
                this.attributes[0].dates.splice(idx, 1);
            } else {
                this.attributes[0].dates.push(day.id);
            }

            this.updatePrice()
        },

    },
    created() {
        this.defaultCurrency = localStorage.getItem('currency') || 'EUR';
        this.start();
    },
}
</script>