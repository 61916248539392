<template>
    <div class="page-app invoice-page">
        <div class="row">
            <div class="col-md-8 col-xl-9 mb-5">
                <div v-if="!loading" class="card shadow-sm border-0 p-3">
                    <div class="card-header bg-white border-0 d-flex justify-content-between align-items-center">
                        <div class="mb-3">
                            <img :src="hotel.logo" style="max-width: 180px" />
                        </div>
                        <div class="d-flex mb-3">
                            <SendInvoice :invoice="invoice" :booking="booking" />
                            <!-- Facturas de reservas-->
                            <AddInvoiceCharge v-if="!invoice.isRectificativa &&
                    invoice.invoice_rectificativa == null &&
                    invoice.booking
                    " :invoice="invoice" :booking="booking" :segments="segmentsAll" :invoiceInfo="invoiceInfo" :extras="extrasAll" />


                            <!-- End  Facturas reservas -->

                            <!-- Start Facturas de no alojados-->
                            <AddInvoiceChargeCustom v-if="!invoice.isRectificativa &&
                    invoice.invoice_rectificativa == null &&
                    !invoice.booking
                    " :invoice="invoice" :segments="segmentsAll" :extras="extrasAll" />
                            <AddPaymentCustom v-if="!invoice.isProforma && !invoice.booking" :invoice="invoice" class="me-2" />

                            <!-- End  Facturas de no alojados -->

                            <DownloadInvoice v-if="!loading" :invoiceId="invoice.id" :showTextBtn="true" />

                            <div v-if="!invoice.isProforma" class="dropdown ms-2">
                                <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i class="fa-solid fa-ellipsis-vertical"></i>
                                </button>
                                <ul class="dropdown-menu" aria-labelledby="dropdownMenu2">
                                    <li>
                                        <button v-if="!invoice.isRectificativa &&
                    invoice.invoice_rectificativa ==
                    null
                    " class="dropdown-item" type="button" @click="rectificarFactura()">
                                            Rectificar factura
                                        </button>
                                        <button v-else class="dropdown-item" type="button" disabled>
                                            Rectificar factura
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div v-if="!invoice.isRectificativa &&
                    invoice.invoice_rectificativa != null
                    " class="col-12">
                            <div class="alert alert-warning" role="alert">
                                Esta factura ha sido rectificada y, por lo
                                tanto, no se puede realizar modificaciones.
                                <router-link :key="$route.fullPath" :to="{
                    name: 'invoice',
                    params: {
                        invoiceId:
                            invoice.invoice_rectificativa,
                    },
                }" class="btn btn-link text-primary btn-sm text-uppercase">Ver
                                    factura
                                    rectificativa</router-link>
                            </div>
                        </div>

                        <div class="mb-4">
                            <div class="fs-5" style="font-weight: 500" v-if="invoice.isRectificativa">
                                {{
                    "Nº Factura Rectificativa: " +
                    invoice.code +
                    invoice.number
                }}
                            </div>
                            <div class="fs-5" style="font-weight: 500" v-else-if="invoice.isProforma">
                                {{
                    "Nº Factura Proforma: " +
                    invoice.code +
                    invoice.number
                }}
                            </div>
                            <div class="fs-5" style="font-weight: 500" v-else>
                                {{
                        "Nº Factura: " +
                        invoice.code +
                        invoice.number
                    }}
                            </div>
                        </div>
                        <div class="row mb-4">
                            <div class="col-md-6">
                                <div class="text-invoice mb-1">
                                    Fecha factura
                                </div>
                                <div style="font-size: 18px; font-weight: 500">
                                    {{
                        formatDate(
                            invoice.date,
                            false,
                            false,
                            (type = "short")
                        )
                    }}
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div v-if="client != null" class="mb-2">
                                    <div class="text-invoice mb-1">
                                        Número de cliente
                                    </div>
                                    <div style="
											font-size: 18px;
											font-weight: 500;
										">
                                        #{{ client.id }}
                                    </div>
                                </div>
                                <div v-if="company != null" class="mb-2">
                                    <div class="text-invoice mb-1">
                                        Número de cliente
                                    </div>
                                    <div style="
											font-size: 18px;
											font-weight: 500;
										">
                                        #{{ company.id }}
                                    </div>
                                </div>
                                <div v-if="invoice.isRectificativa" class="mb-2">
                                    <div class="text-invoice mb-1">
                                        Factura rectificada
                                    </div>
                                    <div style="
											font-size: 18px;
											font-weight: 500;
										">
                                        {{
                    invoiceRectificativa.code +
                    invoiceRectificativa.number
                }}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-6">
                                <ul class="ps-0" style="list-style: none">
                                    <li class="text-uppercase" style="font-weight: 500">
                                        {{ invoice.hotel_tax_contact_name }}
                                    </li>
                                    <li class="text-uppercase text-invoice">
                                        <small>{{ invoice.hotel_tax_nif }}</small>
                                    </li>
                                    <li class="text-uppercase text-invoice">
                                        <small>{{ invoice.hotel_tax_address }}</small>
                                    </li>
                                    <li class="text-uppercase text-invoice">
                                        <small>{{ invoice.hotel_tax_postal_code }}</small>
                                    </li>
                                    <li v-if="invoice.hotel_tax_country" class="text-uppercase text-invoice">
                                        <small>{{ invoice.hotel_tax_country.name }}</small>
                                    </li>
                                    <li class="text-uppercase text-invoice">
                                        <small>{{ hotel.email }}</small>
                                    </li>
                                    <li class="text-uppercase text-invoice">
                                        <small>{{ hotel.telephone }}</small>
                                    </li>
                                </ul>
                            </div>
                            <div class="col-md-6">
                                <ul class="ps-0 mb-1" style="list-style: none">
                                    <li v-if="invoice.tax_contact_name" class="text-uppercase" style="font-weight: 500">
                                        {{ invoice.tax_contact_name }}
                                    </li>

                                    <li class="text-uppercase text-invoice">
                                        <small>{{ invoice.tax_nif }}</small>
                                    </li>
                                    <li v-if="invoice.tax_address != null &&
                    invoice.tax_address != 'None'
                    " class="text-uppercase text-invoice">
                                        <small>{{ invoice.tax_address }}</small>
                                    </li>
                                    <li v-if="invoice.tax_postal_code != null &&
                    invoice.tax_postal_code != 'None'
                    " class="text-uppercase text-invoice">
                                        <small>{{
                    invoice.tax_postal_code
                }}</small>
                                    </li>
                                    <li v-if="invoice.tax_locality != null &&
                    invoice.tax_locality != 'None'
                    " class="text-uppercase text-invoice">
                                        <small>{{
                    invoice.tax_locality
                }}</small>
                                    </li>
                                    <li v-if="country != null" class="text-uppercase text-invoice">
                                        <small>{{ country.name }}</small>
                                    </li>
                                </ul>
                                <div>
                                    <ChangeClientInvoice v-if="!invoice.isRectificativa &&
                    invoice.invoice_rectificativa ==
                    null
                    " :invoiceId="invoice.id" :hasGuests="hasGuests" />
                                </div>
                            </div>
                            <div class="col-md-6"></div>
                        </div>

                        <div class="col-12 mt-3">
                            <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" role="switch" id="enableGroupCharges" @change="update" v-model="invoice.enableGroupCharges" />
                                <label class="form-check-label" for="enableGroupCharges">Agrupar
                                    cargos</label>
                            </div>
                        </div>

                        <div class="table-responsive w-100">
                            <table class="table table-row-dashed">
                                <thead>
                                    <tr class="">
                                        <th>Fecha</th>
                                        <th v-if="invoiceInfo.showDescriptionInInvoice
                    ">
                                            Descripcion
                                        </th>
                                        <th class="text-center" v-if="invoiceInfo.showRoomInInvoice">
                                            Hab.
                                        </th>
                                        <th class="text-center" v-if="invoiceInfo.showQuantityInInvoice
                    ">
                                            Cant.
                                        </th>
                                        <!-- <th class="text-center">Pax.</th> -->
                                        <th class="text-center">Precio</th>
                                        <th class="text-center" v-if="invoiceInfo.showDiscountInInvoice
                    ">
                                            Dto %
                                        </th>
                                        <th class="text-center">Impuestos</th>
                                        <th class="text-center">Total</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="" :key="item" v-for="item in charges">
                                        <InvoiceChargeRow :charge="item" :isRectificativa="invoice.isRectificativa
                    " :booking="booking" :invoice="invoice" :invoiceInfo="invoiceInfo" :segments="segmentsAll" :extras="extrasAll" />
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div v-if="invoice.isAdvancePayment" class="col-md-12">
                            <div class="alert alert-warning" role="alert">
                                Esta factura corresponde a un anticipo de la
                                reserva nº {{ booking.id }}
                            </div>
                        </div>
                        <div class="table-responsive w-100 mt-5">
                            <table class="table table-borderless">
                                <thead>
                                    <tr class="">
                                        <th class="text-uppercase text-center">
                                            Base imponible
                                        </th>
                                        <th class="text-uppercase text-center">
                                            Impuesto
                                        </th>
                                        <th class="text-uppercase text-center">
                                            Total Impuesto
                                        </th>
                                        <th class="text-uppercase text-center">
                                            Total
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-if="invoice.show_taxes" v-for="(rate, index) in invoice.baserate_totals">
                                        <tr v-if="index !== 'total' && rate != 0 && rate == parseFloat(rate)" class="text-center" :key="index">
                                            <td>{{ formatCurrency(rate, defaultCurrency) }}</td>
                                            <td v-if="index === 0">Sin impuestos</td>
                                            <td v-else-if="!isNaN(index)">IVA {{ index }}%</td>
                                            <td>{{ formatCurrency(invoice.iva_totals[index], defaultCurrency) }}</td>
                                            <td>{{ formatCurrency(rate + (invoice.iva_totals[index] || 0), defaultCurrency) }}</td>
                                        </tr>
                                    </template>


                                    <tr class="text-center">
                                        <td><strong>{{ formatCurrency(invoice.baserate_totals["total"], defaultCurrency) }}</strong></td>
                                        <td> <span v-if="!invoice.show_taxes"><em>Sin impuestos</em></span></td>
                                        <td>
                                            <span v-if="!invoice.show_taxes"><em>Sin impuestos</em></span>
                                        </td>
                                        <td>
                                            <strong v-if="invoice.show_taxes">{{ formatCurrency(invoice.total_amount, defaultCurrency) }}</strong>
                                            <strong v-else>{{ formatCurrency(invoice.baserate_amount, defaultCurrency) }}</strong>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div v-else class="card shadow-sm border-0 rounded">
                    <div class="card-body p-5 text-center">
                        <div class="spinner-border text-primary" role="status" style="
								width: 2rem;
								height: 2rem;
								visibility: visble;
							">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div>Cargando información...</div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-xl-3">
                <div class="card shadow-sm border-0 rounded mb-3 p-3">
                    <div class="card-header border-0 bg-white">
                        <h6 class="mb-8 fw-bolder text-invoice text-uppercase">
                            RESUMEN FACTURA
                        </h6>
                    </div>
                    <div class="card-body">
                        <div v-if="invoice != ''">
                            <div class="mb-2">
                                <div class="text-invoice">Nº factura:</div>
                                <div style="font-weight: 500">
                                    {{ invoice.code + invoice.number }}
                                </div>
                            </div>
                            <div class="mb-2">
                                <div class="text-invoice">Fecha factura:</div>
                                <div style="font-weight: 500">
                                    {{
                    formatDate(
                        invoice.date,
                        false,
                        false,
                        (type = "short")
                    )
                }}
                                </div>
                            </div>
                            <div class="mb-2">
                                <div class="text-invoice">Total a pagar:</div>
                                <div style="font-weight: 500">
                                    <span v-if="invoice.show_taxes">{{ formatCurrency(invoice.total_amount, defaultCurrency) }}</span>
                                    <span v-else>{{ formatCurrency(invoice.baserate_amount, defaultCurrency) }}</span>
                                </div>
                            </div>
                            <div class="mb-2">
                                <div class="text-invoice">Cliente:</div>
                                <div style="font-weight: 500">
                                    {{ invoice.tax_contact_name }}
                                </div>
                            </div>

                            <div v-if="isSiigoActive" class="mb-2">
                                <div class="text-invoice">Siigo:</div>
                                <div style="font-weight: 500">
                                    <div class="siigo-status-wrapper">
                                        <div v-if="invoice.siigo_status === 0" class="badge badge-light-primary">Created</div>
                                        <div v-else-if="invoice.siigo_status === 1" class="badge badge-light-success">Modified</div>
                                        <div v-else-if="invoice.siigo_status === 2" class="badge badge-light-danger">Error</div>
                                        <div v-else-if="invoice.siigo_status === 3" class="badge badge-light-warning">Skipped</div>
                                        <div v-else>-</div>
                                    </div>
                                </div>
                            </div>

                            <div class="mb-2">
                                <div class="text-invoice">Reserva:</div>
                                <div v-if="invoice.booking" style="font-weight: 500">
                                    <a :href="'/bookings/view/' + invoice.booking
                    " class="btn btn-sm btn-primary">Ir a la reserva
                                        <span>{{
                    "#" + invoice.booking
                }}</span></a>
                                </div>
                                <div v-else style="font-weight: 500">
                                    <div>Sin asignar</div>
                                </div>
                            </div>

                            <!-- BOTÓN EDITAR FACTURA (Admin) -->
                            <div class="d-grid gap-2 col-12 mx-auto mt-3">
                                <button v-if="roles.includes('pms_superadmin') ||
                    roles.includes('pms_admin')
                    " type="button" class="btn btn-sm btn-secondary mb-2" data-bs-toggle="modal" data-bs-target="#modalEditInvoice">
                                    <i class="fa-solid fa-user-shield me-2"></i>Editar factura
                                </button>
                            </div>

                            <div class="mt-3">
                                <div v-if="invoice != null" class="form-check form-switch">
                                    <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" v-model="invoice.show_taxes" @change="update">
                                    <label class="form-check-label" for="flexSwitchCheckDefault" style="font-size: 14px;">Activar impuestos en factura</label>
                                </div>
                            </div>
                        </div>
                        <div v-else class="text-center p-5">
                            <div class="spinner-border text-primary" role="status" style="
									width: 2rem;
									height: 2rem;
									visibility: visble;
								">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                            <div>Cargando información...</div>
                        </div>
                    </div>
                </div>

                <div v-if="!invoice.isProforma" class="card shadow-sm border-0 rounded mb-3 p-3">
                    <div class="card-header bg-white border-0">
                        <div class="d-flex align-items-center justify-content-between mb-3">
                            <h6 class="mb-8 fw-bolder text-invoice text-uppercase">
                                Pagos
                            </h6>
                            <div v-if="!loading && invoice">
                                <div v-if="invoice.paid" class="d-flex flex-column gap-2">
                                    <span class="badge badge-light-success me-2">Pagado</span>
                                    <span v-if="invoice.left_to_pay < 0" class="badge badge-light-danger me-2">A deber
                                        {{ formatCurrency(invoice.left_to_pay, defaultCurrency) }}</span>
                                </div>
                                <div v-else>
                                    <span class="badge badge-light-warning me-2">Pendiente
                                        {{ formatCurrency(invoice.left_to_pay, defaultCurrency) }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body pt-0">
                        <div v-if="!loading">
                            <div class="mb-3" :key="item" v-for=" item  in  payments ">
                                <InvoicePaymentRow :payment="item" :invoice="invoice" />
                            </div>
                            <div class="d-grid gap-2">
                                <AddPayment v-if="!loading && invoice.booking" :booking="booking" :invoice="invoice" />
                                <AddPaymentCustom v-if="!invoice.isProforma && !invoice.booking
                    " :invoice="invoice" class="me-2" />
                                <AssignPayment v-if="invoice.booking" :invoice="invoice" @payment-assigned="paymentAssigned" />
                            </div>
                        </div>
                        <div v-else class="text-center p-5">
                            <div class="spinner-border text-primary" role="status" style="
									width: 2rem;
									height: 2rem;
									visibility: visble;
								">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                            <div>Cargando información...</div>
                        </div>
                    </div>
                </div>
                <div class="card shadow-sm border-0 rounded mb-3">
                    <div class="card-header bg-white border-0">
                        <div class="d-flex align-items-center justify-content-between mb-3">
                            <h5>Observaciones</h5>
                            <div v-if="!savingnotes">
                                <i class="fa-duotone fa-check-double text-primary"></i>
                            </div>
                            <div v-else>
                                <div class="spinner-border spinner-border-sm text-primary" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                                <span class="ms-2">Guardando...</span>
                            </div>
                        </div>
                    </div>
                    <div class="card-body pt-0">
                        <div v-if="!loading">
                            <textarea rows="3" class="form-control" v-model="invoice.notes" @keyup="updateInvoiceNotes()"></textarea>
                        </div>
                    </div>
                </div>
                <div class="card shadow-sm border-0 rounded mb-3">
                    <div class="card-header bg-white border-0">
                        <div class="d-flex align-items-center justify-content-between mb-3">
                            <h5>Información de pago</h5>
                            <div v-if="!savingPaymentInfo">
                                <i class="fa-duotone fa-check-double text-primary"></i>
                            </div>
                            <div v-else>
                                <div class="spinner-border spinner-border-sm text-primary" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                                <span class="ms-2">Guardando...</span>
                            </div>
                        </div>
                    </div>
                    <div class="card-body pt-0">
                        <div v-if="!loading">
                            <textarea rows="3" class="form-control" v-model="invoice.paymentInfo" @keyup="updateInvoicePaymentInfo()"></textarea>
                        </div>
                    </div>
                </div>
                <div class="card shadow-sm border-0 rounded mb-3">
                    <div class="card-header bg-white border-0">
                        <div class="d-flex align-items-center justify-content-between mb-3">
                            <h5>Etiquetas</h5>
                            <div v-if="!savingtags">
                                <i class="fa-duotone fa-check-double text-primary"></i>
                            </div>
                            <div v-else>
                                <div class="spinner-border spinner-border-sm text-primary" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                                <span class="ms-2">Guardando...</span>
                            </div>
                        </div>
                    </div>
                    <div class="card-body pt-0">
                        <div v-if="!loading">
                            <div v-if="tags.length > 0">
                                <div class="form-check" v-for=" item  in  tags ">
                                    <input class="form-check-input" type="checkbox" :value="item.id" :id="'tag_input_' + item.id" v-model="invoice.tags" @click="
                    updateInvoiceTags(item.id, $event)
                    " />
                                    <label class="form-check-label">
                                        {{ item.name }}
                                    </label>
                                </div>
                            </div>
                            <div v-else>
                                <small>No hay etiquetas disponibles.</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal Edit Invoice -->
        <div v-if="!loading" class="modal fade" id="modalEditInvoice" tabindex="-1" aria-labelledby="modalEditInvoice" aria-hidden="true">
            <div class="modal-dialog modal-md">
                <div class="modal-content">
                    <form @submit="updateInvoiceAdmin">
                        <div class="modal-header">
                            <h5 class="modal-title">Editar factura</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <label class="mb-2">Precio total de la factura ({{ defaultCurrency
                                }})</label>
                            <input type="text" class="form-control" v-model="invoice.total_price" />
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-link text-primary" data-bs-dismiss="modal">
                                Cerrar
                            </button>
                            <button type="submit" id="btnSaveInvoiceAdmin" class="btn btn-primary">
                                Guardar
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.invoice-page .dropdown-toggle::after {
    display: none;
}

.invoice-page .table>thead {
    vertical-align: bottom;
    background: transparent;
    color: #000;
    font-weight: 600;
}

.text-invoice {
    color: #78829d;
    font-weight: 400;
}
</style>

<script>
import ChargeService from "../../services/charge.service";
import ClientService from "../../services/client.service";
import CompanyService from "../../services/company.service";
import CountryService from "../../services/country.service";
import HotelService from "../../services/hotel.service";
import InvoiceService from "../../services/invoice.service";
import InvoiceInfoService from "../../services/invoiceinfo.service";
import PaymentService from "../../services/payment.service";
import BookingService from "../../services/booking.service";
import ExtraService from "../../services/extra.service";
import SegmentService from "../../services/segment.service";
import TagService from "../../services/tag.service";

import AddInvoiceCharge from "../../components/invoices/AddInvoiceCharge";
import AddInvoiceChargeCustom from "../../components/invoices/AddInvoiceChargeCustom";
import InvoiceChargeRow from "../../components/invoices/InvoiceChargeRow";
import AddPayment from "../../components/invoices/AddPayment";
import AddPaymentCustom from "../../components/invoices/AddPaymentCustom";
import InvoicePaymentRow from "../../components/invoices/InvoicePaymentRow";
import ChangeClientInvoice from "../../components/invoices/ChangeClientInvoice";
import SendInvoice from "../../components/invoices/SendInvoice";
import AssignPayment from "@/components/invoices/AssignPayment";


import DownloadInvoice from "../../components/invoices/DownloadInvoice";
import Swal from "sweetalert2";

import userRoles from "@/mixins/user-roles.js";

export default {
    name: "invoice",
    mixins: [userRoles],
    components: {
        AddInvoiceCharge,
        AddInvoiceChargeCustom,
        InvoiceChargeRow,
        AddPayment,
        AddPaymentCustom,
        InvoicePaymentRow,
        DownloadInvoice,
        ChangeClientInvoice,
        SendInvoice,
        AssignPayment
    },
    data() {
        return {
            invoiceId: this.$route.params.invoiceId,
            loading: true,
            hotelId: HotelService.getLocalHotel().id,
            hotel: [],
            invoice: [],
            invoiceRectificativa: [],
            invoiceInfo: [],
            charges: [],
            client: null,
            company: null,
            country: null,
            booking: null,
            payments: [],
            sumIvasTotal: 0,
            updateCharge: {
                price: 0,
                discount: 0,
            },
            chargeSelected: [],
            segmentsAll: [],
            extrasAll: [],
            chargesAll: [],
            savingnotes: false,
            savingPaymentInfo: false,
            savingtags: false,
            hasGuests: false,
            tags: null,
            defaultCurrency: '',
        };
    },
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },
        isSiigoActive() {
            // console.log(this.$store.state.hotel.hotelModules);
            return this.$store.state.hotel.hotelModules.some(
                (module) => module.key === "module_siigo"
            );
        },
    },
    watch: {
        $route: function (newVal, oldVal) {
            this.invoiceId = this.$route.params.invoiceId;
            this.start();
        },
    },
    methods: {
        async start() {
            this.loading = true;
            this.charges = [];
            this.sumIvasTotal = 0;

            await this.getInvoiceInfo();
            await this.getInvoice();
            await this.getHotel();
            await this.getClient();
            await this.getCompany();
            await this.getCountry();
            await this.getPayments();
            await this.getTags();
            if (this.invoice.booking) {
                await this.getBooking();
                await this.getAllCharges();
                this.hasGuests = true;
            }
            await this.getAllSegments();
            await this.getAllExtras();

            this.loading = false;
        },

        async getInvoice() {
            var response = await InvoiceService.getInvoice(this.invoiceId);
            if (response.status == 404 || this.hotelId != response.data.hotel) {
                this.$router.push("/invoices");
            } else {
                this.invoice = response.data;

                if (this.invoice.enableGroupCharges) {
                    this.charges = this.invoice.charges_grouped;
                } else {
                    this.charges = this.invoice.charges_data;
                }
            }

            if (this.invoice.isRectificativa) {
                var responseRectificativa = await InvoiceService.getInvoice(
                    this.invoice.invoice_corrected
                );
                this.invoiceRectificativa = responseRectificativa.data;
            }
            console.log(this.invoice, "Invoice");
        },

        async getPayments() {
            this.payments = await PaymentService.getInvoicePayments(
                this.invoiceId
            );
            this.payments = this.payments.data;

            // console.log(this.payments, "Payments")
        },

        async getClient() {
            if (this.invoice.client != null) {
                var response = await ClientService.getClient(
                    this.invoice.client_hotel
                );
                this.client = response.data;
                // console.log(this.client, "Client")
            }
        },

        async getCompany() {
            if (this.invoice.company != null) {
                var response = await CompanyService.getCompany(
                    this.invoice.company
                );
                this.company = response.data;
            }
        },

        async getCountry() {
            if (this.invoice.tax_country != null) {
                var country = await CountryService.getCountry(
                    this.invoice.tax_country
                );
                this.country = country;
            }
        },

        async getBooking() {
            if (this.invoice.booking != null) {
                var response = await BookingService.getBooking(
                    this.invoice.booking
                );
                this.booking = response.data;
            }
        },

        async getTags() {
            var response = await TagService.listTags({ enabled: true });
            this.tags = response.data;
        },

        async getAllExtras() {
            var response = await ExtraService.getAllExtras();
            this.extrasAll = response.data;
            // console.log(this.extras, "Extras")
        },

        async getAllCharges() {
            var response = await ChargeService.getAllCharges({
                booking: this.invoice.booking,
            });
            this.chargesAll = response.data;
        },

        async getAllSegments() {
            var response = await SegmentService.getAllSegments();
            this.segmentsAll = response.data;
        },

        async update(evt) {
            evt.preventDefault();

            let response = await InvoiceService.updateInvoice(this.invoice.id, {
                code: this.invoice.code,
                date: this.invoice.date,
                number: this.invoice.number,
                show_taxes: this.invoice.show_taxes,
                enableGroupCharges: this.invoice.enableGroupCharges,
            });
            if (response.status == 200) {
                Swal.fire({
                    title: "Se ha actualizado correctamente",
                    icon: "success",
                    confirmButtonColor: "#0091ff",
                }).then(() => {
                    this.start();
                });
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Ha ocurrido un error. Vuelve a intentarlo. Si el error persiste ponte en contacto con soporte@hospedium.com",
                    confirmButtonColor: "#0091ff",
                });
            }
        },

        async updateInvoiceAdmin(evt) {
            evt.preventDefault();
            // console.log(this.booking)
            window.$("#btnSaveInvoiceAdmin").empty();
            window.$("#btnSaveInvoiceAdmin").attr("disabled", "true");
            window
                .$("#btnSaveInvoiceAdmin")
                .append(
                    '<span class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>Guardando'
                );

            let response = await InvoiceService.updateInvoice(this.invoice.id, {
                code: this.invoice.code,
                number: this.invoice.number,
                total_price: this.invoice.total_price,
            });

            window.$("#btnSaveInvoiceAdmin").empty();
            window.$("#btnSaveInvoiceAdmin").append("Guardar");
            window.$("#btnSaveInvoiceAdmin").removeAttr("disabled");
            window.$("#modalEditInvoice").hide();
            window.$("body").removeClass("modal-open");
            window.$(".modal-backdrop").remove();

            if (response.status == 200) {
                Swal.fire({
                    title: "Se ha actualizado correctamente",
                    icon: "success",
                    confirmButtonColor: "#0091ff",
                }).then(() => {
                    this.start();
                });
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Ha ocurrido un error. Vuelve a intentarlo. Si el error persiste ponte en contacto con soporte@hospedium.com",
                    confirmButtonColor: "#0091ff",
                });
            }
        },

        async deleteCharge(charge) {
            let charges = [];
            for (var i = 0; i < this.invoice.charges.length; i++) {
                if (charge != this.charges[i]) {
                    charges.push(this.invoice.charges[i]);
                }
            }
            var newPrice =
                parseFloat(this.invoice.total_price) - parseFloat(charge[6]);

            let response = await InvoiceService.updateInvoice(this.invoice.id, {
                code: this.invoice.code,
                date: this.invoice.date,
                number: this.invoice.number,
                charges: charges,
                total_price: newPrice,
            });
            Swal.fire({
                position: "top-end",
                icon: "success",
                title: "Se ha guardado correctamente.",
                showConfirmButton: false,
                timer: 1500,
                backdrop: false,
                toast: true,
            });
            this.$router.go();
        },

        async getHotel() {
            var response = await HotelService.getHotel(
                HotelService.getLocalHotel().id
            );
            this.hotel = response.data;
        },

        async getInvoiceInfo() {
            var response = await InvoiceInfoService.getInvoiceInfo();
            this.invoiceInfo = response.data;
        },

        async rectificarFactura() {
            Swal.fire({
                icon: "question",
                title: "¿Estás seguro que quieres rectificar esta factura?",
                showCancelButton: true,
                cancelButtonText: "Cancelar",
                confirmButtonColor: "#0091ff",
                confirmButtonText: "Continuar",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    var response = await InvoiceService.rectifyInvoice(
                        this.invoice.id,
                        {
                            invoice: this.invoice.id,
                        }
                    );

                    if (response.status == 200) {
                        Swal.fire({
                            icon: "success",
                            title: "Se ha creado correctamente",
                            showCancelButton: false,
                            confirmButtonColor: "#0091ff",
                            confirmButtonText: "Ver factura rectificativa",
                        }).then(async (result) => {
                            this.charges = [];
                            this.$router.push("/invoices/" + response.data.id);
                            this.invoiceId = response.data.id;
                            await this.start();
                        });
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Ha ocurrido un error. Vuelve a intentarlo. Si el error persiste ponte en contacto con soporte@hospedium.com",
                            confirmButtonColor: "#0091ff",
                        });
                    }
                }
            });
        },

        async updateInvoiceNotes() {
            this.savingnotes = true;
            var response = await InvoiceService.updateInvoiceNotes(
                this.invoice.id,
                { notes: this.invoice.notes }
            );

            if (response.status == 200) {
                this.savingnotes = false;
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Ha ocurrido un error al guarda las observaciones de la factura. Vuelve a intentarlo. Si el error persiste ponte en contacto con soporte@hospedium.com",
                    confirmButtonColor: "#0091ff",
                });
            }
        },
        async updateInvoicePaymentInfo() {
            this.savingPaymentInfo = true;
            var response = await InvoiceService.updateInvoicePaymentInfo(
                this.invoice.id,
                { paymentInfo: this.invoice.paymentInfo }
            );

            if (response.status == 200) {
                this.savingPaymentInfo = false;
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Ha ocurrido un error al guarda las observaciones de la factura. Vuelve a intentarlo. Si el error persiste ponte en contacto con soporte@hospedium.com",
                    confirmButtonColor: "#0091ff",
                });
            }
        },

        async updateInvoiceTags(tagID, event) {
            if (event.target.checked) {
                var status = 1;
            } else {
                var status = -1;
            }
            var response = await InvoiceService.updateInvoiceTags(
                this.invoice.id,
                { tag_id: tagID, status: status }
            );

            if (response.status == 200) {
                this.savingtags = false;
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Ha ocurrido un error al guarda las observaciones de la factura. Vuelve a intentarlo. Si el error persiste ponte en contacto con soporte@hospedium.com",
                    confirmButtonColor: "#0091ff",
                });
            }
        },

        paymentAssigned() {
            this.$router.go(0);
        },

        formatDate(date, hour = false, api = false, type) {
            var months = [
                "enero",
                "febrero",
                "marzo",
                "abril",
                "mayo",
                "junio",
                "julio",
                "agosto",
                "septiembre",
                "octubre",
                "noviembre",
                "diciembre",
            ];
            const short_months = [
                "Ene.",
                "Feb.",
                "Mar.",
                "Abr.",
                "May.",
                "Jun.",
                "Jul.",
                "Ago.",
                "Sep.",
                "Oct.",
                "Nov.",
                "Dic.",
            ];
            const d = new Date(date);
            var result = "";
            if (api) {
                result =
                    d.getFullYear() +
                    "-" +
                    (d.getMonth() + 1) +
                    "-" +
                    d.getDate();
            } else if (hour) {
                result =
                    d.getDate() +
                    " " +
                    months[d.getMonth()] +
                    " " +
                    d.getFullYear() +
                    " - " +
                    d.getHours() +
                    ":" +
                    (d.getMinutes() < 10 ? "0" : "") +
                    d.getMinutes() +
                    ":" +
                    (d.getSeconds() < 10 ? "0" : "") +
                    d.getSeconds();
            } else {
                if (type == "short") {
                    result =
                        d.getDate() +
                        " " +
                        short_months[d.getMonth()] +
                        " " +
                        d.getFullYear();
                } else {
                    result =
                        d.getDate() +
                        " " +
                        months[d.getMonth()] +
                        " " +
                        d.getFullYear();
                }
            }

            return result;
        },


    },

    created() {
        this.defaultCurrency = localStorage.getItem('currency') || 'EUR';
        this.start();
    },
    mounted() {
        if (!this.currentUser) {
            this.$router.push("/login");
        }
    },
};
</script>