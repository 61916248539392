<template>
    <header id="navbar" class="fixed-top">
        <div class="d-flex align-items-center">

            <div class="minified-sidebar-btn justify-content-start me-1">
                <button class="btn btn-transparent  d-flex align-items-center justify-content-center"
                    @click="showSidebar" :class="{ active: isMenuMinified }">
                    <i class="fa-regular fa-bars-staggered"></i>
                </button>
            </div>
            <div class="navbar-full-screen">
                <button class="btn btn-transparent  d-flex align-items-center justify-content-center"
                    @click="toggleFullScreen" :class="{ active: isFullScreen }">
                    <i class="fa-regular fa-expand-wide"></i>
                </button>
            </div>
            <div class="mobile-menu">
                <div class="dropdown">
                    <button class="btn btn-secondary" type="button" data-bs-toggle="dropdown"
                        aria-expanded="false">
                        <i class="fa-regular fa-bars-staggered"></i>
                    </button>
                    <ul class="dropdown-menu">
                        <li v-if="!isHousekeeping" class="nav-item">
                            <router-link id="menu-planning" to="/planning" class="nav-link">
                                <div class="item-title"><i class="fal fa-calendar-alt me-2"></i>Planning
                                </div>
                            </router-link>
                        </li>
                        <li v-if="!isHousekeeping" class="nav-item">
                            <router-link id="menu-checkin" to="/checkin-checkout" class="nav-link">
                                <div class="item-title"> <i
                                        class="fa-regular fa-right-to-bracket me-2"></i>In / Out</div>
                            </router-link>
                        </li>
                        <li v-if="!isHousekeeping" class="nav-item">
                            <router-link id="menu-bookings" to="/bookings" class="nav-link">
                                <div class="item-title"><i
                                        class="fa-regular fa-rectangle-list me-2"></i>Reservas</div>
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link id="menu-rooms" to="/rooms" class="nav-link">
                                <div class="item-title"><i
                                        class="fa-regular fa-bed me-2"></i>Habitaciones</div>
                            </router-link>
                        </li>
                        <li v-if="!isHousekeeping" class="nav-item">
                            <router-link id="menu-invoices" to="/invoices" class="nav-link">
                                <div class="item-title"><i
                                        class="fa-regular fa-credit-card me-2"></i>Facturación</div>
                            </router-link>
                        </li>
                        <li v-if="!isHousekeeping" class="nav-item">
                            <router-link id="menu-channel" to="/channel" class="nav-link">
                                <div class="item-title"><i
                                        class="fa-regular fa-network-wired me-2"></i>Channel</div>
                            </router-link>
                        </li>

                        <li v-if="!isHousekeeping" class="nav-item">
                            <router-link id="menu-rooms" to="/clients" class="nav-link">
                                <div class="item-title"><i class="fa-regular fa-users me-2"></i>Clientes
                                </div>
                            </router-link>
                        </li>
                        <li v-if="!isHousekeeping" class="nav-item">
                            <router-link id="menu-reporting" to="/reporting" class="nav-link">
                                <div class="item-title"><i
                                        class="fa-regular fa-chart-line me-2"></i>Listados</div>
                            </router-link>
                        </li>
                        <li v-if="!isHousekeeping" class="nav-item">
                            <router-link id="menu-settings" to="/settings/hotel" class="nav-link">
                                <div class="item-title"><i class="fa-regular fa-gear me-2"></i>Ajustes
                                </div>
                            </router-link>
                        </li>
                    </ul>
                </div>

            </div>
        </div>
        <div>
            <div class="d-flex align-items-center ms-auto px-3">
                <div v-if="!isHousekeeping" class="me-3">
                    <router-link to="/bookings/create">
                        <button class="btn  btn-primary" style="width: max-content; height: 40px;">
                            <i class="far fa-plus"></i><span class="d-none d-md-inline-block ms-2">Nueva
                                reserva</span>
                        </button>
                    </router-link>
                </div>

                <MazSelect v-if="!loading" id="hotel-select" v-model="currentHotel"
                    :options="userHotels" option-value-key="id" option-label-key="name"
                    option-input-value-key="name" search search-placeholder="Buscar..."
                    @update:model-value="onChangeHotel(currentHotel)"
                    style="font-size: 12px; height: 40px;" autocomplete="nope">
                </MazSelect>
                <div class="profile-dropdown dropdown" @mouseenter="toggleDropdown"
                    @mouseleave="toggleDropdown">
                    <a href="#" class="d-flex align-items-center ms-3 link-dark text-decoration-none"
                        id="dropdownUser3" data-bs-toggle="dropdown" aria-expanded="false">

                        <div class="d-flex align-items-center justify-content-center"
                            style="width: 40px; height: 40px; background: var(--bs-primary); border-radius: 50px;"
                            v-if="user">
                            <span class="text-uppercase text-white" style="font-size: 20px;">{{
                                getFirstCharacter() }}</span>
                        </div>
                    </a>
                    <ul v-show="showDropdown" class="dropdown-menu text-small shadow border-0 p-0"
                        aria-labelledby="dropdownUser3">
                        <div class="d-flex align-items-center p-3" v-if="user"
                            style="border-bottom: 1px solid #F1F1F4;">
                            <div class="d-flex align-items-center justify-content-center me-3"
                                style="width: 40px; height: 40px; background: var(--bs-primary); border-radius: 50px;">
                                <span class="text-uppercase text-white" style="font-size: 20px;">{{
                                    getFirstCharacter() }}</span>
                            </div>
                            <div style="font-weight: 500;">
                                <div style="font-size: 14px;">{{ user.username }}</div>

                                <div v-if="user.email"><small class="text-muted">{{ user.email
                                }}</small></div>

                            </div>
                        </div>
                        <!-- <li><router-link v-if="user" to="/profile" class="dropdown-item">Mi cuenta</router-link ></li> -->
                        <!-- <li><hr class="dropdown-divider"></li> -->
                        <li v-if="user" class="p-3 dropdown-item" @click.prevent="logOut">
                            <i class="fa-regular fa-arrow-right-from-bracket me-2"></i>Cerrar sesión
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </header>
</template>

<style scoped>
.profile-dropdown .dropdown-menu {
    min-width: 300px;
}

.profile-dropdown.dropdown:hover .dropdown-menu {
    display: block;
}

.profile-dropdown .dropdown-item {
    text-decoration: none;
    color: #181C32;
    font-size: 14px;
}

.profile-dropdown .dropdown-menu {
    right: 0;
}

@media screen and (max-width: 768px) {
    #hotel-select {
        max-width: 100px;
    }


}
</style>

<script>
import EventBus from "@/common/EventBus";
import hotelService from "@/services/hotel.service";
import UserService from "@/services/user.service";
import InvoiceInfoService from "@/services/invoiceinfo.service";

export default {
    data: () => ({
        currentHotel: null,
        userHotels: [],
        user: null,
        loading: true,
        isFullScreen: false,
        isMenuMinified: false,
        showDropdown: false,
    }),
    computed: {
        currentUser() {
            return this.$store.state.user.data;
        },
        userRoles() {
            return this.$store.getters['user/userRoles'];
        },
        isHousekeeping() {
            return this.userRoles.includes('pms_hotel_housekeeping');
        },
    },
    methods: {
        logOut() {
            this.$store.dispatch("auth/logout");
            this.$router.push("/login");
        },
        getFirstCharacter() {
            if (this.user && typeof this.user.username === 'string') {
                return this.user.username.substring(0, 1);
            }
            return ''; // Return a default value or handle the error
        },
        async getUserHotels() {
            var response = await hotelService.getUserHotels({
                activePMS: true,
            });
            // console.log(response)
            this.userHotels = response.data;
            await this.getCurentHotel();
            this.loading = false;
        },
        async onChangeHotel(id) {
            await hotelService.updateLocalHotel(id);
            await this.getHotelModules()
            var invoice_info = await this.getInvoiceInfo()
            localStorage.setItem("currency", invoice_info.currency)
            window.location.reload();
        },
        async getHotelModules() {
            var response = await hotelService.getHotelModules();
            var hotel_modules = response.data;
            // console.log(hotel_modules, "hotelModules navbar");
            this.$store.dispatch(
                "hotel/updateHotelModules",
                hotel_modules
            );
        },

        async getCurentHotel() {
            this.currentHotel = await hotelService.getLocalHotel().id;
            //  console.log( this.currentHotel)
        },

        async getUser() {
            this.user = await UserService.getLocalUser();
            //  console.log(this.user)
        },

        async getInvoiceInfo() {
            var response = await InvoiceInfoService.getInvoiceInfo();
            return response.data
        },

        showSidebar() {

            window.$("#sidebar").removeClass("open");
            if (!window.$("#sidebar").hasClass("minified")) {
                window.$("#sidebar").addClass("minified");
                window.$("#navbar").addClass("minified");
                window.$(".page-app").addClass("minified");
                this.isMenuMinified = true;
            } else {
                window.$("#sidebar").removeClass("minified");
                window.$("#navbar").removeClass("minified");
                window.$(".page-app").removeClass("minified");
                this.isMenuMinified = false;
            }

        },
        toggleFullScreen() {
            if (!document.fullscreenElement) {
                let element = document.documentElement;
                if (element.requestFullscreen) {
                    element.requestFullscreen();
                } else if (element.webkitRequestFullscreen) {
                    element.webkitRequestFullscreen();
                } else if (element.msRequestFullscreen) {
                    element.msRequestFullscreen();
                }
                this.isFullScreen = true; // Set state to full-screen
            } else {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.webkitExitFullscreen) {
                    document.webkitExitFullscreen();
                } else if (document.msExitFullscreen) {
                    document.msExitFullscreen();
                }
                this.isFullScreen = false; // Set state to not full-screen
            }
        },

        toggleDropdown() {
            this.showDropdown = !this.showDropdown;
        },
    },
    created() { },
    mounted() {
        EventBus.on("logout", () => {
            this.logOut();
        });
        this.getUser();
        this.getUserHotels();
    },
    beforeUnmount() {
        EventBus.remove("logout");
    },
};
</script>
