<template>
    <div>
        <h2 style="font-size: 18px; font-weight: 300;" class="mb-3">Información del cliente</h2>
        <div v-if="!loading" class="row mb-3">
            <div class="col-md-6 mb-3">
                <label class="form-label">Nombre</label>
                <input type="text" class="form-control" v-model="client.firstName" @change="addClient" required>
            </div>
            <div class="col-md-6 mb-3">
                <label class="form-label">Apellidos</label>
                <input type="text" class="form-control" v-model="client.lastName" @change="addClient" required>
            </div>
            <div class="col-md-6 mb-3">
                <label class="form-label">Email</label>
                <input type="email" class="form-control" v-model="client.email" @change="addClient" required>
            </div>
            <div class="col-md-6 mb-3">
                <label class="form-label">Teléfono</label>
                <input type="text" class="form-control" v-model="client.telephone" @change="addClient" required>
            </div>
            <div class="col-md-12 mb-3">
                <div class="form-check form-switch mb-3">
                    <input class="form-check-input" type="checkbox" v-model="client.sendEmail" @change="addClient">
                    <label class="form-check-label">Mandar Email de confirmación</label>
                </div>
            </div>
            <!-- <div class="col-md-12 mb-3">
                <div class="form-check form-switch mb-3">
                    <input class="form-check-input" type="checkbox" v-model="client.sendPaymentEmail">
                    <label class="form-check-label">Mandar Email de enlace de pago</label>
                </div>
            </div> -->
            <div v-if="!loading && client.sendEmail" class="col-12">
                <EmailEditor ref="emailEditor" v-on:load="editorLoaded" v-on:ready="editorReady" v-on:updated="editorUpdated" />


            </div>
        </div>
    </div>
</template>

<style>
.unlayer-editor iframe {
    min-width: auto !important;
    max-width: 100%;
    height: 85vh !important;
}
</style>

<script>
import HotelService from "@/services/hotel.service";
import InfoService from "../../services/info.service"
import { EmailEditor } from 'vue-email-editor';

import Swal from 'sweetalert2'

export default {
    name: 'ClientCreateBooking',
    emits: ["updateClient"],
    props: {},
    components: {
        EmailEditor,
    },
    data() {
        return {
            client: {
                firstName: '',
                lastName: '',
                telephone: '',
                email: '',
                sendEmail: false,
                sendPaymentEmail: false,
                htmlEmail: null,
            },
            info: null,
            loading: true,
        }
    },
    methods: {
        async start() {
            this.loading = true;
            await this.getInfo();

            var content = JSON.parse(this.info.content)
            this.client.htmlEmail = content['html'];

            this.loading = false;

        },

        async addClient() {
            this.$emit("updateClient", this.client);
        },

        async getInfo() {
            var response = await InfoService.getInfo({ key_name: 'email-confirmed' })
            this.info = response.data;
        },

        editorLoaded() {
            // console.log('editorLoaded');
            // Pass the template JSON here
            var content = JSON.parse(this.info.content)
            this.$refs.emailEditor.editor.loadDesign(content['design']);

            this.$refs.emailEditor.editor.setMergeTags({
                hotel_name: {
                    name: "Hotel Name",
                    value: "{{hotel_name}}",
                },
                booking_number: {
                    name: "Booking Number",
                    value: "{{booking_number}}",
                },
                first_name: {
                    name: "First Name",
                    value: "{{first_name}}",
                },
                hotel_logo: {
                    name: "Hotel Logo",
                    value: "{{hotel_logo}}",
                },
                checkin_date: {
                    name: "Checkin Date",
                    value: "{{checkin_date}}",
                },
                checkout_date: {
                    name: "Checkout Date",
                    value: "{{checkout_date}}",
                },

                booking_price: {
                    name: "Booking Price",
                    value: "{{booking_price}}",
                },

                rooms_info: {
                    name: "Rooms Info",
                    value: "{{rooms_info|safe}}",
                },

            })
        },
        // called when the editor has finished loading
        editorReady() {
            // console.log('editorReady');
            this.$refs.emailEditor.editor.registerCallback('design:updated', (data) => {
                // Aquí manejas el evento de actualización del diseño.
                // var type = data.type; // body, row, content
                // var item = data.item;
                // var changes = data.changes;
                // console.log('design:updated', type, item, changes);

                this.editorUpdated()

                // Aquí puedes realizar acciones adicionales, como auto guardado o actualizaciones de estado.
            });
        },

        editorUpdated() {
            this.$refs.emailEditor.editor.exportHtml((data) => {
                this.client.htmlEmail = data.html;
            });
        }



    },
    created() {
        this.start()
    },
}
</script>