<template>
    <div class="page-app">
        <div class="row pt-2">
            <div class="col-md-4 col-lg-3 col-xl-2 px-3">
                <SidebarMenu />
            </div>
            <div class="col-md-8 col-lg-9 col-xl-10">
                <div class="d-md-flex justify-content-between align-items-center mb-3">
                    <h1>Huéspedes</h1>
                    <div class="d-md-flex">
                        <DatePicker v-model="range" mode="date" :masks="masks" is-range class="me-2">
                            <template v-slot="{ inputValue, inputEvents, isDragging }">
                                <div class="d-flex justify-content-center align-items-center bg-white shadow-sm btn">
                                    <div class="input-group flex-grow-0" style="width: auto">
                                        <span class="input-group-text border-0 bg-white text-primary"><i class="far fa-calendar-week"></i></span>
                                        <input class="flex-grow-0 border-0" :class="isDragging
                            ? 'text-gray-600'
                            : 'text-gray-900'
                            " :value="inputValue.start" v-on="inputEvents.start" style="
												width: 105px;
												cursor: pointer;
											" />
                                    </div>
                                    <span class="me-2 flex-grow-0">
                                        <i class="fas fa-long-arrow-alt-right stroke-current text-gray-600"></i>
                                    </span>
                                    <div class="input-group flex-grow-0" style="width: auto">
                                        <span class="input-group-text border-0 bg-white text-primary"><i class="far fa-calendar-week"></i></span>
                                        <input class="flex-grow-0 border-0" :class="isDragging
                            ? 'text-gray-600'
                            : 'text-gray-900'
                            " :value="inputValue.end" v-on="inputEvents.end" style="
												width: 105px;
												cursor: pointer;
											" />
                                    </div>
                                </div>
                            </template>
                        </DatePicker>

                        <!-- <div
							class="ms-3"
							data-bs-toggle="modal"
							data-bs-target="#modalReportingCheckin"
						>
							<button
								class="btn bg-white text-primary shadow-sm"
								data-bs-toggle="tooltip"
								data-bs-placement="top"
								title="Exportar"
								@click="exportReportingCheckin()"
							>
								<i class="fa-regular fa-arrow-down-to-line"></i>
							</button>
						</div> -->
                    </div>
                </div>
                <div class="card border-0 shadow-sm mb-3" style="min-height: 100px; max-height: 500px; overflow: auto">
                    <div class="card-body p-3">
                        <div v-if="!loading" class="table-resposive">
                            <h6>Lista de huéspedes</h6>
                            <div v-if="data.guests.length > 0">
                                <table class="table table-hover table-bordered mb-5">
                                    <thead class="bg-white text-black">
                                        <tr>
                                            <th style="
													min-width: 160px;
													max-width: 160px;
												">
                                                Fecha de entrada
                                            </th>
                                            <th style="
													min-width: 240px;
													max-width: 240px;
												">
                                                Nombre y Apellidos
                                            </th>
                                            <th style="
													min-width: 130px;
													max-width: 130px;
												">
                                                Procendencia
                                            </th>
                                            <th style="
													min-width: 240px;
													max-width: 240px;
												">
                                                Habitación
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr :key="item" v-for="item in data.guests">
                                            <td>{{ item.dateEntry }}</td>
                                            <td>{{ item.guest_name }}</td>
                                            <td>{{ item.country_name }}</td>
                                            <td>
                                                <router-link v-if="item.bookingroom_id" class="btn btn-sm btn-outline-primary" :to="'/bookings/checkin/' +
                            item.bookingroom_id
                            "><i class="far fa-eye me-2"></i>
                                                    <span v-if="item.bookingroom_room_name
                            ">{{
                            item.bookingroom_room_name
                        }}</span>
                                                    <span v-else>Sin asignar</span>
                                                </router-link>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div v-if="data.guests.length == 0 ||
                            data.guests == '' ||
                            data.guests == null
                            " class="text-center py-5">
                                <div>
                                    <i class="fa-regular fa-circle-info fa-2x text-primary mb-3"></i>
                                </div>
                                No hay información para la fecha seleccionada
                            </div>
                        </div>
                        <div v-else id="spinner-search" class="col-12 text-center py-5">
                            <div class="spinner-border text-primary" role="status" style="
									width: 2rem;
									height: 2rem;
									visibility: visble;
								">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                            <div>Cargando información...</div>
                        </div>
                    </div>
                </div>

                <div class="card border-0 shadow-sm mb-3" style="min-height: 100px; max-height: 500px; overflow: auto">
                    <div class="card-body p-3">
                        <div v-if="!loading" class="table-resposive">
                            <h6>Procendencia países</h6>
                            <div v-if="data.countries.length > 0">
                                <table class="table table-hover table-bordered mb-5">
                                    <thead class="bg-white text-black">
                                        <tr>
                                            <th style="
													min-width: 160px;
													max-width: 160px;
												">
                                                País
                                            </th>
                                            <th style="
													min-width: 120px;
													max-width: 120px;
												">
                                                Nº total huéspedes
                                            </th>
                                            <th style="
													min-width: 120px;
													max-width: 120px;
												">
                                                Porcentaje
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr :key="item" v-for="item in data.countries">
                                            <td>{{ item.country_name }}</td>
                                            <td>{{ item.total_guests }}</td>
                                            <td>{{ item.total_guests_per }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div v-if="data.countries.length == 0 ||
                            data.countries == '' ||
                            data.countries == null
                            " class="text-center py-5">
                                <div>
                                    <i class="fa-regular fa-circle-info fa-2x text-primary mb-3"></i>
                                </div>
                                No hay información para la fecha seleccionada
                            </div>
                        </div>
                        <div v-else id="spinner-search" class="col-12 text-center py-5">
                            <div class="spinner-border text-primary" role="status" style="
									width: 2rem;
									height: 2rem;
									visibility: visble;
								">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                            <div>Cargando información...</div>
                        </div>
                    </div>
                </div>

                <div class="card border-0 shadow-sm mb-3" style="min-height: 100px; max-height: 500px; overflow: auto">
                    <div class="card-body p-3">
                        <div v-if="!loading" class="table-resposive">
                            <h6>Procendencia Comunidades Autónomas - España</h6>
                            <div v-if="data.countries.length > 0">
                                <table class="table table-hover table-bordered mb-5">
                                    <thead class="bg-white text-black">
                                        <tr>
                                            <th style="
													min-width: 120px;
													max-width: 120px;
												">
                                                Comunidad Autónoma
                                            </th>
                                            <th style="
													min-width: 120px;
													max-width: 240px;
												">
                                                Nº total huéspedes
                                            </th>
                                            <th style="
													min-width: 120px;
													max-width: 120px;
												">
                                                Porcentaje
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody :key="item" v-for="item in data.countries">
                                        <tr v-if="item.country_code == 'ESP'" :key="item" v-for="region in item.regions">
                                            <td>{{ region.region_name }}</td>
                                            <td>{{ region.total_guests }}</td>
                                            <td>
                                                {{ region.total_guests_per }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div v-if="data.countries.length == 0 ||
                            data.countries == '' ||
                                data.countries == null
                                " class="text-center py-5">
                                <div>
                                    <i class="fa-regular fa-circle-info fa-2x text-primary mb-3"></i>
                                </div>
                                No hay información para la fecha seleccionada
                            </div>
                        </div>
                        <div v-else id="spinner-search" class="col-12 text-center py-5">
                            <div class="spinner-border text-primary" role="status" style="
									width: 2rem;
									height: 2rem;
									visibility: visble;
								">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                            <div>Cargando información...</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal -->
        <div class="modal fade" id="modalReportingCheckin" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Listado de entradas</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <embed v-if="pdf != null" :src="pdf" frameborder="0" width="100%" height="550px" />
                        <div v-else id="spinner-search" class="col-12 text-center py-5">
                            <div class="spinner-border text-primary" role="status" style="
									width: 2rem;
									height: 2rem;
									visibility: visble;
								">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                            <div>Cargando información...</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
.listings-menu-item {
    cursor: pointer;
}

.listings-menu-item:hover {
    background-color: #fff;
    border-radius: 4px;
}
</style>

<script>
import SidebarMenu from "../../components/reporting/SidebarMenu.vue";

import BookingService from "../../services/booking.service";

export default {
    name: "ReportingGuests",
    components: {
        SidebarMenu: SidebarMenu,
    },
    data() {
        return {
            loading: true,
            pdf: null,
            data: [],
            range: {
                start: new Date().setDate(new Date().getDate() - 1),
                end: new Date(),
            },
            masks: {
                input: "YYYY-MM-DD",
            },
            defaultCurrency: ''
        };
    },
    watch: {
        range: {
            handler: function () {
                this.start();
            },
            deep: true,
        },
    },
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },
    },
    methods: {
        async start() {
            this.loading = true;
            await this.getGuestsReporting();

            this.loading = false;
        },

        async getGuestsReporting() {
            var response = await BookingService.getBookingGuestsReporting({
                startDate: this.formatDate(this.range.start),
                endDate: this.formatDate(this.range.end),
            });
            this.data = response.data;
            console.log(this.data, "Data");
        },

        async exportReportingCheckin() {
            this.pdf = null;
            var response =
                await BookingService.getBookingCheckinListDayReportingPDF({
                    startDate: this.formatDate(this.range.start),
                    endDate: this.formatDate(this.range.end),
                });
            this.pdf = response.data;
            console.log(this.pdf, "PDF");
        },

        formatDate(date) {
            var d = new Date(date),
                month = "" + (d.getMonth() + 1),
                day = "" + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2) month = "0" + month;
            if (day.length < 2) day = "0" + day;

            return [year, month, day].join("-");
        },

    },
    created() {
        this.start();
    },
    mounted() {
        if (!this.currentUser) {
            this.$router.push("/login");
        }
    },
};
</script>