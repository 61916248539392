import api from './api';

class CountryService { 
    

    /**************************************
     *  API HOSPEDIUM
     **************************************/

    async getAllCountries() {
        return await api.get('/countries')
        .then((response) => {
            return Object.freeze(response.data);
        });
    }
    
    getCountry(id) {
        return api.get('/countries/' + id)
        .then((response) => {
            return response.data;
        });
    }

    
 

}

export default new CountryService();