<template>
    <div>
        <div class="d-flex justify-content-between align-items-center payment-box" data-bs-toggle="modal" :data-bs-target="'#modalEditPayment' + payment.id">
            <div class="d-flex">
                <div class="me-3 d-flex align-items-center">
                    <span><i class="fa-regular fa-credit-card text-primary fs-3"></i></span>
                </div>
                <div>
                    <small class="text-uppercase">{{
            paymentMethod.name
        }}</small>
                    <div>
                        <small>
                            {{
                formatDate(
                    payment.date,
                    false,
                    false,
                    (type = "short")
                )
            }}
                        </small>
                    </div>
                    <div class="fs-5" style="font-weight: 500">
                        {{ formatCurrency(quantity, defaultCurrency) }}
                    </div>
                    <button v-if="!payment.isPaymentGateway" class="btn btn-sm btn-link text-primary px-0">
                        <i class="fas fa-edit me-1"></i>Editar
                    </button>
                    <div v-else><small class="text-muted">Pago automático, no editable.</small></div>
                </div>
            </div>
            <div class="text-end"></div>
        </div>

        <!-- Modal Edit Payment -->
        <div class="modal fade" :id="'modalEditPayment' + payment.id" tabindex="-1" aria-labelledby="modalEditPayment" aria-hidden="true">
            <div class="modal-dialog modal-sm">
                <div class="modal-content p-2">
                    <form v-if="!payment.isPaymentGateway">
                        <div class="modal-header border-0">
                            <h5 class="modal-title">Editar pago</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="form-group mb-3">
                                <label class="form-label">Método de Pago</label>
                                <select class="form-control" v-model="editPayment.paymentMethod" required>
                                    <option :key="item" v-for="item in paymentMethods" :value="item.id">
                                        {{ item.name }}
                                    </option>
                                </select>
                            </div>
                            <div class="form-group mb-3">
                                <label class="form-label">Cantidad</label>
                                <input type="text" class="form-control" v-model="editPayment.quantity" />
                            </div>
                            <div class="form-group mb-3">
                                <label class="form-label">Fecha</label>
                                <DatePicker v-model="editPayment.date" mode="date">
                                    <template v-slot="{ inputValue, inputEvents }">
                                        <input class="form-control" :value="inputValue" v-on="inputEvents" />
                                    </template>
                                </DatePicker>
                            </div>
                        </div>
                        <div class="modal-footer d-flex justify-content-between border-0">
                            <div>
                                <button class="btn btn-link text-danger" @click="deletePayment">
                                    <i class="fa-regular fa-trash me-1"></i>Eliminar
                                </button>
                            </div>
                            <div>
                                <button id="submitEditPayment" type="submit" @click="update" class="btn btn-primary">
                                    Guardar
                                </button>
                            </div>
                        </div>
                    </form>
                    <form v-else>
                        <div class="modal-header border-0">
                            <h5 class="modal-title">Editar pago</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="mb-5">Este es un pago automático generado por la pasarela de pago y no se puede editar la información de pago.</div>
                            <button class="btn btn-danger w-100" @click="removeAssignPayment">
                                Quitar pago asociado de la factura
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.payment-box {
    background: #f6f7f9;
    cursor: pointer;
    border-radius: 10px;
    padding: 10px 15px;
}

.payment-box .icon {
    background: #e3ecfd;
    padding: 2px 5px;
    border-radius: 2px;
    border-color: #e3ecfd;
}

.payment-box:hover {
    background: #e3ecfd;
    border: 1px solid #0091ff;
}
</style>

<script>
import PaymentMethodService from "../../services/paymentmethod.service";
import PaymentService from "../../services/payment.service";
import InvoiceService from "../../services/invoice.service";

import Swal from "sweetalert2";
import moment from 'moment-timezone';

export default {
    name: "InvoicePaymentRow",
    props: {
        payment: Object,
        invoice: Object,
    },
    data() {
        return {
            id: this.payment.id,
            client: this.payment.client,
            paymentMethod: this.payment.paymentMethod,
            quantity: this.payment.quantity,
            editPayment: {
                id: this.payment.id,
                paymentMethod: this.payment.paymentMethod,
                invoice: this.payment.invoice,
                quantity: this.payment.quantity,
                date: this.payment.date,
            },
            paymentMethods: [],
            masks: {
                input: "YYYY-MM-DD",
            },
            defaultCurrency: ''
        };
    },
    methods: {
        async start() {
            this.editPayment.date = this.toUTCDate(this.editPayment.date)
            await this.getPaymentMethod();
            await this.getAllPaymentMethods();
        },
        toUTCDate(localDate) {
            localDate = new Date(localDate)
            return new Date(
                localDate.getUTCFullYear(),
                localDate.getUTCMonth(),
                localDate.getUTCDate(),
            );
        },
        async getPaymentMethod() {
            var response = await PaymentMethodService.getPaymentMethod(
                this.payment.paymentMethod
            );
            this.paymentMethod = response.data;
            // console.log(this.paymentMethod , "Payment Method")
        },
        async getAllPaymentMethods() {
            var response = await PaymentMethodService.getAllPaymentMethod();
            this.paymentMethods = response.data;
        },

        formatDate(date, hour = false, api = false, type) {
            var months = [
                "enero",
                "febrero",
                "marzo",
                "abril",
                "mayo",
                "junio",
                "julio",
                "agosto",
                "septiembre",
                "octubre",
                "noviembre",
                "diciembre",
            ];
            const short_months = [
                "Ene.",
                "Feb.",
                "Mar.",
                "Abr.",
                "May.",
                "Jun.",
                "Jul.",
                "Ago.",
                "Sep.",
                "Oct.",
                "Nov.",
                "Dic.",
            ];
            const d = new Date(date);
            var result = "";
            if (api) {
                result =
                    d.getUTCFullYear() +
                    "-" +
                    (d.getUTCMonth() + 1) +
                    "-" +
                    d.getUTCDate();
            } else if (hour) {
                result =
                    d.getDate() +
                    " " +
                    months[d.getMonth()] +
                    " " +
                    d.getFullYear() +
                    " - " +
                    d.getHours() +
                    ":" +
                    (d.getMinutes() < 10 ? "0" : "") +
                    d.getMinutes() +
                    ":" +
                    (d.getSeconds() < 10 ? "0" : "") +
                    d.getSeconds();
            } else {
                if (type == "short") {
                    result =
                        d.getUTCDate() +
                        " " +
                        short_months[d.getUTCMonth()] +
                        " " +
                        d.getUTCFullYear();
                } else {
                    result =
                        d.getUTCDate() +
                        " " +
                        months[d.getUTCMonth()] +
                        " " +
                        d.getUTCFullYear();
                }
            }

            return result;
        },

        async update(evt) {
            evt.preventDefault();
            window.$("#submitEditPayment").empty();
            window
                .$("#submitEditPayment")
                .append(
                    '<span class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>Guardando'
                );

            // Suponiendo que `this.addPayment.date` es una cadena de fecha en formato reconocible por moment
            var m = moment(this.editPayment.date);  // Crear un objeto moment a partir de la fecha

            // Adivinar la zona horaria del usuario y ajustar la fecha a ella
            var userTimeZone = moment.tz.guess();
            var localDate = m.tz(userTimeZone);

            // Extraer mes, día y año en la zona horaria del usuario
            var month = "" + (localDate.month() + 1),  // month() devuelve un índice basado en cero, por eso se suma 1
                day = "" + localDate.date(),
                year = localDate.year();

            // var d = new Date(this.editPayment.date),
            //     month = "" + (d.getMonth() + 1),
            //     day = "" + d.getDate(),
            //     year = d.getFullYear();

            if (month.length < 2) month = "0" + month;
            if (day.length < 2) day = "0" + day;
            this.editPayment.date = [year, month, day].join("-");

            this.editPayment.quantity = parseFloat(this.editPayment.quantity);

            let response = await PaymentService.updatePayment(this.editPayment);

            window.$("#submitEditPayment").empty();
            window.$("#submitEditPayment").append("Guardar");

            if (response.status == 201 || response.status == 200) {
                window.$("#modalEditPayment").hide();
                window.$("body").removeClass("modal-open");
                window.$(".modal-backdrop").remove();
                Swal.fire({
                    icon: "success",
                    title: "Pago actualizado",
                    confirmButtonColor: "#0091ff",
                }).then((result) => {
                    this.$router.go();
                });
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Ha ocurrido un error. Vuelve a intentarlo. Si el error persiste ponte en contacto con soporte@hospedium.com",
                    confirmButtonColor: "#0091ff",
                });
            }
        },

        async deletePayment(evt) {
            evt.preventDefault();
            let response = await PaymentService.deletePayment({
                id: this.payment.id,
            });

            if (response.status == 200) {
                window.$("#modalEditPayment").hide();
                window.$("body").removeClass("modal-open");
                window.$(".modal-backdrop").remove();
                Swal.fire({
                    icon: "success",
                    title: "Pago eliminado",
                    confirmButtonColor: "#0091ff",
                }).then((result) => {
                    this.$router.go();
                });
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Ha ocurrido un error. Vuelve a intentarlo. Si el error persiste ponte en contacto con soporte@hospedium.com",
                    confirmButtonColor: "#0091ff",
                });
            }
        },

        async removeAssignPayment() {

            this.$swal.fire({
                title: 'Guardando...',
                text: 'Por favor espera mientras se guarda.',
                icon: 'info',
                allowOutsideClick: false,
                showConfirmButton: false,
                willOpen: () => {
                    this.$swal.showLoading();
                }
            });

            try {
                const response = await PaymentService.assignPaymentToInvoice(
                    this.payment.id, {
                    invoice_id: null
                });

                if (response.status == 200) {
                    this.$swal.fire({
                        title: 'Éxito',
                        text: 'El pago ha quitado correctamente.',
                        icon: 'success',
                        confirmButtonText: 'OK',
                        confirmButtonColor: '#0091ff',
                    }).then(() => {
                        this.$router.go(0); // Recarga la ruta actual
                    });
                } else {
                    this.$swal.fire({
                        title: 'Error',
                        text: 'Ocurrió un error al quitar el pago de la factura. Por favor, intenta nuevamente.',
                        icon: 'error',
                        confirmButtonText: 'OK',
                        confirmButtonColor: '#0091ff',
                    });
                }
            } catch (error) {
                console.error('Error al asignar el pago:', error);
                this.$swal.fire({
                    title: 'Error',
                    text: 'Ocurrió un error al quitar el pago. Por favor, intenta nuevamente.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#0091ff',
                });
            }

        }

    },
    created() {
        this.defaultCurrency = localStorage.getItem('currency') || 'EUR';
        this.start();
    },
};
</script>