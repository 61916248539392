import api from "./api";
import HotelService from "./hotel.service";

class ChargeService {
	/**************************************
	 *  API HOSPEDIUM
	 **************************************/
	async addCharge(params) {
		let hotel = HotelService.getLocalHotel();
		params.hotel = hotel.id;
		return await api
			.post("/charges", params)
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return { status: error.response.status, data: error.response.data };
			});
	}

	async updateCharge(id, params) {
		return await api
			.put("/charges/" + id, params)
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return { status: error.response.status, data: error.response.data };
			});
	}

	async getAllCharges(params) {
		let hotel = HotelService.getLocalHotel();
		params.hotel = hotel.id;
		return await api
			.get("/charges", { params })
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return { status: error.response.status, data: error.response.data };
			});
	}

	async getCharge(id) {
		return await api
			.get("/charges/" + id)
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return { status: error.response.status, data: error.response.data };
			});
	}

	async deleteCharge(id) {
		let hotel = HotelService.getLocalHotel();
		return await api
			.post("/charges/" + id + "/delete?hotel=" + hotel.id)
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return { status: error.response.status, data: error.response.data };
			});
	}
	async createNewChargeToBooking(data) {
		let hotel = HotelService.getLocalHotel();
		var params = {
			hotel: hotel.id,
		};
		return await api
			.post("/charges/create", data, { params: params })
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return { status: error.response.status, data: error.response.data };
			});
	}

	async editChargeBooking(data) {
		let hotel = HotelService.getLocalHotel();
		var params = {
			hotel: hotel.id,
		};
		return await api
			.post("/charges/edit", data, { params: params })
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return { status: error.response.status, data: error.response.data };
			});
	}

	async deleteChargesFromBookingroom(bookingroom_pk, data) {
		let hotel = HotelService.getLocalHotel();
		var params = {
			hotel: hotel.id,
		};
		return await api
			.post(`/charges/bookingroom/${bookingroom_pk}/delete/`, data, { params: params })
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return { status: error.response.status, data: error.response.data };
			});
	}
}

export default new ChargeService();
