import axios from "axios";

var API_URL = ''

if (location.hostname === "localhost") {
  API_URL =  "http://127.0.0.1:8000/api";
} else {
  API_URL =  "https://api.hospedium.io/api";
}

const instance = axios.create({
  baseURL: API_URL,
  headers: {  
    "Content-Type": "application/json",
  },
});

export default instance;