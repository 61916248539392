<template>
    <td>
        <button class="btn btn-sm badge-light-primary" style="cursor: pointer">
            <i class="far fa-eye"></i>
        </button>
    </td>
    <td>
        <span class="badge badge-secondary">#{{ bookingroom.id }}</span>
    </td>
    <td>
        <span class="badge badge-light-info">{{ bookingroom.portal }}</span>
    </td>
    <td>{{ formatDate(bookingroom.checkin, false) }}</td>
    <td>{{ formatDate(bookingroom.checkout, false) }}</td>
    <td>{{ bookingroom.adults + bookingroom.children }}</td>
    <td>
        <span v-if="bookingroom.roomType">{{ bookingroom.roomType.name }}</span>
    </td>
    <td>
        <span v-if="bookingroom.show_taxes">{{ formatCurrency(bookingroom.total_amount, defaultCurrency) }}</span>
        <span v-else>{{ formatCurrency(bookingroom.baserate_amount, defaultCurrency) }}<br><small><em>Sin impuestos</em></small></span>
    </td>
    <td class="text-uppercase">
        {{ bookingroom.client }}
    </td>
    <td>{{ formatDate(bookingroom.dlm, true) }}</td>
    <td>
        <span v-if="bookingroom.status == 4" class="badge badge-light-primary">Confirmado</span>
        <span v-else-if="bookingroom.status == 7" class="badge badge-light-danger">Cancelado</span>
        <span v-else-if="bookingroom.status == 5" class="badge badge-light-danger">Rechazado</span>
        <span v-else-if="bookingroom.status == 8" class="badge badge-light-warning">Modificado</span>
        <span v-else-if="bookingroom.status == 6" class="badge badge-light-dark">No show</span>
    </td>
</template>

<script>
import { boolean } from "yup/lib/locale";
import BookingService from "../../services/booking.service";

export default {
    name: "PlanningNotAssignedRoomRow",
    props: {
        bookingroom: Object,
    },
    data() {
        return {
            defaultCurrency: ''
        };
    },
    methods: {
        formatDate(date, hour = false) {
            var days = ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"];
            var months = [
                "ene", "feb", "mar", "abr", "may", "jun",
                "jul", "ago", "sep", "oct", "nov", "dic"
            ];
            var currentDate = new Date(date);




            if (hour) {
                var day = currentDate.getDate(); // Día local
                var month = months[currentDate.getMonth()]; // Mes local
                var year = currentDate.getFullYear(); // Año local
                var hours = currentDate.getHours(); // Horas
                var minutes = currentDate.getMinutes(); // Minutos

                // Formatea la fecha local con la hora 
                return `${day} ${month} ${year} - ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
            } else {
                // Formatea solo la fecha en UTC
                var day = currentDate.getUTCDate(); // Día local
                var month = months[currentDate.getUTCMonth()]; // Mes local
                var year = currentDate.getUTCFullYear(); // Año local
                return `${day} ${month} ${year}`;
            }
        },

    },
    created() {
    },
    mounted() { },
};
</script>