<template>
    <div class="page-app">
        <div class="row pt-2">
            <div class="col-md-4 col-lg-3 col-xl-2 px-3">
                <SidebarMenu />
            </div>
            <div class="col-md-8 col-lg-9 col-xl-10">
                <div class="d-md-flex justify-content-between align-items-center mb-3">
                    <h1>Ventas</h1>
                    <div class="d-md-flex">
                        <DatePicker v-model="range" mode="date" :masks="masks" is-range class="me-2">
                            <template v-slot="{ inputValue, inputEvents, isDragging }">
                                <div class="d-flex justify-content-center align-items-center bg-white shadow-sm btn">
                                    <div class="input-group  flex-grow-0" style="width: auto;">
                                        <span class="input-group-text border-0 bg-white text-primary"><i class="far fa-calendar-week"></i></span>
                                        <input class="flex-grow-0 border-0" :class="isDragging ? 'text-gray-600' : 'text-gray-900'" :value="inputValue.start" v-on="inputEvents.start"
                                            style="width: 105px; cursor: pointer;" />
                                    </div>
                                    <span class="me-2 flex-grow-0">
                                        <i class="fas fa-long-arrow-alt-right stroke-current text-gray-600"></i>
                                    </span>
                                    <div class="input-group flex-grow-0" style="width: auto;">
                                        <span class="input-group-text border-0 bg-white text-primary"><i class="far fa-calendar-week"></i></span>
                                        <input class="flex-grow-0 border-0" :class="isDragging ? 'text-gray-600' : 'text-gray-900'" :value="inputValue.end" v-on="inputEvents.end"
                                            style="width: 105px; cursor: pointer;" />
                                    </div>
                                </div>
                            </template>
                        </DatePicker>

                        <div data-bs-toggle="modal" data-bs-target="#modalReportingCheckin">
                            <button class="btn bg-white text-primary shadow-sm" data-bs-toggle="tooltip" data-bs-placement="top" title="Exportar" @click="exportReport()"><i
                                    class="fa-regular fa-arrow-down-to-line"></i></button>
                        </div>
                    </div>
                </div>
                <div class="card border-0 shadow-sm mb-3">
                    <div class="card-body p-3">
                        <div v-if="!loading">
                            <div class="d-flex mb-3">
                                <router-link to="/reporting/sales" class="btn btn-sm rounded-pill reporting-chart-nav-pill">Ventas</router-link>
                                <router-link to="/reporting/sales_by_payment" class="btn btn-sm rounded-pill reporting-chart-nav-pill">Ventas por
                                    forma de pago</router-link>
                            </div>
                            <MazChart v-bind="{ ...lineChart }" id="sales-chart" />
                        </div>
                        <div v-else id="spinner-search" class="col-12 text-center py-5">
                            <div class="spinner-border text-primary" role="status" style="width: 2rem; height: 2rem; visibility: visble;">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                            <div>Cargando información...</div>
                        </div>
                    </div>
                </div>
                <div class="card border-0 shadow-sm mb-3" style="min-height: 100px; max-height: 500px; overflow: auto;">
                    <div class="card-body p-3">
                        <div v-if="!loading" class="table-responsive scrollbar">
                            <table class="table table-hover table-bordered mb-0" style="font-size: 0.8em;">
                                <thead class="bg-white text-black">
                                    <tr>
                                        <th class="text-center" style="min-width: 200px; max-width: 200px;"></th>
                                        <th :key="item" v-for="item in data.days" class="text-center" style="min-width: 100px; max-width: 100px;">{{
                            formatDate(item, true) }}</th>
                                        <td class="text-center" style="min-width: 100px; max-width: 100px; font-weight: 500;">
                                            Total</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="text-end" style="font-weight: 500;">Impuestos<i class="fa-solid fa-circle ms-2" style="color: rgba(255, 110, 107, 1);"></i></td>
                                        <td class="text-center" :key="item" v-for="item in data.data">{{
                            formatCurrency(item.taxes, defaultCurrency) }}</td>
                                        <td class="text-center" style="font-weight: 500;">{{
                            formatCurrency(data.total.taxes, defaultCurrency) }}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-end" style="font-weight: 500;">Ventas Clientes<i class="fa-solid fa-circle ms-2" style="color: rgba(28, 206, 159, 1);"></i></td>
                                        <td class="text-center" :key="item" v-for="item in data.data">{{
                            formatCurrency(item.sales.client, defaultCurrency) }}</td>
                                        <td class="text-center" style="font-weight: 500;">{{
                            formatCurrency(data.total.sales.client, defaultCurrency) }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="text-end" style="font-weight: 500;">Ventas Empresas<i class="fa-solid fa-circle ms-2" style="color: rgba(23, 164, 186, 1);"></i></td>
                                        <td class="text-center" :key="item" v-for="item in data.data">{{
                            formatCurrency(item.sales.company, defaultCurrency) }}</td>
                                        <td class="text-center" style="font-weight: 500;">{{
                            formatCurrency(data.total.sales.company, defaultCurrency) }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="text-end" style="font-weight: 500;">Total<i class="fa-solid fa-circle ms-2" style="color: rgba(153, 205, 50, 1);"></i></td>
                                        <td class="text-center" :key="item" v-for="item in data.data" style="font-weight: 500;">{{ formatCurrency(item.total,
                            defaultCurrency) }}</td>
                                        <td class="text-center" style="font-weight: 500;">{{
                            formatCurrency(data.total.total, defaultCurrency) }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div v-else id="spinner-search" class="col-12 text-center py-5">
                            <div class="spinner-border text-primary" role="status" style="width: 2rem; height: 2rem; visibility: visble;">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                            <div>Cargando información...</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal -->
        <div class="modal fade" id="modalReportingCheckin" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Listado de entradas</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <embed v-if="pdf != null" :src="pdf" frameborder="0" width="100%" height="550px">
                        <div v-else id="spinner-search" class="col-12 text-center py-5">
                            <div class="spinner-border text-primary" role="status" style="width: 2rem; height: 2rem; visibility: visble;">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                            <div>Cargando información...</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
#sales-chart {
    display: block;
    box-sizing: unset;
    max-height: 250px;
    min-width: 100%;
    width: auto;
    aspect-ratio: auto !important;
    overflow: auto !important;
    overflow-clip-margin: unset;
    height: auto;
}

.listings-menu-item {
    cursor: pointer;
}

.listings-menu-item:hover {
    background-color: #fff;
    border-radius: 4px;
}

.table-responsive.scrollbar {
    overflow-x: auto;
}

.table-responsive.scrollbar::-webkit-scrollbar {
    -webkit-appearance: none;
}

.table-responsive.scrollbar::-webkit-scrollbar:vertical {
    width: 12px;
}

.table-responsive.scrollbar::-webkit-scrollbar:horizontal {
    height: 12px;
}

.table-responsive.scrollbar::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, .2);
    border-radius: 10px;
    border: 2px solid #ffffff;
}

.table-responsive.scrollbar::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #ffffff;
}
</style>

<script>
import SidebarMenu from "../../components/reporting/SidebarMenu.vue"
import ReportingService from "../../services/reporting.service";



export default {
    name: 'ReportingSales',
    components: {
        SidebarMenu: SidebarMenu
    },
    data() {
        return {
            loading: true,
            pdf: null,
            data: [],
            range: {
                start: new Date(new Date().setDate(new Date().getDate() - 30)),
                end: new Date(),
            },
            masks: {
                input: 'YYYY-MM-DD',
            },
            lineChart: null,
            defaultCurrency: ''
        }
    },
    watch: {
        range: {
            handler: function () {
                this.start();
            },
            deep: true
        }
    },
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },
    },
    methods: {
        async start() {
            this.loading = true
            await this.getInvoicesReportingByDate();

            var taxes = []
            var sales_client = []
            var sales_company = []
            var total = []

            for (var i = 0; i < this.data.data.length; i++) {
                taxes.push(this.data.data[i].taxes)
            }

            for (var i = 0; i < this.data.data.length; i++) {
                sales_client.push(this.data.data[i].sales.client)
            }

            for (var i = 0; i < this.data.data.length; i++) {
                sales_company.push(this.data.data[i].sales.company)
            }

            for (var i = 0; i < this.data.data.length; i++) {
                total.push(this.data.data[i].total)
            }

            this.lineChart = {
                type: 'line',
                data: {
                    labels: this.data.days,
                    datasets: [
                        {
                            label: 'Impuestos',
                            data: taxes,
                            fill: true,
                            borderColor: 'rgba(255, 110, 107, 1)',
                            tension: 0.5,
                            backgroundColor: 'rgba(255, 110, 107, 0.3)',
                        },
                        {
                            label: 'Ventas clientes',
                            data: sales_client,
                            fill: true,
                            borderColor: 'rgba(28, 206, 159, 1)',
                            tension: 0.5,
                            backgroundColor: 'rgba(28, 206, 159, 0.3)',
                        },
                        {
                            label: 'Ventas empresas',
                            data: sales_company,
                            fill: true,
                            borderColor: 'rgba(23, 164, 186, 1)',
                            tension: 0.5,
                            backgroundColor: 'rgba(23, 164, 186, 0.3)',
                        },
                        {
                            label: 'Total',
                            data: total,
                            fill: true,
                            borderColor: 'rgba(153, 205, 50, 1)',
                            tension: 0.5,
                            backgroundColor: 'rgba(153, 205, 50, 0.1)',
                        },
                    ],
                    options: {
                        responsive: true,
                        maintainAspectRatio: false,
                        scales: {
                            yAxes: [{
                                ticks: {
                                    beginAtZero: true
                                }
                            }]
                        }
                    }
                }
            }
            this.loading = false
        },

        async getInvoicesReportingByDate() {
            var response = await ReportingService.getInvoicesReportingByDate({
                startDate: this.formatDate(this.range.start),
                endDate: this.formatDate(this.range.end)
            })
            this.data = response.data;
            console.log(this.data, "data")
        },

        async exportReport() {
            this.pdf = null;
            var response = await ReportingService.getInvoicesReportingByDatePDF({
                startDate: this.formatDate(this.range.start),
                endDate: this.formatDate(this.range.end)
            })
            this.pdf = response.data;
            console.log(this.pdf, "PDF")
        },

        formatDate(date, show) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();



            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            if (show) {
                return [day, month, year].join('/');
            }


            return [year, month, day].join('-');
        },


    },
    created() {
        this.start()
    },
    mounted() {
        if (!this.currentUser) {
            this.$router.push('/login');
        }
    }
};

</script>