export const hotel = {
    namespaced: true,
    state: {
        hotelModules: [],
    },
    mutations: {
        setHotelModules(state, modules) {
            state.hotelModules = modules;
        },
    },
    actions: {
        initializeStore({ dispatch }) {
            const savedModules = localStorage.getItem("hotelModules");
            if (savedModules) {
                dispatch("hotel/updateHotelModules", JSON.parse(savedModules));
            }
        },
        updateHotelModules({ commit }, modules) {
            commit("setHotelModules", modules);
        },
    },
};
