<template>
    <div class="page-app">
        <div class="row pt-2">
            <div class="col-md-4 col-lg-3 col-xl-2 px-3">
                <SidebarMenu />
            </div>
            <div class="col-md-8 col-lg-9 col-xl-10">
                <div class="d-md-flex justify-content-between align-items-center mb-3">
                    <h1>Listado INE Mensual</h1>
                    <div class="d-md-flex">
                        <div class="d-md-flex justify-content-between align-items-center me-2">
                            <select class="form-select" v-model="month" style="border-radius: 4px 0 0 4px">
                                <option value="1">Enero</option>
                                <option value="2">Febrero</option>
                                <option value="3">Marzo</option>
                                <option value="4">Abril</option>
                                <option value="5">Mayo</option>
                                <option value="6">Junio</option>
                                <option value="7">Julio</option>
                                <option value="8">Agosto</option>
                                <option value="9">Septiembre</option>
                                <option value="10">Octubre</option>
                                <option value="11">Noviembre</option>
                                <option value="12">Diciembre</option>
                            </select>
                            <select class="form-select" v-model="year" style="border-radius: 0; border-left: 0">
                                <option value="2022">2022</option>
                                <option value="2023">2023</option>
                                <option value="2024">2024</option>
                            </select>
                            <button class="btn btn-primary" @click="getData()" style="border-radius: 0 4px 4px 0">
                                Generar
                            </button>
                        </div>
                        <div>
                            <button class="btn bg-white text-primary shadow-sm" data-bs-toggle="tooltip" data-bs-placement="top" title="Exportar" @click="download(data.data.xml)" :disabled="loading">
                                <i class="fa-regular fa-arrow-down-to-line me-1"></i>Descargar XML
                            </button>
                        </div>
                    </div>
                </div>
                <div class="card border-0 shadow-sm mb-3" style="min-height: 100px; max-height: 650px; overflow: auto">
                    <div class="card-body p-3">
                        <div v-if="!loading" class="table-responsive scrollbar">
                            <h6 class="mb-3">Orígenes Entradas</h6>
                            <table class="table table-hover table-bordered mb-0" style="font-size: 0.8em">
                                <thead class="bg-white text-black" v-if="data.data.origins.length > 0">
                                    <tr>
                                        <th>{{ month + "/" + year }}</th>
                                        <th :key="item" v-for="item in data.data.origins[0]
                                .days" class="text-center" style="min-width: 120px">
                                            {{ "Día " + item.day }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody v-if="data.data.origins.length > 0">
                                    <tr class="align-middle" v-for="row in data.data.origins">
                                        <td>
                                            {{ row.country_name }}
                                            <span v-if="row.region_name != null">{{ row.region_name
                                                }}</span>
                                        </td>
                                        <td :key="item" v-for="item in row.days" class="text-center">
                                            {{ item.data.entradas }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div v-else id="spinner-search" class="col-12 text-center py-5">
                            <div class="spinner-border text-primary" role="status" style="
									width: 2rem;
									height: 2rem;
									visibility: visble;
								">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                            <div>Cargando información...</div>
                        </div>
                    </div>
                </div>

                <div class="card border-0 shadow-sm mb-3" style="min-height: 100px; max-height: 650px; overflow: auto">
                    <div class="card-body p-3">
                        <div v-if="!loading" class="table-responsive scrollbar">
                            <h6 class="mb-3">Orígenes Salidas</h6>
                            <table class="table table-hover table-bordered mb-0" style="font-size: 0.8em" v-if="data.data.origins.length > 0">
                                <thead class="bg-white text-black">
                                    <tr>
                                        <th>{{ month + "/" + year }}</th>
                                        <th :key="item" v-for="item in data.data.origins[0]
                                .days" class="text-center" style="min-width: 120px">
                                            {{ "Día " + item.day }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="align-middle" v-for="row in data.data.origins">
                                        <td>
                                            {{ row.country_name }}
                                            <span v-if="row.region_name != null">{{ row.region_name
                                                }}</span>
                                        </td>
                                        <td :key="item" v-for="item in row.days" class="text-center">
                                            {{ item.data.salidas }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div v-else id="spinner-search" class="col-12 text-center py-5">
                            <div class="spinner-border text-primary" role="status" style="
									width: 2rem;
									height: 2rem;
									visibility: visble;
								">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                            <div>Cargando información...</div>
                        </div>
                    </div>
                </div>

                <div class="card border-0 shadow-sm mb-3" style="min-height: 100px; max-height: 650px; overflow: auto">
                    <div class="card-body p-3">
                        <div v-if="!loading" class="table-responsive scrollbar">
                            <h6 class="mb-3">Orígenes Pernoctaciones</h6>
                            <table class="table table-hover table-bordered mb-0" style="font-size: 0.8em" v-if="data.data.origins.length > 0">
                                <thead class="bg-white text-black">
                                    <tr>
                                        <th>{{ month + "/" + year }}</th>
                                        <th :key="item" v-for="item in data.data.origins[0]
                                .days" class="text-center" style="min-width: 120px">
                                            {{ "Día " + item.day }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="align-middle" v-for="row in data.data.origins">
                                        <td>
                                            {{ row.country_name }}
                                            <span v-if="row.region_name != null">{{ row.region_name
                                                }}</span>
                                        </td>
                                        <td :key="item" v-for="item in row.days" class="text-center">
                                            {{ item.data.pernoctaciones }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div v-else id="spinner-search" class="col-12 text-center py-5">
                            <div class="spinner-border text-primary" role="status" style="
									width: 2rem;
									height: 2rem;
									visibility: visble;
								">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                            <div>Cargando información...</div>
                        </div>
                    </div>
                </div>

                <div class="card border-0 shadow-sm mb-3" style="min-height: 100px; max-height: 650px; overflow: auto">
                    <div class="card-body p-3">
                        <div v-if="!loading" class="table-responsive scrollbar">
                            <h6 class="mb-3">Ocupación habitaciones</h6>
                            <table class="table table-hover table-bordered mb-0" style="font-size: 0.8em" v-if="data.data.rooms.length > 0">
                                <thead class="bg-white text-black">
                                    <tr>
                                        <th>{{ month + "/" + year }}</th>
                                        <th :key="item" v-for="item in data.data.rooms" class="text-center" style="min-width: 120px">
                                            {{ "Día " + item.day }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="align-middle">
                                        <td>Habitaciones dobles uso doble</td>
                                        <td :key="item" v-for="item in data.data.rooms" class="text-center">
                                            {{
                                item.data
                                    .habitaciones_dobles_uso_doble
                            }}
                                        </td>
                                    </tr>
                                    <tr class="align-middle">
                                        <td class="text-center">
                                            Habitaciones dobles uso individual
                                        </td>
                                        <td :key="item" v-for="item in data.data.rooms" class="text-center">
                                            {{
                                item.data
                                    .habitaciones_dobles_uso_individual
                            }}
                                        </td>
                                    </tr>
                                    <tr class="align-middle">
                                        <td class="text-center">
                                            Habitaciones otras
                                        </td>
                                        <td :key="item" v-for="item in data.data.rooms" class="text-center">
                                            {{ item.data.habitaciones_otras }}
                                        </td>
                                    </tr>
                                    <tr class="align-middle">
                                        <td class="text-center">
                                            Plazas supletorias
                                        </td>
                                        <td :key="item" v-for="item in data.data.rooms" class="text-center">
                                            {{ item.data.plazas_supletorias }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div v-else id="spinner-search" class="col-12 text-center py-5">
                            <div class="spinner-border text-primary" role="status" style="
									width: 2rem;
									height: 2rem;
									visibility: visble;
								">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                            <div>Cargando información...</div>
                        </div>
                    </div>
                </div>
                <div class="card border-0 shadow-sm mb-3" style="min-height: 100px; max-height: 650px; overflow: auto">
                    <div class="card-body p-3">
                        <div v-if="!loading" class="table-responsive scrollbar">
                            <h6 class="mb-3">Precios</h6>
                            <div>
                                ADR mensual: {{ data.data.prices.adr_mensual }}
                            </div>
                            <div class="mb-3">
                                Revpar mensual:
                                {{ data.data.prices.revpar_mensual }}
                            </div>
                            <table class="table table-hover table-bordered mb-0" style="font-size: 0.8em">
                                <thead class="bg-white text-black">
                                    <tr>
                                        <th>{{ month + "/" + year }}</th>
                                        <th class="text-center" style="min-width: 120px">
                                            ADR
                                        </th>
                                        <th class="text-center" style="min-width: 120px">
                                            PCTN
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="align-middle">
                                        <td>Touroperador tradicional</td>
                                        <td class="text-center">
                                            {{
                                data.data.prices
                                    .adr_touroperador_tradicional
                            }}
                                        </td>
                                        <td class="text-center">
                                            {{
                                    data.data.prices
                                        .pctn_touroperador_tradicional +
                                    "%"
                                }}
                                        </td>
                                    </tr>
                                    <tr class="align-middle">
                                        <td>Touroperador online</td>
                                        <td class="text-center">
                                            {{
                                    data.data.prices
                                        .adr_touroperador_online
                                }}
                                        </td>
                                        <td class="text-center">
                                            {{
                                    data.data.prices
                                        .pctn_touroperador_online +
                                    "%"
                                }}
                                        </td>
                                    </tr>
                                    <tr class="align-middle">
                                        <td>Empresas</td>
                                        <td class="text-center">
                                            {{ data.data.prices.adr_empresas }}
                                        </td>
                                        <td class="text-center">
                                            {{
                                data.data.prices.pctn_empresas +
                                "%"
                            }}
                                        </td>
                                    </tr>
                                    <tr class="align-middle">
                                        <td>Agencia tradicional</td>
                                        <td class="text-center">
                                            {{
                                    data.data.prices
                                        .adr_agencia_tradicional
                                }}
                                        </td>
                                        <td class="text-center">
                                            {{
                                    data.data.prices
                                        .pctn_agencia_tradicional +
                                    "%"
                                }}
                                        </td>
                                    </tr>
                                    <tr class="align-middle">
                                        <td>Agencia online</td>
                                        <td class="text-center">
                                            {{
                                    data.data.prices
                                        .adr_agencia_online
                                }}
                                        </td>
                                        <td class="text-center">
                                            {{
                                    data.data.prices
                                        .pctn_agencia_online + "%"
                                }}
                                        </td>
                                    </tr>
                                    <tr class="align-middle">
                                        <td>Particulares</td>
                                        <td class="text-center">
                                            {{
                                    data.data.prices
                                        .adr_particulares
                                }}
                                        </td>
                                        <td class="text-center">
                                            {{
                                    data.data.prices
                                        .pctn_particulares + "%"
                                }}
                                        </td>
                                    </tr>
                                    <tr class="align-middle">
                                        <td>Grupos</td>
                                        <td class="text-center">
                                            {{ data.data.prices.adr_grupos }}
                                        </td>
                                        <td class="text-center">
                                            {{
                                data.data.prices.pctn_grupos +
                                "%"
                            }}
                                        </td>
                                    </tr>
                                    <tr class="align-middle">
                                        <td>Internet</td>
                                        <td class="text-center">
                                            {{ data.data.prices.adr_internet }}
                                        </td>
                                        <td class="text-center">
                                            {{
                                data.data.prices.pctn_internet +
                                "%"
                            }}
                                        </td>
                                    </tr>
                                    <tr class="align-middle">
                                        <td>Otros</td>
                                        <td class="text-center">
                                            {{ data.data.prices.adr_otros }}
                                        </td>
                                        <td class="text-center">
                                            {{
                                data.data.prices.pctn_otros +
                                "%"
                            }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div v-else id="spinner-search" class="col-12 text-center py-5">
                            <div class="spinner-border text-primary" role="status" style="
									width: 2rem;
									height: 2rem;
									visibility: visble;
								">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                            <div>Cargando información...</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal -->
        <div class="modal fade" id="modalReportingCheckin" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Listado de cobros</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <embed v-if="pdf != null" :src="pdf" frameborder="0" width="100%" height="550px" />
                        <div v-else id="spinner-search" class="col-12 text-center py-5">
                            <div class="spinner-border text-primary" role="status" style="
									width: 2rem;
									height: 2rem;
									visibility: visble;
								">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                            <div>Cargando información...</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
#sales-chart {
    display: block;
    box-sizing: unset;
    max-height: 250px;
    min-width: 100%;
    width: auto;
    aspect-ratio: auto !important;
    overflow: auto !important;
    overflow-clip-margin: unset;
    height: auto;
}

.listings-menu-item {
    cursor: pointer;
}

.listings-menu-item:hover {
    background-color: #fff;
    border-radius: 4px;
}

.table-responsive.scrollbar {
    overflow-x: auto;
}

.table-responsive.scrollbar::-webkit-scrollbar {
    -webkit-appearance: none;
}

.table-responsive.scrollbar::-webkit-scrollbar:vertical {
    width: 12px;
}

.table-responsive.scrollbar::-webkit-scrollbar:horizontal {
    height: 12px;
}

.table-responsive.scrollbar::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    border: 2px solid #ffffff;
}

.table-responsive.scrollbar::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #ffffff;
}
</style>

<script>
import SidebarMenu from "../../components/reporting/SidebarMenu.vue";
import ReportingService from "../../services/reporting.service";

export default {
    name: "ReportingPayments",
    components: {
        SidebarMenu: SidebarMenu,
    },
    data() {
        return {
            loading: true,
            pdf: null,
            data: [],
            month: new Date().getMonth(),
            year: new Date().getFullYear(),
            defaultCurrency: ''
        };
    },
    watch: {
        range: {
            handler: function () {
                this.start();
            },
            deep: true,
        },
    },
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },
    },
    methods: {
        async start() {
            this.loading = true;
            await this.getData();

            this.loading = false;
        },

        async getData() {
            var response = await ReportingService.createINE({
                month: this.month,
                year: this.year,
            });
            this.data = response.data;
            console.log(this.data, "data");
        },

        download(data) {
            // data = data.replace(/\s+/g, "");
            var filename = "INE_" + this.month + "_" + this.year + ".xml";
            var pom = document.createElement("a");
            var bb = new Blob([data], { type: "text/plain" });

            pom.setAttribute("href", window.URL.createObjectURL(bb));
            pom.setAttribute("download", filename);

            pom.dataset.downloadurl = [
                "text/plain",
                pom.download,
                pom.href,
            ].join(":");
            pom.draggable = true;
            pom.classList.add("dragout");

            pom.click();
        },

        async exportReport() {
            this.pdf = null;
            var response = await ReportingService.getPaymentsReportingByDatePDF(
                {
                    startDate: this.formatDate(this.range.start),
                    endDate: this.formatDate(this.range.end),
                }
            );
            this.pdf = response.data;
            console.log(this.pdf, "PDF");
        },

        formatDate(date, show) {
            var d = new Date(date),
                month = "" + (d.getMonth() + 1),
                day = "" + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2) month = "0" + month;
            if (day.length < 2) day = "0" + day;

            if (show) {
                return [day, month, year].join("/");
            }

            return [year, month, day].join("-");
        },


    },
    created() {
        this.start();
    },
    mounted() {
        if (!this.currentUser) {
            this.$router.push("/login");
        }
    },
};
</script>