import api from './api';
import HotelService from "./hotel.service";

class PaymentMethodService { 


    /**************************************
     *  API HOSPEDIUM
     **************************************/
    async addPaymentMethod(params) {
        return await api.post('/paymentmethod', params)
        .then((response) => {
            return {status: response.status, data: response.data};
        })
        .catch(function (error) {
            return {status: error.response.status, data: error.response.data};
        });
    }

    async updatePaymentMethod(id, params) {
        return await api.put('/paymentmethod/'+id, params)
        .then((response) => {
            return {status: response.status, data: response.data};
        })
        .catch(function (error) {
            return {status: error.response.status, data: error.response.data};
        });
    }

    async getAllPaymentMethod() {
        let hotel = HotelService.getLocalHotel();
        return await api.get('/paymentmethod?hotel=' + hotel.id)
        .then((response) => {
            return {status: response.status, data: response.data};
        })
        .catch(function (error) {
            return {status: error.response.status, data: error.response.data};
        });
    }

    async getPaymentMethod(id) {
        return await api.get('/paymentmethod/' + id)
        .then((response) => {
            return {status: response.status, data: response.data};
        })
        .catch(function (error) {
            return {status: error.response.status, data: error.response.data};
        });
    }

    async deletePaymentMethod(id) {
        return await api.delete('/paymentmethod/' + id)
        .then((response) => {
            return {status: response.status, data: response.data};
        })
        .catch(function (error) {
            return {status: error.response.status, data: error.response.data};
        });
    }
}

export default new PaymentMethodService();