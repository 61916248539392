import api from "./api";
import HotelService from "./hotel.service";

class ReportingService {
	async getInvoicesReportingByDate(params) {
		let hotel = HotelService.getLocalHotel();
		params.hotel = hotel.id;
		return api
			.get("invoices/reporting", { params: params })
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async getInvoicesReportingByDatePDF(params) {
		let hotel = HotelService.getLocalHotel();
		params.hotel = hotel.id;
		return api
			.get("invoices/reporting/export/pdf", {
				params: params,
				responseType: "blob",
			})
			.then((response) => {
				var date = new Date();
				var filename = "ventas_" + date.getFullYear() + date.getMonth() + date.getDate() + "_" + date.getHours() + date.getMinutes() + date.getSeconds();
				const url = window.URL.createObjectURL(new Blob([response.data], { type: "application/pdf" }));
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", filename + ".pdf"); //or any other extension
				document.body.appendChild(link);
				// link.click(function(){
				//     window.$("#dialog").dialog();
				// });
				return { status: response.status, data: link.href };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async getInvoicesByPaymentMethodReportingByDate(params) {
		let hotel = HotelService.getLocalHotel();
		params.hotel = hotel.id;
		return api
			.get("invoices/payment_method/reporting", { params: params })
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async getInvoicesByPaymentMethodReportingByDatePDF(params) {
		let hotel = HotelService.getLocalHotel();
		params.hotel = hotel.id;
		return api
			.get("invoices/payment_method/reporting/export/pdf", {
				params: params,
				responseType: "blob",
			})
			.then((response) => {
				var date = new Date();
				var filename = "ventas_tipo_de_pago_" + date.getFullYear() + date.getMonth() + date.getDate() + "_" + date.getHours() + date.getMinutes() + date.getSeconds();
				const url = window.URL.createObjectURL(new Blob([response.data], { type: "application/pdf" }));
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", filename + ".pdf"); //or any other extension
				document.body.appendChild(link);
				// link.click(function(){
				//     window.$("#dialog").dialog();
				// });
				return { status: response.status, data: link.href };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async getPaymemtsReportingByDate(params) {
		let hotel = HotelService.getLocalHotel();
		params.hotel = hotel.id;
		return api
			.get("payments/reporting", { params: params })
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async getPaymentsReportingByDatePDF(params) {
		let hotel = HotelService.getLocalHotel();
		params.hotel = hotel.id;
		return api
			.get("payments/reporting/export/pdf", {
				params: params,
				responseType: "blob",
			})
			.then((response) => {
				var date = new Date();
				var filename = "cobros_" + date.getFullYear() + date.getMonth() + date.getDate() + "_" + date.getHours() + date.getMinutes() + date.getSeconds();
				const url = window.URL.createObjectURL(new Blob([response.data], { type: "application/pdf" }));
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", filename + ".pdf"); //or any other extension
				document.body.appendChild(link);
				// link.click(function(){
				//     window.$("#dialog").dialog();
				// });
				return { status: response.status, data: link.href };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async getPaymemtLinksReportingByDate(params) {
		let hotel = HotelService.getLocalHotel();
		params.hotel = hotel.id;
		return api
			.get("payments/links/reporting", { params: params })
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async getProductionByChannelReportingByDate(params) {
		let hotel = HotelService.getLocalHotel();
		params.hotel = hotel.id;
		return api
			.get("bookings/production_by_channel/reporting", { params: params })
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async createINE(data) {
		let hotel = HotelService.getLocalHotel();
		var params = { hotel: hotel.id };
		return api
			.post("reporting/ine/month", data, { params: params })
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async getParkingReporting(params) {
		let hotel = HotelService.getLocalHotel();
		params.hotel = hotel.id;
		return api
			.get("/bookings/reporting/parking", { params: params })
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async getParkingReportingByDatePDF(params) {
		let hotel = HotelService.getLocalHotel();
		params.hotel = hotel.id;
		return api
			.get("/bookings/reporting/parking/pdf", {
				params: params,
				responseType: "blob",
			})
			.then((response) => {
				var date = new Date();
				var filename = "parking_" + date.getFullYear() + date.getMonth() + date.getDate() + "_" + date.getHours() + date.getMinutes() + date.getSeconds();
				const url = window.URL.createObjectURL(new Blob([response.data], { type: "application/pdf" }));
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", filename + ".pdf"); //or any other extension
				document.body.appendChild(link);
				// link.click(function(){
				//     window.$("#dialog").dialog();
				// });
				return { status: response.status, data: link.href };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async getProductionReporting(params) {
		let hotel = HotelService.getLocalHotel();
		params.hotel = hotel.id;
		return api
			.get("/bookings/reporting/production", { params: params })
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async getProductionReportingByDatePDF(params) {
		let hotel = HotelService.getLocalHotel();
		params.hotel = hotel.id;
		return api
			.get("/bookings/reporting/production/pdf", {
				params: params,
				responseType: "blob",
			})
			.then((response) => {
				var date = new Date();
				var filename = "production_" + date.getFullYear() + date.getMonth() + date.getDate() + "_" + date.getHours() + date.getMinutes() + date.getSeconds();
				const url = window.URL.createObjectURL(new Blob([response.data], { type: "application/pdf" }));
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", filename + ".pdf"); //or any other extension
				document.body.appendChild(link);
				// link.click(function(){
				//     window.$("#dialog").dialog();
				// });
				return { status: response.status, data: link.href };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async getProductionTagsReporting(data) {
		let hotel = HotelService.getLocalHotel();
		data.hotel = hotel.id;
		return api
			.post("/bookings/reporting/production/tags/", data)
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async getProductionTagsReportingByDatePDF(data) {
		let hotel = HotelService.getLocalHotel();
		data.hotel = hotel.id;
		return api
			.post("/bookings/reporting/production/tags/pdf/", data, {
				responseType: "blob",
			})
			.then((response) => {
				var date = new Date();
				var filename = "production_tags_" + date.getFullYear() + date.getMonth() + date.getDate() + "_" + date.getHours() + date.getMinutes() + date.getSeconds();
				const url = window.URL.createObjectURL(new Blob([response.data], { type: "application/pdf" }));
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", filename + ".pdf"); //or any other extension
				document.body.appendChild(link);
				// link.click(function(){
				//     window.$("#dialog").dialog();
				// });
				return { status: response.status, data: link.href };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async getHousekeepingPDF(data) {
		let hotel = HotelService.getLocalHotel();
		data.hotel = hotel.id;
		return api
			.post("/rooms/reporting/housekeeping/pdf/", data, {
				responseType: "blob",
			})
			.then((response) => {
				var date = new Date();
				var filename = "production_tags_" + date.getFullYear() + date.getMonth() + date.getDate() + "_" + date.getHours() + date.getMinutes() + date.getSeconds();
				const url = window.URL.createObjectURL(new Blob([response.data], { type: "application/pdf" }));
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", filename + ".pdf"); //or any other extension
				document.body.appendChild(link);
				// link.click(function(){
				//     window.$("#dialog").dialog();
				// });
				return { status: response.status, data: link.href };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async getStaysReporting(data) {
		let hotel = HotelService.getLocalHotel();
		data.hotel = hotel.id;
		return api
			.post("/bookings/reporting/stays/", data)
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async getStaysReportingByDatePDF(data) {
		let hotel = HotelService.getLocalHotel();
		data.hotel = hotel.id;
		return api
			.post("/bookings/reporting/stays/pdf/", data, {
				responseType: "blob",
			})
			.then((response) => {
				var date = new Date();
				var filename = "stays_" + date.getFullYear() + date.getMonth() + date.getDate() + "_" + date.getHours() + date.getMinutes() + date.getSeconds();
				const url = window.URL.createObjectURL(new Blob([response.data], { type: "application/pdf" }));
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", filename + ".pdf"); //or any other extension
				document.body.appendChild(link);
				// link.click(function(){
				//     window.$("#dialog").dialog();
				// });
				return { status: response.status, data: link.href };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async getForecastOccupancyReporting(data) {
		let hotel = HotelService.getLocalHotel();
		data.hotel = hotel.id;
		return api
			.post("/bookings/reporting/occupancy/", data)
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async getForecastOccupancyReportingPDF(data) {
		let hotel = HotelService.getLocalHotel();
		data.hotel = hotel.id;
		return api
			.post("/rooms/reporting/occupancy/pdf/", data, {
				responseType: "blob",
			})
			.then((response) => {
				var date = new Date();
				var filename = "reporting_occupancy_" + date.getFullYear() + date.getMonth() + date.getDate() + "_" + date.getHours() + date.getMinutes() + date.getSeconds();
				const url = window.URL.createObjectURL(new Blob([response.data], { type: "application/pdf" }));
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", filename + ".pdf"); //or any other extension
				document.body.appendChild(link);
				// link.click(function(){
				//     window.$("#dialog").dialog();
				// });
				return { status: response.status, data: link.href };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}
}

export default new ReportingService();
