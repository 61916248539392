<template>
	<div id="login-page" class="d-flex h-100 flex-column">
		<div class="container px-4 py-5 mx-auto">
			<div class="card border-0 shadow-sm">
				<div class="d-flex flex-md-row flex-column-reverse">
					<div class="card card1 border-0">
						<div class="row justify-content-center my-auto">
							<div class="col-md-8 col-10 my-5">
								<div
									class="row justify-content-center px-3 mb-3 text-center"
								>
									<a
										href="https://hospedium.com"
										target="_blank"
										><img
											src="@/assets/img/favicon-circle.png"
											class="img-login-logo"
									/></a>
								</div>
								<h3 class="mb-5 text-center heading">
									Hospedium <span>PMS</span>
								</h3>

								<div v-if="step == 1">
									<h4>¿Has olvidado tu contraseña?</h4>
									<p>
										Introduce tu email para resetear la
										contraseña.
									</p>

									<Form
										@submit="handleResetPassword"
										:validation-schema="schema1"
									>
										<div class="form-group">
											<Field
												name="email"
												type="email"
												class="form-control form-control-lg form-control-solid"
											/>
											<ErrorMessage
												name="email"
												class="error-feedback text-danger"
											/>
										</div>

										<div
											class="row justify-content-center mb-3 px-3 mt-0"
										>
											<button
												class="btn btn-block btn-color"
												:disabled="loading"
											>
												<span
													v-show="loading"
													class="spinner-border spinner-border-sm me-2"
												></span>
												<span>Enviar</span>
											</button>
										</div>
									</Form>
								</div>

								<div v-else-if="step == 2">
									<h4>Introduce el código</h4>
									<p>
										Comprueba la bandeja de entrada de
										correo electrónico e introduce el código
										que te hemos enviado. Si no encuentras
										el email revisa la carpeta de SPAM.
									</p>

									<Form
										@submit="handleValidateToken"
										:validation-schema="schema2"
									>
										<div class="form-group">
											<Field
												name="token"
												type="text"
												class="form-control form-control-lg form-control-solid"
											/>
											<ErrorMessage
												name="token"
												class="error-feedback text-danger"
											/>
										</div>

										<div
											class="row justify-content-center mb-3 px-3 mt-0"
										>
											<button
												class="btn btn-block btn-color"
												:disabled="loading"
											>
												<span
													v-show="loading"
													class="spinner-border spinner-border-sm me-2"
												></span>
												<span>Continuar</span>
											</button>
										</div>
									</Form>
								</div>

								<div v-else-if="step == 3">
									<h4>Introduce una nueva contraseña</h4>

									<Form
										@submit="handleConfirmPassword"
										:validation-schema="schema3"
									>
										<div class="form-group">
											<label
												class="form-control-label text-muted"
												>Contraseña nueva</label
											>
											<Field
												name="password"
												type="password"
												class="form-control form-control-lg form-control-solid"
												autocomplete
											/>
											<div class="mt-2">
												<ErrorMessage
													name="password"
													class="error-feedback text-danger mt-2"
												/>
											</div>
										</div>
										<div class="form-group">
											<label
												class="form-control-label text-muted"
												>Repetir contraseña</label
											>
											<Field
												name="password2"
												type="password"
												class="form-control form-control-lg form-control-solid"
												autocomplete
											/>
											<div class="mt-2">
												<ErrorMessage
													name="password2"
													class="error-feedback text-danger mt-2"
												/>
											</div>
										</div>

										<div
											class="row justify-content-center mb-3 px-3 mt-0"
										>
											<button
												class="btn btn-block btn-color"
												:disabled="loading"
											>
												<span
													v-show="loading"
													class="spinner-border spinner-border-sm me-2"
												></span>
												<span>Enviar</span>
											</button>
										</div>
									</Form>
								</div>

								<div v-else-if="step == 4">
									<h4>
										¡Se ha cambiado la contraseña
										correctamente!
									</h4>

									<router-link
										class="btn btn-block btn-color"
										to="/login"
									>
										<span>Ir a Iniciar Sesión</span>
									</router-link>
								</div>

								<div
									class="row justify-content-center my-2"
									v-if="step != 4"
								>
									<router-link to="/login"
										><small class="text-muted"
											>Volver a iniciar sesión</small
										></router-link
									>
								</div>
								<div class="form-group mt-3">
									<div
										v-if="message"
										class="alert alert-danger border-0 shadow-sm"
										role="alert"
									>
										{{ message }}
									</div>
								</div>
							</div>
						</div>
						<div class="bottom text-center mb-5">
							<div class="copyright">
								<small
									>©{{ currentYear }}
									<a
										href="https://hospedium.com"
										target="_blank"
										>HOSPEDIUM HOTEL GROUP</a
									>.<br />Powered by
									<a href="https://wejyc.com" target="_blank"
										>WEJYC</a
									>.</small
								>
							</div>
						</div>
					</div>
					<div class="card card2 border-0">
						<div class="my-auto mx-md-5 px-md-5 right">
							<h3 class="text-white mb-5">
								Cadena de
								<strong>hoteles independiente</strong>
							</h3>
							<div class="text-white mb-3">¿NECESITAS AYUDA?</div>
							<div class="text-white mb-3">
								<i class="fa-regular fa-phone me-3"></i>+34 912
								90 69 05
							</div>
							<div class="text-white mb-3">
								<i class="fa-regular fa-envelope me-3"></i
								>info@hospedium.com
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style>
#login-page {
	justify-content: center;
}
#login-page .error-feedback {
	font-size: 14px;
}
#login-page .img-login-logo {
	width: 100%;
	max-width: 50px;
	margin: 0 auto;
}
#login-page .copyright {
	font-size: 12px;
	text-align: center;
}
#login-page .copyright a {
	color: #000;
	text-decoration: none;
}
@media screen and (max-width: 768px) {
	#login-page {
		justify-content: normal;
	}
	#login-page .copyright {
		position: relative;
		padding: 50px 10px !important;
	}
}

#login-page input #login-page {
	background-color: #f3e5f5;
	border-radius: 50px !important;
	padding: 12px 15px 12px 15px !important;
	width: 100%;
	box-sizing: border-box;
	border: none !important;
	border: 1px solid #f3e5f5 !important;
	font-size: 16px !important;
	color: #000 !important;
	font-weight: 400;
}

#login-page input:focus {
	-moz-box-shadow: none !important;
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
	border: 1px solid #0091ff !important;
	outline-width: 0;
	font-weight: 400;
}

#login-page button:focus {
	-moz-box-shadow: none !important;
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
	outline-width: 0;
}

#login-page .card {
	border: none;
}

#login-page .card1.card {
	width: 50%;
	padding: 40px 30px 10px 30px;
	border-radius: 0;
}

#login-page .card2.card {
	width: 50%;
	background-image: linear-gradient(to right, #0091ff, #275cb2);
	border-radius: 0;
}

#login-page .heading {
	margin-bottom: 60px !important;
}

#login-page ::placeholder {
	color: #000 !important;
	opacity: 1;
}

#login-page :-ms-input-placeholder {
	color: #000 !important;
}

#login-page ::-ms-input-placeholder {
	color: #000 !important;
}

#login-page .form-control-label {
	font-size: 14px;
	margin-left: 15px;
}

#login-page .msg-info {
	padding-left: 16px;
	margin-bottom: 30px;
}

#login-page .btn-color {
	color: #fff;
	background-image: linear-gradient(to right, #0091ff, #275cb2);
	padding: 15px;
	cursor: pointer;
	border: none !important;
	margin-top: 40px;
}

#login-page .btn-color:hover {
	color: #fff;
	background-image: linear-gradient(to right, #275cb2, #0091ff);
}

#login-page .bottom {
	width: 100%;
	margin-top: 50px !important;
}

#login-page .sm-text {
	font-size: 15px;
}

@media screen and (max-width: 992px) {
	#login-page .card1.card {
		width: 100%;
		padding: 40px 30px 10px 30px;
	}

	#login-page .card2.card {
		width: 100%;
	}

	#login-page .right {
		margin-top: 100px !important;
		margin-bottom: 100px !important;
	}
}

@media screen and (max-width: 768px) {
	#login-page .container {
		padding: 10px !important;
	}

	#login-page .card2.card {
		padding: 50px;
	}

	#login-page .right {
		margin-top: 50px !important;
		margin-bottom: 50px !important;
	}
}
</style>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";

import AuthService from "../../services/auth.service";

export default {
	name: "ResetPasswordView",
	components: {
		Form,
		Field,
		ErrorMessage,
	},
	data() {
		const schema1 = yup.object().shape({
			email: yup.string().required("El campo email es obligatorio."),
		});

		const schema2 = yup.object().shape({
			token: yup.string().required("Debes introducir un código."),
		});

		const schema3 = yup.object().shape({
			password: yup.string().required("Debes introducir una contraseña."),
			password2: yup.string().required("Debes confirmar tu contraseña."),
		});

		return {
			loading: false,
			currentYear: new Date().getFullYear(),
			message: "",
			schema1,
			schema2,
			schema3,
			step: 1,
			token: null,
		};
	},
	computed: {
		loggedIn() {
			return this.$store.state.auth.status.loggedIn;
		},
	},
	created() {
		if (this.loggedIn) {
			this.$router.push("/");
		}
	},
	methods: {
		async handleResetPassword(user) {
			this.message = "";
			this.loading = true;
			// console.log("Email: " + user.email);
			var response = await AuthService.resetPassword({
				email: user.email,
			});

			if (response.status == 200) {
				this.step = 2;
			} else {
				this.message = "El email introducido no es válido";
			}

			this.loading = false;
		},

		async handleValidateToken(user) {
			this.message = "";
			this.loading = true;
			// console.log("Email: " + user.email);
			var response = await AuthService.resetPasswordValidateToken({
				token: user.token,
			});

			if (response.status == 200) {
				this.token = user.token;
				this.step = 3;
			} else {
				this.message = "El código introducido no es válido";
			}

			this.loading = false;
		},

		async handleConfirmPassword(user) {
			this.message = "";
			this.loading = true;

			if (user.password != user.password2) {
				this.message = "Las contraseñas no coinciden.";
				this.loading = false;
				return;
			}
			// console.log("Email: " + user.email);

			var response = await AuthService.resetPasswordConfirm({
				token: this.token,
				password: user.password,
			});

			if (response.status == 200) {
				this.token = response.data.token;
				this.step = 4;
			} else {
				this.message = JSON.stringify(response.data);
			}

			this.loading = false;
		},
	},
};
</script>

<style scoped>
</style>