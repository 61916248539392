<template>
    <div v-if="!loading">
        <button class="btn btn-primary mt-3 text-uppercase" data-bs-toggle="modal" data-bs-target="#modalAddGuest">
            <i class="fa-regular fa-plus me-1"></i>Añadir huésped
        </button>
        <!-- Modal Add Huesped-->
        <div class="modal fade" id="modalAddGuest" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl modal-dialog-scrollable">
                <div class="modal-content border-0">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">
                            Añadir Huésped
                        </h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body p-4">
                        <div id="search_client" class="mb-3">
                            <div class="input-group mb-3">
                                <span class="input-group-text border-0"><i class="fa-regular fa-magnifying-glass"></i></span>
                                <input type="text" class="form-control" placeholder="Buscar por nombre, apellidos, email..." v-model="search" @keyup="handleSearch($this)" />
                            </div>

                            <div class="col-12">
                                <ul v-if="suggestedClients != ''" class="guest-suggested-clients" v-click-outside="onClickOutside">
                                    <li :key="c" v-for="c in suggestedClients" @click.stop="selectClient(c)">
                                        <div class="d-flex align-items-center">
                                            <div v-if="c.id == client.id" class="client-icon clientSelected">
                                                <i class="fa-solid fa-user-check"></i>
                                            </div>

                                            <div v-else class="client-icon">
                                                <i class="fa-regular fa-user-plus"></i>
                                            </div>

                                            <div>
                                                <div class="title">
                                                    {{
        c.firstName +
        " " +
        c.lastName1 +
        " " +
        c.lastName2
    }}
                                                    <span v-if="c.nif" class="badge badge-light ms-3">{{
        c.nif }}</span>
                                                </div>
                                                <small style="
														color: #99a1b7;
														font-weight: 300;
													" class="text-lowercase">{{ c.email }}</small>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>

                            <div v-if="client.id > 0" class="p-3 badge badge-light-primary" style="font-weight: 500">
                                Se ha seleccionado el cliente:
                                <span class="text-uppercase ms-1">{{
        client.firstName +
        " " +
        client.lastName1 +
        " " +
        client.lastName2
    }}</span>.
                                <span class="btn btn-link text-primary ms-1" style="text-decoration: underline" @click.stop="reset('client')">Resetear campos</span>
                            </div>
                        </div>

                        <div class="row mb-5">
                            <div class="col-md-6 mb-4">
                                <div class="mb-3 fs-4" style="font-weight: 500">
                                    Información personal
                                </div>
                                <div class="mb-2">
                                    <MazInput id="firstName" v-model="client.firstName" label="Nombre" autocomplete="none" :error="errors.client.firstName" required>
                                        <template #left-icon>
                                            <i class="fa-regular fa-user-pen"></i>
                                        </template>
                                    </MazInput>
                                </div>
                                <div class="mb-2">
                                    <MazInput id="lastName1" v-model="client.lastName1" label="Primer apellido" autocomplete="none" :error="errors.client.lastName1">
                                        <template #left-icon>
                                            <i class="fa-regular fa-user-pen"></i>
                                        </template>
                                    </MazInput>
                                </div>
                                <div class="mb-2">
                                    <MazInput id="lastName2" v-model="client.lastName2" label="Segundo apellido" autocomplete="none" :error="errors.client.lastName2" required>
                                        <template #left-icon>
                                            <i class="fa-regular fa-user-pen"></i>
                                        </template>
                                    </MazInput>
                                </div>
                                <div class="mb-2">
                                    <MazInput id="email" v-model="client.email" label="Email" autocomplete="none" type="email" :error="errors.client.email">
                                        <template #left-icon>
                                            <i class="fa-regular fa-envelope"></i>
                                        </template>
                                    </MazInput>
                                </div>
                                <div class="mb-2">
                                    <MazPhoneNumberInput v-model="client.phone" show-code-on-list :translations="{
        countrySelector: {
            placeholder: 'Código país',
            error: 'Elige país',
        },
        phoneInput: {
            placeholder: 'Nº teléfono',
            example: 'Ej:',
        },
    }" :no-example="true" :error="errors.client.phone">
                                    </MazPhoneNumberInput>
                                </div>

                                <div class="mb-2">
                                    <MazSelect id="sex" v-model="client.sex" label="Sexo" autocomplete="none" :error="errors.client.sex" :options="sexOptions" required>
                                    </MazSelect>
                                </div>

                                <div class="mb-2">
                                    <MazInput id="dateofbirth" v-model="client.dateofbirth" label="Fecha de
                                                nacimiento" autocomplete="none" type="date" :error="errors.client.dateofbirth" required>
                                    </MazInput>
                                </div>

                                <div class="mb-2">
                                    <MazSelect id="nationality" v-model="client.nationality" label="Nacionalidad" :options="countries" option-value-key="id" option-label-key="name"
                                        option-input-value-key="name" search search-placeholder="Buscar..." required :error="errors.client.nationality">
                                        <template #left-icon>
                                            <MazIcon name="flag" />
                                        </template>
                                    </MazSelect>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="mb-3 fs-4" style="font-weight: 500">
                                    Documentos
                                </div>
                                <div class="mb-2">
                                    <MazSelect id="document_type" v-model="client.document_type" @update:model-value="updateDocumentSelected" label="Tipo de documento" autocomplete="none"
                                        :error="errors.client.document_type" :options="documentOptions" required>
                                    </MazSelect>
                                </div>
                                <div class="mb-2">
                                    <MazInput id="nif" v-model="client.nif" label="Nº documento" autocomplete="none" :error="errors.client.nif" required>
                                        <template #left-icon>
                                            <i class="fa-regular fa-passport"></i>
                                        </template>
                                    </MazInput>
                                    <small class="text-muted">* Introduce el número del documento.
                                        Verifica que está bien escrito.</small>
                                </div>
                                <div class="mb-2" id="document_expeditionDate_container">
                                    <MazInput id="document_expeditionDate" v-model="client.document_expeditionDate" label="Fecha Expedición" autocomplete="none" type="date" :error="errors.client
        .document_expeditionDate
        " required>
                                    </MazInput>
                                </div>
                                <div class="mb-2" id="document_experationDate_container">
                                    <MazInput id="document_experationDate" v-model="client.document_experationDate" label="Fecha Expiración" autocomplete="none" type="date" :error="errors.client
        .document_experationDate
        " required>
                                    </MazInput>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="mb-3 fs-4" style="font-weight: 500">
                                    Dirección
                                </div>
                                <div class="mb-2">
                                    <MazInput id="address" v-model="client.address" label="Dirección" autocomplete="none" :error="errors.client.address">
                                        <template #left-icon>
                                            <i class="fa-regular fa-location-dot"></i>
                                        </template>
                                    </MazInput>
                                </div>

                                <div class="mb-2" v-if="client.country == 66">
                                    <MazSelect id="region" v-model="client.region" label="Provincia" :options="regions" option-value-key="id" option-label-key="name" option-input-value-key="name"
                                        search search-placeholder="Buscar..." required :error="errors.client.region">
                                    </MazSelect>
                                </div>

                                <div class="row mb-2">
                                    <div class="col-8">
                                        <MazInput id="locality" v-model="client.locality" label="Ciudad" autocomplete="none" :error="errors.client.locality">
                                        </MazInput>
                                    </div>
                                    <div class="col-4">
                                        <MazInput id="postal_code" v-model="client.postal_code" label="Código Postal" autocomplete="none" :error="errors.client.postal_code">
                                        </MazInput>
                                    </div>
                                </div>
                                <div class="mb-2">
                                    <MazSelect id="country" v-model="client.country" label="Seleccionar país" :options="countries" option-value-key="id" option-label-key="name"
                                        option-input-value-key="name" search search-placeholder="Buscar..." required :error="errors.client.country">
                                    </MazSelect>
                                </div>

                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" v-model="checkedAddress" id="flexCheckChecked" />
                                    <label class="form-check-label" for="flexCheckChecked">
                                        Mantener esta dirección como dirección
                                        de facturación.
                                    </label>
                                </div>
                            </div>
                            <div v-if="!checkedAddress" class="col-md-6">
                                <div class="mb-3 fs-4" style="font-weight: 500">
                                    Dirección de facturación
                                </div>

                                <div class="mb-2">
                                    <MazInput id="tax_address" v-model="client.tax_address" label="Dirección" autocomplete="none" :error="errors.client.tax_address">
                                        <template #left-icon>
                                            <i class="fa-regular fa-location-dot"></i>
                                        </template>
                                    </MazInput>
                                </div>

                                <div class="mb-2" v-if="client.tax_country == 66">
                                    <MazSelect id="tax_region" v-model="client.tax_region" label="Provincia" :options="regions" option-value-key="id" option-label-key="name"
                                        option-input-value-key="name" search search-placeholder="Buscar..." required :error="errors.client.tax_region">
                                        <template #left-icon>
                                            <MazIcon name="flag" />
                                        </template>
                                    </MazSelect>
                                </div>

                                <div class="row mb-2">
                                    <div class="col-8">
                                        <MazInput id="tax_locality" v-model="client.tax_locality" label="Ciudad" autocomplete="none" :error="errors.client.tax_locality">
                                        </MazInput>
                                    </div>
                                    <div class="col-4">
                                        <MazInput id="tax_postal_code" v-model="client.tax_postal_code" label="Código Postal" autocomplete="none" :error="errors.client.tax_postal_code
        ">
                                        </MazInput>
                                    </div>
                                </div>
                                <div class="mb-2">
                                    <MazSelect id="tax_country" v-model="client.tax_country" label="Seleccionar país" :options="countries" option-value-key="id" option-label-key="name"
                                        option-input-value-key="name" search search-placeholder="Buscar..." required :error="errors.client.tax_country">
                                    </MazSelect>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="mb-3 fs-4" style="font-weight: 500">
                                    Observaciones
                                </div>
                                <div class="col-md-12">
                                    <textarea type="text" class="form-control" v-model="client.notes" rows="3"></textarea>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="mb-3 fs-4" style="font-weight: 500">
                                    Alergias
                                </div>
                                <div class="col-md-12">
                                    <textarea type="text" class="form-control" v-model="client.alergies" rows="3"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer text-center">
                        <button class="btn btn-secondary me-3" data-bs-dismiss="modal">
                            Cerrar
                        </button>
                        <button type="submit" id="submitAddGuest" class="btn btn-primary" @click.prevent.stop="addClient">
                            Guardar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
#modalAddGuest .modal-header {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    padding: var(--bs-modal-header-padding);
    border-bottom: 1px solid #f1f1f4;
    border-top-left-radius: var(--bs-modal-inner-border-radius);
    border-top-right-radius: var(--bs-modal-inner-border-radius);
}

#modalAddGuest .modal-footer {
    display: flex;
    flex-shrink: 0;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
    background-color: var(--bs-modal-footer-bg);
    border-top: 1px solid #f1f1f4;
    border-bottom-right-radius: var(--bs-modal-inner-border-radius);
    border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}

.guest-suggested-clients {
    text-transform: uppercase;
    width: 100%;
    height: auto;
    max-height: 170px;
    overflow: auto;
    padding: 5px !important;
    list-style: none;
    font-weight: 500;
    border-bottom: 1px solid #dbdfe9;
}

.guest-suggested-clients li {
    cursor: pointer;
    padding: 10px;
    font-weight: 500;
}

.guest-suggested-clients li .title {
    font-size: 12px;
    font-weight: 500;
}

.guest-suggested-clients li:hover {
    border: 1px dashed #dbdfe9;
    border-radius: 10px;
}

#search_client input {
    background: #f9f9f9;
    color: #4b5675;
    border-color: #f9f9f9;
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important;
    min-height: calc(1.5em + 1.65rem + 2px);
    padding: 0.825rem 1.5rem;
    font-size: 1.15rem;
    border-radius: 0.625rem;
}

#search_client .input-group-text {
    position: absolute;
    left: 0;
    z-index: 6;
    height: 100%;
}

#search_client i {
    color: #4b5675;
    font-size: 18px;
}

#search_client .client-icon {
    width: 40px;
    height: 40px;
    color: var(--bs-secondary-inverse);
    background-color: var(--bs-secondary);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
}

#search_client .client-icon i {
    font-size: 14px;
}

.guest-suggested-clients {
    height: 200px;
    /* Establece la altura del contenedor */
    overflow-y: scroll;
    /* Asegura que la barra de desplazamiento vertical esté siempre visible */

    /* Personalización de la barra de desplazamiento - Para Webkit (Chrome, Safari) */
    scrollbar-width: thin;
    /* Ancho de la barra de desplazamiento */
    scrollbar-color: darkgrey white;
    /* Color de la barra de desplazamiento */
}

.guest-suggested-clients::-webkit-scrollbar {
    width: 10px;
    /* Ancho de la barra de desplazamiento */
}

.guest-suggested-clients::-webkit-scrollbar-track {
    background: white;
    /* Color del riel de la barra de desplazamiento */
}

.guest-suggested-clients::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    /* Color del 'pulggar' o asa de la barra de desplazamiento */
    border-radius: 5px;
    /* Bordes redondeados del pulgar de la barra de desplazamiento */
}
</style>

<script>
import ClientService from "@/services/client.service";
import CountryService from "@/services/country.service";
import BookingService from "@/services/booking.service";
import RegionService from "@/services/region.service";
import InfoService from "@/services/info.service";
import Swal from "sweetalert2";

export default {
    name: "AddGuest",
    props: {
        bookingroom: Object,
        booking: Object,
    },
    emits: ["updateGuestList"],
    watch: {
        bookingroom: function () {
            // watch it
            this.start();
        },
    },
    data() {
        return {
            client: {
                nationality: 66,
            },
            countries: [],
            regions: [],
            clients: [],
            suggestedClients: [], // Resultados de búsqueda
            mutationBookingroom: [],
            checkedAddress: true,
            parte: null,
            loading: true,
            searching: false,
            search: "",
            errors: [],
            timeoutId: null,
            sexOptions: [
                {
                    label: "Masculino",
                    value: "m",
                },
                {
                    label: "Femenino",
                    value: "f",
                },
            ],

            documentOptions: [
                {
                    label: "DNI",
                    value: "dni",
                },
                {
                    label: "Pasaporte",
                    value: "passport",
                },
                {
                    label: "Carné de conducir",
                    value: "driving_license",
                },
                {
                    label: "Carta o Documento de Identidad",
                    value: "identity_cart_or_document",
                },
                {
                    label: "Tarjeta de residencia España",
                    value: "residence_cart",
                },
                {
                    label: "Tarjeta de residencia País UE",
                    value: "residence_cart_country_ue",
                },
                {
                    label: "Cédula de ciudadanía",
                    value: "citizenship_card"
                },
                {
                    label: "Cédula de extranjería",
                    value: "foreign_identity_card"
                },
                {
                    label: "Registro civil",
                    value: "civil_registry"
                }
            ],
        };
    },
    methods: {
        async start() {
            this.loading = true;
            this.mutationBookingroom = Object.assign({}, this.bookingroom);

            await this.getCountries();
            await this.getRegions();
            await this.getNumParte();
            await this.resetErrors();

            if (this.booking.email != "") {
                this.client.email = this.booking.email;
            }

            if (this.booking.telephone != "" && this.booking.telephone != null) {
                this.client.phone = this.modifyTelephone(
                    this.booking.telephone
                );
            }

            this.loading = false;
        },

        modifyTelephone(telephone) {
            // Verifica si el teléfono comienza con '00'
            if (telephone.startsWith("00")) {
                // Reemplaza '00' por '34'
                return "0034" + telephone.substring(2);
            }
            // Si no comienza con '00', devuelve el teléfono original
            return telephone;
        },

        resetErrors() {
            // RESETAMOS LOS ERRORES
            this.errors = {
                client: {
                    firstName: false,
                    lastName1: false,
                    lastName2: false,
                    email: false,
                    dateofbirth: false,
                    nationality: false,
                    sex: false,
                    document_type: false,
                    nif: false,
                    document_expeditionDate: false,
                    document_experationDate: false,
                    country: false,
                    region: false,
                },
            };
        },

        updateDocumentSelected() {
            var document_experationDate_container = document.getElementById("document_experationDate_container");
            // console.log(document_experationDate)
            if (this.client.document_type == "citizenship_card") {
                document_experationDate_container.style.display = "none";
            } else {
                document_experationDate_container.style.display = "block";
            }

        },

        async addClient(evt) {
            evt.preventDefault();
            this.resetErrors();

            var now = new Date(this.bookingroom.checkin);
            var error = false;

            // COMPROBAMOS EL CAMPO firstName
            if (
                this.client.firstName == undefined ||
                this.client.firstName == ""
            ) {
                this.errors.client.firstName = true;
                error = true;
            }

            // COMPROBAMOS EL CAMPO lastName1
            if (
                this.client.lastName1 == undefined ||
                this.client.lastName1 == ""
            ) {
                this.errors.client.lastName1 = true;
                error = true;
            }

            // COMPROBAMOS EL CAMPO lastName2
            if (
                (this.client.lastName2 == undefined ||
                    this.client.lastName2 == "") &&
                this.client.nationality == 66
            ) {
                this.errors.client.lastName2 = true;
                error = true;
            }

            // COMPROBAMOS EL CAMPO email
            if (this.client.email == undefined || this.client.email == "") {
                this.client.email = null;
            }

            // COMPROBAMOS EL CAMPO sex
            if (this.client.sex == undefined || this.client.sex == "") {
                this.errors.client.sex = true;
                error = true;
            }

            // COMPROBAMOS QUE LA FECHA DE NACIMIENTO SEA MENOR A LA FECHA DE CHECKIN
            var dateofbirth = new Date(this.client.dateofbirth);
            if (
                this.client.dateofbirth == undefined ||
                dateofbirth >= now ||
                dateofbirth.getFullYear() < 1900
            ) {
                this.errors.client.dateofbirth = true;
                error = true;
            }

            // COMPROBAMOS EL CAMPO nationality
            if (
                this.client.nationality == undefined ||
                this.client.nationality == ""
            ) {
                this.errors.client.nationality = true;
                error = true;
            }

            // COMPROBAMOS EL CAMPO document_type
            if (
                this.client.document_type == undefined ||
                this.client.document_type == ""
            ) {
                this.errors.client.document_type = true;
                error = true;
            }

            // COMPROBAMOS EL CAMPO nif
            if (this.client.nif == undefined || this.client.nif == "") {
                this.errors.client.nif = true;
                error = true;
            } else if (this.client.document_type == "dni") {
                var result = await this.validateDNI(this.client.nif);

                if (!result) {
                    this.errors.client.nif = true;
                }
            }

            // COMPROBAMOS EL CAMPO document_expeditionDate
            var document_expeditionDate = new Date(
                this.client.document_expeditionDate
            );
            if (
                this.client.document_expeditionDate == undefined ||
                document_expeditionDate >= now ||
                (document_expeditionDate.getFullYear() < 2000 && this.client.document_type != "citizenship_card")
            ) {
                this.errors.client.document_expeditionDate = true;
                error = true;
            }

            // COMPROBAMOS EL CAMPO document_experationDate SOLO SI EL TIPO DE DOCUMENTO NO ES "civil_registry" o "citizenship_card"
            if (this.client.document_type !== "civil_registry" && this.client.document_type !== "citizenship_card") {
                var document_experationDate = new Date(this.client.document_experationDate);
                if (
                    this.client.document_experationDate == undefined ||
                    document_experationDate <= this.client.document_expeditionDate ||
                    document_experationDate < now
                ) {
                    this.errors.client.document_experationDate = true;
                    error = true;
                }
            }

            // COMPROBAMOS EL CAMPO country
            if (this.client.country == undefined || this.client.country == "") {
                this.errors.client.country = true;
                error = true;
            }

            if (
                (this.client.country == 66 &&
                    this.client.region == undefined) ||
                this.client.region == ""
            ) {
                this.errors.client.region = true;
                error = true;
            }

            if (this.checkedAddress) {
                this.client.tax_address = this.client.address;
                this.client.tax_region = this.client.region;
                this.client.tax_locality = this.client.locality;
                this.client.tax_postal_code = this.client.postal_code;
                this.client.tax_country = this.client.country;
            }

            // console.log(error, "error")

            if (error == true) {
                return;
            } else {
                window.$("#submitAddGuest").empty();
                window
                    .$("#submitAddGuest")
                    .append(
                        '<span class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>Guardando'
                    );

                var response = await BookingService.addGuestToBookingRoom({
                    bookingroom: this.bookingroom.id,
                    client: this.client,
                });

                if (response.status == 200) {
                    this.reset("client");

                    Swal.fire({
                        icon: "success",
                        title: "Huésped añadido correctamente",
                        showCancelButton: true,
                        cancelButtonText: "Cerrar",
                        confirmButtonColor: "#0091ff",
                        confirmButtonText: "Añadir otro huésped",
                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.$("#modalAddGuest").show();
                        } else {
                            this.updateGuestList();
                        }
                    });
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Ha ocurrido un error. Vuelve a intentarlo. Si el error persiste ponte en contacto con soporte@hospedium.com",
                        confirmButtonColor: "#0091ff",
                    });
                }

                window.$("#submitAddGuest").empty();
                window.$("#submitAddGuest").append("Guardar");

                window.$("#modalAddGuest").hide();
                window.$("body").removeClass("modal-open");
                window.$(".modal-backdrop").remove();
            }
        },

        async getNumParte() {
            var response = await InfoService.getInfo({
                key_name: "parte-viajero-num",
            });
            this.parte = response.data;
        },

        async getAllClients(search) {
            this.searching = true;
            var response = await ClientService.searchClients({
                search: search,
            });
            this.clients = response.data;
            this.searching = false;
            // console.log(this.clients, "Clients");
        },
        async getCountries() {
            this.countries = await CountryService.getAllCountries();
        },

        async getRegions() {
            this.regions = await RegionService.getAllRegions();
            this.regions = this.regions.data;
        },

        async validateDNI(dni) {
            var numero, let2, letra;
            var expresion_regular_dni = /^[XYZ]?\d{5,8}[A-Z]$/;

            dni = dni.toUpperCase();

            if (expresion_regular_dni.test(dni) === true) {
                numero = dni.substr(0, dni.length - 1);
                numero = numero.replace("X", 0);
                numero = numero.replace("Y", 1);
                numero = numero.replace("Z", 2);
                let2 = dni.substr(dni.length - 1, 1);
                numero = numero % 23;
                letra = "TRWAGMYFPDXBNJZSQVHLCKET";
                letra = letra.substring(numero, numero + 1);
                if (letra != let2) {
                    //alert('Dni erroneo, la letra del NIF no se corresponde');
                    return false;
                } else {
                    //alert('Dni correcto');
                    return true;
                }
            } else {
                //alert('Dni erroneo, formato no válido');
                return false;
            }
        },

        updateGuestList() {
            this.$emit("updateGuestList");
        },

        onClickOutside(event) {
            this.suggestedClients = []
        },

        reset(key) {
            this.search = null;
            if (key == "client") {
                this.client = {
                    id: 0,
                    firstName: "",
                    lastName1: "",
                    lastName2: "",
                    email: "",
                    tax_address: "",
                    tax_locality: "",
                    tax_postal_code: "",
                    tax_country: "",
                    nif: "",
                };
            }
        },

        handleSearch(event) {
            // Cancelar la solicitud anterior si existe un temporizador en curso
            if (this.timeoutId) {
                clearTimeout(this.timeoutId);
            }

            // Establecer un temporizador para realizar la solicitud después de un retraso
            this.timeoutId = setTimeout(() => {
                this.searchClient(this.search);
            }, 500); // Puedes ajustar el tiempo de espera según tus necesidades
        },

        async searchClient(search) {
            if (search != null && search.length > 3) {
                await this.getAllClients(search);
                this.suggestedClients = this.clients;
                window.$(".guest-suggested-clients").removeClass("d-none");
                window.$(".guest-suggested-clients").addClass("d-block");
                // console.log(this.suggestedClients);
            }
        },

        selectClient(client) {
            this.client = client;
            this.search = null;
            this.suggestedClients = [];

            if (this.client.region != null) {
                this.client.region = parseInt(this.client.region);
            }
            window.$(".guest-suggested-clients").removeClass("d-block");
            window.$(".guest-suggested-clients").addClass("d-none");
        },
    },
    created() {
        this.start();
    },
};
</script>