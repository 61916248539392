export default [
    {
        path: "/rooms",
        name: "rooms",
        component: () => import("@/views/rooms/Rooms.vue"),

        beforeEnter: () => {
            localStorage.setItem("menu", "menu-rooms");
        },
    },
    {
        path: "/rooms/:roomId",
        name: "ViewRoom",
        component: () => import("@/views/rooms/ViewRoom.vue"),
        beforeEnter: () => {
            localStorage.setItem("menu", "menu-rooms");
        },
    },
];
