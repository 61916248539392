<template>
    <div>
        <div v-if="!loading">
            <div class="btn-sm rounded room-link shadow-none w-100">
                <div class="room-card  align-items-center">
                    <div class="item-card-sm text-start">
                        <router-link class="btn btn-light btn-active-light-primary me-2" :to="{ name: 'checkin', params: { bookingroomId: bookingroom.id } }">
                            <i class="fa-regular fa-pen-to-square"></i>
                        </router-link>
                    </div>

                    <div class="text-start item-card">
                        <div><strong>Tipo hab.</strong><br>
                            {{ bookingroom.roomType.name }}</div>
                    </div>
                    <div class="text-start item-card">
                        <div v-if="bookingroom.rate"><strong>Tarifa</strong><br>{{ bookingroom.rate.name
                            }}</div>
                    </div>
                    <div class="text-start item-card">
                        <div>
                            <strong>Precio total</strong><br>
                            <span v-if="show_taxes">{{ formatCurrency(bookingroom.total_amount) }}<br><small class="text-muted">Impuestos incluidos</small></span>
                            <span v-else>{{ formatCurrency(bookingroom.baserate_amount) }}<br><small class="text-muted">Impuestos no incluidos</small></span>
                        </div>
                    </div>
                    <div class="item-card text-start">
                        <span v-if="bookingroom.status == 2" class="badge badge-light-primary">Submitted</span>
                        <span v-else-if="bookingroom.status == 4" class="badge badge-light-primary">Confirmado</span>
                        <span v-else-if="bookingroom.status == 5" class="badge badge-secondary">Checkout</span>
                        <span v-else-if="bookingroom.status == 6" class="badge badge-light-dark">No
                            Show</span>
                        <span v-else-if="bookingroom.status == 7" class="badge badge-light-danger">Cancelado</span>
                        <span v-else-if="bookingroom.status == 8" class="badge badge-light-warning">Modificado</span>
                        <span v-else-if="bookingroom.status == 9" class="badge badge-light-success me-1">Checkin</span>
                        <span v-else-if="bookingroom.status == 10" class="badge badge-secondary">Checkout</span>
                    </div>
                    <div class="item-card-sm">
                        <div><i class="fa-solid fa-person me-2"></i>{{ bookingroom.adults }}</div>
                        <div><i class="fa-solid fa-baby me-2"></i>{{ bookingroom.children }}</div>
                    </div>
                </div>
                <div class="footer-card w-100 text-start">
                    <!-- <div v-if="charges != ''" class="item-card">
                        <div><strong>Extras</strong></div>
                        <ul :key="item" v-for="item in charges" class="mb-0">
                            <li v-if="item.isExtra"><small>{{ item.date }}<i class="fa-solid fa-arrow-right-long mx-2"></i>{{ item.description + ' (' + formatCurrency(item.price) + ')' }}</small></li>
                        </ul>
                    </div> -->
                    <div class="item-card">
                        <div>
                            <h3 v-if="bookingroom.room" class="text-uppercase">{{ bookingroom.room.name
                                }}</h3>
                            <div v-else><span class="badge badge-light-danger fw-bold">SIN
                                    ASIGNAR</span></div>
                        </div>
                        <div>
                            <button class="btn btn-sm btn-primary" data-bs-toggle="modal" :data-bs-target="'#modalChangeRoom' + bookingroom.id" @click="changeRoomModal(bookingroom)">Cambiar
                                habitación</button>
                        </div>
                    </div>
                    <div class="item-card">
                        <div class="row mx-0">
                            <div class="col-md-6">
                                <div class="mb-2">
                                    <strong>Check-in</strong>
                                </div>
                                <div class="form-check form-switch mt-3 mb-2">
                                    <input class="form-check-input" type="checkbox" :id="'checkinRegister' + bookingroom.id" type-action="checkinRegister" value="1" @change="registerHour"
                                        :checked="isCheckinRegisterChecked(bookingroom)" :disabled="shouldDisableCheckinRegister(bookingroom)" style="
                                            font-size: 24px;
                                            margin-left: -1.5em;
                                            margin-top: 2px;
                                            cursor: pointer;
                                        " />
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="mb-2">
                                    <strong>Check-out</strong>
                                </div>
                                <div class="form-check form-switch mb-2 mt-3">
                                    <input class="form-check-input" type="checkbox" :id="'checkoutRegister' + bookingroom.id" type-action="checkoutRegister" value="1" @change="registerHour" style="
                                            font-size: 24px;
                                            margin-left: -1.5em;
                                            margin-top: 2px;
                                            cursor: pointer;
                                        " :checked="isCheckoutRegisterChecked(bookingroom)" :disabled="shouldDisableCheckoutRegister(bookingroom)" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-end">
                <PlanningChangeColorBookingRoom :bookingroomId="bookingroom.id" :colorSelected="bookingroom.color" @updatePlanning="updatePlanning" />
            </div>



        </div>
        <div v-else id="spinner-search" class="col-12 text-center py-5">
            <div class="spinner-border text-primary" role="status" style="width: 2rem; height: 2rem; visibility: visble;">
                <span class="visually-hidden">Loading...</span>
            </div>
            <div><small>Cargando información...</small></div>
        </div>
        <!-- Modal modalChangeRoom -->
        <div class="modal fade modalChangeRoom" :id="'modalChangeRoom' + bookingroom.id" aria-labelledby="modalChangeRoom" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Seleccionar Habitación</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div v-if="modalRoomLoading" class="text-center">
                            <div class="spinner-border text-primary" role="status" style="
                                        width: 2rem;
                                        height: 2rem;
                                        visibility: visble;
                                    ">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                            <div>Buscando habitaciones disponibles...</div>
                        </div>
                        <div v-if="!modalRoomLoading">
                            <div class="form-group">
                                <MazSelect id="selectedRoom" v-model="selectedRoom" label="Seleccionar habitación" :options="availableRooms" option-value-key="id" option-label-key="name"
                                    option-input-value-key="name" search search-placeholder="Buscar..." class="text-uppercase" v-slot="{ option, isSelected }" :item-height="50">
                                    <div class="d-flex align-items-center p-3 w-100" style="width: 100%">
                                        <i class="fa-solid fa-bed-front me-3"></i>
                                        <div>
                                            <div>
                                                {{ option.name }}
                                            </div>
                                            <div>
                                                <small>{{
            option.roomType
        }}</small>
                                            </div>
                                        </div>
                                        <i v-if="isSelected" class="fa-duotone fa-check-double ms-5"></i>
                                    </div>
                                </MazSelect>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button v-if="!modalRoomLoading" :id="'submitButtonUpdateRoom' + bookingroom.id" type="button" class="btn btn-primary" @click="updateRoom">
                            Guardar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import ChargeService from "../../services/charge.service";

import PlanningChangeColorBookingRoom from "../../components/planning/PlanningChangeColorBookingRoom"

import BookingService from "@/services/booking.service";
import RoomService from "@/services/room.service";

import Swal from "sweetalert2";

export default {
    name: 'PlanningBookingRoomOffCanvas',
    props: {
        bookingroom: Object,
        show_taxes: Boolean
    },
    emits: ["updatePlanning"],
    components: {
        PlanningChangeColorBookingRoom: PlanningChangeColorBookingRoom
    },
    data() {
        return {
            loading: true,
            charges: [],
            modalRoomLoading: true,
            selectedRoom: null,
            availableRooms: [],
            availability: [],
            reloadKey: 0,
            defaultCurrency: ''
        }
    },
    methods: {
        async start() {
            this.loading = true;
            await this.getCharges();
            this.loading = false;
        },

        async getCharges() {
            var response = await ChargeService.getAllCharges({
                bookingroom: this.bookingroom.id
            })
            this.charges = response.data;
            // console.log(this.charges, "Charges")
        },

        updatePlanning() {
            this.$emit("updatePlanning");
        },

        isCheckinRegisterChecked(bookingroom) {
            return bookingroom.checkinRegister !== "" && bookingroom.checkinRegister != null;
        },
        shouldDisableCheckinRegister(bookingroom) {
            return bookingroom.checkoutRegister != null || bookingroom.room == null
        },
        isCheckoutRegisterChecked(bookingroom) {
            return bookingroom.checkoutRegister !== "" && bookingroom.checkoutRegister != null;
        },
        shouldDisableCheckoutRegister(bookingroom) {
            return bookingroom.checkinRegister == null || bookingroom.room == null
        },

        async registerHour(e) {
            var response = "";
            var elmID = e.target.id;

            // console.log(elmID)
            var elm = window.$("#" + elmID)
            var checked = elm.is(":checked");
            var key = elm.attr("type-action")

            var status = false
            var date = new Date()
            if (key == "checkinRegister") {
                if (checked == 1) {
                    status = true
                } else {
                    date = null
                    status = false
                }
                response = await BookingService.checkin({
                    checkinRegister: date,
                    bookingroom: this.bookingroom.id,
                    status: status,
                });

                if (response.status == 400) {
                    window.$("#" + key).prop("checked", false);
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: response.data.message,
                        confirmButtonColor: "#0091ff",
                    });
                }

                this.bookingroom.checkinRegister = response.data.checkinRegister;
                this.bookingroom.status = response.data.status;
                // console.log(response.data, "Register Checkin Hour");
            } else if (key == "checkoutRegister") {
                if (checked == 1) {
                    status = true
                } else {
                    date = null
                    status = false
                }
                response = await BookingService.checkout({
                    checkoutRegister: date,
                    bookingroom: this.bookingroom.id,
                    status: status,
                });
                this.bookingroom.checkoutRegister = response.data.checkoutRegister;
                this.bookingroom.status = response.data.status;
                // console.log(
                //     response.data,
                //     "Register Checkout Hour"
                // );
            }

            this.updatePlanning()
        },

        async changeRoomModal(bookingroom) {
            this.modalRoomLoading = true;
            this.reloadKey++;
            this.selectedRoom = null;
            this.availableRooms = []
            this.availability = []
            await this.getAvailability(bookingroom);
            await this.getAvailableRooms(bookingroom);
            this.modalRoomLoading = false;
        },

        async getAvailability(bookingroom) {
            var response = await BookingService.getAvailability({
                startDate: bookingroom.checkin,
                endDate: bookingroom.checkout,
            });
            this.availability = response.data;
            // console.log(this.availability, "Availability");
        },


        async getAvailableRooms(bookingroom) {
            var arr = [];
            arr.push({
                id: null,
                name: "Sin asignar",
                roomType: "",
            });
            if (bookingroom.room != "" && bookingroom.room !== null && bookingroom.room != undefined) {
                arr.push({
                    id: bookingroom.room.id,
                    name: bookingroom.room.name,
                    roomType: bookingroom.room.roomType.name,
                });
                this.selectedRoom = bookingroom.room.id;
            }

            for (var i = 0; i < this.availability.length; i++) {
                for (
                    var r = 0;
                    r < this.availability[i].roomsAvailable.length;
                    r++
                ) {
                    arr.push({
                        id: this.availability[i].roomsAvailable[r].id,
                        name: this.availability[i].roomsAvailable[r].name,
                        roomType:
                            this.availability[i].roomsAvailable[r].roomType,
                    });
                }
            }
            this.availableRooms = arr;
            // console.log(this.availableRooms, "Available Rooms");
        },

        async getRoom(id) {

            var response = await RoomService.getRoom(id);
            return response.data;
        },

        async updateRoom() {
            window.$("#submitButtonUpdateRoom" + this.bookingroom.id).attr("disabled", "");
            window.$("#submitButtonUpdateRoom" + this.bookingroom.id).empty();
            window
                .$("#submitButtonUpdateRoom" + this.bookingroom.id)
                .append(
                    '<span class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>Guardando'
                );
            if (this.selectedRoom == 0) {
                this.selectedRoom = null;
            }

            var response = await BookingService.updateBookingRoom(
                this.bookingroom.id,
                {
                    room: this.selectedRoom,
                }
            );
            if (response.status == 200) {
                window.$("#modalChangeRoom" + this.bookingroom.id).hide();
                window.$("body").removeClass("modal-open");
                window.$(".modal-backdrop").remove();

                if (this.selectedRoom != null && this.selectedRoom != undefined) {
                    var room = await this.getRoom(this.selectedRoom)
                    this.bookingroom.room = room
                } else {
                    this.bookingroom.room = null
                }

                this.updatePlanning()

                Swal.fire({
                    position: "top",
                    icon: "success",
                    title: "Se ha guardado correctamente.",
                    showConfirmButton: false,
                    timer: 2000,
                });
            } else {
                Swal.fire({
                    position: "top",
                    icon: "error",
                    title: "Ha ocurrido un error.",
                    showConfirmButton: false,
                    timer: 2000,
                });
            }

            window.$("#submitButtonUpdateRoom" + this.bookingroom.id).removeAttr("disabled");
            window.$("#submitButtonUpdateRoom" + this.bookingroom.id).empty();
            window.$("#submitButtonUpdateRoom" + this.bookingroom.id).append("Guardar");
        },



    },
    created() {
        this.start()
    },
    mounted() {
    }

}
</script>

<style scoped>
.modalChangeRoom {
    background: rgba(0, 0, 0, 0.5) !important;
}

.room-link {
    border: dashed !important;
    border-color: #DBDFE9 !important;
    margin-bottom: 10px;
    padding: 10px;
}

.footer-card {
    border-top: 1px solid !important;
    border-color: #DBDFE9 !important;
    padding-top: 10px;
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer-card .item-card {
    width: 50%;
}

.item-card {
    width: 20%;
    margin-bottom: 0;
}

.item-card-sm {
    width: 10%;
    margin-bottom: 0;
}

.room-card {
    display: flex;
    flex-direction: row;
}

@media screen and (max-width: 768px) {
    .item-card {
        width: 100%;
        margin-bottom: 10px;
    }

    .item-card-sm {
        width: 100%;
        margin-bottom: 10px;
    }

    .room-card {
        display: flex;
        flex-direction: column;
    }

    .footer-card .item-card {
        width: 100%;
    }
}
</style>
