import api from "./api";
import TokenService from "./token.service";
import UserService from "./user.service";
import HotelService from "./hotel.service";
import BookingService from "./booking.service";
import InvoiceInfo from "./invoiceinfo.service";

class AuthService {
	async login({ username, password }) {
		try {
			const response = await api.post("/token/", { username, password });
			await TokenService.setAuth(response.data);
			await UserService.setCurrentLocalUser();
			await HotelService.setInitialLocalHotel();
			await BookingService.setInitialPlanning();
			await InvoiceInfo.setCurrentCurrency();

			return response.data;
		} catch (error) {
			// Manejo adecuado del error
			throw error;
		}
	}

	logout() {
		localStorage.clear();
	}

	register({ username, email, password }) {
		return api.post("/auth/signup", {
			username,
			email,
			password,
		});
	}

	async resetPassword(data) {
		return await api
			.post("/auth/password/reset/", data)
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}
	async resetPasswordValidateToken(data) {
		return await api
			.post("/auth/password/reset/validate_token/", data)
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async resetPasswordConfirm(data) {
		return await api
			.post("/auth/password/reset/confirm/", data)
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}
}

export default new AuthService();
