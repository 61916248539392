<template>
    <div class="h-100">
        <div class="booking-footer-button" data-bs-toggle="modal" data-bs-target="#modalDownloadBooking" @click="generateBookingPDF()">
            <i class="fa-regular fa-download"></i>
            <div class="title">Imprimir</div>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="modalDownloadBooking" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Reserva #{{ bookingID }}</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <embed v-if="!loading" :src="pdf" frameborder="0" width="100%" height="550px" />
                        <div v-else id="spinner-search" class="col-12 text-center py-5">
                            <div class="spinner-border text-primary" role="status" style="
									width: 2rem;
									height: 2rem;
									visibility: visble;
								">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                            <div>Cargando información...</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import BookingService from "../../services/booking.service";

export default {
    name: "BookingDownload",
    props: {
        bookingID: Number,
    },
    components: {},
    data() {
        return {
            pdf: "",
            loading: true,
        };
    },
    methods: {
        async generateBookingPDF() {
            this.loading = true;
            var response = await BookingService.bookingPDF(this.bookingID);
            this.pdf = response.data;
            // console.log(this.pdf, "PDF");
            this.loading = false;
        },
    },
    created() { },
};
</script>