<template>
    <div v-if="!loading">
        <div v-if="items.length == 0" class="col-12 text-center pt-5 pb-3">
            No se han encontrado facturas.
        </div>
        <div v-else>
            <div id="invoicesTable" class="table-responsive mb-3">
                <EasyDataTable :headers="headers" :items="items" :search-field="searchField" :search-value="searchValue" :rows-per-page="50" rows-per-page-message="resultados por página"
                    rows-of-page-separator-message="de" :rows-items="[50, 100, 150]" table-class-name="customize-table">
                    <template #item-operation="item">
                        <div class="operation-wrapper">
                            <div class="d-flex align-items-center">
                                <RouterLink :to="'invoices/' + item.id" class="btn btn-primary me-2">
                                    <i class="fa-regular fa-eye"></i>
                                </RouterLink>
                                <DownloadInvoice v-if="!loading" :invoiceId="item['id']" :showTextBtn="false" />
                            </div>
                        </div>
                    </template>
                    <template #item-number="{
        number,
        isProforma,
        isAdvancePayment,
        isRectificativa,
    }">
                        <div class="number-wrapper">
                            <div>
                                {{ number }}
                                <i v-if="isAdvancePayment" class="fa-solid fa-square-a text-primary ms-2"></i>
                                <i v-if="isProforma" class="fa-solid fa-square-p text-dark ms-2"></i>
                                <i v-if="isRectificativa" class="fa-solid fa-square-r text-info ms-2"></i>
                            </div>
                        </div>
                    </template>
                    <template #item-subtotal="{ subtotal }">
                        <div class="total-wrapper">
                            <div class="">{{ subtotal }}</div>
                        </div>
                    </template>
                    <template #item-taxes="{ taxes, show_taxes }">
                        <div class="total-wrapper">
                            <div v-if="show_taxes">{{ taxes }}</div>
                            <div v-else><em>Sin impuestos</em></div>
                        </div>
                    </template>
                    <template #item-total="{ total, show_taxes, subtotal }">
                        <div class="total-wrapper">
                            <div v-if="show_taxes">{{ total }}</div>
                            <div v-else>{{ subtotal }}</div>
                        </div>
                    </template>
                    <template #item-total_paid="{ total_paid }">
                        <div class="total-wrapper">
                            <div class="">{{ total_paid }}</div>
                        </div>
                    </template>
                    <template #item-left_to_pay="{ left_to_pay }">
                        <div class="total-wrapper">
                            <div class="">{{ left_to_pay }}</div>
                        </div>
                    </template>
                    <template #item-status="{ status, isProforma }">
                        <div class="status-wrapper">
                            <div v-if="isProforma">
                                <span class="badge badge-dark w-100">Proforma</span>
                            </div>
                            <div v-else>
                                <span v-if="status" class="badge badge-light-success">Pagado</span>
                                <span v-else class="badge badge-light-warning">Pendiente</span>
                                <!-- <span v-if="item['Rectificativa'] != ''" class="badge badge-info text-dark ms-2">{{item['Rectificativa']}}</span> -->
                            </div>
                        </div>
                    </template>

                    <template #item-type="{ isCompany, isClient }">
                        <div class="type-wrapper">
                            <div v-if="isClient" class="badge badge-light-info">
                                Cliente
                            </div>
                            <div v-if="isCompany" class="badge badge-light-dark">
                                Empresa
                            </div>
                        </div>
                    </template>

                    <template #item-client="{ client }">
                        <div class="type-wrapper">
                            <div class="text-uppercase">{{ client }}</div>
                        </div>
                    </template>

                    <template #item-booking="{ booking }">
                        <div class="booking-wrapper">
                            <div v-if="booking" class="badge badge-secondary">
                                {{ "#" + booking }}
                            </div>
                            <div v-else>-</div>
                        </div>
                    </template>

                    <template #item-typeInvoice="{
        isProforma,
        isAdvancePayment,
        isRectificativa,
        corrected,
    }">
                        <div class="typeInvoice-wrapper">
                            <div v-if="isProforma" style="text-transform: none">
                                Factura proforma
                            </div>
                            <div v-else-if="isAdvancePayment" style="text-transform: none">
                                Factura anticipo
                            </div>
                            <div v-else-if="isRectificativa" style="text-transform: none">
                                Factura rectificativa<br />
                                <small class="" v-if="corrected != '' && corrected != null">{{
        "Factura rectificada: " + corrected
    }}</small>
                            </div>
                            <div v-else style="text-transform: none">
                                Factura
                            </div>
                        </div>
                    </template>

                    <template #item-tags="{ tags }">
                        <div class="tags-wrapper">
                            <div v-for="tag in tags">
                                <span class="text-primary me-2">{{
        "#" + tag.name
    }}</span>
                            </div>
                        </div>
                    </template>
                    <template #item-siigo_status="{ siigo_status }">
                        <div class="siigo-status-wrapper">
                            <div v-if="siigo_status === 0" class="badge badge-light-primary">Created</div>
                            <div v-else-if="siigo_status === 1" class="badge badge-light-success">Modified</div>
                            <div v-else-if="siigo_status === 2" class="badge badge-light-danger">Error</div>
                            <div v-else-if="siigo_status === 3" class="badge badge-light-warning">Skipped</div>
                            <div v-else>-</div>
                        </div>
                    </template>
                </EasyDataTable>
            </div>
        </div>
    </div>
    <div v-else id="spinner-search" class="col-12 text-center py-5">
        <div class="spinner-border text-primary" role="status" style="width: 2rem; height: 2rem; visibility: visble">
            <span class="visually-hidden">Loading...</span>
        </div>
        <div>Buscando facturas...</div>
    </div>
</template>

<style>
#invoicesTable .customize-table table {
    border-collapse: collapse !important;
}

#invoicesTable .customize-table th {
    text-transform: uppercase;
    font-weight: 500 !important;
    font-size: 12px;
}

#invoicesTable .customize-table table> :not(caption)>*>* {
    padding: 0.5rem 0.5rem;
    color: var(--bs-table-color-state,
            var(--bs-table-color-type, var(--bs-table-color)));
    background-color: var(--bs-table-bg);
    border-bottom-width: var(--bs-border-width);
    box-shadow: inset 0 0 0 9999px var(--bs-table-bg-state,
            var(--bs-table-bg-type, var(--bs-table-accent-bg)));
}

#invoicesTable .customize-table td {
    border: none;
    font-size: 12px;
}

#invoicesTable .customize-table table tr {
    border-bottom-width: 1px !important;
    border-bottom-style: dashed !important;
    border-bottom-color: var(--bs-border-color) !important;
}

#invoicesTable td {
    min-width: 100px;
}

#invoicesTable .customize-table {
    --easy-table-border: 0;
    --easy-table-row-border: 1px solid rgba(230, 230, 230, 0.7);

    --easy-table-header-font-size: 14px;
    --easy-table-header-height: 50px;
    --easy-table-header-font-color: #000;
    --easy-table-header-background-color: #fff;

    --easy-table-header-item-padding: 10px 15px;

    --easy-table-body-even-row-font-color: #fff;
    --easy-table-body-even-row-background-color: #4c5d7a;

    --easy-table-body-row-font-color: #000;
    --easy-table-body-row-background-color: #fff;
    --easy-table-body-row-height: 50px;
    --easy-table-body-row-font-size: 14px;

    --easy-table-body-row-hover-font-color: #2d3a4f;
}
</style>

<script>
import { sortBy } from "lodash";

import InvoiceService from "@/services/invoice.service";
import ClientService from "@/services/client.service";
import CompanyService from "@/services/company.service";

import DownloadInvoice from "@/components/invoices/DownloadInvoice";

export default {
    name: "TableInvoices",
    props: {
        id: String,
        isClient: Boolean,
        isCompany: Boolean,
        isRectificativa: Boolean,
        startDate: Date,
        endDate: Date,
        filterStatusPayment: Number,
        filterType: Number,
        filterClient: Number,
        filterTag: Number,
        searchValue: String,
    },
    components: {
        DownloadInvoice: DownloadInvoice,
    },
    data() {
        return {
            loading: true,
            searchField: [],
            headers: [],
            items: [],
            defaultCurrency: ''
        };
    },
    watch: {
        startDate: function () {
            // watch it
            this.start();
        },

        endDate: function () {
            // watch it
            this.start();
        },

        filterStatusPayment: function () {
            // watch it
            this.start();
        },
        filterType: function () {
            // watch it
            this.start();
        },
        filterClient: function () {
            // watch it
            this.start();
        },
        filterTag: function () {
            // watch it
            this.start();
        },
    },
    computed: {
        isSiigoActive() {
            // console.log(this.$store.state.hotel.hotelModules);
            return this.$store.state.hotel.hotelModules.some(
                (module) => module.key === "module_siigo"
            );
        },
    },
    methods: {
        async start() {
            this.loading = true;

            this.headers = [
                { text: "", value: "operation" },
                { text: "Fecha", value: "date", sortable: true },
                { text: "Número", value: "number", sortable: true },
                {
                    text: "Tipo de factura",
                    value: "typeInvoice",
                    sortable: true,
                },
                { text: "Tipo de cliente", value: "type", sortable: true },
                { text: "Cliente", value: "client", sortable: true },
                { text: "Reserva", value: "booking", sortable: true },
                { text: "Subtotal", value: "subtotal", sortable: true },
                { text: "Impuestos", value: "taxes", sortable: true },
                { text: "Total", value: "total", sortable: true },
                { text: "Cobrado", value: "total_paid", sortable: true },
                { text: "Pendiente", value: "left_to_pay", sortable: true },
                { text: "Estado", value: "status", sortable: true },
                { text: "Etiquetas", value: "tags", sortable: true },
            ];

            // Añadir la columna "siigo_status" si isSiigoActive está activo
            if (this.isSiigoActive) {
                this.headers.push({ text: "Siigo", value: "siigo_status", sortable: true });
            }


            await this.getInvoices();

            this.loading = false;
        },

        async getInvoices() {
            var response = await InvoiceService.getInvoicesByDate({
                startDate: this.formatDate(this.startDate),
                endDate: this.formatDate(this.endDate),
                filterStatusPayment: this.filterStatusPayment,
                filterType: this.filterType,
                filterClient: this.filterClient,
                filterTag: this.filterTag,
            });
            var arr = [];

            for (var i = 0; i < this.headers.length; i++) {
                this.searchField.push(this.headers[i].value);
            }

            this.searchField.push();

            if (response.status == 200) {
                // console.log(response.data);
                for (var i = 0; i < response.data.length; i++) {
                    var d = new Date(response.data[i].dateCreated);
                    d = this.formatDate(d, true);

                    var isClient = false;
                    var isCompany = false;

                    if (response.data[i].client_hotel) {
                        isClient = true;
                    } else if (response.data[i].company_hotel) {
                        isCompany = true;
                    }

                    var corrected = null;
                    if (response.data[i].invoice_corrected) {
                        corrected = [
                            response.data[i].invoice_corrected.code,
                            response.data[i].invoice_corrected.number,
                        ].join("");
                    }

                    var item = {
                        id: response.data[i].id,
                        date: d,
                        number: [
                            response.data[i].code,
                            response.data[i].number,
                        ].join(""),
                        isProforma: response.data[i].isProforma,
                        isAdvancePayment: response.data[i].isAdvancePayment,
                        client: response.data[i].tax_contact_name,
                        subtotal: this.formatCurrency(
                            response.data[i].baserate_amount
                        ),
                        taxes: this.formatCurrency(
                            response.data[i].tax_amount
                        ),
                        total: this.formatCurrency(
                            response.data[i].total_amount
                        ),
                        left_to_pay: this.formatCurrency(
                            response.data[i].left_to_pay
                        ),
                        total_paid: this.formatCurrency(
                            response.data[i].total_paid
                        ),
                        status: response.data[i].paid,
                        isClient: isClient,
                        isCompany: isCompany,
                        isRectificativa: response.data[i].isRectificativa,
                        corrected: corrected,
                        booking: response.data[i].booking,
                        tags: response.data[i].tags,
                        show_taxes: response.data[i].show_taxes,
                    };

                    // Añadir siigo_status si isSiigoActive está activo
                    if (this.isSiigoActive) {
                        item.siigo_status = response.data[i].siigo_status;
                    }


                    arr.push(item);
                }
            }
            this.items = arr;
            console.log(this.items, "Invoices")
        },

        formatDate(date) {
            var d = new Date(date),
                month = "" + (d.getMonth() + 1),
                day = "" + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2) month = "0" + month;
            if (day.length < 2) day = "0" + day;

            return [year, month, day].join("-");
        },


        generatePDF(id) {
            this.invoiceDownloadId = id;
            this.$refs.DownloadInvoice.generatePDF();
        },
    },
    created() {
        this.defaultCurrency = localStorage.getItem('currency') || 'EUR';
        this.start();
    },
};
</script>