<template>
    <tr :id="'rate' + rate.id" :key="rate" v-for="(rate, index) in roomType.rates">
        <td v-if="index == 0" :rowspan='roomType.rates.length' class="align-middle">{{ roomType.name
            }}
        </td>
        <td class="align-middle">
            <div class="mb-2">
                <div class="btn-group" role="group">
                    <button type="button" class="btn btn-sm btn-primary" disabled
                        style="display: inline-flex; align-items: center; width: 75px"><i
                            class="fas fa-male me-1"></i>Adultos</button>
                    <button :id="'minusAdults' + roomType.roomType + rate.id"
                        @click="minusAdult(rate.id, index)" type="button"
                        class="btn btn-sm btn-outline-primary"><i
                            class="fa-solid fa-minus"></i></button>
                    <input :id="'countAdults' + roomType.roomType + rate.id" type="button"
                        class="btn btn-sm btn-outline-primary" disabled :value="rate.occupancy"
                        style="width: 30px">
                    <button :id="'plusAdults' + roomType.roomType + rate.id"
                        @click="plusAdult(rate.id, index)" type="button"
                        class="btn btn-sm btn-outline-primary"><i
                            class="fa-solid fa-plus"></i></button>
                </div>
            </div>
            <div>
                <div class="btn-group" role="group">
                    <button type="button" class="btn btn-sm btn-primary" disabled
                        style="display: inline-flex; align-items: center; width: 75px"><i
                            class="fa-solid fa-baby me-1"></i>Niños</button>
                    <button :id="'minusChildren' + roomType.roomType + rate.id"
                        @click="minusChildren(rate.id, index)" type="button"
                        class="btn btn-sm btn-outline-primary"><i
                            class="fa-solid fa-minus"></i></button>
                    <input :id="'countChildren' + roomType.roomType + rate.id" type="button"
                        class="btn btn-sm btn-outline-primary" disabled :value="0"
                        style="width: 30px">
                    <button :id="'plusChildren' + roomType.roomType + rate.id"
                        @click="plusChildren(rate.id, index)" type="button"
                        class="btn btn-sm btn-outline-primary"><i
                            class="fa-solid fa-plus"></i></button>
                </div>
            </div>
        </td>
        <td class="align-middle"><small>{{ rate.name }}</small></td>
        <td class="align-middle text-center">
            <div class="price text-center">
                <div class="input-group mb-3" style="width: 130px; margin: 0 auto;">
                    <input :id="'price' + roomType.roomType + rate.id" type="number"
                        class="form-control" :value="rate.price_total"
                        @change.stop.prevent="changePrice(rate.id)" changedPrice="false">
                    <span class="input-group-text">{{ defaultCurrency }}</span>
                </div>
            </div>
            <div :id="'price_day' + roomType.roomType + rate.id" class="price-day"
                :days="rate.days.length">
                <small>{{ setPriceDay(rate.price_total, rate.days.length) }}</small>
            </div>
        </td>
        <td class="align-middle text-center">
            <div class="btn-group" role="group">
                <button :id="'minus' + roomType.roomType + rate.id" @click="minus(rate.id)"
                    type="button" class="btn btn-sm btn-outline-primary"><i
                        class="fa-solid fa-minus"></i></button>
                <input value="0" :id="'count' + roomType.roomType + rate.id" type="button"
                    class="btn btn-sm btn-outline-primary" disabled style="width: 30px">
                <button :id="'plus' + roomType.roomType + rate.id" @click="plus(rate.id)"
                    type="button" class="btn btn-sm btn-outline-primary"><i
                        class="fa-solid fa-plus"></i></button>
            </div>
            <div>
                <small v-if="roomType.numRoomsAvailable == 0" class="text-danger quantity"
                    :id="'quantity' + roomType.roomType + rate.id"
                    :quantity="roomType.numRoomsAvailable">Quedan {{
        roomType.numRoomsAvailable
    }}</small>
                <small v-else class="quantity" :id="'quantity' + roomType.roomType + rate.id"
                    :quantity="roomType.numRoomsAvailable">Quedan {{
        roomType.numRoomsAvailable
                    }}</small>
            </div>
        </td>
    </tr>
</template>

<script>

export default {
    name: 'CreateBookingRoomTableRow',
    emits: ["addRoomSelected", "removeRoomSelected"],
    props: {
        roomType: Object
    },
    data() {
        return {
            roomId: this.roomType.roomType,
            defaultCurrency: ''
        }
    },
    methods: {

        setCurrency(number) {
            const formatter = new Intl.NumberFormat('es-ES', {
                style: 'currency',
                currency: this.defaultCurrency
            })

            return formatter.format(number)
        },

        setPriceDay(price, num) {
            var result = parseFloat(price / num).toFixed(2) + ' ' + this.defaultCurrency + '/día'
            if (price == 0) {
                result = '0 ' + this.defaultCurrency + '/día';
            }
            return result;
        },
        plusAdult(rateId, index) {
            var oldValue = parseInt(window.$('#countAdults' + this.roomId + rateId).val());
            var newValue = parseInt(oldValue) + parseInt(1);
            window.$('#countAdults' + this.roomId + rateId).val(newValue);
            this.changePersons(rateId, index);
        },
        minusAdult(rateId, index) {
            var oldValue = parseInt(window.$('#countAdults' + this.roomId + rateId).val());
            if (oldValue > 1) {
                var newValue = parseInt(oldValue) - parseInt(1);
                window.$('#countAdults' + this.roomId + rateId).val(newValue);
            }
            this.changePersons(rateId, index);
        },
        plusChildren(rateId, index) {
            var oldValue = parseInt(window.$('#countChildren' + this.roomId + rateId).val());
            var newValue = parseInt(oldValue) + parseInt(1);
            window.$('#countChildren' + this.roomId + rateId).val(newValue);
            this.changePersons(rateId, index);
        },
        minusChildren(rateId, index) {
            var oldValue = parseInt(window.$('#countChildren' + this.roomId + rateId).val());
            if (oldValue > 0) {
                var newValue = parseInt(oldValue) - parseInt(1);
                window.$('#countChildren' + this.roomId + rateId).val(newValue);
            }

            this.changePersons(rateId, index);
        },

        changePersons(rateId, index) {
            var days = this.roomType.rates[index]['days'];
            var adults = parseInt(window.$('#countAdults' + this.roomId + rateId).val());
            var children = parseInt(window.$('#countChildren' + this.roomId + rateId).val());
            var persons = adults + children;
            var current_price = window.$('#price' + this.roomId + rateId).val();

            if (persons >= this.roomType.rates[index]['occupancy']) {
                window.$('#price' + this.roomId + rateId).val(parseFloat(this.roomType.rates[index]['price_total']))
            } else {
                var price = 0;
                for (var i = 0; i < days.length; i++) {
                    if (days[i]['occupancy']) {
                        for (var occ = 0; occ < days[i]['occupancy'].length; occ++) {
                            if (days[i]['occupancy'][occ]['id'] == persons) {
                                price += parseFloat(days[i]['occupancy'][occ]['price'])
                            }
                        }
                    }
                }

                if (price == 0) {
                    price = parseFloat(this.roomType.rates[index]['price_total'])
                }

                window.$('#price' + this.roomId + rateId).val(price)
            }



            this.changePrice(rateId)
        },

        changePrice(rateId) {
            var adults = parseInt(window.$('#countAdults' + this.roomId + rateId).val());
            var price = window.$('#price' + this.roomId + rateId)
            price.attr("changedPrice", "true");

            var price_day = window.$('#price_day' + this.roomId + rateId)
            price_day.empty();
            price_day.append('<small>' + this.setPriceDay(parseFloat(window.$('#price' + this.roomId + rateId).val()), parseInt(price_day.attr("days"))) + '</small>');

            window.$('#countAdults' + this.roomId + rateId).val(adults)
        },

        plus(rateId) {
            var quantity = parseInt(window.$('#quantity' + this.roomId + rateId).attr("quantity"));
            var oldValue = parseInt(window.$('#count' + this.roomId + rateId).val());
            var adults = parseInt(window.$('#countAdults' + this.roomId + rateId).val());
            var children = parseInt(window.$('#countChildren' + this.roomId + rateId).val());
            var price_total = parseFloat(window.$('#price' + this.roomId + rateId).val())
            var changedPrice = window.$('#price' + this.roomId + rateId).attr("changedPrice")

            if (quantity > 0) {
                this.addRoomSelected({
                    roomId: this.roomId,
                    rateId: rateId,
                    adults: adults,
                    children: children,
                    changedPrice: changedPrice,
                    price_total: price_total
                });
                var newValue = parseInt(oldValue) + parseInt(1);
                quantity -= 1
                window.$('#count' + this.roomId + rateId).val(newValue);

                window.$('#room' + this.roomId + ' .quantity').each(function (i, obj) {
                    window.$('#' + obj.id).attr("quantity", quantity);
                    window.$('#' + obj.id).empty();
                    window.$('#' + obj.id).html("Quedan " + quantity)
                });
                if (newValue > 0) {
                    window.$('#minus' + this.roomId + rateId).removeAttr("disabled");
                    window.$('#minus' + this.roomId + rateId).removeClass("disabled");
                }

                if (quantity == 0) {
                    window.$('#room' + this.roomId + ' .plus').each(function (i, obj) {
                        window.$('#' + obj.id).attr("disabled", "");
                        window.$('#' + obj.id).addClass("disabled");
                    });
                }
            }
        },
        minus(rateId) {
            var quantity = parseInt(window.$('#quantity' + this.roomId + rateId).attr("quantity"));
            var oldValue = parseInt(window.$('#count' + this.roomId + rateId).val());

            if (oldValue > 0) {
                this.removeRoomSelected({
                    roomId: this.roomId,
                    rateId: rateId
                });
                var newValue = parseInt(oldValue) - parseInt(1);
                quantity += 1
                window.$('#count' + this.roomId + rateId).val(newValue);

                window.$('#room' + this.roomId + ' .quantity').each(function (i, obj) {
                    window.$('#' + obj.id).attr("quantity", quantity);
                });

                if (newValue < quantity) {
                    window.$('#room' + this.roomId + ' .plus').each(function (i, obj) {
                        window.$('#' + obj.id).removeAttr("disabled");
                        window.$('#' + obj.id).removeClass("disabled");
                    });
                }

                if (newValue == 0) {
                    window.$('#minus' + this.roomId + rateId).attr("disabled", "");
                    window.$('#minus' + this.roomId + rateId).addClass("disabled");
                }
            }
        },
        addRoomSelected(data) {
            this.$emit("addRoomSelected", data);
        },
        removeRoomSelected(data) {
            this.$emit("removeRoomSelected", data);
        },

    },
    created() {
        this.defaultCurrency = localStorage.getItem('currency') || 'EUR';
    },
}
</script>