<template>
    <div class="page-app">
        <div class="row">
            <div class="col-lg-9 col-md-8">
                <div class="card mb-3 shadow-sm border-0" style="min-height: 400px">
                    <div class="card-header bg-white">
                        <nav id="navbar-new-booking-search" class="nav nav-pills nav-justified">
                            <li class="text-sm-center nav-link active" id="step-1" @click="goToStep(1)">
                                <div class="mb-2">
                                    <span class="num">1</span>
                                </div>
                                <div>
                                    <span class="subtitle">Fechas/PAX/Tarifa</span>
                                </div>
                            </li>
                            <li class="text-sm-center nav-link" id="step-2" @click="goToStep(2)">
                                <div class="mb-2">
                                    <span class="num">2</span>
                                </div>
                                <div><span class="subtitle">Extras</span></div>
                            </li>
                            <li class="text-sm-center nav-link" id="step-3" @click="goToStep(3)">
                                <div class="mb-2">
                                    <span class="num">3</span>
                                </div>
                                <div><span class="subtitle">Cliente</span></div>
                            </li>
                        </nav>
                    </div>
                    <div class="card-body" v-if="step == 1">
                        <form class="d-block d-md-flex justify-content-between" @submit.prevent>
                            <div class="mb-4">
                                <DatePicker v-model="range" mode="date" :masks="masks" is-range>
                                    <template v-slot="{
                                inputValue,
                                inputEvents,
                                isDragging,
                            }">
                                        <div class="d-md-flex d-block justify-content-start align-items-center">
                                            <div class="input-group flex-grow-0" style="width: auto">
                                                <span class="input-group-text" id="basic-addon1"><i class="far fa-calendar-week"></i></span>
                                                <input class="flex-grow-0 ps-3 pe-2 py-1 bg-gray-100 border rounded input-calendar" :class="isDragging
                                ? 'text-gray-600'
                                : 'text-gray-900'
                                " :value="inputValue.start" v-on="inputEvents.start" />
                                            </div>
                                            <span class="nput-group mx-2 flex-grow-0">
                                                <i class="fas fa-long-arrow-alt-right stroke-current text-gray-600"></i>
                                            </span>
                                            <div class="input-group flex-grow-0" style="width: auto">
                                                <span class="input-group-text" id="basic-addon1"><i class="far fa-calendar-week"></i></span>
                                                <input class="flex-grow-0 ps-3 pe-2 py-1 bg-gray-100 border rounded input-calendar" :class="isDragging
                                ? 'text-gray-600'
                                : 'text-gray-900'
                                " :value="inputValue.end" v-on="inputEvents.end" />
                                            </div>
                                        </div>
                                    </template>
                                </DatePicker>
                            </div>
                        </form>

                        <div v-if="!loading" class="table-responsive">
                            <table id="bookings-table-new" class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>Tipo hab.</th>
                                        <th>Personas</th>
                                        <th>Tarifas</th>
                                        <th class="text-center">Precio</th>
                                        <th class="text-center">Disponibles</th>
                                    </tr>
                                </thead>
                                <tbody :id="'room' + item.roomType" :key="item" v-for="item in availability">
                                    <CreateBookingRoomTableRow @addRoomSelected="addRoomSelected" @removeRoomSelected="removeRoomSelected" :roomType="item" />
                                </tbody>
                            </table>
                        </div>
                        <div v-if="loading" class="text-center mt-5">
                            <p><small>Cargando información...</small></p>

                            <div class="d-flex justify-content-center mt-5">
                                <div id="spinner-search" class="spinner-border text-primary" role="status" style="width: 3rem; height: 3rem">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body" v-if="step == 2">
                        <ExtrasCreateBooking :roomSelected="roomSelected" :checkin="this.formatDate(this.range.start)" :checkout="this.formatDate(this.range.end)"
                            @updateRoomSelected="updateRoomSelected" @updateTotalPrice="updateTotalPrice" @removeTotalPrice="removeTotalPrice" />
                    </div>
                    <div class="card-body" v-if="step == 3">
                        <ClientCreateBooking @updateClient="updateClient" />
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-4">
                <div class="card mb-3 shadow-sm border-0">
                    <div class="card-header bg-white">
                        <div class="modal-title">Resumen</div>
                    </div>
                    <div class="card-body">
                        <div class="mb-2">
                            <i class="far fa-calendar-week me-2" style="width: 18px"></i>
                            <span>{{
                                setFormatDate(this.range.start) +
                                " - " +
                                setFormatDate(this.range.end)
                            }}</span>
                        </div>
                        <div class="mb-2">
                            <i class="fas fa-moon me-2" style="width: 18px"></i>
                            <span v-if="nights == 1">{{
                                nights + " noche"
                            }}</span>
                            <span v-else>{{ nights + " noches" }}</span>
                        </div>
                        <div class="mb-2">
                            <i class="fas fa-male me-2" style="width: 18px"></i>
                            <span>{{ "Adultos: " + totalAdults }}</span>
                        </div>
                        <div class="mb-3">
                            <i class="fa-solid fa-baby me-2" style="width: 18px"></i>
                            <span>{{ "Niños: " + totalChildren }}</span>
                        </div>
                        <div :key="item" v-for="item in roomSelected" class="mb-3">
                            <div>
                                <i class="fas fa-bed me-2"></i>{{ "Habitación " + item.roomName }}
                            </div>
                            <div class="d-flex justify-content-between">
                                <div>
                                    <div>
                                        <small>{{ item.rateName }}</small>
                                    </div>
                                </div>
                                <div>
                                    <span class="text-primary">{{
                                setCurrency(item.price)
                            }}</span>
                                </div>
                            </div>
                            <div v-if="item.extras != ''">
                                <div><small>Extras:</small></div>
                                <ul>
                                    <li :key="e" v-for="e in item.extras">
                                        <div class="d-flex justify-content-between">
                                            <div>
                                                <small>{{
                                e.description
                            }}</small>
                                            </div>
                                            <div>
                                                <span class="text-primary">{{
                                    setCurrency(e.price)
                                }}</span>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="border-top text-end pt-3">
                            <span class="text-primary fs-5">{{
                                "Total: " + setCurrency(totalPrice)
                            }}</span>
                        </div>
                        <div v-if="roomSelected != '' && step < 3" class="mt-3 text-center">
                            <button class="btn btn-primary" @click="nextStep">
                                Continuar<i class="far fa-long-arrow-right ms-2"></i>
                            </button>
                        </div>
                        <div v-if="roomSelected == ''" class="mt-3">
                            <p class="text-danger mb-0">
                                <i class="fas fa-long-arrow-left me-2"></i>Selecciona habitación
                            </p>
                        </div>
                        <div v-if="step == 3" class="mt-3 text-center">
                            <button id="submitNewBooking" @click="createBooking" class="btn btn-primary">
                                Finalizar reserva
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BookingService from "../../services/booking.service";

import CreateBookingRoomTableRow from "../../components/bookings/CreateBookingRoomTableRow.vue";
import ExtrasCreateBooking from "../../components/bookings/ExtrasCreateBooking.vue";
import ClientCreateBooking from "../../components/bookings/ClientCreateBooking.vue";

import Swal from "sweetalert2";

export default {
    name: "CreateBooking",
    components: {
        CreateBookingRoomTableRow: CreateBookingRoomTableRow,
        ExtrasCreateBooking: ExtrasCreateBooking,
        ClientCreateBooking: ClientCreateBooking,
    },
    data() {
        return {
            range: {
                start: new Date(),
                end: new Date(new Date().setDate(new Date().getDate() + 1)),
            },
            masks: {
                input: "YYYY-MM-DD",
            },
            availability: [],
            roomSelected: [],
            nights: 1,
            totalPrice: 0,
            totalAdults: 0,
            totalChildren: 0,
            step: 1,
            client: [],
            loading: true,
            defaultCurrency: ''
        };
    },
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },
    },
    watch: {
        range: {
            handler: function () {
                this.start();
            },
            deep: true,
        },
    },
    methods: {
        async start() {
            this.loading = true;
            this.totalPrice = 0;
            this.totalAdults = 0;
            this.totalChildren = 0;
            this.roomSelected = [];
            this.availability = [];
            this.client = [];
            await this.getAvailability();
            await this.getNights();
            this.loading = false;
        },
        goToStep(id) {
            if (this.roomSelected != "") {
                var previous = window.$("#step-" + parseInt(this.step));
                previous.removeClass("active");
                this.step = id;
                var current = window.$("#step-" + this.step);
                current.addClass("active");

                if (id == 1) {
                    this.start();
                }
            }
        },

        async nextStep() {
            this.step += 1;
            var previous = window.$("#step-" + (parseInt(this.step) - 1));
            previous.removeClass("active");
            var current = window.$("#step-" + this.step);
            current.addClass("active");
        },
        setFormatDate(date) {
            var dd = String(date.getDate()).padStart(2, "0");
            var mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
            var yyyy = date.getFullYear();

            date = dd + "/" + mm + "/" + yyyy;
            return date;
        },
        getNights() {
            var timeDiff = Math.abs(
                this.range.end.getTime() - this.range.start.getTime()
            );
            this.nights = Math.ceil(timeDiff / (1000 * 3600 * 24));
            // console.log(this.nights);
        },

        async getAvailability() {
            var response = await BookingService.getAvailability({
                startDate: this.range.start.toISOString().split("T")[0],
                endDate: this.range.end.toISOString().split("T")[0],
            });
            this.availability = response.data;
            console.log(this.availability, "Availability");
        },

        addRoomSelected(data) {
            this.availability.forEach(async (room) => {
                // console.log(room, "ROOM")
                //console.log(data, "DATA")
                const { roomType, name, rates } = room;

                if (data.roomId == roomType) {
                    let rate = rates.filter((r) => r.id == data.rateId);
                    let deepCopyRate = JSON.parse(JSON.stringify(rate[0]));
                    // console.log(rate, "RATE");

                    // Si hemos cambiado el precio, actualizamos el precio por día
                    if (data.changedPrice) {
                        var price_day = parseFloat(
                            data.price_total / deepCopyRate.days.length
                        );
                        price_day =
                            Math.round((price_day + Number.EPSILON) * 100) /
                            100;

                        for (var i = 0; i < deepCopyRate.days.length; i++) {
                            deepCopyRate.days[i]["price"] = price_day;
                        }
                    }

                    // Comprobamos si existen días en la tarifa; Si no, los calculamos
                    var days = [];
                    if (deepCopyRate.days.length > 0) {
                        days = deepCopyRate.days;
                    } else {
                        var sD = new Date(this.range.start);
                        var eD = new Date(this.range.end);
                        // array of dates
                        const datesArray = [];

                        // loop from start date to end date
                        for (var d = sD; d <= eD; d.setDate(d.getDate() + 1)) {
                            datesArray.push(new Date(d));
                        }

                        var price_day = parseFloat(
                            data.price_total / (datesArray.length - 1)
                        );

                        for (var i = 0; i < datesArray.length - 1; i++) {
                            days.push({
                                currency: this.defaultCurrency,
                                day: this.formatDate(datesArray[i]),
                                maximumStay: -1,
                                minimumStay: -1,
                                price: price_day,
                            });
                        }
                    }

                    this.roomSelected.push({
                        id: roomType,
                        roomTypeId: roomType,
                        roomName: name,
                        rateId: deepCopyRate.id,
                        rateName: deepCopyRate.name,
                        price: data.price_total,
                        priceTotal: data.price_total,
                        occupancy: deepCopyRate.occupancy,
                        extras: [],
                        adults: data.adults,
                        children: data.children,
                        days: days,
                        changedPrice: data.changedPrice,
                        checkin: this.formatDate(this.range.start),
                        checkout: this.formatDate(this.range.end),
                    });
                    this.totalPrice += data.price_total;
                    this.totalAdults += data.adults;
                    this.totalChildren += data.children;
                    return false;
                }
            });

            // console.log(data, "Add Room");
            // console.log(this.roomSelected, "roomSelected");
        },

        removeRoomSelected(data) {
            for (var i = 0; i < this.roomSelected.length; i++) {
                if (
                    this.roomSelected[i].id == data.roomId &&
                    this.roomSelected[i].rateId == data.rateId
                ) {
                    this.totalPrice -= this.roomSelected[i].price;
                    this.totalAdults -= this.roomSelected[i].adults;
                    this.totalChildren -= this.roomSelected[i].children;
                    this.roomSelected.splice(i, 1);
                    break;
                }
            }
            // console.log(data, "Remove Room");
        },

        async updateRoomSelected(data) {
            this.roomSelected = data;
            // console.log("UPDATE ROOM SELECTED", this.roomSelected)
        },
        async updateTotalPrice(data) {
            // console.log("Total Price Before:", this.totalPrice);
            this.totalPrice = (
                parseFloat(this.totalPrice) + parseFloat(data)
            ).toFixed(2);
            // console.log("Updated Total Price", data);
            // console.log("Total Price After:", this.totalPrice);
        },

        async removeTotalPrice(data) {
            this.totalPrice = (
                parseFloat(this.totalPrice) - parseFloat(data)
            ).toFixed(2);
        },

        updateClient(data) {
            this.client = data;
            // console.log("CLIENT DATA", this.client)
        },

        setCurrency(number) {
            const formatter = new Intl.NumberFormat("es-ES", {
                style: "currency",
                currency: this.defaultCurrency,
            });

            return formatter.format(number);
        },
        sortRatesByPrice(data) {
            let sortedData = data.sort(function (a, b) {
                return a.price - b.price;
            });
            return sortedData;
        },

        formatDate(date) {
            var d = new Date(date),
                month = "" + (d.getMonth() + 1),
                day = "" + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2) month = "0" + month;
            if (day.length < 2) day = "0" + day;

            return [year, month, day].join("-");
        },

        getDatesInRange(startDate, endDate) {
            const date = new Date(startDate.getTime());
            const dates = [];
            while (date <= endDate) {
                dates.push(this.formatDate(new Date(date)));
                date.setDate(date.getDate() + 1);
            }
            return dates;
        },

        async createBooking() {
            var error = false;

            if (this.client.firstName == "" || this.cliente == "") {
                error = true;

                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "El campo nombre es obligatorio",
                    confirmButtonColor: "#0091ff",
                });
            } else if (
                (this.client.sendEmail == true || this.client.sendPaymentEmail == true) &&
                this.client.email == ""
            ) {
                error = true;

                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "El campo email es obligatorio",
                    confirmButtonColor: "#0091ff",
                });
            }

            if (!error) {
                window.$("#submitNewBooking").attr("disabled", "");
                window.$("#submitNewBooking").empty();
                window
                    .$("#submitNewBooking")
                    .append(
                        '<span class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>Guardando'
                    );

                const d1 = this.range.start;
                const d2 = this.range.end;

                const dates = this.getDatesInRange(d1, d2);

                var Booking = await BookingService.addBooking({
                    bookingId: Math.floor(100000 + Math.random() * 900000),
                    portal: 22,
                    checkin: this.formatDate(this.range.start),
                    checkout: this.formatDate(this.range.end),
                    status: 4,
                    firstName: this.client.firstName,
                    lastName: this.client.lastName,
                    email: this.client.email,
                    telephone: this.client.telephone,
                    price: this.totalPrice,
                    currencyCode: this.defaultCurrency,
                    numRooms: this.roomSelected.length,
                    adults: parseInt(this.totalAdults),
                    children: parseInt(this.totalChildren),
                    roomsSelected: this.roomSelected,
                    sendEmail: this.client.sendEmail,
                    sendPaymentEmail: this.client.sendPaymentEmail,
                    htmlEmail: this.client.htmlEmail,
                });

                console.log(Booking);

                var updateChannelInventory = [];
                for (var i = 0; i < this.roomSelected.length; i++) {
                    var RS = this.roomSelected[i];
                    var index = updateChannelInventory.findIndex(
                        (x) => x["roomType"] === RS.id
                    );

                    if (index >= 0) {
                        updateChannelInventory[index]["quantity"] += 1;
                    } else {
                        updateChannelInventory.push({
                            roomType: RS.id,
                            quantity: 1,
                        });
                    }
                }

                var res = await BookingService.updateChannelInventory(
                    {
                        startDate: this.formatDate(this.range.start),
                        endDate: this.formatDate(this.range.end),
                    },
                    updateChannelInventory
                );

                // console.log(res)

                window.$("#submitNewBooking").empty();
                window.$("#submitNewBooking").append("Finalizar reserva");
                window.$("#submitNewBooking").removeAttr("disabled");

                if (Booking.status == 201) {
                    Swal.fire({
                        position: "top",
                        icon: "success",
                        title: "Se ha creado correctamente.",
                        showConfirmButton: false,
                        timer: 1500,
                        backdrop: false,
                        toast: false,
                    });

                    this.$router.push("/bookings/view/" + Booking.data.id);
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Ha ocurrido un error. Vuelve a intentarlo. Si el error persiste ponte en contacto con soporte@hospedium.com",
                        confirmButtonColor: "#0091ff",
                    }).then(() => {
                        this.$router.push("/");
                    });
                }
            }
        },
    },
    created() {
        this.defaultCurrency = localStorage.getItem('currency') || 'EUR';
        this.start();
    },
    mounted() {
        if (!this.currentUser) {
            this.$router.push("/login");
        }
    },
};
</script>