<template>
    <div v-if="!loading">
        <button class="btn btn btn-primary" style="width: max-content" data-bs-toggle="modal" data-bs-target="#modalAddBookingRoom">
            <i class="far fa-plus" aria-hidden="true"></i><span class="d-none d-md-inline-block ms-1">Añadir Habitación</span>
        </button>

        <!-- Modal Add Habitación -->
        <div class="modal fade" id="modalAddBookingRoom" tabindex="-1" aria-labelledby="modalAddBookingRoom" aria-hidden="true">
            <div class="modal-dialog modal-xl modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-header border-0">
                        <h5 class="modal-title">Añadir nueva habitación</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body px-4 pt-0">
                        <div v-if="!loading" class="row">
                            <div>
                                <div class="mb-4">
                                    <h6>Selecciona fecha de entrada y salida</h6>
                                    <DatePicker v-model="range" mode="date" :masks="masks" :min-date="minDate" is-range>
                                        <template v-slot="{ inputValue, inputEvents, isDragging }">
                                            <div class="d-flex align-items-center">
                                                <div class="input-group flex-grow-0" style="width: auto">
                                                    <span class="input-group-text" id="basic-addon1"><i class="far fa-calendar-week"></i></span>
                                                    <input class="flex-grow-0 ps-2 pe-2 py-1 bg-gray-100 border rounded" :class="isDragging
        ? 'text-gray-600'
        : 'text-gray-900'
        " :value="inputValue.start" v-on="inputEvents.start" style="width: 105px" />
                                                </div>
                                                <span class="nput-group mx-2 flex-grow-0">
                                                    <i class="fas fa-long-arrow-alt-right stroke-current text-gray-600"></i>
                                                </span>
                                                <div class="input-group flex-grow-0" style="width: auto">
                                                    <span class="input-group-text" id="basic-addon1"><i class="far fa-calendar-week"></i></span>
                                                    <input class="flex-grow-0 ps-2 pe-2 py-1 bg-gray-100 border rounded" :class="isDragging
        ? 'text-gray-600'
        : 'text-gray-900'
        " :value="inputValue.end" v-on="inputEvents.end" style="width: 105px" />
                                                </div>
                                            </div>
                                        </template>
                                    </DatePicker>
                                </div>
                                <div class="mb-4">
                                    <h6>Selecciona el tipo de habitación</h6>
                                    <select class="form-control" v-model="selectedRoomType" @change="getData(true)">
                                        <option value="null">Seleccionar habitación</option>
                                        <option :key="item.id" v-for="item in availability" :value="item.roomType" :disabled="item.numRoomsAvailable === 0">
                                            {{ formatOption(item) }}
                                        </option>
                                    </select>
                                </div>

                                <div class="mb-4">
                                    <h6>Selecciona el número de habitaciones que quieres añadir</h6>
                                    <select class="form-control" v-model="selectedQuantity">
                                        <option value="null">Nº de habitaciones</option>
                                        <option :key="item" v-for="item in quantityOptions" :value="item">
                                            {{ item }}
                                        </option>
                                    </select>
                                </div>
                                <div class="mb-4">
                                    <h5>Selecciona la tarifa y su precio</h5>
                                    <select class="form-control mb-3" v-model="selectedRate" @change="onChangeRate">
                                        <option value="null">Seleccionar tarifa</option>
                                        <option :key="item.id" v-for="item in rateOptions" :disabled="item.numRoomsAvailable === 0" :value="item.id">
                                            {{ item.name }}
                                        </option>
                                    </select>
                                    <div class="input-group">
                                        <input type="text" class="form-control" v-model="selectedPrice">
                                        <span class="input-group-text" id="basic-addon2">{{ defaultCurrency }}</span>

                                    </div>
                                    <div class="mb-3"><small>*Precio total de una sola habitación en su totalidad de noches.</small></div>
                                </div>

                            </div>
                            <div class="text-center">
                                <button id="submitAddBookingRoom" type="submit" class="btn btn-primary" @click.stop.prevent="addBookingRoom">
                                    Añadir
                                </button>
                            </div>
                        </div>

                        <div v-else id="spinner-search" class="col-12 text-center py-5">
                            <div class="spinner-border text-primary" role="status" style="
											width: 2rem;
											height: 2rem;
											visibility: visble;
										">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                            <div>Buscando habitaciones disponibles...</div>
                        </div>
                    </div>


                </div>

            </div>
        </div>
    </div>
</template>

<script>
import BookingService from "../../services/booking.service";

import Swal from "sweetalert2";
import { nextTick } from 'vue';

export default {
    name: "AddBookingRoom",
    props: {
        booking: Object,
    },
    components: {},
    data() {
        return {
            loading: true,
            loadingInitialSetup: true,
            defaultCurrency: '',
            availability: null,
            rateOptions: [],
            quantityOptions: [],
            selectedRoomType: null,
            selectedQuantity: null,
            selectedRate: null,
            selectedPrice: 0,
            range: {
                start: null,
                end: null
            },
            masks: {
                input: "YYYY-MM-DD",
                data: "DD/MM/YYYY",
            },
            minDate: new Date(),
        };
    },
    watch: {
        range: {
            handler: async function () {
                if (!this.loadingInitialSetup) {
                    await this.onDateRangeChange();
                }
            },
            deep: true,
            immediate: false
        },
    },
    methods: {
        async start() {
            this.loading = true;
            let startDate = this.formatDate(this.booking.checkin)
            let endDate = this.formatDate(this.booking.checkout)
            await this.getAvailability(startDate, endDate)


            this.loading = false;
            this.loadingInitialSetup = false;

        },

        getData(hasChangeRoomType = false) {

            if (hasChangeRoomType) {
                this.selectedQuantity = null;
                this.selectedRate = null;
                this.selectedPrice = 0;
                // console.log('Ha cambiado el tipo de habitación');

                // Muestra SweetAlert de cargando...
                Swal.fire({
                    title: 'Cargando...',
                    text: 'Por favor espera',
                    allowOutsideClick: false,
                    confirmButtonColor: '#0091ff',
                    onBeforeOpen: () => {
                        Swal.showLoading(); // Muestra el loader de SweetAlert
                    }
                });
            }
            if (this.selectedRoomType != null) {
                const roomType = this.availability.find(item => item.roomType === this.selectedRoomType);

                if (roomType) {
                    // console.log('Habitación encontrada:', roomType);
                    // Asigna las tarifas disponibles a rateOptions
                    this.rateOptions = roomType.rates;

                    // Obtiene el número de habitaciones disponibles
                    const numRoomsAvailable = roomType.numRoomsAvailable;

                    // Crea opciones de cantidad basadas en el número de habitaciones disponibles
                    this.quantityOptions = [];
                    for (let i = 1; i <= numRoomsAvailable; i++) {
                        this.quantityOptions.push(i);
                    }
                    Swal.close();

                } else {
                    // console.log('Tipo de habitación no encontrado en la disponibilidad');
                    // Resetea las opciones si no se encuentra el tipo de habitación
                    this.rateOptions = [];
                    this.quantityOptions = [];
                    Swal.close();
                }

            } else {
                // console.log('No se ha seleccionado un tipo de habitación');
                Swal.close(); // Cierra el SweetAlert si no hay selección de habitación
            }

        },

        async onDateRangeChange() {
            if (!this.loadingInitialSetup) {
                // Muestra SweetAlert de cargando...
                Swal.fire({
                    title: 'Cargando...',
                    text: 'Por favor espera',
                    allowOutsideClick: false,
                    confirmButtonColor: '#0091ff',
                    showConfirmButton: false,
                    onBeforeOpen: () => {
                        Swal.showLoading(); // Muestra el loader de SweetAlert
                    }
                });

                this.selectedRoomType = null;
                this.selectedQuantity = null;
                this.selectedRate = null;
                this.selectedPrice = 0;
                let startDate = this.formatDate(this.range.start)
                let endDate = this.formatDate(this.range.end)

                await this.getAvailability(startDate, endDate)
                Swal.close();
            }
        },

        async onChangeRate() {
            // Check if a rate is selected
            if (this.selectedRate !== null) {
                // Find the matching rate object using selectedRate
                const selectedRateObj = this.rateOptions.find(rate => rate.id === this.selectedRate);

                // If the rate object exists, update the selected price
                if (selectedRateObj) {
                    this.selectedPrice = selectedRateObj.price_total;
                } else {
                    // Handle the case where the rate object is not found (optional)
                    this.selectedPrice = 0;
                }
            }
        },

        async getAvailability(startDate, endDate) {
            var response = await BookingService.getAvailability({
                startDate: startDate,
                endDate: endDate,
            });
            this.availability = response.data;
            // console.log(this.availability, "Availability");
        },


        async addBookingRoom() {
            // Verificar si alguna de las selecciones es nula
            if (this.selectedRoomType === null || this.selectedQuantity === null || this.selectedRate === null || this.selectedPrice === null) {
                // Mostrar alerta usando SweetAlert2
                Swal.fire({
                    icon: 'warning',
                    title: 'Campos incompletos',
                    text: 'Debe seleccionar una habitación, cantidad, tarifa y precio antes de continuar.',
                    confirmButtonText: 'Aceptar',
                    confirmButtonColor: '#0091ff',
                });
                return; // Salir del método para evitar la llamada a addBookingRoom
            }

            // Convert commas to dots for `selectedPrice`
            this.selectedPrice = this.selectedPrice.toString().replace(',', '.');

            // Attempt to parse the price into a float
            let price = parseFloat(this.selectedPrice);


            // Check if the parsed price is not a valid number or less than zero
            if (isNaN(price) || price < 0) {
                Swal.fire({
                    icon: 'warning',
                    title: 'Precio no válido',
                    text: 'El precio debe ser un número positivo.',
                    confirmButtonText: 'Aceptar',
                    confirmButtonColor: '#0091ff',
                });
                return;
            }

            // Mostrar alerta de cargando
            Swal.fire({
                title: 'Añadiendo...',
                text: 'Por favor espera mientras se añade la habitación.',
                allowOutsideClick: false, // Deshabilitar cerrar alerta haciendo clic fuera
                didOpen: () => {
                    Swal.showLoading(); // Mostrar indicador de carga
                }
            });

            let checkin = this.formatDate(this.range.start)
            let checkout = this.formatDate(this.range.end)
            // Si todos los campos están completos, proceder con la llamada a BookingService
            var response = await BookingService.addBookingRoomToBooking({
                booking: this.booking.id,
                checkin: checkin,
                checkout: checkout,
                roomType: this.selectedRoomType,
                quantity: this.selectedQuantity,
                rate: this.selectedRate,
                price: price
            })

            if (response.status == 201) {
                // Procesar la respuesta según lo necesites
                console.log('Habitación agregada:', response);
                Swal.fire({
                    icon: 'success',
                    title: 'Habitación añadida',
                    text: 'La habitación ha sido agregada con éxito.',
                    confirmButtonText: 'Aceptar',
                    confirmButtonColor: '#0091ff',
                }).then((result) => {
                    if (result.value) {
                        this.$router.go(); // Esto recargará la vista actual
                    }
                });
            } else {
                // Manejo de errores en caso de fallo de la API
                console.error('Error al agregar la habitación:');
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Ocurrió un error al agregar la habitación. Por favor, inténtelo nuevamente.',
                    confirmButtonText: 'Aceptar',
                    confirmButtonColor: '#0091ff',
                });
            };


        },

        async toUTCDate(localDate) {
            return new Date(
                localDate.getUTCFullYear(),
                localDate.getUTCMonth(),
                localDate.getUTCDate(),
            );
        },

        formatDate(date) {
            var d = new Date(date),
                month = "" + (d.getMonth() + 1),
                day = "" + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2) month = "0" + month;
            if (day.length < 2) day = "0" + day;

            return [year, month, day].join("-");
        },

        formatOption(item) {
            return `${item.name} - ${item.numRoomsAvailable} ${item.numRoomsAvailable === 1 ? 'disponible' : 'disponibles'}`;
        },


    },
    async created() {
        this.defaultCurrency = localStorage.getItem('currency') || 'EUR';

        let startDate = new Date(this.booking.checkin)
        let endDate = new Date(this.booking.checkout)
        this.range = {
            start: await this.toUTCDate(startDate),
            end: await this.toUTCDate(endDate)
        }
        await this.start();
    },
    mounted() {
        // window.$("#modalAddBookingRoom").on("hidden.bs.modal", this.resetModal);

    },
};
</script>