<template>
    <div v-if="!loading">
        <div class="d-grid gap-2 col-12 mx-auto">
            <button class="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#modalAddPayment">
                Añadir Pago
            </button>
        </div>

        <!-- Modal Add Payment -->
        <div class="modal fade" id="modalAddPayment" tabindex="-1" aria-labelledby="modalAddPayment" aria-hidden="true">
            <div class="modal-dialog modal-sm">
                <div class="modal-content p-2">
                    <form @submit="add">
                        <div class="modal-header border-0">
                            <h5 class="modal-title">Añadir nuevo pago</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="form-group mb-3">
                                <label class="form-label">Método de Pago</label>
                                <select class="form-control" v-model="addPayment.paymentMethod" required>
                                    <option :key="item" v-for="item in paymentMethods" :value="item.id">
                                        {{ item.name }}
                                    </option>
                                </select>
                            </div>
                            <div class="form-group mb-3">
                                <label class="form-label">Cantidad</label>
                                <input type="text" class="form-control" v-model="addPayment.quantity" />
                            </div>
                            <div class="form-group mb-3">
                                <label class="form-label">Fecha</label>
                                <DatePicker v-model="addPayment.date" is-required>
                                    <template v-slot="{ inputValue, inputEvents }">
                                        <input class="form-control" :value="inputValue" v-on="inputEvents" />
                                    </template>
                                </DatePicker>
                            </div>
                        </div>
                        <div class="modal-footer border-0">
                            <a type="button" class="btn btn-link text-decoration-none text-primary" data-bs-dismiss="modal">Cerrar</a>
                            <button id="submitAddPayment" type="submit" class="btn btn-primary">
                                Guardar
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PaymentMethodService from "../../services/paymentmethod.service";
import PaymentService from "../../services/payment.service";

import Swal from "sweetalert2";

export default {
    name: "AddPaymentCustom",
    props: {
        invoice: Object,
    },
    data() {
        return {
            paymentMethods: [],
            addPayment: {
                paymentMethod: "",
                invoice: this.invoice.id,
                quantity: parseFloat(this.invoice.left_to_pay),
                date: new Date(),
                currency: ''
            },
            masks: {
                input: "YYYY-MM-DD",
            },
            loading: true,
        };
    },
    methods: {
        async start() {
            this.loading = true;

            await this.getPaymentMethods();

            // Marcarmos Método de pago por defecto
            for (var i = 0; i < this.paymentMethods.length; i++) {
                // console.log(this.paymentMethods[i])
                if (this.paymentMethods[i].isDefault) {
                    this.addPayment.paymentMethod = this.paymentMethods[i].id;
                    break;
                }
            }

            this.loading = false;
        },
        async getPaymentMethods() {
            var response = await PaymentMethodService.getAllPaymentMethod();
            this.paymentMethods = response.data;
        },
        async add(evt) {
            evt.preventDefault();
            window.$("#submitAddPayment").empty();
            window
                .$("#submitAddPayment")
                .append(
                    '<span class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>Guardando'
                );

            var d = new Date(this.addPayment.date),
                month = "" + (d.getMonth() + 1),
                day = "" + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2) month = "0" + month;
            if (day.length < 2) day = "0" + day;
            this.addPayment.date = [year, month, day].join("-");
            this.addPayment.quantity = parseFloat(this.addPayment.quantity);

            let response = await PaymentService.addPayment(this.addPayment);

            window.$("#submitAddPayment").empty();
            window.$("#submitAddPayment").append("Guardar");

            if (response.status == 201 || response.status == 200) {
                this.name = null;
                window.$("#modalAddPayment").hide();
                window.$("body").removeClass("modal-open");
                window.$(".modal-backdrop").remove();
                Swal.fire({
                    icon: "success",
                    title: "Pago añadido",
                    confirmButtonColor: "#0091ff",
                }).then((result) => {
                    this.$router.go();
                });
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Ha ocurrido un error. Vuelve a intentarlo. Si el error persiste ponte en contacto con soporte@hospedium.com",
                    confirmButtonColor: "#0091ff",
                });
            }
        },
    },
    created() {
        this.addPayment.currency = localStorage.getItem('currency') || 'EUR';
        this.start();
    },
};
</script>