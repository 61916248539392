<template>
    <div>
        <vue3-html2pdf v-if="!loading" :show-layout="false" :float-layout="true" :enable-download="false" :preview-modal="true" :paginate-elements-by-height="1400"
            :filename="'listado-entradas-salidas-' + range.start + range.end" :pdf-quality="2" :manual-pagination="true" pdf-content-width="1010px" :html-to-pdf-options="htmlToPdfOptions"
            ref="html2Pdf">
            <template v-slot:pdf-content>
                <!-- PDF Content Here -->
                <div class="pdf-item">
                    <h3>{{ hotel.name }}</h3>
                    <h5>Listado de costes de habitaciones por fecha</h5>
                    <h5 v-if="formatDate(range.end) != formatDate(range.start)">Fechas del {{
            formatDate(range.start, true) }} al {{ formatDate(range.end, true) }}</h5>
                    <h5 v-else>Fecha {{ formatDate(range.start, true) }}</h5>
                </div>


                <div class="pdf-item" v-for="(slice, index) in slices" :key="slice">
                    <div class="table-responsive w-100 mt-3">
                        <table class="table table-bordered table-sm mt-3">
                            <thead>
                                <tr style="background: #0091ff;">
                                    <th class="text-uppercase">Día</th>
                                    <th class="text-uppercase">Habitación</th>
                                    <th class="text-uppercase">Reserva</th>
                                    <th class="text-uppercase">Checkin</th>
                                    <th class="text-uppercase">Checkout</th>
                                    <th class="text-uppercase">Régimen</th>
                                    <th class="text-uppercase">PAX</th>
                                    <th class="text-uppercase">Precio día</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr :key="item" v-for="item in slice">
                                    <td class="text-uppercase"><small>{{ item.day }}</small></td>
                                    <td class="text-uppercase"><small>{{ item.room }}</small></td>
                                    <td class="text-uppercase"><small>{{ item.booking }}</small></td>
                                    <td class="text-uppercase"><small>{{ item.checkin }}</small></td>
                                    <td class="text-uppercase"><small>{{ item.checkout }}</small></td>
                                    <td class="text-uppercase"><small>{{ item.rate }}</small></td>
                                    <td class="text-uppercase"><small>{{ item.persons }}</small></td>
                                    <td class="text-uppercase"><small>{{ item.price_night }}
                                            {{ defaultCurrency }}/día</small></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="html2pdf__page-number  d-flex justify-content-between ">
                        <div>{{ "Fecha creación: " + creation_date }}</div>
                        <div>{{ "Página " + (index + 1) }}</div>
                    </div>
                    <div class="html2pdf__page-break"></div>

                </div>

            </template>
        </vue3-html2pdf>
        <div>
            <h6 class="mb-3">Generar listado de coste de habitaciones por fecha</h6>
            <label class="form-label">Seleccionar fechas</label>
            <DatePicker v-model="range" mode="date" :masks="masks" is-range>
                <template v-slot="{ inputValue, inputEvents, isDragging }">
                    <div class="d-flex justify-content-center align-items-center">
                        <div class="input-group  flex-grow-0" style="width: auto;">
                            <span class="input-group-text" id="basic-addon1"><i class="far fa-calendar-week"></i></span>
                            <input class="flex-grow-0 ps-2 pe-2 py-1 bg-gray-100 border rounded" :class="isDragging ? 'text-gray-600' : 'text-gray-900'" :value="inputValue.start"
                                v-on="inputEvents.start" style="width: 105px;" />
                        </div>
                        <span class="nput-group mx-2 flex-grow-0">
                            <i class="fas fa-long-arrow-alt-right stroke-current text-gray-600"></i>
                        </span>
                        <div class="input-group flex-grow-0" style="width: auto;">
                            <span class="input-group-text" id="basic-addon1"><i class="far fa-calendar-week"></i></span>
                            <input class="flex-grow-0 ps-2 pe-2 py-1 bg-gray-100 border rounded" :class="isDragging ? 'text-gray-600' : 'text-gray-900'" :value="inputValue.end" v-on="inputEvents.end"
                                style="width: 105px;" />
                        </div>
                    </div>
                </template>
            </DatePicker>

            <div class="col-12 mt-4 pt-3">
                <button class="btn btn-primary" @click="generateReport">Generar reporte</button>
            </div>
        </div>
    </div>
</template>


<script>
import BookingService from "../../services/booking.service";
import HotelService from "../../services/hotel.service";

import Vue3Html2pdf from 'vue3-html2pdf'

export default {
    name: 'BookingsByDayList',
    components: {
        Vue3Html2pdf
    },
    data() {
        return {
            hotel: HotelService.getLocalHotel(),
            range: {
                start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
                end: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
            },
            masks: {
                input: 'YYYY-MM-DD',
            },
            loading: true,
            bookingsDay: [],
            slices: [],
            numPages: [],
            pageNumber: 1,
            creation_date: Date.now(),
            htmlToPdfOptions: {
                margin: 10,
                jsPDF: {
                    format: 'a4',
                    orientation: 'landscape'
                }
            },
            defaultCurrency: ''
        }
    },
    methods: {
        async start() {
            this.loading = true
            await this.getBookingsDay();
            await this.getNumPages();
            await this.sliceIntoChunks();
            await this.getCurrentDatetime();

            this.loading = false
        },
        async getBookingsDay() {
            var response = await BookingService.getBookingRoomListDayCost({
                startDate: this.formatDate(this.range.start),
                endDate: this.formatDate(this.range.end)
            })
            this.bookingsDay = response.data;
            // console.log(this.bookingsDay, "bookingsDay")
        },

        async getCurrentDatetime() {
            var currentdate = new Date();
            this.creation_date = currentdate.getDate() + "/"
                + (currentdate.getMonth() + 1) + "/"
                + currentdate.getFullYear() + " - "
                + currentdate.getHours() + ":"
                + currentdate.getMinutes() + ":"
                + currentdate.getSeconds();
        },

        async getNumPages() {
            this.numPages = Math.ceil(this.bookingsDay.length / 18)
        },

        async sliceIntoChunks() {
            var arr = this.bookingsDay;
            var chunkSize = 18;
            const res = [];
            for (let i = 0; i < arr.length; i += chunkSize) {
                const chunk = arr.slice(i, i + chunkSize);
                res.push(chunk);
            }
            this.slices = res;
            // console.log(this.slices, "slices")
        },



        formatDate(date) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            return [year, month, day].join('-');
        },
        async generateReport() {
            await this.start();
            this.$refs.html2Pdf.generatePdf()
        }

    },
    created() {
        this.defaultCurrency = localStorage.getItem('currency') || 'EUR';
    }
}
</script>