import { createApp } from "vue";
import App from "./App.vue";
import router from "@/router/router";
import store from "./store";
import SwalPlugin from "@/plugins/sweetalert2";
import BootstrapPlugin from "@/plugins/bootstrap";

// Import styles and scripts
import "./index";
import "v-calendar/dist/style.css";
import "vue3-swatches/dist/style.css";
import "maz-ui/css/main.css";
import "vue3-easy-data-table/dist/style.css";

import setupInterceptors from "./services/setupInterceptors";
import { setupCalendar, Calendar, DatePicker } from "v-calendar";
import { QuillEditor } from "@vueup/vue-quill";
import vClickOutside from "click-outside-vue3";
import VSwatches from "vue3-swatches";
import Vue3EasyDataTable from "vue3-easy-data-table";
import { VueSignaturePad } from "vue-signature-pad";
import drag from "v-drag";
import closeModalsMixin from "@/mixins/closeModalsMixin";
import { globalMixins } from "@/mixins/globalMixins";

// Maz-UI Components
import MazPhoneNumberInput from "maz-ui/components/MazPhoneNumberInput";
import MazSelect from "maz-ui/components/MazSelect";
import MazInput from "maz-ui/components/MazInput";
import MazIcon from "maz-ui/components/MazIcon";
import MazInputPrice from "maz-ui/components/MazInputPrice";
import MazChart from "maz-ui/components/MazChart";
import MazPicker from "maz-ui/components/MazPicker";
import MazCheckbox from "maz-ui/components/MazCheckbox";
import MazInputTags from "maz-ui/components/MazInputTags";
import MazTextarea from "maz-ui/components/MazTextarea";
import MazRadioButtons from "maz-ui/components/MazRadioButtons";
import MazRadio from "maz-ui/components/MazRadio";
import MazInputNumber from "maz-ui/components/MazInputNumber";

// jQuery setup
window.$ = window.jQuery = require("jquery");
window.$ = window.$.extend(require("jquery-ui"));

window.$(".modal").on("hidden.bs.modal", function () {
	//remove the backdrop
	console.log("remove the backdrop");
	window.$(".modal-backdrop").remove();
});

// Setup interceptors
setupInterceptors(store);

const app = createApp(App);

// Use plugins and mixins
app.use(router);
app.use(store);
app.use(vClickOutside);
app.use(setupCalendar, {});
app.use(VSwatches);
app.use(drag, {});
app.use(SwalPlugin);
app.use(BootstrapPlugin);
app.mixin(closeModalsMixin);
app.mixin(globalMixins);

store.dispatch("initializeStore");

// Register global components
const components = {
	Calendar,
	DatePicker,
	QuillEditor,
	MazPhoneNumberInput,
	MazSelect,
	MazInput,
	MazIcon,
	MazInputPrice,
	MazInputNumber,
	MazRadio,
	MazRadioButtons,
	MazChart,
	MazPicker,
	MazCheckbox,
	MazInputTags,
	MazTextarea,
	EasyDataTable: Vue3EasyDataTable,
	VueSignaturePad,
};

Object.entries(components).forEach(([name, component]) => {
	app.component(name, component);
});

app.provide("mazIconPath", "/icons");
app.mount("#app");
