<template>
    <div class="modal fade" id="availabilityModal" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="staticBackdropLabel">
                        Disponibilidad: {{ currentItem.day }}
                    </h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="table-responsive">
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Tipo de Habitación</th>
                                    <th>Totales</th>
                                    <th>Disponibles</th>
                                    <th>Channel</th>
                                    <th>PMS</th>
                                    <th>Ocupadas</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="room in currentItem.roomTypes" :key="room.id">
                                    <td>
                                        {{ room.name }}
                                        <span v-if="isOverbookingActive || isSuperadmin || isAdmin">
                                            <i v-if="room.overbooking_partial" class="fa-solid fa-circle-exclamation text-warning" style="margin-left: 5px;"></i>
                                            <i v-if="room.overbooking_total" class="fa-solid fa-circle-exclamation text-danger" style="margin-left: 5px;"></i>
                                        </span>
                                    </td>
                                    <td class="text-center">
                                        <div>{{ room.numRooms }}</div>
                                    </td>
                                    <td class="text-center">
                                        <div :class="{ 'bg-danger text-white': room.roomsAvailable < 0 }">
                                            {{ room.roomsAvailable }}
                                        </div>
                                    </td>
                                    <td class="text-center">
                                        <div :class="{ 'bg-danger text-white': room.roomsAvailableChannel > room.roomsAvailable && room.roomsAvailableChannel != 0 || room.roomsAvailableChannel < 0 }">
                                            {{ room.roomsAvailableChannel }}
                                        </div>
                                    </td>
                                    <td class="text-center">
                                        <div :class="{ 'bg-danger text-white': room.roomsAvailablePMS > room.roomsAvailable && room.roomsAvailablePMS != 0 || room.roomsAvailablePMS < 0 }">
                                            {{ room.roomsAvailablePMS }}
                                        </div>
                                    </td>
                                    <td class="text-center">
                                        <div :class="{ 'bg-danger text-white': room.numRooms < room.numBookings }">{{ room.numBookings }}</div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div>
                        <ul style="font-size: 0.8em;">
                            <li><strong>Totales:</strong> número total de habitaciones que tiene el hotel para un determinado tipo de habitación.</li>
                            <li><strong>Disponibles:</strong> número total de habitaciones que tiene el hotel disponibles para un determinado tipo de habitación.</li>
                            <li><strong>Channel:</strong> número total de habitaciones puestas a la venta en el Channel Manager para un determinado tipo de habitación.</li>
                            <li><strong>PMS:</strong> número total de habitaciones puestas a la venta (PMS = Nº disponibles - Nº Channel) para un determinado tipo de habitación.</li>
                            <li><strong>Ocupadas:</strong> número total de habitaciones ya reservadas para un determinado tipo de habitación.</li>
                        </ul>
                        <div v-if="isOverbookingActive || isSuperadmin || isAdmin">
                            <div><i class="fa-solid fa-circle-exclamation text-warning me-2" style="margin-left: 10px;"></i><strong>Overbooking parcial:</strong> Se están vendiendo
                                más habitaciones de las debidas.</div>
                            <div> <i class="fa-solid fa-circle-exclamation text-danger me-2" style="margin-left: 10px;"></i><strong>Overbooking total:</strong> No hay habitaciones
                                disponibles.</div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="showPreviousDay" :disabled="currentIndex === 0">Anterior</button>
                    <button type="button" class="btn btn-secondary" @click="showNextDay" :disabled="currentIndex === availabilityByDay.length - 1">Siguiente</button>
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        currentItem: Object,
        currentIndex: Number,
        availabilityByDay: Array,
    },
    computed: {
        isOverbookingActive() {
            return this.$store.state.hotel.hotelModules.some(
                (module) => module.key === "module_overbooking"
            );
        },
        userRoles() {
            return this.$store.getters['user/userRoles'];
        },
        isSuperadmin() {
            return this.userRoles.includes('pms_superadmin');
        },
        isAdmin() {
            return this.userRoles.includes('pms_admin');
        },
    },
    methods: {
        showPreviousDay() {
            this.$emit('showPreviousDay', this.currentIndex);
        },
        showNextDay() {
            this.$emit('showNextDay', this.currentIndex);
        },
    },
};
</script>
