import { createStore } from "vuex";
import { auth } from "./auth.module";
import { user } from "./user.module";
import { hotel } from "./hotel.module";
import HotelService from "@/services/hotel.service.js";

const store = createStore({
    modules: {
        auth,
        user,
        hotel,
    },
    actions: {
        async initializeStore({ dispatch, state }) {
            let hotelModules = localStorage.getItem("hotelModules");
            if (!hotelModules && state.auth.status.loggedIn) {
                // Suponiendo que 'state.auth.isLoggedIn' es una bandera en tu store que indica si el usuario está autenticado
                hotelModules = await HotelService.getHotelModules();
                dispatch("hotel/updateHotelModules", hotelModules.data);
                localStorage.setItem(
                    "hotelModules",
                    JSON.stringify(hotelModules.data)
                );
            } else if (hotelModules) {
                dispatch("hotel/updateHotelModules", JSON.parse(hotelModules));
            }
        },
    },
});

export default store;
