import api from "./api";
import HotelService from "./hotel.service";

class CompanyService {
	async addCompany(data) {
		let hotel = HotelService.getLocalHotel();
		return await api
			.post("/companies/hotel/create/" + hotel.id + "/", data)
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return { status: error.response.status, data: error.response.data };
			});
	}

	async updateCompany(comopanyID, data) {
		return await api
			.put("/companies/hotel/update/" + comopanyID, data)
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return { status: error.response.status, data: error.response.data };
			});
	}

	async getAllCompanies(params) {
		let hotel = HotelService.getLocalHotel();
		return await api
			.get("/companies/hotel/list/" + hotel.id, { params: params })
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return { status: error.response.status, data: error.response.data };
			});
	}
	async searchCompanies(params) {
		let hotel = HotelService.getLocalHotel();
		return await api
			.get("/companies/hotel/list/" + hotel.id, { params: params })
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return { status: error.response.status, data: error.response.data };
			});
	}

	async getCompany(companyID) {
		return await api
			.get("/companies/hotel/single/" + companyID)
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return { status: error.response.status, data: error.response.data };
			});
	}
}

export default new CompanyService();
