<template>
    <div>
        <div v-if="!loading" class="booking-footer-button" data-bs-toggle="modal" data-bs-target="#modalSendEmailBooking">
            <i class="fa-regular fa-envelopes"></i>
            <div class="title">Email</div>
        </div>



        <!-- Modal Send Email -->
        <div class="modal fade" id="modalSendEmailBooking" tabindex="-1" aria-labelledby="modalSendEmailBooking" aria-hidden="true">
            <div class="modal-dialog modal-xl">
                <div class="modal-content p-2">
                    <div class="modal-header border-0">
                        <h5 class="modal-title">Enviar email</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div v-if="!loading" class="modal-body">
                        <div class="form-group mb-1">
                            <MazInputTags v-model="recipient_list_to" label="Para:" color="primary" size="sm" :error="errors.recipient_list_to"
                                @update:model-value="check_recipient_list_to(recipient_list_to)" />
                        </div>
                        <div class="form-group mb-1">
                            <MazInputTags v-model="recipient_list_cc" label="CC:" color="primary" size="sm" @update:model-value="check_recipient_list_cc(recipient_list_cc)" />
                        </div>
                        <div class="form-group mb-1">
                            <MazInputTags v-model="recipient_list_bcc" label="BCC:" color="primary" size="sm" @update:model-value="check_recipient_list_bcc(recipient_list_bcc)" />
                        </div>
                        <div class="form-group mb-1">
                            <MazInput v-model="subject" label="Asunto:" color="primary" />
                        </div>


                        <EmailEditor ref="emailEditor" v-on:load="editorLoaded" v-on:ready="editorReady" v-on:updated="editorUpdated" />

                        <div class="alert alert-light" role="alert">
                            <h6>Archivos adjuntos</h6>
                            <div class="btn btn-sm badge-light-primary"><i class="fa-regular fa-file-pdf me-2"></i>{{ "Booking #" +
            booking.bookingId }}
                            </div>
                        </div>

                    </div>
                    <div class="modal-footer border-0">
                        <a type="button" class="btn btn-link text-decoration-none text-primary" data-bs-dismiss="modal">Cerrar</a>
                        <button id="submitSendEmail" type="submit" class="btn btn-primary" @click.prevent.stop="sendEmail">
                            <i class="fa-regular fa-paper-plane me-2"></i>Enviar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import HotelService from "@/services/hotel.service";
import InfoService from "@/services/info.service";
import BookingService from "@/services/booking.service";

import Swal from "sweetalert2";

import { EmailEditor } from "vue-email-editor";

import { Modal } from "bootstrap";

export default {
    name: "EmailsBooking",
    props: {
        booking: Object,
    },
    components: {
        EmailEditor,
    },
    data() {
        return {
            recipient_list_to: [],
            recipient_list_cc: [],
            recipient_list_bcc: [],
            loading: true,
            subject: null,
            html_content: null,
            hotel: HotelService.getLocalHotel(),
            info: null,
            error: false,
            myModal: null,
            errors: {}

        };
    },
    methods: {
        async start() {
            this.loading = true;
            await this.getInfo();

            var content = JSON.parse(this.info.content);
            this.html_content = content["html"];

            if (this.booking && this.booking.email) {
                this.recipient_list_to.push(this.booking.email)
            }


            this.subject = this.hotel.name + " ~ Reserva #" + this.booking.bookingId

            this.loading = false;
        },

        async getInfo() {
            var response = await InfoService.getInfo({
                key_name: "email-confirmed",
            });
            this.info = response.data;
            // console.log(this.info, "Info")
        },

        editorLoaded() {
            // console.log("editorLoaded");
            // Pass the template JSON here
            var content = JSON.parse(this.info.content);
            this.$refs.emailEditor.editor.loadDesign(content["design"]);


            this.$refs.emailEditor.editor.setMergeTags({
                hotel_name: {
                    name: "Hotel Name",
                    value: "{{hotel_name}}",
                },
                booking_number: {
                    name: "Booking Number",
                    value: "{{booking_number}}",
                },
                first_name: {
                    name: "First Name",
                    value: "{{first_name}}",
                },
                hotel_logo: {
                    name: "Hotel Logo",
                    value: "{{hotel_logo}}",
                },
                checkin_date: {
                    name: "Checkin Date",
                    value: "{{checkin_date}}",
                },
                checkout_date: {
                    name: "Checkout Date",
                    value: "{{checkout_date}}",
                },

                booking_price: {
                    name: "Booking Price",
                    value: "{{booking_price}}",
                },

                rooms_info: {
                    name: "Rooms Info",
                    value: "{{rooms_info|safe}}",
                },
            })
        },
        // called when the editor has finished loading
        editorReady() {
            // console.log("editorReady");
            this.$refs.emailEditor.editor.registerCallback('design:updated', (data) => {
                // Aquí manejas el evento de actualización del diseño.
                // var type = data.type; // body, row, content
                // var item = data.item;
                // var changes = data.changes;
                // console.log('design:updated', type, item, changes);

                this.editorUpdated()

                // Aquí puedes realizar acciones adicionales, como auto guardado o actualizaciones de estado.
            });
        },

        editorUpdated() {
            this.exportHtml()
        },


        check_recipient_list_to(newEmails) {
            // Define la expresión regular para validar el formato de correo electrónico
            const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            let invalidEmailsFound = false;

            // Filtra la lista para mantener solo los elementos que cumplen con el formato de correo electrónico
            // y detecta si hay correos inválidos
            this.recipient_list_to = newEmails.filter(email => {
                const isValid = emailRegex.test(email);
                if (!isValid) invalidEmailsFound = true;
                return isValid;
            });

            // Muestra un toast si se encontró al menos un correo inválido
            if (invalidEmailsFound) {
                Swal.fire({
                    toast: true,
                    icon: 'error',
                    title: 'El email introducido no es válido.',
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                });
            }
        },

        check_recipient_list_cc(newEmails) {
            // Define la expresión regular para validar el formato de correo electrónico
            const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            let invalidEmailsFound = false;

            // Filtra la lista para mantener solo los elementos que cumplen con el formato de correo electrónico
            // y detecta si hay correos inválidos
            this.recipient_list_cc = newEmails.filter(email => {
                const isValid = emailRegex.test(email);
                if (!isValid) invalidEmailsFound = true;
                return isValid;
            });

            // Muestra un toast si se encontró al menos un correo inválido
            if (invalidEmailsFound) {
                Swal.fire({
                    toast: true,
                    icon: 'error',
                    title: 'El email introducido no es válido.',
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                });
            }
        },

        check_recipient_list_bcc(newEmails) {
            // Define la expresión regular para validar el formato de correo electrónico
            const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            let invalidEmailsFound = false;

            // Filtra la lista para mantener solo los elementos que cumplen con el formato de correo electrónico
            // y detecta si hay correos inválidos
            this.recipient_list_bcc = newEmails.filter(email => {
                const isValid = emailRegex.test(email);
                if (!isValid) invalidEmailsFound = true;
                return isValid;
            });

            // Muestra un toast si se encontró al menos un correo inválido
            if (invalidEmailsFound) {
                Swal.fire({
                    toast: true,
                    icon: 'error',
                    title: 'El email introducido no es válido.',
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                });
            }
        },


        async sendEmail() {
            this.errors = {}
            if (this.recipient_list_to.length == 0) {
                this.errors.recipient_list_to = true
                this.error = true
            }

            if (this.error == true) {
                return;
            } else {

                Swal.fire({
                    title: 'Enviando...',
                    didOpen: () => {
                        Swal.showLoading();
                    },
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    showConfirmButton: false
                });

                var response = await BookingService.sendEmailBooking(this.booking.id, {
                    subject: this.subject,
                    recipient_list_to: this.recipient_list_to,
                    recipient_list_cc: this.recipient_list_cc,
                    recipient_list_bcc: this.recipient_list_bcc,
                    html_content: this.html_content,
                })

                Swal.close();

                if (response.status == 200) {
                    Swal.fire({
                        icon: "success",
                        title: "Correo enviado",
                        confirmButtonColor: "#0091ff",
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.myModal.hide();
                        }
                    });
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Ha ocurrido un error. " + response.data.message,
                        confirmButtonColor: "#0091ff",
                    });
                }
            }
        },

        async exportHtml() {

            await this.$refs.emailEditor.editor.exportHtml(
                (data) => {

                    // console.log('exportHtml', data);
                    this.html_content = data.html
                }
            )
        }
    },
    created() {
        this.start();
    },
    mounted() {
        this.myModal = new Modal(document.getElementById('modalSendEmailBooking'));
    }
};
</script>