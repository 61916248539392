import api from "./api";
import HotelService from "./hotel.service";

class RateService {
    /**************************************
     *  API HOSPEDIUM
     **************************************/
    addRate(params) {
        return api.post("/rates", params).then((response) => {
            return { status: response.status, data: response.data };
        });
    }

    updateRate(id, params) {
        return api.put("/rates/" + id, params).then((response) => {
            return { status: response.status, data: response.data };
        });
    }

    async getAllRates() {
        let hotel = HotelService.getLocalHotel();
        return await api.get("/rates?hotel=" + hotel.id).then((response) => {
            return Object.freeze({
                status: response.status,
                data: response.data,
            });
        });
    }

    async getActiveRates() {
        let hotel = HotelService.getLocalHotel();
        return await api
            .get("/rates/active?hotel=" + hotel.id)
            .then((response) => {
                return Object.freeze({
                    status: response.status,
                    data: response.data,
                });
            });
    }

    async getRate(id) {
        return await api.get("/rates/" + id).then((response) => {
            return { status: response.status, data: response.data };
        });
    }
}

export default new RateService();
