import api from './api';

class RegionService { 
    

    /**************************************
     *  API HOSPEDIUM
     **************************************/

    async getAllRegions() {
        return await api.get('/regions')
        .then((response) => {
            return {status: response.status, data: response.data};
        })
        .catch(function (error) {
            return {status: error.response.status, data: error.response.data};
        });
    }
    
    getRegion(id) {
        return api.get('/regions/' + id)
        .then((response) => {
            return {status: response.status, data: response.data};
        })
        .catch(function (error) {
            return {status: error.response.status, data: error.response.data};
        });
    }
}

export default new RegionService();