<template>
    <div>
        <!-- Botón para abrir el modal -->
        <button class="btn btn-primary w-100" @click="openModal">
            Asociar pago existente
        </button>

        <!-- Modal para seleccionar el pago -->
        <div class="modal fade" id="assignPaymentModal" tabindex="-1" aria-labelledby="assignPaymentModalLabel" aria-hidden="true" ref="assignPaymentModal">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="assignPaymentModalLabel">Seleccionar Pago</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="mb-3">
                            <label for="paymentSelect" class="form-label">Selecciona un pago existente</label>
                            <MazSelect v-model="selectedPayment" :options="paymentOptions" placeholder="Selecciona un pago" />
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                        <button type="button" class="btn btn-primary" @click="assignPayment">Asignar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import PaymentService from '@/services/payment.service';

export default {
    name: 'AssignPayment',
    emits: ['payment-assigned'],
    props: {
        invoice: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            selectedPayment: null,  // Variable para almacenar el pago seleccionado
            paymentOptions: [],      // Opciones de pagos disponibles
        };
    },
    methods: {
        openModal() {
            const modal = new this.$bootstrap.Modal(this.$refs.assignPaymentModal);
            modal.show();
        },
        async loadPayments() {
            try {
                const bookingId = this.invoice.booking; // Extraer bookingId desde invoice.booking
                const response = await PaymentService.getBookingPayments(bookingId);
                let payments = response.data;

                console.log(payments, "payments");

                // Si payments no es un array, ajusta la asignación según la estructura real
                if (!Array.isArray(payments)) {
                    payments = payments.paymentsList || []; // Ajustar según la estructura de response.data
                }

                // Filtrar los pagos que no tienen factura asociada (invoice = null)
                this.paymentOptions = payments
                    .filter(payment => payment.invoice === null)
                    .map(payment => ({
                        value: payment.id,
                        label: `Pago #${payment.id} - ${payment.quantity} ${payment.currency}`
                    }));

            } catch (error) {
                console.error('Error al cargar los pagos:', error);
            }
        },
        async assignPayment() {
            if (this.selectedPayment) {
                this.$swal.fire({
                    title: 'Guardando...',
                    text: 'Por favor espera mientras se guarda la asignación.',
                    icon: 'info',
                    allowOutsideClick: false,
                    showConfirmButton: false,
                    willOpen: () => {
                        this.$swal.showLoading();
                    }
                });

                try {
                    const response = await PaymentService.assignPaymentToInvoice(
                        this.selectedPayment, {
                        invoice_id: this.invoice.id
                    });

                    if (response.status == 200) {
                        this.$swal.fire({
                            title: 'Éxito',
                            text: 'El pago ha sido asignado correctamente.',
                            icon: 'success',
                            confirmButtonText: 'OK',
                            confirmButtonColor: '#0091ff',
                        }).then(() => {
                            this.$router.go(0); // Recarga la ruta actual
                        });
                    } else {
                        this.$swal.fire({
                            title: 'Error',
                            text: 'Ocurrió un error al asignar el pago. Por favor, intenta nuevamente.',
                            icon: 'error',
                            confirmButtonText: 'OK',
                            confirmButtonColor: '#0091ff',
                        });
                    }
                } catch (error) {
                    console.error('Error al asignar el pago:', error);
                    this.$swal.fire({
                        title: 'Error',
                        text: 'Ocurrió un error al asignar el pago. Por favor, intenta nuevamente.',
                        icon: 'error',
                        confirmButtonText: 'OK',
                        confirmButtonColor: '#0091ff',
                    });
                }
            } else {
                // Si no se seleccionó ningún pago, mostrar un mensaje de advertencia.
                this.$swal.fire({
                    icon: 'warning',
                    title: 'No se ha seleccionado un pago',
                    text: 'Por favor selecciona un pago antes de continuar.',
                    confirmButtonColor: '#0091ff',
                });
            }
        },
        closeModal() {
            const modal = this.$bootstrap.Modal.getInstance(this.$refs.assignPaymentModal);
            modal.hide();
        }
    },
    created() {
        this.loadPayments();  // Cargar las opciones de pagos cuando el componente es creado
    }
};
</script>

<style scoped>
/* Estilos específicos para el componente si es necesario */
</style>