<template>
    <div>
        <button class="btn btn-primary me-2" data-bs-toggle="modal" data-bs-target="#modalAddCompany">
            <i class="fa-regular fa-plus me-2"></i>Añadir empresa
        </button>

        <!-- Modal Add Company -->
        <div class="modal fade" id="modalAddCompany" tabindex="-1"
            aria-labelledby="modalAddCompanyTitle" aria-hidden="true">
            <div class="modal-dialog modal-fullscreen">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="modalAddCompanyTitle">Nueva empresa</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal"
                            aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="row mb-5">
                            <div class="col-md-6 mb-4">
                                <div class="mb-3 fs-4" style="font-weight: 500">
                                    Información empresa
                                </div>
                                <div class="mb-2">
                                    <MazInput id="social_reason" v-model="company.social_reason"
                                        label="Razón Social" autocomplete="none"
                                        :error="errors.social_reason" required>
                                    </MazInput>
                                </div>
                                <div class="mb-2">
                                    <MazInput id="cif" v-model="company.cif" label="CIF"
                                        autocomplete="none" :error="errors.cif">
                                    </MazInput>
                                </div>
                                <div class="mb-2">
                                    <MazInput id="email" v-model="company.email" label="Email"
                                        autocomplete="none" type="email" :error="errors.email">
                                        <template #left-icon>
                                            <i class="fa-regular fa-envelope"></i>
                                        </template>
                                    </MazInput>
                                </div>
                                <div class="mb-5">
                                    <MazPhoneNumberInput v-model="company.phone" show-code-on-list
                                        :translations="{
                                            countrySelector: {
                                                placeholder: 'Código país',
                                                error: 'Elige país',
                                            },
                                            phoneInput: {
                                                placeholder: 'Nº teléfono',
                                                example: 'Ej:',
                                            },
                                        }" :no-example="true" :error="errors.phone">
                                    </MazPhoneNumberInput>
                                </div>
                                <div class="row">
                                    <h6>Tipo:</h6>
                                    <div class="mb-2 col-12">
                                        <MazCheckbox v-model="company.isCompany">Es empresa
                                        </MazCheckbox>
                                    </div>
                                    <div class="mb-2 col-12">
                                        <MazCheckbox v-model="company.isAgency">Es agencia</MazCheckbox>
                                    </div>
                                </div>

                            </div>
                            <div class="col-md-6 mb-4">
                                <div class="mb-3 fs-4" style="font-weight: 500">
                                    Datos facturación
                                </div>
                                <div class="mb-2">
                                    <MazSelect id="country" v-model="company.tax_country"
                                        label="Seleccionar país" :options="countries"
                                        option-value-key="id" option-label-key="name"
                                        option-input-value-key="name" search
                                        search-placeholder="Buscar..." :error="errors.country">
                                    </MazSelect>
                                </div>
                                <div class="mb-2" v-if="company.tax_country == 66">
                                    <MazSelect id="tax_region" v-model="company.tax_region"
                                        label="Provincia" :options="regions" option-value-key="id"
                                        option-label-key="name" option-input-value-key="name" search
                                        search-placeholder="Buscar..." :error="errors.tax_region">
                                        <template #left-icon>
                                            <MazIcon name="flag" />
                                        </template>
                                    </MazSelect>
                                </div>
                                <div class="mb-2">
                                    <MazInput id="tax_address" v-model="company.tax_address"
                                        label="Dirección" autocomplete="none"
                                        :error="errors.tax_address">
                                        <template #left-icon>
                                            <i class="fa-regular fa-location-dot"></i>
                                        </template>
                                    </MazInput>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-8">
                                        <MazInput id="locality" v-model="company.locality"
                                            label="Ciudad" autocomplete="none" :error="errors.locality">
                                        </MazInput>
                                    </div>
                                    <div class="col-4">
                                        <MazInput id="postal_code" v-model="company.postal_code"
                                            label="Código Postal" autocomplete="none"
                                            :error="errors.postal_code">
                                        </MazInput>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary"
                            data-bs-dismiss="modal">Cerrar</button>
                        <button id="submitAddCompany" type="submit" class="btn btn-primary"
                            @click.prevent.stop="addCompany">Guardar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CompanyService from "@/services/company.service";
import CountryService from "@/services/country.service";
import RegionService from "@/services/region.service";
import { Modal } from "bootstrap";
import Swal from 'sweetalert2'

export default {
    name: 'AddCompany',
    emits: [
        "reloadClients"
    ],
    data() {
        return {
            loading: true,
            company: {},
            errors: {},
            countries: [],
            regions: [],
            myModal: null,
            error: false
        }
    },
    methods: {
        async start() {
            this.loading = true;
            await this.getCountries()
            await this.getRegions()
            this.loading = false;
        },
        async getCountries() {
            this.countries = await CountryService.getAllCountries();
        },

        async getRegions() {
            var response = await RegionService.getAllRegions();
            this.regions = response.data;
        },
        resetErrors() {
            this.errors = {};
        },

        async addCompany() {
            this.resetErrors();

            var now = new Date();
            var error = false;

            // COMPROBAMOS EL CAMPO social_reason
            if (
                this.company.social_reason == undefined ||
                this.company.social_reason == ""
            ) {
                this.errors.social_reason = true;
                error = true;
            }


            if (error == true) {
                return;
            } else {
                Swal.fire({
                    title: 'Guardando empresa...',
                    text: 'Por favor, espera mientras se crea la ficha de empresa.',
                    didOpen: () => {
                        Swal.showLoading();
                    },
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    showConfirmButton: false
                });

                var response = await CompanyService.addCompany(this.company);
                Swal.close();

                if (response.status == 201) {
                    this.company = {}

                    Swal.fire({
                        icon: "success",
                        title: "Empresa creada correctamente",
                        confirmButtonColor: "#0091ff",
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.myModal.hide();
                            this.$emit('reloadClients');
                        }
                    });
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Ha ocurrido un error. " + response.data.message,
                        confirmButtonColor: "#0091ff",
                    });
                }
            }
        },
    },
    created() {
        this.start();
    },
    mounted() {
        this.myModal = new Modal(document.getElementById('modalAddCompany'));
    }
}
</script>