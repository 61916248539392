import api from "./api";
import HotelService from "./hotel.service";

class UserService {
	/**************************************
	 *  LOCAL STORAGE
	 **************************************/

	getLocalUser() {
		const user = JSON.parse(localStorage.getItem("user"));
		return user;
	}

	async updateLocalUser(id) {
		let user = await this.getCurrentUser(id);
		this.removeLocalUser();
		this.setLocalUser(user);
	}

	setLocalUser(user) {
		localStorage.setItem("user", JSON.stringify(user));
	}

	removeLocalUser() {
		localStorage.removeItem("user");
	}
	// Testing
	async setCurrentLocalUser() {
		let user = await this.getCurrentUser();
		this.setLocalUser(user);
	}

	/**************************************
	 *  API HOSPEDIUM
	 **************************************/

	async getCurrentUser() {
		return await api.get("/users/me").then(async (response) => {
			if (response.data.id) {
				var roles = await this.getCurrentUserRoles();
				response.data.roles = roles.data;

				// Guardamos la info del usuario en variable local "user"
				return response.data;
			}
		});
	}

	async getHotelUsers() {
		let hotel = HotelService.getLocalHotel();
		let params = {
			hotel: hotel.id,
			platform: 1,
		};
		return await api
			.get("/users/hotel/list", { params: params })
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return { status: error.response.status, data: error.response.data };
			});
	}

	async getUsersRoles() {
		return await api
			.get("/users/roles/list")
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return { status: error.response.status, data: error.response.data };
			});
	}

	async createUser(data) {
		let hotel = HotelService.getLocalHotel();
		data.hotels = [hotel.id];
		return await api
			.post("/users/create/", data)
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return { status: error.response.status, data: error.response.data };
			});
	}

	async updateUser(pk, data) {
		let hotel = HotelService.getLocalHotel();
		var params = {
			hotel: hotel.id,
			platform: 1,
		};
		return await api
			.put("/users/" + pk, data, { params: params })
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return { status: error.response.status, data: error.response.data };
			});
	}

	async deleteUser(pk) {
		let hotel = HotelService.getLocalHotel();
		var params = {
			hotel: hotel.id,
			platform: 1,
		};
		return await api
			.delete("/users/" + pk, { params: params })
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return { status: error.response.status, data: error.response.data };
			});
	}

	async getCurrentUserRoles() {
		return await api
			.get("/users/me/roles")
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return { status: error.response.status, data: error.response.data };
			});
	}
}

export default new UserService();
