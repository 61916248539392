<template>
    <div class="h-100">
        <div class="booking-footer-button" data-bs-toggle="modal" data-bs-target="#modalGuests"
            style="position: relative">
            <i class="fa-regular fa-users-viewfinder"></i><span class="badge badge-light-primary"
                style="padding: 0 5px; position: absolute; top: 8%; right: 20%;">{{ guests.length
                }}</span>
            <div class="title">Huéspedes</div>
        </div>

        <!-- Modal Guests -->
        <div v-if="!loading" class="modal fade" id="modalGuests" tabindex="-1"
            aria-labelledby="modalGuests" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                <div class="modal-content p-3">
                    <div class="modal-header border-0">
                        <div class="modal-title mb-0 d-flex align-items-center">
                            <i class="fa-regular fa-users-viewfinder fs-3 me-3 text-primary"></i>
                            <div class="fs-4" style="font-weight: 500">
                                Huéspedes registrados
                            </div>
                        </div>
                        <button type="button" class="btn-close" data-bs-dismiss="modal"
                            aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div v-if="guests.length > 0" class="table-responsive"
                            style="padding-bottom: 200px">
                            <table class="table table-row-dashed">
                                <thead>
                                    <tr>
                                        <th>Huésped</th>
                                        <th>Habitación</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr :key="item" v-for="item in guests">
                                        <td>
                                            <div>
                                                {{ item.client_hotel.firstName ?
                    item.client_hotel.firstName : '' }}

                                                {{ item.client_hotel.lastName1 ?
                    item.client_hotel.lastName1 : '' }}
                                                {{ item.client_hotel.lastName2 ?
                    item.client_hotel.lastName2 : '' }}
                                            </div>
                                            <div>
                                                <small>
                                                    Registrado:
                                                    {{
                    formatDateHour(
                        item.dateCreated
                    )
                                                    }}
                                                </small>
                                            </div>
                                        </td>
                                        <td>
                                            <MazSelect v-model="item.room"
                                                label="Asignar habitación" :options="rooms"
                                                option-value-key="id" option-label-key="name"
                                                option-input-value-key="name" @update:model-value="
                    handleRoomChange(
                        item.id,
                        $event
                    )
                    " />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div v-else>No hay huéspedes registrados</div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-link text-primary"
                            data-bs-dismiss="modal">
                            Cerrar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { format, parseISO } from "date-fns";
import { es } from "date-fns/locale";
import Swal from "sweetalert2";

import BookingService from "@/services/booking.service";

export default {
    name: "ModalGuestsBooking",
    props: {
        booking: Object,
        guests: Array,
    },
    data() {
        return {
            loading: true,
            rooms: [],
        };
    },
    methods: {
        async start() {
            this.loading = true;
            // console.log("Guests", this.guests);
            await this.getBookingRoomsSelected();
            this.loading = false;
        },

        async getBookingRoomsSelected() {
            var response = await BookingService.getBookingRoomsSelected(
                this.booking.id
            );
            this.rooms = response.data;
            this.rooms.push({
                id: null,
                name: "Sin asignar",
            });
            // console.log(this.rooms, "Rooms");
        },

        handleRoomChange(guestId, selectedRoomId) {
            // Encuentra el índice de la habitación seleccionada
            const roomIndex = this.rooms.findIndex(
                (room) => room.id === selectedRoomId
            );

            // Llama a tu método original con el índice encontrado
            this.assignRoomToGuest(guestId, roomIndex);
        },

        async assignRoomToGuest(guestId, roomIndex) {
            var room_selected = this.rooms[roomIndex];
            var response = await BookingService.assignRoomToGuest(guestId, {
                room: room_selected.id,
                bookingroom: room_selected.bookingroom,
            });

            if (response.status == 200) {
                Swal.fire({
                    position: "top-end",
                    toast: true,
                    icon: "success",
                    title: "Cambios guardados correctamente.",
                    showConfirmButton: false,
                    timer: 1500,
                });
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Ha ocurrido un error. Vuelve a intentarlo. Si el error persiste ponte en contacto con soporte@hospedium.com",
                    confirmButtonColor: "#0091ff",
                });
            }
        },

        formatDateHour(originalDate) {
            const date = parseISO(originalDate);
            return format(date, "d MMM yyyy 'a las' HH:mm'h'", { locale: es });
        },
    },
    created() {
        this.start();
    },
};
</script>


<style scoped>
.table:not(.table-bordered).table-row-dashed tr {
    border-bottom-width: 1px;
    border-bottom-style: dashed;
    border-bottom-color: var(--bs-border-color);
}

td {
    border: none !important;
}

th {
    text-transform: uppercase;
    font-weight: 500 !important;
}

.btn-view-guest .badge {
    padding: 0 5px;
}
</style>