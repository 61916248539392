import api from "./api";

class PMSService {
	async getCurrentPMSVersion() {
		return await api
			.get("/version")
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return { status: error.response.status, data: error.response.data };
			});
	}

	async getAllPMSVersions() {
		return await api
			.get("/version/log")
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return { status: error.response.status, data: error.response.data };
			});
	}
}

export default new PMSService();
