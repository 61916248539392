import { createWebHistory, createRouter } from "vue-router";
import store from "@/store";
import HotelService from "@/services/hotel.service";
import UserService from "@/services/user.service";

import Login from "@/views/Login.vue";
import ResetPasswordView from "@/views/auth/ResetPasswordView.vue";

import Planning from "@/views/planning/Planning.vue";

// Checkin Checkout
import CheckinCheckoutList from "@/views/checkin-checkout/CheckinCheckoutList.vue";

import Clients from "@/views/clients/Clients.vue";
import Client from "@/views/clients/Client.vue";
import Company from "@/views/clients/Company.vue";
import Channel from "@/views/channel/Channel.vue";
import Invoices from "@/views/invoices/Invoices.vue";
import Invoice from "@/views/invoices/Invoice.vue";

// Bookings Views
import Booking from "@/views/bookings/Booking.vue";
import CreateBooking from "@/views/bookings/CreateBooking.vue";
import ViewBooking from "@/views/bookings/ViewBooking.vue";
import Checkin from "@/views/bookings/Checkin.vue";

// Reporting Views
import Reporting from "@/views/reporting/Reporting.vue";
import ReportingCheckin from "@/views/reporting/ReportingCheckin.vue";
import ReportingCheckout from "@/views/reporting/ReportingCheckout.vue";
import ReportingSales from "@/views/reporting/ReportingSales.vue";
import ReportingSalesByPayment from "@/views/reporting/ReportingSalesByPayment.vue";
import ReportingProductionByChannel from "@/views/reporting/ReportingProductionByChannel.vue";
import ReportingPayments from "@/views/reporting/ReportingPayments.vue";
import ReportingINE from "@/views/reporting/ReportingINE.vue";
import ReportingGuests from "@/views/reporting/ReportingGuests.vue";
import ReportingBreakfast from "@/views/reporting/ReportingBreakfast.vue";
import ReportingParking from "@/views/reporting/ReportingParking.vue";

// Importa las rutas
import RoomRoutes from "./routes/room";
import ReportingRoutes from "./routes/reporting";
import GuestRoutes from "./routes/guest";
import SettingsRoutes from "./routes/settings";
import OthersRoutes from "./routes/others";

// lazy-loaded
const Profile = () => import("@/views/Profile.vue");
const BoardUser = () => import("@/views/BoardUser.vue");

const routes = [
	{
		path: "/",
		name: "home",
		component: Planning,
		beforeEnter: () => {
			localStorage.setItem("menu", "menu-planning");
		},
	},
	{
		path: "/planning",
		name: "planning",
		component: Planning,
		beforeEnter: () => {
			localStorage.setItem("menu", "menu-planning");
		},
	},
	{
		path: "/checkin-checkout",
		name: "Checkin Checkout List",
		component: CheckinCheckoutList,
		beforeEnter: () => {
			localStorage.setItem("menu", "menu-checkin-checkout");
		},
	},

	{
		path: "/bookings",
		name: "bookings",
		component: Booking,
		beforeEnter: () => {
			localStorage.setItem("menu", "menu-bookings");
		},
	},
	{
		path: "/bookings/create",
		name: "New Booking",
		component: CreateBooking,
		beforeEnter: () => {
			localStorage.setItem("menu", "menu-bookings");
		},
	},

	{
		path: "/bookings/view/:bookingId",
		name: "ViewBooking",
		component: ViewBooking,
		beforeEnter: () => {
			localStorage.setItem("menu", "menu-bookings");
		},
	},
	{
		path: "/bookings/checkin/:bookingroomId",
		name: "checkin",
		component: Checkin,
		beforeEnter: () => {
			localStorage.setItem("menu", "menu-bookings");
		},
	},
	{
		path: "/clients",
		name: "clients",
		component: Clients,
		beforeEnter: () => {
			localStorage.setItem("menu", "menu-clients");
		},
	},
	{
		path: "/clients/:clientId",
		name: "client",
		component: Client,
		beforeEnter: () => {
			localStorage.setItem("menu", "menu-clients");
		},
	},
	{
		path: "/company/:companyId",
		name: "company",
		component: Company,
		beforeEnter: () => {
			localStorage.setItem("menu", "menu-clients");
		},
	},
	{
		path: "/invoices/",
		name: "invoices",
		component: Invoices,
		beforeEnter: () => {
			localStorage.setItem("menu", "menu-invoices");
		},
	},
	{
		path: "/invoices/:invoiceId",
		name: "invoice",
		component: Invoice,
		beforeEnter: () => {
			localStorage.setItem("menu", "menu-invoices");
		},
	},
	{
		path: "/channel",
		name: "channel",
		component: Channel,
		beforeEnter: () => {
			localStorage.setItem("menu", "menu-channel");
		},
	},
	{
		path: "/reporting",
		name: "Reporting",
		component: Reporting,
		beforeEnter: () => {
			localStorage.setItem("menu", "menu-reporting");
		},
	},
	{
		path: "/reporting/checkin",
		name: "ReportingCheckin",
		component: ReportingCheckin,
		beforeEnter: () => {
			localStorage.setItem("menu", "menu-reporting");
		},
	},
	{
		path: "/reporting/checkout",
		name: "ReportingCheckout",
		component: ReportingCheckout,
		beforeEnter: () => {
			localStorage.setItem("menu", "menu-reporting");
		},
	},
	{
		path: "/reporting/sales",
		name: "ReportingSales",
		component: ReportingSales,
		beforeEnter: () => {
			localStorage.setItem("menu", "menu-reporting");
		},
	},
	{
		path: "/reporting/sales_by_payment",
		name: "ReportingSalesByPayment",
		component: ReportingSalesByPayment,
		beforeEnter: () => {
			localStorage.setItem("menu", "menu-reporting");
		},
	},
	{
		path: "/reporting/breakfast",
		name: "ReportingBreakfast",
		component: ReportingBreakfast,
		beforeEnter: () => {
			localStorage.setItem("menu", "menu-reporting");
		},
	},
	{
		path: "/reporting/parking",
		name: "ReportingParking",
		component: ReportingParking,
		beforeEnter: () => {
			localStorage.setItem("menu", "menu-reporting");
		},
	},
	{
		path: "/reporting/payments",
		name: "ReportingPayments",
		component: ReportingPayments,
		beforeEnter: () => {
			localStorage.setItem("menu", "menu-reporting");
		},
	},
	{
		path: "/reporting/production_by_channel",
		name: "ReportingProductionByChannel",
		component: ReportingProductionByChannel,
		beforeEnter: () => {
			localStorage.setItem("menu", "menu-reporting");
		},
	},

	{
		path: "/reporting/ine",
		name: "ReportingINE",
		component: ReportingINE,
		beforeEnter: () => {
			localStorage.setItem("menu", "menu-reporting");
		},
	},
	{
		path: "/reporting/guests",
		name: "ReportingGuests",
		component: ReportingGuests,
		beforeEnter: () => {
			localStorage.setItem("menu", "menu-reporting");
		},
	},

	{
		path: "/login",
		component: Login,
	},
	{
		path: "/password/reset",
		component: ResetPasswordView,
	},
	{
		path: "/profile",
		name: "profile",
		// lazy-loaded
		component: Profile,
	},
	{
		path: "/user",
		name: "user",
		// lazy-loaded
		component: BoardUser,
	},
];

routes.push(...RoomRoutes);
routes.push(...ReportingRoutes);
routes.push(...GuestRoutes);
routes.push(...SettingsRoutes);
routes.push(...OthersRoutes);

const router = createRouter({
	history: createWebHistory(),
	routes,
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition;
		} else {
			return { top: 0 };
		}
	},
});

export default router;

router.beforeEach(async (to, from, next) => {
	const publicPages = ["/login", "/password/reset"];
	const loggedIn = localStorage.getItem("auth");

	// Si intenta acceder a una página pública y ya está logueado, redirige a home
	if (publicPages.includes(to.path)) {
		return loggedIn ? next("/") : next();
	}

	// Si intenta acceder a una página restringida y no está logueado, redirige a login
	if (!loggedIn) {
		return next("/login");
	}

	// Elimina el modal background si existe
	let modalBackground = document.querySelector(".modal-backdrop");
	if (modalBackground) {
		modalBackground.remove();
	}

	try {
		// Carga los roles solo si es necesario
		let userRoles = store.getters["user/userRoles"];
		if (!userRoles || userRoles.length === 0) {
			await store.dispatch("user/fetchUserRoles");
			userRoles = store.getters["user/userRoles"];
		}

		// Restricción basada en roles
		if (userRoles.includes("pms_hotel_housekeeping") && to.path !== "/rooms") {
			return next("/rooms");
		}

		// Lógica para cargar módulos de hotel
		let hotelModules = await HotelService.getHotelModules();
		store.dispatch("hotel/updateHotelModules", hotelModules.data);

		next(); // Continúa con la navegación normal si no hay restricciones
	} catch (error) {
		console.error("Error in navigation guard:", error);
		next("/error"); // Redirige a una página de error o maneja como prefieras
	}
});
