<template>
    <div class="searchBar">
        <!-- Filter Search -->
        <div class="input-group mb-3">
            <input type="search" class="form-control" @keyup="search" placeholder="Buscar" v-model="searchWord">
        </div>
    </div>

    <div class="table-responsive mb-5">
        <table id="tableComponent" class="table table-bordered table-striped table-sm">
            <thead>
                <tr>
                    <!-- loop through each value of the fields to get the table header -->
                    <th  v-for="field in tableHeaders" :key='field' @click="sortTable(field)"  class="text-uppercase"> 
                        {{field}} <i class="fa-regular fa-arrow-down-arrow-up"></i>
                    </th>
                </tr>
            </thead>
            <tbody>
                <!-- Loop through the list get the each student data -->
                <tr v-for="item in updatedList" :key='item'>
                    <td v-for="field in tableHeaders" :key='field' class="text-uppercase">{{item[field]}}</td>
                </tr>
            </tbody>
        </table> 
    </div>
</template>

<script>
    import { sortBy} from 'lodash';

    export default {
        name: 'TableComponent',
        props:{
            tableData: Array,            
            tableHeaders: Array,
        }, 
        data () {
            return {
                sort: false,
                updatedList: this.tableData,
                searchWord: ''
            }
        },
        methods: {
            sortTable(col) {
                this.sort = true
                this.updatedList = sortBy(this.tableData, col)
                console.log(this.updatedList)
            },
            search() {

                var res = [];
                if (this.searchWord != '') {
                    res = this.updatedList.filter((obj) =>
                        JSON.stringify(obj).toLowerCase().includes(this.searchWord.toLowerCase())
                    );
                    this.updatedList = res
                } else {
                    this.updatedList = this.tableData
                }

            }
        },
    }
</script>