<template>
    <td class="align-items-center">
        <div class="d-flex">
            <button class="btn btn-light btn-active-light-primary me-2">
                <i class="fa-regular fa-pen-to-square"></i>
            </button>
            <div v-if="!loading">
                <div v-if="room != null" class="fw-bold">
                    {{ room.name }}
                </div>
                <div v-else>
                    <span class="badge badge-light-danger fw-bold">SIN ASIGNAR</span>
                </div>
                <div class="mt-1">{{ bookingroom.roomType.name }}</div>
            </div>
        </div>
    </td>
    <td class="align-items-center">
        <div v-if="rate != null && !loading">
            {{ rate.name }}<br />{{ formatCurrency(bookingroom.price, defaultCurrency) }}
        </div>
    </td>

    <td class="align-items-center">
        <span v-if="show_taxes">{{ formatCurrency(bookingroom.total_amount, defaultCurrency) }}</span>
        <span v-else>{{ formatCurrency(bookingroom.baserate_amount, defaultCurrency) }}<br><small><em>Sin impuestos</em></small></span>
    </td>

    <td class="align-items-center">
        <span v-if="bookingroom.status == 2" class="badge badge-light-primary me-1">Submitted</span>
        <span v-else-if="bookingroom.status == 4" class="badge badge-light-primary me-1">Confirmado</span>
        <span v-else-if="bookingroom.status == 5" class="badge badge-light-secondary me-1">Checkout</span>
        <span v-else-if="bookingroom.status == 6" class="badge badge-light-dark me-1">No Show</span>
        <span v-else-if="bookingroom.status == 7" class="badge badge-light-danger me-1">Cancelado</span>
        <span v-else-if="bookingroom.status == 8" class="badge badge-light-warning me-1">Modificado</span>
        <span v-else-if="bookingroom.status == 9" class="badge badge-light-success me-1">Checkin</span>
        <span v-else-if="bookingroom.status == 10" class="badge badge-light-secondary me-1">Checkout</span>
    </td>
    <td>
        <i class="fa-regular fa-person me-2"></i>{{ bookingroom.guests.length }}
    </td>
    <td>{{ convertDate(bookingroom.checkin) }}</td>
    <td>{{ convertDate(bookingroom.checkout) }}</td>
</template>

<script>
export default {
    name: "ViewBookingRoomRow",
    props: {
        bookingroom: Object,
        index: Number,
        show_taxes: Boolean
    },
    data() {
        return {
            loading: true,
            rate: null,
            room: null,
            defaultCurrency: ''
        };
    },
    methods: {
        async start() {
            this.loading = true;
            this.rate = this.bookingroom.rate;
            this.room = this.bookingroom.room;
            this.loading = false;
        },

        convertDate(dateStr) {
            if (!dateStr) {
                return;
            }

            const dateParts = dateStr.split("-");
            if (dateParts.length !== 3) {
                return;
            }

            const year = dateParts[0];
            const month = dateParts[1];
            const day = dateParts[2];

            return this.formatDate(year, month, day);
        },
        formatDate(year, month, day) {
            const months = [
                "Ene",
                "Feb",
                "Mar",
                "Abr",
                "May",
                "Jun",
                "Jul",
                "Ago",
                "Sep",
                "Oct",
                "Nov",
                "Dic",
            ];

            const monthName = months[parseInt(month, 10) - 1]; // Restamos 1 porque los meses son 0-indexados en JavaScript

            return `${monthName} ${day}, ${year}`;
        },
    },
    created() {
        this.defaultCurrency = localStorage.getItem('currency') || 'EUR';
        this.start();
    },
};
</script>

<style scoped>
td {
    border: none !important;
}
</style>