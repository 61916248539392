<template>
    <div class="card mb-3 border-0 shadow-sm p-3">
        <div class="card-header bg-white border-0">
            <div class="d-flex justify-content-between">
                <div class="modal-title mb-0 d-flex align-items-center">
                    <i class="fa-regular fa-credit-card-front me-2 text-primary fs-3"></i>
                    <div class="fs-4" style="font-weight: 500">
                        Pasarela de pago
                    </div>
                </div>
            </div>
        </div>
        <div class="card-body">
            <div v-if="booking.cards_paynopain.length > 0">
                <div v-for="item in booking.cards_paynopain" :key="item" class="mb-2">
                    <CreditCardInfo :card="item" :booking="booking" />
                </div>
            </div>
            <!-- BOTÓN ACCIONES TARJETAS -->
            <AddCreditCard :booking="booking" />

            <SendPaymentLinkBooking :booking="booking" />


            <!-- <div class="d-grid gap-2">
                <button type="button" class="btn btn-sm btn-outline-primary mb-2">
                    <i class="fa-regular fa-wallet me-2"></i>Cobrar
                </button>
            </div> -->
        </div>
    </div>
</template>

<script>
import CreditCardInfo from "@/components/bookings/CreditCardInfo.vue";
import AddCreditCard from "@/components/bookings/AddCreditCard.vue";
import SendPaymentLinkBooking from "@/components/bookings/SendPaymentLinkBooking.vue";

export default {
    name: "PaymentGatewayBooking",
    props: {
        booking: Object,
    },
    components: {
        CreditCardInfo,
        AddCreditCard,
        SendPaymentLinkBooking
    },
};
</script>

<style scoped>
/* Add any specific styles for the new component here */
</style>