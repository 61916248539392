// API Services
import UserService from "@/services/user.service";

// User Roles
async function getCurrentUserRoles() {
	var response = await UserService.getCurrentUserRoles();
	return response.data;
}

export default [
	{
		path: "/settings/",
		name: "settings hotel",
		component: () => import("@/views/settings/SettingHotel.vue"),
		beforeEnter: () => {
			localStorage.setItem("menu", "menu-settings");
		},
	},
	{
		path: "/settings/hotel",
		name: "settings hotel",
		component: () => import("@/views/settings/SettingHotel.vue"),
		beforeEnter: () => {
			localStorage.setItem("menu", "menu-settings");
		},
	},
	{
		path: "/settings/rooms",
		name: "settings rooms",
		component: () => import("@/views/settings/SettingRoom.vue"),
		beforeEnter: () => {
			localStorage.setItem("menu", "menu-settings");
		},
	},
	{
		path: "/settings/rates",
		name: "settings rates",
		component: () => import("@/views/settings/SettingRate.vue"),
		beforeEnter: () => {
			localStorage.setItem("menu", "menu-settings");
		},
	},
	{
		path: "/settings/extras",
		name: "settings extras",
		component: () => import("@/views/settings/SettingExtra.vue"),
		beforeEnter: () => {
			localStorage.setItem("menu", "menu-settings");
		},
	},
	{
		path: "/settings/segments",
		name: "settings segments",
		component: () => import("@/views/settings/SettingSegment.vue"),
		beforeEnter: () => {
			localStorage.setItem("menu", "menu-settings");
		},
	},
	{
		path: "/settings/invoices",
		name: "settings invoices",
		component: () => import("@/views/settings/SettingInvoice.vue"),
		beforeEnter: () => {
			localStorage.setItem("menu", "menu-settings");
		},
	},
	{
		path: "/settings/lists",
		name: "settings lists",
		component: () => import("@/views/settings/SettingLists.vue"),
		beforeEnter: () => {
			localStorage.setItem("menu", "menu-settings");
		},
	},
	{
		path: "/settings/paymentmethod",
		name: "settings payment method",
		component: () => import("@/views/settings/SettingPaymentMethod.vue"),
		beforeEnter: () => {
			localStorage.setItem("menu", "menu-settings");
		},
	},
	{
		path: "/settings/partes-viajero",
		name: "settings partes de viajero",
		component: () => import("@/views/settings/SettingPartesViajero.vue"),
		beforeEnter: () => {
			localStorage.setItem("menu", "menu-settings");
		},
	},
	{
		path: "/settings/email",
		name: "settings email",
		component: () => import("@/views/settings/SettingEmail.vue"),
		beforeEnter: () => {
			localStorage.setItem("menu", "menu-settings");
		},
	},
	{
		path: "/settings/users",
		name: "Settings Users",
		component: () => import("@/views/settings/SettingUsers.vue"),
		beforeEnter: async (to, from, next) => {
			const roles = await getCurrentUserRoles();
			if (roles.includes("pms_superadmin") || roles.includes("pms_admin")) {
				localStorage.setItem("menu", "menu-settings");
				next();
			} else {
				next("/login");
			}
		},
	},
	{
		path: "/settings/tags",
		name: "Settings Tags",
		component: () => import("@/views/settings/SettingTag.vue"),
	},
	{
		path: "/settings/portales",
		name: "settings portal",
		component: () => import("@/views/settings/SettingPortal.vue"),
		beforeEnter: () => {
			localStorage.setItem("menu", "menu-settings");
		},
	},
	{
		path: "/settings/taxes",
		name: "SettingsTax",
		component: () => import("@/views/settings/SettingTax.vue"),
		beforeEnter: async (to, from, next) => {
			const roles = await getCurrentUserRoles();
			if (roles.includes("pms_superadmin") || roles.includes("pms_admin")) {
				localStorage.setItem("menu", "menu-settings");
				next();
			} else {
				next("/login");
			}
		},
	},
	{
		path: "/settings/modules",
		name: "SettingModules",
		component: () => import("@/views/settings/SettingModules.vue"),
		beforeEnter: async (to, from, next) => {
			const roles = await getCurrentUserRoles();
			if (roles.includes("pms_superadmin") || roles.includes("pms_admin")) {
				localStorage.setItem("menu", "menu-settings");
				next();
			} else {
				next("/login");
			}
		},
	},
	{
		path: "/settings/checkin-online",
		name: "SettingCheckinOnline",
		component: () => import("@/views/settings/SettingCheckinOnline.vue"),
		beforeEnter: async (to, from, next) => {
			const roles = await getCurrentUserRoles();
			if (roles.includes("pms_superadmin") || roles.includes("pms_admin") || roles.includes("pms_hotel_admin")) {
				localStorage.setItem("menu", "menu-settings");
				next();
			} else {
				next("/login");
			}
		},
	},
	{
		path: "/settings/aloha2",
		name: "SettingAloha2",
		component: () => import("@/views/settings/integrations/SettingAloha2.vue"),
		beforeEnter: async (to, from, next) => {
			const roles = await getCurrentUserRoles();
			if (roles.includes("pms_superadmin") || roles.includes("pms_admin") || roles.includes("pms_hotel_admin")) {
				localStorage.setItem("menu", "menu-settings");
				next();
			} else {
				next("/login");
			}
		},
	},
	{
		path: "/settings/fideltour",
		name: "SettingFideltour",
		component: () => import("@/views/settings/integrations/SettingFideltour.vue"),
		beforeEnter: async (to, from, next) => {
			const roles = await getCurrentUserRoles();
			if (roles.includes("pms_superadmin") || roles.includes("pms_admin") || roles.includes("pms_hotel_admin")) {
				localStorage.setItem("menu", "menu-settings");
				next();
			} else {
				next("/login");
			}
		},
	},
	{
		path: "/settings/siigo",
		name: "SettingSiigo",
		component: () => import("@/views/settings/integrations/SettingSiigo.vue"),
		beforeEnter: async (to, from, next) => {
			const roles = await getCurrentUserRoles();
			if (roles.includes("pms_superadmin") || roles.includes("pms_admin") || roles.includes("pms_hotel_admin")) {
				localStorage.setItem("menu", "menu-settings");
				next();
			} else {
				next("/login");
			}
		},
	},

	{
		path: "/settings/channel-mapping",
		name: "SettingChannelMapping",
		component: () => import("@/views/settings/channels/SettingChannelMapping.vue"),
		beforeEnter: async (to, from, next) => {
			const roles = await getCurrentUserRoles();
			if (roles.includes("pms_superadmin") || roles.includes("pms_admin")) {
				localStorage.setItem("menu", "menu-settings");
				next();
			} else {
				next("/login");
			}
		},
	},

	{
		path: "/settings/channel-exceptions",
		name: "SettingChannelException",
		component: () => import("@/views/settings/channels/SettingChannelException.vue"),
		beforeEnter: async (to, from, next) => {
			const roles = await getCurrentUserRoles();
			if (roles.includes("pms_superadmin") || roles.includes("pms_admin")) {
				localStorage.setItem("menu", "menu-settings");
				next();
			} else {
				next("/login");
			}
		},
	},
	{
		path: "/settings/paynopain",
		name: "SettingPaynopain",
		component: () => import("@/views/settings/integrations/SettingPaynopain.vue"),
		beforeEnter: async (to, from, next) => {
			const roles = await getCurrentUserRoles();
			if (roles.includes("pms_superadmin") || roles.includes("pms_admin") || roles.includes("pms_hotel_admin")) {
				localStorage.setItem("menu", "menu-settings");
				next();
			} else {
				next("/login");
			}
		},
	},
];
