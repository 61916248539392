<template>
    <div>

        <div class="d-grid gap-2">
            <button type="button" class="btn btn-sm btn-outline-primary mb-2" data-bs-toggle="modal" data-bs-target="#modalAddCard"><i class="fa-regular fa-plus me-2"></i>Añadir tarjeta</button>
        </div>

         <!-- Modal View Credit Card -->
        <div class="modal fade" id="modalAddCard" tabindex="-1" aria-labelledby="modalCardInfo" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-fullscreen-lg-down">
                <div class="modal-content">
                    <div class="modal-header">
                        <h6 class="mb-0">Añadir nueva tarjeta</h6>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="col-md-12 mb-3">
                            <MazInput 
                                v-model="newCard.card_holder" label="Titular de la tarjeta">
                                <template #left-icon>
                                    <i class="fa-regular fa-user"></i>
                                </template>
                            </MazInput>
                        </div>
                        <div class="col-md-12 mb-3">
                            <MazInput 
                                v-model="newCard.card_pan" label="Número de tarjeta">
                                <template #left-icon>
                                    <i class="fa-regular fa-credit-card-front"></i>
                                </template>
                            </MazInput>
                        </div>
                        <div class="row">
                            <div class="col-md-6 mb-3">
                                <div class="row">
                                    <div class="col-6">
                                        <MazInput 
                                            v-model="newCard.card_expiry_month" label="Mes">
                                            <template #left-icon>
                                                <i class="fa-regular fa-calendar-days"></i>
                                            </template>
                                        </MazInput>
                                    </div>
                                    <div class="col-6">
                                        <MazInput 
                                            v-model="newCard.card_expiry_year" label="Año">
                                            <template #left-icon>
                                                <i class="fa-regular fa-calendar-days"></i>
                                            </template>
                                        </MazInput>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 mb-3">
                                <MazInput 
                                    v-model="newCard.card_cvv" label="CVV">
                                    <template #left-icon>
                                        <i class="fa-regular fa-key"></i>
                                    </template>
                                </MazInput>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer">
                        <button type="button" class="btn btn-link text-primary" data-bs-dismiss="modal">Cerrar</button>
                        <button id="submitAddCard" type="submit" class="btn btn-primary" @click.stop.prevent="createCreditCard()">Guardar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

   

</template>

<script>
    
    import Swal from 'sweetalert2'

    import CreditCardService from "../../services/creditcard.service"

    export default {
        name: 'AddCreditCard',
        props: {
            booking: Object
        },
        data () {
            return {
                newCard: {
                    bookingID: this.booking.id
                },
                loading: true
            }
        },
        methods: {
            async start() {
                this.loading = true;
                this.loading = true;
            },


            async createCreditCard() {
                window.$("#submitAddCard").empty();
                window.$("#submitAddCard").append('<span class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>Guardando');

                var response = await CreditCardService.createCreditCard(this.newCard)

                console.log(response, "SHGGJHKLÑ")

                window.$("#submitAddCard").empty();
                window.$("#submitAddCard").append('Guardar');

                if (response.status == 200) {
                    window.$('#modalAddCard').hide();
                    window.$('body').removeClass('modal-open');
                    window.$('.modal-backdrop').remove();

                    Swal.fire({
                        icon: 'success',
                        title: 'Tarjeta añadida correctamente.',
                        confirmButtonColor: '#0091ff'
                    }).then(() => {
                        this.$router.go();
                    })            
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Ha ocurrido un error. Vuelve a intentarlo. Si el error persiste ponte en contacto con soporte@hospedium.com',
                        confirmButtonColor: '#0091ff'
                    });
                }
            }

        },
        created() {
            this.start()
        }
    }
</script>