<template>
    <div class="page-app">
        <div class="row">
            <div class="col-12 col-lg-6 pb-5">
                <div class="card mb-3 border-0 shadow-sm p-3">
                    <div class="card-header bg-white border-0">
                        <div class="d-flex justify-content-between">
                            <div class="modal-title mb-0 d-flex align-items-center">

                                <i class="fa-regular fa-users me-2 text-primary fs-3"></i>
                                <div class="fs-4" style="font-weight: 500">
                                    Clientes
                                </div>
                            </div>
                            <div class="d-flex">
                                <AddClient @reloadClients="reloadClients" />
                                <div class="dropdown">
                                    <a class="btn btn-secondary" href="#" role="button"
                                        data-bs-toggle="dropdown" aria-expanded="false">
                                        <i class="fa-regular fa-ellipsis-vertical"></i>
                                    </a>

                                    <ul class="dropdown-menu">
                                        <li><a class="dropdown-item" href="#" @click="exportClients"><i
                                                    class="fa-regular fa-file-xls me-2"></i>Exportar
                                                a Excel</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>


                    </div>
                    <div class="card-body">
                        <TableClients id="client-table" :isClient="true"></TableClients>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-6 pb-5">

                <div class="card mb-3 border-0 shadow-sm p-3">
                    <div class="card-header bg-white border-0">
                        <div class="d-flex justify-content-between">
                            <div class="modal-title mb-0 d-flex align-items-center">

                                <i class="fa-regular fa-building me-2 text-primary fs-3"></i>
                                <div class="fs-4" style="font-weight: 500">
                                    Empresas
                                </div>
                            </div>
                            <div class="d-flex">
                                <AddCompany @reloadClients="reloadClients" />
                                <!-- <div class="dropdown">
                                        <a class="btn btn-secondary" href="#" role="button"
                                            data-bs-toggle="dropdown" aria-expanded="false">
                                            <i class="fa-regular fa-ellipsis-vertical"></i>
                                        </a>

                                        <ul class="dropdown-menu">
                                            <li><a class="dropdown-item" href="#" @click="exportCompanies"><i
                                                        class="fa-regular fa-file-xls me-2"></i>Exportar
                                                    a Excel</a>
                                            </li>
                                        </ul>
                                    </div> -->
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <TableClients id="client-table" :isCompany="true"></TableClients>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import TableClients from "@/components/clients/TableClients.vue";
import ClientService from "@/services/client.service";
import CompanyService from "@/services/company.service";

import Swal from 'sweetalert2';

import AddClient from "@/components/clients/AddClient.vue"
import AddCompany from "@/components/clients/AddCompany.vue"

export default {
    name: "clients",
    components: {
        TableClients,
        AddClient,
        AddCompany
    },
    data() {
        return {
            clients: [],
            companies: [],
        };
    },
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },
    },
    methods: {
        async exportClients() {
            // Mostrar SweetAlert con loading
            Swal.fire({
                title: 'Descargando...',
                text: 'Por favor, espera mientras se prepara tu archivo.',
                didOpen: () => {
                    Swal.showLoading();
                },
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: false
            });

            try {
                await ClientService.exportClientsCSV();
                Swal.close();
            } catch {
                Swal.fire({ title: 'Error', text: 'No se pudo descargar el archivo', icon: 'error', confirmButtonColor: "#0091ff" });

            }
        },

        reloadClients() {
            // Reload the current page
            window.location.reload();
        }
    },
    created() { },
    mounted() {
        if (!this.currentUser) {
            this.$router.push("/login");
        }
    },
};
</script>