<template>
    <div class="d-flex mb-3">
        <div>
            <small class="me-2" style="text-wrap: nowrap">Cambiar color:</small>
        </div>
        <VSwatches v-model="color" :swatches="swatches" show-fallback></VSwatches>
    </div>
</template>

<script>
import BookingService from "../../services/booking.service";

import Swal from "sweetalert2";

export default {
    name: "PlanningChangeColorBookingRoom",
    props: {
        bookingroomId: Number,
        colorSelected: String,
    },
    emits: ["updatePlanning"],
    data() {
        return {
            swatches: [
                "#0091ff",
                "#6e00ff",
                "#ae00ff",
                "#91FF00",
                "#00FF6E",
                "#00FFAE",
                "#FF5733",
                "#5086AF",
            ],
            color: this.colorSelected,
        };
    },
    watch: {
        bookingroomId: function () {
            // watch it
            this.start();
        },

        color: function () {
            this.changeColor();
        },
    },
    methods: {
        async changeColor() {
            var response = await BookingService.changeColorBookingRoom({
                bookingroom: this.bookingroomId,
                color: this.color,
            });

            if (response.status == 200) {
                Swal.fire({
                    position: "top-end",
                    icon: "success",
                    title: "Se ha actualizado correctamente.",
                    showConfirmButton: false,
                    timer: 1500,
                    backdrop: false,
                    toast: true,
                });
                this.$emit("updatePlanning");
            } else {
                Swal.fire({
                    position: "top-end",
                    icon: "error",
                    title: "Ha ocurrido un error.",
                    showConfirmButton: false,
                    timer: 1500,
                    backdrop: false,
                    toast: true,
                });
            }
        },
    },
    created() { },
    mounted() { },
};
</script>
