import api from "./api";
import HotelService from "./hotel.service";

class RoomService {
	/**************************************
	 *  API HOSPEDIUM
	 **************************************/
	async addRoom(params) {
		let hotel = HotelService.getLocalHotel();
		params.hotel = hotel.id;
		return await api
			.post("/rooms", params)
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async addTypeRoom(params) {
		let hotel = HotelService.getLocalHotel();
		params.hotel = hotel.id;
		return await api
			.post("/rooms/types", params)
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async updateRoom(id, params) {
		let hotel = HotelService.getLocalHotel();
		params.hotel = hotel.id;
		return await api
			.put("/rooms/" + id, params)
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async updateTypeRoom(id, params) {
		let hotel = HotelService.getLocalHotel();
		params.hotel = hotel.id;
		return await api
			.put("/rooms/types/" + id, params)
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async getAllRooms() {
		let hotel = HotelService.getLocalHotel();
		return await api
			.get("/rooms?hotel=" + hotel.id)
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async cloneRoom(pk) {
		let hotel = HotelService.getLocalHotel();
		var params = {
			hotel: hotel.id,
		};
		return await api
			.post("/rooms/clone/" + pk + "/", { paramms: params })
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async deleteRoom(pk) {
		let hotel = HotelService.getLocalHotel();
		var params = {
			hotel: hotel.id,
		};
		return await api
			.delete("/rooms/delete/" + pk + "/", { paramms: params })
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async getHouseKeepingRooms(externalParams) {
		let hotel = HotelService.getLocalHotel();

		const params = externalParams || {};

		params.hotel = hotel.id;
		params.housekeeping = 1;

		return await api
			.get("/rooms", { params: params })
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async getRoomsByType(params) {
		let hotel = HotelService.getLocalHotel();
		return await api
			.get("/rooms?hotel=" + hotel.id + "&roomtype=" + params)
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async getAllRoomsType() {
		let hotel = HotelService.getLocalHotel();
		return await api
			.get("/rooms/types?hotel=" + hotel.id)
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async getRoom(id) {
		return await api
			.get("/rooms/" + id)
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	getRoomTypeByRoomcloudID(id) {
		let hotel = HotelService.getLocalHotel();
		return api.get("/rooms/types/roomcloudID?hotel=" + hotel.id + "&roomcloudID=" + id).then((response) => {
			return response.data;
		});
	}

	getRoomType(id) {
		return api.get("/rooms/types/" + id).then((response) => {
			return response.data;
		});
	}

	async changeAllRoomsSituation(data) {
		let hotel = HotelService.getLocalHotel();
		var params = {
			hotel: hotel.id,
		};
		return await api
			.post("/rooms/change/situation/", data, { params: params })
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}
}

export default new RoomService();
