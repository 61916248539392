import api from './api';
import HotelService from "./hotel.service";

class ExtraService { 


    /**************************************
     *  API HOSPEDIUM
     **************************************/
    async addExtra(params) {
        return await api.post('/extras', params)
        .then((response) => {
            return {status: response.status, data: response.data};
        })
        .catch(function (error) {
            return {status: error.response.status, data: error.response.data};
        });
    }

    async updateExtra(id, params) {
        return await api.put('/extras/'+id, params)
        .then((response) => {
            return {status: response.status, data: response.data};
        })
        .catch(function (error) {
            return {status: error.response.status, data: error.response.data};
        });
    }

    async getAllExtras() {
        let hotel = HotelService.getLocalHotel();
        return await api.get('/extras?hotel=' + hotel.id)
        .then((response) => {
            return {status: response.status, data: response.data};
        })
        .catch(function (error) {
            return {status: error.response.status, data: error.response.data};
        });
    }

    async getExtra(id) {
        return await api.get('/extras/' + id)
        .then((response) => {
            return {status: response.status, data: response.data};
        })
        .catch(function (error) {
            return {status: error.response.status, data: error.response.data};
        });
    }
}

export default new ExtraService();