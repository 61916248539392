<template>
    <td>
        <div class="d-flex align-items-center">
            <button class="btn btn-light btn-active-light-primary me-2">
                <i class="fa-regular fa-pen-to-square"></i>
            </button>
            <div>
                {{ invoice.code }}{{ invoice.number }}
                <i v-if="invoice.isAdvancePayment" class="fa-solid fa-square-a text-primary ms-2"></i>
                <i v-if="invoice.isProforma" class="fa-solid fa-square-p text-dark ms-2"></i>
            </div>
        </div>
    </td>
    <td class="align-items-center">
        <div>
            <span>{{
                    formatCurrency(invoice.baserate_amount, defaultCurrency)
                }}</span>
        </div>
    </td>
    <td class="align-items-center">
        <div>
            <span v-if="invoice.show_taxes" class="">{{ formatCurrency(invoice.tax_amount, defaultCurrency) }}</span>
            <span v-else class="badge badge-light-dark">Sin impuestos</span>
        </div>
    </td>

    <td class="align-items-center">
        <div v-if="invoice.show_taxes">
            <span v-if="invoice.total_amount >= 0" class="text-success">{{ formatCurrency(invoice.total_amount, defaultCurrency) }}</span>
            <span v-else class="text-danger">{{ formatCurrency(invoice.total_amount, defaultCurrency) }}</span>
        </div>
        <div v-else>
            <span v-if="invoice.baserate_amount >= 0" class="text-success">{{ formatCurrency(invoice.baserate_amount, defaultCurrency) }}</span>
            <span v-else class="text-danger">{{ formatCurrency(invoice.baserate_amount, defaultCurrency) }}</span>
        </div>
    </td>
    <td class="align-items-center">
        <div v-if="!invoice.paid && !invoice.isProforma">
            <span class="badge badge-light-warning">Pago pendiente</span>
        </div>
        <div v-if="invoice.paid && !invoice.isProforma">
            <span class="badge badge-light-success">Pagada</span>
        </div>
        <div v-if="invoice.isProforma">
            <span class="badge badge-light-dark">Proforma</span>
        </div>
    </td>
    <td>
        {{ convertDate(invoice.date) }}
    </td>
    <td class="align-items-center">
        <div class="d-flex align-items-center">
            <div v-if="isClient" class="badge badge-light-info me-3">
                Cliente
            </div>
            <div v-if="isCompany" class="badge badge-light-dark me-3">
                Empresa
            </div>

            <div class="text-uppercase">{{ invoice.tax_contact_name }}</div>
        </div>
    </td>
</template>

<script>
export default {
    name: "ViewBookingPaymentsRow",
    props: {
        invoice: Object,
    },
    data() {
        return {
            id: this.invoice.id,
            isClient: false,
            isCompany: false,
            loading: true,
            defaultCurrency: ''
        };
    },
    methods: {
        start() {
            this.loading = true;

            if (this.invoice.client_hotel) {
                this.isClient = true;
            } else if (this.invoice.company) {
                this.isCompany = true;
            }

            this.loading = false;
        },


        convertDate(dateStr) {
            if (!dateStr) {
                return;
            }

            const dateParts = dateStr.split("-");
            if (dateParts.length !== 3) {
                return;
            }

            const year = dateParts[0];
            const month = dateParts[1];
            const day = dateParts[2];

            return this.formatDate(year, month, day);
        },
        formatDate(year, month, day) {
            const months = [
                "Ene",
                "Feb",
                "Mar",
                "Abr",
                "May",
                "Jun",
                "Jul",
                "Ago",
                "Sep",
                "Oct",
                "Nov",
                "Dic",
            ];

            const monthName = months[parseInt(month, 10) - 1]; // Restamos 1 porque los meses son 0-indexados en JavaScript

            return `${monthName} ${day}, ${year}`;
        },
    },
    created() {
        this.defaultCurrency = localStorage.getItem('currency') || 'EUR';
        this.start();
    },
};
</script>

<style scoped>
td {
    border: none !important;
}
</style>