<template>
    <div class="d-flex">
        <strong style="text-wrap: nowrap">Asignar un mismo color a todas las estancias de la reserva:</strong>
        <div style="width: 48px;" class="ms-3">
            <VSwatches v-model="color" :swatches="swatches" show-fallback></VSwatches>
        </div>
    </div>
</template>

<script>
import BookingService from "../../services/booking.service";

export default {
    name: "PlanningChangeColorBooking",
    props: {
        bookingId: Number,
        colorSelected: String,
    },
    emits: ["updateColor"],
    data() {
        return {
            swatches: [
                "#0091ff",
                "#6e00ff",
                "#ae00ff",
                "#91FF00",
                "#00FF6E",
                "#00FFAE",
                "#FF5733",
                "#5086AF",
            ],
            color: this.colorSelected,
        };
    },
    watch: {
        bookingId: function () {
            // watch it
            this.start();
        },

        color: function () {
            this.changeColor();
        },
    },
    methods: {
        async changeColor() {

            this.$swal.fire({
                title: 'Guardando...',
                allowOutsideClick: false,
                didOpen: () => {
                    this.$swal.showLoading();
                }
            });

            var response = await BookingService.updateBookingColor(
                this.bookingId,
                {
                    color: this.color,
                }
            );

            if (response.status == 200) {
                this.$swal.fire({
                    position: "top-end",
                    icon: "success",
                    title: "Se ha actualizado correctamente.",
                    showConfirmButton: false,
                    timer: 1500,
                    toast: true,
                });
                this.$emit("updateColor");
            } else {
                this.$swal.fire({
                    position: "top-end",
                    icon: "error",
                    title: "Ha ocurrido un error.",
                    showConfirmButton: false,
                    timer: 1500,
                    toast: true,
                });
            }
        },
    },
    created() { },
    mounted() { },
};
</script>
