<template>
    <div id="checkin-page" class="page-app">
        <div v-if="bookingroom != '' && bookingroom.status != 7" class="row">
            <div class="col-12">
                <div class="card border-0 shadow-sm mb-3 p-3">
                    <div class="d-md-flex justify-content-between">
                        <div class="fs-4" style="font-weight: 500">
                            Estancia #{{ bookingroom.id }}
                        </div>
                        <div>
                            <!-- EDITAR ESTANCIA -->
                            <button type="button" class="btn btn-secondary btn-active-light-primary me-2" data-bs-toggle="modal" data-bs-target="#modalEditBR" @click="changeEditBRModal">
                                <i class="fa-regular fa-pen-to-square me-2"></i>Editar
                            </button>
                            <!-- EDITAR PRECIOS TARIFA -->
                            <button v-if="!loading" type="button" class="btn btn-primary me-2" data-bs-toggle="modal" data-bs-target="#chargesAccommodationModal">
                                Editar precios tarifa
                            </button>
                            <BookingroomAccommodationChargesModal v-if="!loading" :charges="charges" :show_taxes="booking.show_taxes" :bookingroom="bookingroom" @reloadView="reloadView" />

                            <!-- BOTÓN MODIFICAR FECHAS -->
                            <button v-if="bookingroom.status != 7 && !loading" type="button" class="btn btn-dark me-2" data-bs-toggle="modal" data-bs-target="#modalEditDates">
                                <i class="fa-solid fa-calendar-days me-2"></i>Modificar fechas
                            </button>
                            <button type="button" class="btn btn-danger" @click="cancelBR">
                                Cancelar habitación
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-9">
                <div class="card border-0 shadow-sm mb-3 p-3">
                    <div class="card-header bg-white d-flex align-items-center justify-content-between border-0">
                        <div class="card-title mb-0 d-flex aling-items-center">
                            <i class="fa-regular fa-calendar fs-3 me-3 text-primary"></i>
                            <div class="fs-4" style="font-weight: 500">
                                Check-in / Check-out
                            </div>
                        </div>
                        <div>
                            <span v-if="bookingroom.status == 4 ||
            bookingroom.status == 2 ||
            bookingroom.status == 8
            " class="badge badge-warning text-uppercase">Check-in
                                pendiente</span>
                            <span v-else-if="bookingroom.status == 9" class="badge badge-success text-uppercase">Check-in
                                completado</span>
                            <span v-else-if="bookingroom.status == 10" class="badge badge-secondary text-uppercase">Check-out
                                completado</span>
                        </div>
                    </div>
                    <div class="card-body">
                        <div>
                            <div class="row mx-0 mb-3">
                                <div class="col-6">
                                    <div class="mb-2">
                                        <strong>Fecha de llegada</strong>
                                    </div>
                                    <div class="mb-3">
                                        <span class="fs-5">{{ checkin }}</span>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="mb-2">
                                        <strong>Fecha de salida</strong>
                                    </div>
                                    <div class="mb-3">
                                        <span class="fs-5">{{ checkout }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row mx-0">
                                <div class="col-md-6">
                                    <div class="mb-2">
                                        <strong>Checkin registrado</strong>
                                    </div>
                                    <span v-if="bookingroom.status == 9 ||
            bookingroom.status == 10
            " class="badge badge-light-success">Check-in
                                        completado</span>
                                    <span v-else class="badge badge-light-danger">Check-in
                                        pendiente</span>
                                    <div class="form-check form-switch mt-3 mb-3">
                                        <input class="form-check-input" type="checkbox" id="checkinRegister" value="1" @click="handleCheckinCheckboxClick" style="
												font-size: 24px;
												margin-left: -1.5em;
												margin-top: 2px;
												cursor: pointer;
											" />
                                    </div>
                                    <MazPicker v-model="checkinRegister" time :minuteInterval="1" format="YYYY-MM-DD HH:mm" class="mb-2 w-100" @update:model-value="handleCheckinMazPickerChange"
                                        size="sm" :disabled="!isCheckinChecked" />
                                    <small class="mb-3">Pulsa el botón para marcar el
                                        checkin</small>
                                </div>
                                <div class="col-md-6">
                                    <div class="mb-2">
                                        <strong>Checkout registrado</strong>
                                    </div>
                                    <span v-if="bookingroom.status == 10" class="badge badge-light-success">Check-out
                                        completado</span>
                                    <span v-else class="badge badge-light-danger">Check-out
                                        pendiente</span>
                                    <div class="form-check form-switch mb-3 mt-3">
                                        <input class="form-check-input" type="checkbox" id="checkoutRegister" value="1" @click="handleCheckoutCheckboxClick" style="
												font-size: 24px;
												margin-left: -1.5em;
												margin-top: 2px;
												cursor: pointer;
											" />
                                    </div>
                                    <MazPicker v-model="checkoutRegister" time :minuteInterval="1" format="YYYY-MM-DD HH:mm" class="mb-2 w-100" @update:model-value="handleCheckoutMazPickerChange"
                                        size="sm" :disabled="!isCheckoutChecked" />
                                    <small class="mb-3">Pulsa el botón para marcar el
                                        checkout</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card border-0 shadow-sm mb-3 p-3">
                    <div class="card-header bg-white d-md-flex align-items-center justify-content-between border-0 mb-3 mb-md-0">
                        <div class="card-title mb-0 d-flex align-items-center">
                            <i class="fa-regular fa-users text-primary fs-3 me-3"></i>
                            <div class="fs-4" style="font-weight: 500">
                                Huéspedes
                            </div>
                        </div>
                        <AddGuest v-if="!loading" :booking="booking" :bookingroom="bookingroom" @updateGuestList="updateGuestList" />
                    </div>
                    <div class="card-body">
                        <div>
                            <div class="alert alert-warning" role="alert">
                                <strong><i class="fa-regular fa-circle-info me-2"></i>No olvides
                                    añadir
                                    tantos huéspedes como
                                    personas alojadas en la habitación.</strong>
                            </div>
                            <div v-if="bookingroom.guests == ''">
                                No hay huéspedes asignados a esta habitación
                            </div>
                            <div v-else-if="guests != ''">
                                <div class="table-responsive">
                                    <table class="table table-row-dashed">
                                        <thead>
                                            <tr class="text-start text-gray-400">
                                                <th>Huésped</th>
                                                <th>Documento</th>
                                                <th>Fecha registro</th>
                                                <!-- <th>Fecha Check-In</th> -->
                                                <th>Parte viajero</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr :key="item" v-for="item in guests" class="align-middle">
                                                <td>
                                                    <div class="d-flex align-items-center">
                                                        <router-link :to="{
            name: 'GuestView',
            params: {
                guestID:
                    item.id,
            },
        }" class="btn btn-light btn-active-light-primary me-3"><i class="fa-regular fa-user-tag"></i></router-link>
                                                        <div class="text-uppercase">
                                                            {{
            item.client_hotel.firstName +
            (item.client_hotel.lastName1 ? ' ' +
                item.client_hotel.lastName1 : '') +
            (item.client_hotel.lastName2 ? ' ' +
                item.client_hotel.lastName2 : '')
        }}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div v-if="item.client_hotel
                .document_type ==
            'dni'
            ">
                                                        DNI
                                                    </div>
                                                    <div v-else-if="item.client_hotel
            .document_type ==
            'passport'
            ">
                                                        Pasaporte
                                                    </div>
                                                    <div v-else-if="item.client_hotel
            .document_type ==
            'driving_license'
            ">
                                                        Carné de conducir
                                                    </div>
                                                    <div v-else-if="item.client_hotel
            .document_type ==
            'residence_cart'
            ">
                                                        Tarjeta de residencia
                                                    </div>

                                                    <div v-else-if="item.client_hotel
            .document_type ==
            'citizenship_card'
            ">
                                                        Cédula de ciudadanía
                                                    </div>

                                                    <div v-else-if="item.client_hotel
            .document_type ==
            'foreign_identity_card'
            ">
                                                        Cédula de extranjería
                                                    </div>


                                                    <div v-else-if="item.client_hotel
            .document_type ==
            'civil_registry'
            ">
                                                        Registro civil
                                                    </div>


                                                    <div v-else-if="item.client_hotel
            .document_type ==
            'other'
            ">
                                                        Otro
                                                    </div>
                                                    <div>
                                                        <small>{{
            item.client_hotel.nif
        }}</small>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        {{
                formatDate(
                    item.dateCreated,
                    true
                )
            }}
                                                    </div>
                                                </td>
                                                <!-- <td>
													<div>
														{{
															formatDate(
																item.dateEntry,
																true
															)
														}}
													</div>
												</td> -->
                                                <td>
                                                    <ParteViajero class="mb-2" :guestID="item.id" />
                                                    <router-link v-if="item.sign &&
            isCheckinOnlineModuleActive
            " class="badge badge-light-success" :to="'/guests/' + item.id
            ">
                                                        <i class="fa-regular fa-signature me-2"></i>Firmado
                                                    </router-link>
                                                    <router-link v-else-if="isCheckinOnlineModuleActive
            " class="btn badge badge-light-danger" :to="'/guests/' + item.id
            ">
                                                        <i class="fa-regular fa-signature me-2"></i>Pendiente
                                                        de firma
                                                    </router-link>
                                                </td>
                                                <td class="text-end">
                                                    <button class="btn btn-link btn-sm text-danger" @click="
            deleteGuest(item.id)
            ">
                                                        <i class="fa-regular fa-trash me-2"></i>Eliminar
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div v-else id="spinner-search" class="col-12 text-center py-5">
                                <div class="spinner-border text-primary" role="status" style="
										width: 2rem;
										height: 2rem;
										visibility: visble;
									">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                                <div>Cargando información...</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card border-0 shadow-sm mb-3 p-3">
                    <div class="card-header bg-white d-md-flex align-items-center justify-content-between border-0">
                        <div class="card-title mb-0 d-flex align-items-center mb-3 mb-md-0">
                            <i class="fa-regular fa-rectangle-list text-primary fs-3 me-3"></i>
                            <div class="fs-4" style="font-weight: 500">
                                Cargos extras
                            </div>
                        </div>
                        <div class="d-md-flex gap-3">

                            <AddCharge v-if="!loading" :extras="extras" :booking="booking" :bookingroom="bookingroom" :segments="segments" class="mb-2 mb-md-0" />
                            <div><button v-if="!loading" class="btn btn-secondary" :disabled="deleteChargeList.length == 0" @click="handleDeleteChargeList">{{ `Eliminar (${deleteChargeList.length})`
                                    }}</button></div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div>
                            <div class="alert alert-warning" role="alert">
                                <strong><i class="fa-regular fa-circle-info me-2"></i>Aquí puedes
                                    añadir
                                    o eliminar todos los
                                    cargos asociados a la habitación.</strong>
                            </div>

                            <div v-if="!loading" class="table-responsive">
                                <table v-if="charges != ''" class="table table-row-dashed">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>Fecha</th>
                                            <th>Descripción</th>
                                            <th>Cantidad</th>
                                            <th>Precio</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody :key="charge" v-for="charge in charges">
                                        <EditChargeBookingRoomRow v-if="!loading" :charge="charge" :bookingroom="bookingroom" :booking="booking" :extras="extras" :segments="segments"
                                            @getCharges="getCharges" @addChargeToDeleteList="addChargeToDeleteList" />
                                    </tbody>
                                </table>
                            </div>

                            <div v-if="loading" id="spinner-search" class="col-12 text-center py-5">
                                <div class="spinner-border text-primary" role="status" style="
										width: 2rem;
										height: 2rem;
										visibility: visble;
									">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                                <div>Cargando información...</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="sticky-top">
                    <div class="card">
                        <div class="card-body">
                            <div class="mb-4">
                                <div v-if="room != ''">
                                    <div class="alert alert-primary text-center" role="alert">
                                        <h6>Habitación</h6>
                                        <h4 class="mb-3">{{ room.name }}</h4>
                                        <button type="button" class="btn btn-sm btn-primary text-uppercase" data-bs-toggle="modal" data-bs-target="#modalChangeRoom" @click="changeRoomModal">
                                            <i class="fa-regular fa-pen-to-square me-2"></i>Cambiar
                                            habitación
                                        </button>
                                    </div>
                                    <div v-if="room.roomType.id !=
            bookingroom.roomType.id
            " class="alert alert-warning" role="alert">
                                        <small><i class="fa-regular fa-triangle-exclamation me-2"></i><strong>La
                                                habitación seleccionada no
                                                corresponde al tipo de
                                                habitación de la
                                                reserva.</strong></small>
                                    </div>
                                </div>
                                <div v-else>
                                    <div class="alert alert-danger text-center" role="alert">
                                        <h6>Habitación</h6>
                                        <h4 class="mb-3">Ninguna</h4>
                                        <button type="button" class="btn btn-sm btn-primary text-uppercase" data-bs-toggle="modal" data-bs-target="#modalChangeRoom" @click="changeRoomModal">
                                            Asignar habitación
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div v-if="booking != ''" class="mb-4 text-center">
                                <h5 class="mb-3">
                                    <span>{{ "Reserva #" + booking.id }}</span>
                                </h5>
                                <h5 class="mb-1"><span>Cliente</span></h5>
                                <div class="mb-3 text-uppercase">
                                    {{
            booking.firstName +
            " " +
            booking.lastName
        }}
                                </div>
                                <h5 class="mb-1">
                                    <span>Tipo de habitación</span>
                                </h5>
                                <div class="mb-3 text-uppercase">
                                    {{ bookingroom.roomType.name }}
                                </div>
                                <h5 class="mb-1"><span>Tarifa</span></h5>
                                <div class="mb-3 text-uppercase">
                                    {{ rate.name }}
                                </div>
                                <h5 class="mb-1">
                                    <span>Adultos / Niños</span>
                                </h5>
                                <div class="mb-3 text-uppercase">
                                    <i class="fa-solid fa-person me-2"></i>{{ bookingroom.adults }}
                                    <i class="fa-light fa-child ms-3 me-2"></i>{{
            bookingroom.children
        }}
                                </div>
                                <h5 class="mb-1"><span>Precio total tarifa</span></h5>
                                <div class="mb-3 ">
                                    <span v-if="booking.show_taxes">{{ formatCurrency(bookingroom.price, defaultCurrency) }}<br><small>Impuestos incluidos</small></span>
                                    <span v-else>{{ formatCurrency(bookingroom.price, defaultCurrency) }}<br><small>Impuestos No incluidos</small></span>
                                </div>
                                <div class="d-grid gap-2">
                                    <router-link :to="{
            name: 'ViewBooking',
            params: { bookingId: booking.id },
        }" class="btn btn-primary text-white text-uppercase mb-3"><i class="fa-regular fa-house me-2"></i>Volver a la
                                        reserva</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="list-example" class="list-group"></div>
                </div>
            </div>
        </div>
        <div v-else class="row">
            <div v-if="bookingroom != ''" class="col-md-8 offset-md-2">
                <div class="card">
                    <div class="card-body text-center">
                        <h5 class="mb-5">
                            Esta habitación se encuentra cancelada
                        </h5>
                        <router-link :to="{
            name: 'ViewBooking',
            params: { bookingId: bookingroom.booking },
        }" class="btn btn-primary btn-sm text-white text-uppercase mb-3"><i class="fa-regular fa-house me-2"></i>Volver a la
                            reserva</router-link>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal modalChangeRoom -->
        <div class="modal fade" id="modalChangeRoom" aria-labelledby="modalChangeRoom" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Seleccionar Habitación</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div v-if="modalRoomLoading" class="text-center">
                            <div class="spinner-border text-primary" role="status" style="
									width: 2rem;
									height: 2rem;
									visibility: visble;
								">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                            <div>Buscando habitaciones disponibles...</div>
                        </div>
                        <div v-else>
                            <div class="form-group">
                                <MazSelect id="selectedRoom" v-model="selectedRoom" label="Seleccionar habitación" :options="availableRooms" option-value-key="id" option-label-key="name"
                                    option-input-value-key="name" search search-placeholder="Buscar..." class="text-uppercase" v-slot="{ option, isSelected }" :itemHeight="50">
                                    <div class="d-flex align-items-center p-3 w-100" style="width: 100%">
                                        <i class="fa-solid fa-bed-front me-3"></i>
                                        <div>
                                            <div>
                                                {{ option.name }}
                                            </div>
                                            <div>
                                                <small>{{
            option.roomType
        }}</small>
                                            </div>
                                        </div>
                                        <i v-if="isSelected" class="fa-duotone fa-check-double ms-5"></i>
                                    </div>
                                </MazSelect>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button v-if="!modalRoomLoading" id="submitButtonUpdateRoom" type="button" class="btn btn-primary" @click="updateRoom">
                            Guardar
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal modalEditRoom -->
        <div class="modal fade" id="modalEditBR" aria-labelledby="modalEditBR" aria-hidden="true" v-if="!loading">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Editar datos de habitación</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="alert alert-warning" role="alert">
                            <p>
                                Estas intentando modificar una reserva de
                                <strong>{{ booking.portal.name }} </strong> el
                                cliente debe de avisar al canal para que llegue
                                el cambio.
                            </p>
                            <p>
                                <strong>Si necesitas cambiar el tipo de habitación
                                    revisa si dispones de habitaciones y como
                                    afecta a la disponibilidad del channel
                                    manager.</strong>
                            </p>
                        </div>
                        <div v-if="modalEditBRLoading" class="text-center">
                            <div class="spinner-border text-primary" role="status" style="
									width: 2rem;
									height: 2rem;
									visibility: visble;
								">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                            <div>Buscando habitaciones disponibles...</div>
                        </div>
                        <div v-else>
                            <div class="form-group mb-3">
                                <label>Seleccionar tipo de habitación</label>
                                <select class="form-control" v-model="updateBR.roomType">
                                    <option :key="item" v-for="item in roomTypeOptions" :value="item.id">
                                        {{ item.name }}
                                    </option>
                                </select>
                            </div>
                            <div class="form-group mb-3">
                                <label>Seleccionar tarifa</label>
                                <select class="form-control" v-model="updateBR.rate">
                                    <option :value="0">Ninguna</option>
                                    <option :key="item" v-for="item in rateOptions" :value="item.id">
                                        {{ item.name }}
                                    </option>
                                </select>
                            </div>
                            <div class="row">
                                <div class="form-group mb-3 col-6">
                                    <label>Adultos</label>
                                    <input type="text" class="form-control" v-model="updateBR.adults" />
                                </div>
                                <div class="form-group mb-3 col-6">
                                    <label>Niños</label>
                                    <input type="text" class="form-control" v-model="updateBR.children" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button v-if="!modalEditBRLoading" id="submitButtonUpdateBR" type="button" class="btn btn-primary" @click="updateBookingRoom">
                            Guardar
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal Edit Dates -->
        <div v-if="!loading" class="modal fade" id="modalEditDates" tabindex="-1" aria-labelledby="modalEditDates" aria-hidden="true">
            <div class="modal-dialog modal-md">
                <div class="modal-content">
                    <form @submit.prevent.stop="changeBookingRoomDates">
                        <div class="modal-header">
                            <h5 class="modal-title">
                                Editar fechas de la estancia
                            </h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="alert alert-primary" role="alert">
                                <i class="fa-solid fa-circle-info me-1"></i>
                                Este cambio afecta solamente a esta habitación.
                                El precio total de la habitación se modifiará
                                automáticamente cogiendo los precios del Channel
                                Manager en base a las nuevas fechas indicadas.
                                <p><strong>
                                        Las reservas que proceden de canales NO devuelve cupo al
                                        Channel
                                        Manager de las fechas antiguas para evitar generar
                                        overbooking.
                                        Solamente devuelve cupo al Channel con reservas directas del
                                        PMS.</strong>
                                </p>
                            </div>

                            <label class="mb-2">Seleccionar fecha de check-in y
                                check-out</label>
                            <DatePicker v-model="range" mode="date" :masks="masks" :min-date="minDate" is-range v-if="this.bookingroom.checkinRegister == null">
                                <template v-slot="{
            inputValue,
            inputEvents,
            isDragging,
        }">
                                    <div class="d-flex align-items-center">
                                        <div class="input-group flex-grow-0" style="width: auto">
                                            <span class="input-group-text" id="basic-addon1"><i class="far fa-calendar-week"></i></span>
                                            <input class="flex-grow-0 ps-2 pe-2 py-1 bg-gray-100 border rounded" :class="isDragging
            ? 'text-gray-600'
            : 'text-gray-900'
            " :value="inputValue.start" v-on="inputEvents.start" style="width: 105px" />
                                        </div>
                                        <span class="nput-group mx-2 flex-grow-0">
                                            <i class="fas fa-long-arrow-alt-right stroke-current text-gray-600"></i>
                                        </span>
                                        <div class="input-group flex-grow-0" style="width: auto">
                                            <span class="input-group-text" id="basic-addon1"><i class="far fa-calendar-week"></i></span>
                                            <input class="flex-grow-0 ps-2 pe-2 py-1 bg-gray-100 border rounded" :class="isDragging
            ? 'text-gray-600'
            : 'text-gray-900'
            " :value="inputValue.end" v-on="inputEvents.end" style="width: 105px" />
                                        </div>
                                    </div>
                                </template>
                            </DatePicker>
                            <DatePicker v-model="range.end" mode="date" :masks="masks" :min-date="minDate" v-else>
                                <template v-slot="{ inputValue, inputEvents }">
                                    <div class="d-flex align-items-center">
                                        <div class="input-group flex-grow-0" style="width: auto">
                                            <span class="input-group-text" id="basic-addon1"><i class="far fa-calendar-week"></i></span>
                                            <input class="flex-grow-0 ps-2 pe-2 py-1 bg-gray-100 border rounded text-gray-900" :value="range.start" style="width: 105px" disabled />
                                        </div>
                                        <span class="nput-group mx-2 flex-grow-0">
                                            <i class="fas fa-long-arrow-alt-right stroke-current text-gray-600"></i>
                                        </span>
                                        <div class="input-group flex-grow-0" style="width: auto">
                                            <span class="input-group-text" id="basic-addon1"><i class="far fa-calendar-week"></i></span>
                                            <input class="flex-grow-0 ps-2 pe-2 py-1 bg-gray-100 border rounded" :class="isDragging
            ? 'text-gray-600'
            : 'text-gray-900'
            " :value="inputValue" v-on="inputEvents" style="width: 105px" />
                                        </div>
                                    </div>
                                </template>
                            </DatePicker>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-link text-primary" data-bs-dismiss="modal">
                                Cerrar
                            </button>
                            <button type="submit" id="submitChangeBookingRoomDates" class="btn btn-primary">
                                Actualizar
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
#checkin-page .sticky-top {
    top: 5.5rem !important;
    z-index: 2;
}

.table:not(.table-bordered).table-row-dashed tr {
    border-bottom-width: 1px;
    border-bottom-style: dashed;
    border-bottom-color: var(--bs-border-color);
}

th {
    text-transform: uppercase;
    font-weight: 500 !important;
}

td {
    border: none !important;
}

#signature {
    border: 2px solid #000;
    border-radius: 10px;
    margin-bottom: 20px;
}
</style>


<script>
import HotelService from "../../services/hotel.service";
import BookingService from "../../services/booking.service";
import RoomService from "../../services/room.service";
import RateService from "../../services/rate.service";
import ChargeService from "../../services/charge.service";
import ExtraService from "../../services/extra.service";
import SegmentService from "../../services/segment.service";

import AddGuest from "../../components/bookings/AddGuest.vue";
import AddCharge from "../../components/bookings/AddCharge.vue";
import EditChargeBookingRoomRow from "../../components/bookings/EditChargeBookingRoomRow.vue";
import ParteViajero from "../../components/bookings/ParteViajero.vue";

import BookingroomAccommodationChargesModal from '@/components/bookings/BookingroomAccommodationChargesModal.vue';


import Swal from "sweetalert2";
import { ref } from 'vue';
import moment from 'moment-timezone';


export default {
    name: "checkin",
    components: {
        AddGuest,
        AddCharge,
        EditChargeBookingRoomRow,
        ParteViajero,
        BookingroomAccommodationChargesModal
    },
    data() {
        return {
            bookingroomId: this.$route.params.bookingroomId,
            hotelId: HotelService.getLocalHotel().id,
            bookingroom: [],
            booking: [],
            availability: [],
            guests: [],
            room: [],
            rate: [],
            selectedRoom: null,
            loading: true,
            loadingCheckinCheckout: true,
            modalRoomLoading: true,
            modalEditBRLoading: true,
            roomTypeOptions: [],
            rateOptions: [],
            availableRooms: [],
            checkin: "",
            checkout: "",
            checkinRegister: null,
            checkoutRegister: null,
            charges: [],
            extras: [],
            segments: [],
            updateBR: [],
            isCheckinChecked: false,
            isCheckoutChecked: false,
            range: {
                start: new Date(
                    new Date().getFullYear(),
                    new Date().getMonth(),
                    1
                ),
                end: new Date(
                    new Date().getFullYear(),
                    new Date().getMonth() + 1,
                    0
                ),
            },
            masks: {
                input: "YYYY-MM-DD",
                data: "DD/MM/YYYY",
            },
            minDate: null,
            triggeredByCheckbox: false,
            defaultCurrency: '',
            deleteChargeList: []
        };
    },

    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },
        isCheckinOnlineModuleActive() {
            // console.log(this.$store.state.hotel.hotelModules);
            return this.$store.state.hotel.hotelModules.some(
                (module) => module.key === "module_firma_digital"
            );
        },
    },
    methods: {
        async start() {
            this.loadingCheckinCheckout = true
            await this.getBookingRoom();


            this.selectedRoom = this.bookingroom.room;
            this.checkin = this.formatDate(this.toUTCDate(new Date(this.bookingroom.checkin)));
            this.checkout = this.formatDate(this.toUTCDate(new Date(this.bookingroom.checkout)));

            // console.log("Checkin: " + this.checkin)
            // console.log("Checkout: " + this.checkout)

            this.range.start = this.toUTCDate(new Date(this.bookingroom.checkin));
            this.range.end = this.toUTCDate(new Date(this.bookingroom.checkout));

            if (this.bookingroom.checkinRegister != null) {
                this.minDate = this.range.start;
            }

            if (
                this.bookingroom.checkinRegister != "" &&
                this.bookingroom.checkinRegister != null
            ) {
                this.checkinRegister = this.convertToAware(this.bookingroom.checkinRegister)
                window.$("#checkinRegister").attr("checked", "");
                this.isCheckinChecked = true
            } else {
                window.$("#checkoutRegister").attr("disabled", "");
            }
            if (
                this.bookingroom.checkoutRegister != "" &&
                this.bookingroom.checkoutRegister != null
            ) {
                this.checkoutRegister = this.convertToAware(this.bookingroom.checkoutRegister)
                window.$("#checkoutRegister").attr("checked", "");
                window.$("#checkinRegister").attr("disabled", "");
                this.isCheckoutChecked = true
            }

            // console.log(this.checkinRegister, "checkinRegister")
            // console.log(this.checkoutRegister, "checkoutRegister")
            this.loadingCheckinCheckout = false
            // console.log(this.bookingroom, "bookingroom")
            await this.getBooking();
            await this.getGuests();
            await this.getRoom();
            await this.getRate();
            await this.getExtras();
            await this.getCharges();
            await this.getSegments();

            this.loading = false;

            if (this.bookingroom.status == 9) {
                window
                    .$("#selectRoomType" + this.bookingroom.id)
                    .attr("disabled", "");
                window
                    .$("#selectRoom" + this.bookingroom.id)
                    .attr("disabled", "");
                window
                    .$("#selectRate" + this.bookingroom.id)
                    .attr("disabled", "");
            }


            // console.log(this.checkinRegister, "checkinRegister 2")
            // console.log(this.checkoutRegister, "checkoutRegister 2")
        },

        toUTCDate(localDate) {
            return new Date(
                localDate.getUTCFullYear(),
                localDate.getUTCMonth(),
                localDate.getUTCDate(),
            );
        },

        async reloadView() {
            await this.start()
        },


        async getBookingRoom() {
            var response = await BookingService.getBookingRoom(
                this.bookingroomId
            );
            if (response.status == 404 || this.hotelId != response.data.hotel) {
                this.$router.push("/");
            } else {
                this.bookingroom = response.data;
            }
            // console.log(this.bookingroom, "BookingRoom");
        },

        async getBooking() {
            var response = await BookingService.getBooking(
                this.bookingroom.booking
            );
            this.booking = response.data;
            // console.log(this.booking, "Booking");
        },

        async getRoom() {
            var id = this.bookingroom.room;
            this.room = [];
            if (id != "" && id !== null) {
                var response = await RoomService.getRoom(id);
                this.room = response.data;
            }
        },

        async getRate() {
            var id = this.bookingroom.rate;
            if (id != "" && id !== null) {
                var response = await RateService.getRate(id);
                this.rate = response.data;
            }
            // console.log(this.rate, "Rate")
        },

        async getExtras() {
            var response = await ExtraService.getAllExtras();
            this.extras = response.data;
            // console.log(this.extras, "Extras")
        },

        async getCharges() {
            var response = await BookingService.getBookingroomCharges(this.bookingroom.id);
            this.charges = response.data;
            // console.log(this.charges, "Charges")
        },

        async getSegments() {
            var response = await SegmentService.getAllSegments();
            this.segments = response.data;
        },

        async getGuests() {
            var response = await BookingService.getBookingroomGuests(
                this.bookingroomId
            );
            // console.log(response.data, "Guests");
            this.guests = response.data;
        },

        async updateGuestList() {
            await this.start();
        },

        async deleteGuest(guestId) {
            var response = await BookingService.deleteGuest(guestId);

            if (response.status == 204) {
                Swal.fire({
                    position: "top",
                    icon: "success",
                    title: "Se ha eliminado correctamente.",
                    showConfirmButton: false,
                    timer: 1500,
                    backdrop: false,
                });
                await this.updateGuestList();
            } else {
                Swal.fire({
                    position: "top",
                    icon: "error",
                    title: "Ha ocurrido un error.",
                    showConfirmButton: false,
                    timer: 1500,
                    backdrop: false,
                });
            }
        },

        async endCheckin() {
            var response = await BookingService.updateBookingRoom(
                this.bookingroom.id,
                { status: 9 }
            );
            response = await RoomService.updateRoom(this.bookingroom.room, {
                occupied: true,
            });

            if (response.status == 200) {
                this.bookingroom = response.data;
                Swal.fire({
                    position: "top",
                    icon: "success",
                    title: "Se ha guardado correctamente.",
                    showConfirmButton: false,
                    timer: 1500,
                    backdrop: false,
                });
                await this.start();
            } else {
                Swal.fire({
                    position: "top",
                    icon: "error",
                    title: "Ha ocurrido un error.",
                    showConfirmButton: false,
                    timer: 1500,
                    backdrop: false,
                });
            }
        },

        async getRoomTypeOptions() {
            var response = await RoomService.getAllRoomsType();
            this.roomTypeOptions = response.data;
        },

        async getRateOptions() {
            var response = await RateService.getActiveRates();
            this.rateOptions = response.data;
        },

        async getAvailableRooms() {
            var arr = [];
            arr.push({
                id: null,
                name: "Sin asignar",
                roomType: "",
            });
            if (this.bookingroom.room != "" && this.bookingroom.room !== null) {
                arr.push({
                    id: this.room.id,
                    name: this.room.name,
                    roomType: this.room.roomType.name,
                });
            }

            for (var i = 0; i < this.availability.length; i++) {
                for (
                    var r = 0;
                    r < this.availability[i].roomsAvailable.length;
                    r++
                ) {
                    arr.push({
                        id: this.availability[i].roomsAvailable[r].id,
                        name: this.availability[i].roomsAvailable[r].name,
                        roomType:
                            this.availability[i].roomsAvailable[r].roomType,
                    });
                }
            }
            this.availableRooms = arr;
            // console.log(this.availableRooms, "Available Rooms");
        },

        async updateRoom() {
            window.$("#submitButtonUpdateRoom").attr("disabled", "");
            window.$("#submitButtonUpdateRoom").empty();
            window
                .$("#submitButtonUpdateRoom")
                .append(
                    '<span class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>Guardando'
                );
            if (this.selectedRoom == 0) {
                this.selectedRoom = null;
            }

            var response = await BookingService.updateBookingRoom(
                this.bookingroomId,
                {
                    room: this.selectedRoom,
                }
            );
            if (response.status == 200) {
                window.$("#modalChangeRoom").hide();
                window.$("body").removeClass("modal-open");
                window.$(".modal-backdrop").remove();
                await this.getBookingRoom();
                await this.getRoom();
                Swal.fire({
                    position: "top",
                    icon: "success",
                    title: "Se ha guardado correctamente.",
                    showConfirmButton: false,
                    timer: 2000,
                });
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: response.data.message,
                    confirmButtonColor: "#0091ff",
                });
            }

            window.$("#submitButtonUpdateRoom").removeAttr("disabled");
            window.$("#submitButtonUpdateRoom").empty();
            window.$("#submitButtonUpdateRoom").append("Guardar");
        },

        async updateChannelInventory(data, add) {
            await BookingService.updateChannelInventory(
                {
                    startDate: this.booking.checkin,
                    endDate: this.booking.checkout,
                    add: add,
                },
                data
            );
        },

        async updateBookingRoom() {
            window.$("#submitButtonUpdateBR").attr("disabled", "");
            window.$("#submitButtonUpdateBR").empty();
            window
                .$("#submitButtonUpdateBR")
                .append(
                    '<span class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>Guardando'
                );

            // Obtenemos el objeto del room selected

            var roomType_before = null;
            var roomType_after = null;
            if (this.bookingroom.roomType != null) {
                roomType_before = this.bookingroom.roomType.id;
            }
            if (this.updateBR.roomType != null) {
                roomType_after = this.updateBR.roomType;
            }

            // console.log(roomType_before, "roomType_before");
            // console.log(roomType_after, "roomType_after");

            // Actualizamos el BookingRoom
            var response = await BookingService.updateBookingRoom(
                this.bookingroomId,
                {
                    roomType: this.updateBR.roomType,
                    rate: this.updateBR.rate,
                    adults: this.updateBR.adults,
                    children: this.updateBR.children,
                }
            );

            if (response.status == 200) {
                // Si el el tipo de habitación ha cambiado, actualizamos en el Channel
                if (roomType_before != null && roomType_after != null) {
                    if (roomType_before != roomType_after) {
                        // console.log("CASE 1");
                        // Sumamos 1 en el aanterior tipo de habitación
                        await this.updateChannelInventory(
                            [{ roomType: roomType_before, quantity: 1 }],
                            1
                        );
                        // Restamos 1 en el nuevo tipo de habitación
                        await this.updateChannelInventory(
                            [{ roomType: roomType_after, quantity: 1 }],
                            0
                        );
                    }
                } else if (roomType_before == null && roomType_after != null) {
                    // console.log("CASE 2");
                    // Restamos 1 en el nuevo tipo de habitación
                    await this.updateChannelInventory(
                        [{ roomType: roomType_after, quantity: 1 }],
                        0
                    );
                } else if (roomType_after == null && roomType_before != null) {
                    // console.log("CASE 3");
                    // Sumamos 1 en el aanterior tipo de habitación
                    await this.updateChannelInventory(
                        [{ roomType: roomType_before, quantity: 1 }],
                        1
                    );
                }

                window.$("#modalEditBR").hide();
                window.$("body").removeClass("modal-open");
                window.$(".modal-backdrop").remove();
                await this.getBookingRoom();
                await this.getRate();
                Swal.fire({
                    position: "top",
                    icon: "success",
                    title: "Se ha guardado correctamente.",
                    showConfirmButton: false,
                    timer: 2000,
                });
            } else {
                Swal.fire({
                    position: "top",
                    icon: "error",
                    title: "Ha ocurrido un error.",
                    showConfirmButton: false,
                    timer: 2000,
                });
            }

            window.$("#submitButtonUpdateBR").removeAttr("disabled");
            window.$("#submitButtonUpdateBR").empty();
            window.$("#submitButtonUpdateBR").append("Guardar");
        },

        async cancelBR() {
            Swal.fire({
                icon: "question",
                title: "¿Estás seguro que quieres cancelar esta habitación?",
                text: "Al cancelar la habitación se actualizará la disponiblidad en el Channel Manager",
                showDenyButton: false,
                showCancelButton: true,
                confirmButtonText: "Confirmar cancelación",
                confirmButtonColor: "#0091ff",
                cancelButtonText: "No",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    var response = await BookingService.cancelBookingRoom({
                        bookingroom: this.bookingroomId,
                        cancel: true,
                    });
                    if (response.status == 200) {
                        await this.getBookingRoom();
                        Swal.fire({
                            title: "¡Cancelada correctamente!",
                            icon: "success",
                            confirmButtonColor: "#0091ff",
                        });
                    }
                }
            });
        },

        async getAvailability() {
            var response = await BookingService.getAvailability({
                startDate: this.bookingroom.checkin,
                endDate: this.bookingroom.checkout,
            });
            this.availability = response.data;
            // console.log(this.availability, "Availability");
        },

        async changeRoomModal() {
            this.modalRoomLoading = true;
            await this.getAvailability();
            await this.getAvailableRooms();
            this.modalRoomLoading = false;
        },

        async changeEditBRModal() {
            this.modalEditBRLoading = true;
            var roomType = null;
            if (this.bookingroom.roomType != null) {
                roomType = this.bookingroom.roomType.id;
            }
            this.updateBR = {
                roomType: roomType,
                rate: this.bookingroom.rate,
                price: this.bookingroom.price,
                adults: this.bookingroom.adults,
                children: this.bookingroom.children,
            };
            await this.getRoomTypeOptions();
            await this.getRateOptions();
            this.modalEditBRLoading = false;
        },

        formatDate(inputDate, includeTime = false, shortMonth = false, formatForAPI = false) {
            const monthsShort = ["ene.", "feb.", "mar.", "abr.", "may.", "jun.", "jul.", "ago.", "sep.", "oct.", "nov.", "dic."];
            const monthsLong = ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"];

            var result = "";
            if (inputDate) {
                // Asumiendo que inputDate es un objeto Date, convertirlo a UTC
                let d = new Date(inputDate);

                if (formatForAPI) {
                    // Formato para API en UTC
                    result = `${d.getFullYear()}-${(d.getMonth() + 1).toString().padStart(2, '0')}-${d.getDate().toString().padStart(2, '0')}`;
                } else if (includeTime) {
                    // Formato con hora y mes en texto
                    let monthNames = shortMonth ? monthsShort : monthsLong;
                    result = `${d.getDate()} ${monthNames[d.getMonth()]} ${d.getFullYear()} - ${d.getUTCHours()}:${d.getUTCMinutes().toString().padStart(2, '0')}`;
                } else {
                    // Formato solo fecha con mes en texto
                    let monthNames = shortMonth ? monthsShort : monthsLong;
                    result = `${d.getDate()} ${monthNames[d.getMonth()]} ${d.getFullYear()}`;
                }
                // console.log(result)
            }

            return result;

        },

        handleCheckinCheckboxClick() {
            this.isCheckinChecked = !this.isCheckinChecked;
            this.triggeredByCheckbox = true;
            this.registerHour('checkinRegister');
        },
        handleCheckinMazPickerChange(value) {
            if (!this.triggeredByCheckbox) {
                this.registerHour('checkinRegister');
            }
            this.triggeredByCheckbox = false;
        },

        handleCheckoutCheckboxClick() {
            this.isCheckoutChecked = !this.isCheckoutChecked;
            this.triggeredByCheckbox = true;
            this.registerHour('checkoutRegister');
        },
        handleCheckoutMazPickerChange(value) {
            if (!this.triggeredByCheckbox) {
                this.registerHour('checkoutRegister');
            }
            this.triggeredByCheckbox = false;
        },


        async registerHour(key) {
            // console.log("registerHour")
            if (!this.loading) {
                var response = "";
                var checked = window.$("#" + key).is(":checked");

                if (key == "checkinRegister") {
                    if (checked == 1) {
                        this.isCheckinChecked = true;
                        var checkinRegister = this.convertToAware(new Date())
                        if (this.checkinRegister != null) {
                            checkinRegister = this.convertToAware(this.checkinRegister)
                        } else {
                            this.checkinRegister = checkinRegister
                        }
                        response = await BookingService.checkin({
                            checkinRegister: checkinRegister,
                            bookingroom: this.bookingroom.id,
                            status: true,
                        });
                        window.$("#checkoutRegister").removeAttr("disabled");
                        this.minDate = this.range.start;
                    } else {
                        this.isCheckinChecked = false;
                        this.checkinRegister = null
                        response = await BookingService.checkin({
                            checkinRegister: null,
                            bookingroom: this.bookingroom.id,
                            status: false,
                        });
                        window.$("#checkoutRegister").attr("disabled", "");
                        this.minDate = null;
                    }

                    if (response.status == 200) {
                        this.bookingroom = response.data;
                    } else {
                        window.$("#" + key).prop("checked", false);
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response.data.message,
                            confirmButtonColor: "#0091ff",
                        });
                        this.isCheckinChecked = false
                    }

                    // console.log(response.data, "Register Checkin Hour");
                } else if (key == "checkoutRegister") {
                    if (checked == 1) {
                        this.isCheckoutChecked = true;
                        var checkoutRegister = this.convertToAware(new Date())
                        if (this.bookingroom.checkoutRegister != null) {
                            checkoutRegister = this.convertToAware(this.bookingroom.checkoutRegister)
                        } else {
                            this.checkoutRegister = checkoutRegister
                        }
                        response = await BookingService.checkout({
                            checkoutRegister: checkoutRegister,
                            bookingroom: this.bookingroom.id,
                            status: true,
                        });
                        window.$("#checkinRegister").attr("disabled", "");
                    } else {
                        this.checkoutRegister = null
                        this.isCheckoutChecked = false;
                        response = await BookingService.checkout({
                            checkoutRegister: null,
                            bookingroom: this.bookingroom.id,
                            status: false,
                        });
                        window.$("#checkinRegister").removeAttr("disabled");
                    }
                    this.bookingroom = response.data;
                    // console.log(
                    // 	response.data,
                    // 	"Register Checkout Hour"
                    // );
                }
            }
        },


        convertToAware(datetime) {
            // Parse the naive datetime string into a moment object in local time
            let naiveMoment = moment(datetime);

            // Guess the user's current timezone
            let userTimeZone = moment.tz.guess(true);

            // Convert it to a timezone-aware moment object in the user's local timezone
            let awareMoment = naiveMoment.tz(userTimeZone);

            // Update the utcAwareDate with the ISO string of the aware moment
            return awareMoment.format('YYYY-MM-DDTHH:mm:ssZ');
        },

        async changeBookingRoomDates() {
            window.$("#submitChangeBookingRoomDates").empty();
            window.$("#submitChangeBookingRoomDates").attr("disabled", "");
            window
                .$("#submitChangeBookingRoomDates")
                .append(
                    '<span class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>Guardando'
                );


            var response = await BookingService.changeBookinRoomgDates({
                checkin: this.formatDate(this.range.start, false, false, true),
                checkout: this.formatDate(this.range.end, false, false, true),
                bookingroom: this.bookingroom.id,
            });

            window.$("#submitChangeBookingRoomDates").empty();
            window.$("#submitChangeBookingRoomDates").append("Actualizar");
            window.$("#submitChangeBookingRoomDates").removeAttr("disabled");

            if (response.status == 200) {
                window.$("#modalEditDates").hide();
                window.$("body").removeClass("modal-open");
                window.$(".modal-backdrop").remove();

                Swal.fire({
                    icon: "success",
                    title: "Las fechas de la habitación se han actualizado correctamente.",
                    confirmButtonColor: "#0091ff",
                }).then(() => {
                    this.start();
                });
            } else if (response.status == 400) {
                window.$("#modalEditDates").hide();
                window.$("body").removeClass("modal-open");
                window.$(".modal-backdrop").remove();

                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: response.data.message,
                    confirmButtonColor: "#0091ff",
                });
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Ha ocurrido un error. Vuelve a intentarlo. Si el error persiste ponte en contacto con soporte@hospedium.com",
                    confirmButtonColor: "#0091ff",
                });
            }
        },

        addChargeToDeleteList(chargeId, isChecked) {
            if (isChecked) {
                // Añadir el ID del cargo a la lista si no está ya presente
                if (!this.deleteChargeList.includes(chargeId)) {
                    this.deleteChargeList.push(chargeId);
                }
            } else {
                // Eliminar el ID del cargo de la lista si está presente
                const index = this.deleteChargeList.indexOf(chargeId);
                if (index !== -1) {
                    this.deleteChargeList.splice(index, 1);
                }
            }
            // console.log(this.deleteChargeList);
        },

        async handleDeleteChargeList() {
            if (this.deleteChargeList.length === 0) {
                this.$swal.fire({
                    icon: 'warning',
                    title: 'No hay cargos seleccionados',
                    text: 'Por favor, seleccione al menos un cargo para eliminar.',
                });
                return;
            }

            const result = await this.$swal.fire({
                title: '¿Estás seguro?',
                text: "Esta acción eliminará los cargos seleccionados.",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sí, eliminar',
                cancelButtonText: 'Cancelar'
            });

            if (result.isConfirmed) {
                this.$swal.fire({
                    title: 'Eliminando...',
                    text: 'Por favor, espera.',
                    allowOutsideClick: false,
                    didOpen: () => {
                        this.$swal.showLoading();
                    }
                });

                try {
                    const response = await ChargeService.deleteChargesFromBookingroom(this.bookingroom.id, { charges: this.deleteChargeList });

                    if (response.status === 200) {
                        this.$swal.fire({
                            icon: 'success',
                            title: 'Cargos eliminados',
                            text: 'Los cargos seleccionados han sido eliminados correctamente.',
                            confirmButtonColor: '#3085d6'
                        });
                        this.charges = [];
                        this.start();
                    } else {
                        throw new Error('Error al eliminar los cargos');
                    }
                } catch (error) {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Ha ocurrido un error al eliminar los cargos. Inténtelo de nuevo más tarde.',
                        confirmButtonColor: '#d33'
                    });
                }
            }
        }


    },
    created() {
        this.defaultCurrency = localStorage.getItem('currency') || 'EUR';
        this.start();
    },
    mounted() {
        if (!this.currentUser) {
            this.$router.push("/login");
        }
    },
};
</script>