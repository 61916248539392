<template>
    <div class="page-app">
        
        <div id="channel-filter" class="card mb-3">
            <div class="card-body">
                <form class="" @submit.prevent>
                    <div class="mb-4">
                        <div class="mb-3">Seleccionar fechas</div>
                        <DatePicker
                            v-model="range"
                            mode="date"
                            :masks="masks"
                            is-range
                        >
                            <template v-slot="{ inputValue, inputEvents, isDragging }">
                                <div class="d-md-flex d-block justify-content-start align-items-center">
                                    <div class="input-group  flex-grow-0" style="width: auto;">
                                        <span class="input-group-text" id="basic-addon1"><i class="far fa-calendar-week"></i></span>
                                        <input
                                            class="flex-grow-0 ps-3 pe-2 py-1 bg-gray-100 border rounded"
                                            :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                                            :value="inputValue.start"
                                            v-on="inputEvents.start"
                                        />
                                    </div>
                                    <span class="nput-group mx-2 flex-grow-0">
                                        <i class="fas fa-long-arrow-alt-right stroke-current text-gray-600"></i>
                                    </span>
                                    <div class="input-group flex-grow-0" style="width: auto;">
                                        <span class="input-group-text" id="basic-addon1"><i class="far fa-calendar-week"></i></span>
                                        <input
                                            class="flex-grow-0 ps-3 pe-2 py-1 bg-gray-100 border rounded"
                                            :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                                            :value="inputValue.end"
                                            v-on="inputEvents.end"
                                           
                                        />
                                    </div>
                                </div>
                            </template>
                        </DatePicker>
                    </div>
                </form>
            </div>
        </div>
        <div id="channel-inventory" class="card">
            <div class="card-body">
                <div class="table-responsive">
                    <table id="" class="table table-bordered">
                        <thead>
                            <tr>
                                <th></th>
                                <th class="text-center" :key="item" v-for="item in dates" :class="'th-'+item.planning.class"><small>{{item.planning.day}}</small></th>
                            </tr>
                        </thead>
                        <tbody :key="item" v-for="item in map">
                            <tr>
                                <td ><strong>{{ item.room.name }}</strong></td>
                                <td :key="a" v-for="a in item.room.availability" :class="a.class"><small>{{ a.quantity }}</small></td>
                            </tr>
                            <!-- <tr :key="rate" v-for="rate in item.rates">
                                <td>{{ rate.name }}</td>
                                <td :key="a" v-for="a in rate.availability" class="text-right"><small>{{ a.price }}</small></td>
                            </tr> -->
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<style> 
    #channel-inventory thead th:first-child  {
        left:0px;
        z-index: 3;
    }
  
   
</style>

<script>

    import RoomcloudService from "../../services/roomcloud.service";
    import RoomService from "../../services/room.service";
    import HotelService from "../../services/hotel.service";


    export default {
        name: 'Channel',
        components: {
        },
        data () {
            return {
                inventory: [],
                dates: [],
                roomsType: [],
                map: [],
                rates: [],
                range: {
                    start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
                    end: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
                },
                masks: {
                    input: 'YYYY-MM-DD',
                },
            }
        },
        computed: {
            currentUser() {
                return this.$store.state.auth.user;
            },
        },
        watch: {
            range: {
                handler: function () {
                    this.onDateRangeChange();
                    // console.log(this.range)
                },
                deep: true
            }
        },
        methods: {
            onDateRangeChange() {
                this.start();
            },
            async start() {

                this.dates = await this.getDates(this.range.start, this.range.end);
                await this.getInventory();
                await this.getRates();
                await this.getRoomsType();
                
                // console.log(this.range.start);
                // console.log(this.range.end)

                // console.log(this.dates)
                await this.mapInventory();
            },
            async getInventory() {
                var response = await RoomcloudService.getInventory({
                    hotel: HotelService.getLocalHotel().id,
                    startDate: this.range.start.toISOString().split('T')[0],
                    endDate: this.range.end.toISOString().split('T')[0],
                });
                this.inventory = Object.freeze(response.data.availability)

                console.log(this.inventory, "Inventory");
            },
            async getRates() {
                var response = await RoomcloudService.getRates({
                    hotel: HotelService.getLocalHotel().id,
                });
                this.rates = Object.freeze(response.data.rate);

                // console.log(this.rates)
            },
            async getRoomsType() {
                var response = await RoomService.getAllRoomsType();
                this.roomsType = response.data;
                console.log(this.roomsType, "RoomTypes")
                
            },

            mapInventory() {
                this.map = []
                // console.log(this.inventory)
                for(var i=0; i<this.roomsType.length; i++) {

                    // console.log(this.roomsType[i])
                    // console.log(this.inventory);
                    var availability = []
                    var c = null
                    var mapRate = []
                    var rowRate = []
                    for(var d=0; d<this.inventory.length; d++) {
                        // console.log(parseInt(this.roomsType[i].roomcloudID[0]))
                        // console.log(parseInt(this.inventory[d].roomId))

                        var ratesArray = []
                        if (this.roomsType[i].roomcloudID.includes(parseInt(this.inventory[d].roomId))) {
                            // console.log("YESSSSSS")
                            if(this.inventory[d].quantity>0){
                                c = 'bg-info';
                            } else {
                                c = 'bg-danger';
                            }
                            availability.push({
                                day: this.inventory[d].day,
                                quantity: this.inventory[d].quantity,
                                class: c
                            });

                            for(var r=0; r<this.inventory[d].rate.length; r++) {
                                ratesArray.push({
                                    day: this.inventory[d].day,
                                    coa: this.inventory[d].rate[r].coa,
                                    cod: this.inventory[d].rate[r].cod,
                                    currency: this.inventory[d].rate[r].currency,
                                    minimumStay: this.inventory[d].rate[r].minimumStay,
                                    price: this.inventory[d].rate[r].price,
                                    rateId: this.inventory[d].rate[r].rateId
                                });
                            }
                            rowRate.push(ratesArray)
                            // console.log(mapRate)
                        }
                    }
                    let mapRoomObject = {
                        name: this.roomsType[i].name,
                        availability: availability,
                        isRoom: true,
                        isRate: false
                    }
                    
                    // console.log(this.rates)
                    for(var t=0; t<this.rates.length; t++) {

                        // console.log(this.rates[t])
                        var arrayRowRate = []
                        for(var p = 0; p < rowRate.length; p++) {

                            // console.log(rowRate[p])
                            for(var j = 0; j < rowRate[p].length; j++) {

                                if(this.rates[t].rateId == rowRate[p][j].rateId) {
                                    arrayRowRate.push(rowRate[p][j]);
                                }
                            }
                        }
                        

                        // console.log(arrayRowRate)

                        if (arrayRowRate.length != 0) {
                            let mapRateObject = {
                                name: this.rates[t].description,
                                availability: arrayRowRate,
                                isRoom: false,
                                isRate: true
                            }
                            mapRate.push(mapRateObject)
                        }
                        
                    }

                    this.map.push({
                        room: mapRoomObject,
                        rates: mapRate
                    })

                    
                }
                console.log(JSON.parse(JSON.stringify(this.map)))

            },
            
            getDates(startDate, endDate) { 
                // Returns an array of dates between the two dates
                const dates = []
                let currentDate = startDate

                var days = ['dom','lun','mar','mié','jue','vie','sáb'];
                var months = ['ene','feb','mar','abr','may','jun','jul','ago','sep','oct','nov','dic'];


                while (currentDate <= endDate) {
                    var options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
                    var date1 = currentDate.toLocaleDateString('es-ES', options)
                    var date2 = this.formatDate(currentDate)
                    var plannig_date = {
                        day: days[currentDate.getDay()] + ' ' + currentDate.getDate() +  ' ' + months[currentDate.getMonth()],
                        class: currentDate.getDay()
                    }
                    dates.push({long: date1, short: date2, planning: plannig_date})
                    currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1))
                }
                // console.log(dates, 'dates')
                return dates;
            },

            formatDate(date) {
                var d = new Date(date),
                    month = '' + (d.getMonth() + 1),
                    day = '' + d.getDate(),
                    year = d.getFullYear();

                if (month.length < 2) 
                    month = '0' + month;
                if (day.length < 2) 
                    day = '0' + day;

                return [year, month, day].join('-');
            }, 
        },
        created() {
            this.start();
        },
        mounted() {
            if (!this.currentUser) {
                this.$router.push('/login');
            }
        }
    };
</script>