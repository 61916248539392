 <template>
    <div class="page-app">
        <div class="mb-3">
            <label>Fecha seleccionada</label>
            <DatePicker 
                    v-model="day" 
                    :masks="masks"
                >
                    <template v-slot="{ inputValue, inputEvents }">

                        <input
                        class="form-control"
                        :value="inputValue"
                        v-on="inputEvents"
                        />
                    </template>
            </DatePicker> 
        </div>
        <div v-if="!loading" class="row">
            <div class="col-md-6">
                <div class="card border-0 shadow-sm mb-3">
                    <div class="card-header bg-white">
                        <div class="d-flex align-items-center justify-content-between">
                            <div>
                                <h3>Checkin</h3>
                                <p class="lead mb-2">{{formatDate(day, false, true) }}</p>

                            </div>
                            <div>
                                <div v-if="isFloat(progressCheckin) || isInt(progressCheckin)" class="text-end">
                                    <div style="font-size: 2em;">{{ progressCheckin + "%" }}</div>
                                    <div>Check-in completados: {{ numCheckinDone + ' de ' +  numCheckinTotal}}</div>
                                </div>
                                <span v-else><i class="fa-regular fa-circle-info me-2"></i>No hay entradas para hoy</span>
                            </div>
                        </div>

                        <div class="progress">
                            <div class="progress-bar progress-bar-striped progress-bar-animated bg-success" role="progressbar" :aria-valuenow="progressCheckin" aria-valuemin="0" aria-valuemax="100" :style="'width: ' + progressCheckin + '%'"></div>
                            
                        </div>
                    </div>
                    <div class="card-body" style="max-height: 500px; overflow: auto;">
                        <div v-for="item in checkinList.bookings" :key="item">
                            <div v-for="br in item.bookingrooms" :key="br">
                                <router-link :to="{ name: 'checkin', params: {bookingroomId: br.id}}" class="bookingroom-link">
                                    <div class="bookingroom-box">
                                        <div class="row">
                                            <div class="col-4">
                                                    <div>
                                                        <div style="font-weight: 400;">{{ "Reserva: #" + item.id }}</div>
                                                        <div>{{ item.portal }}</div>
                                                        <div class="text-transform text-muted"><small>{{ item.client }}</small></div>
                                                    </div>
                                            </div>

                                            <div class="col-4">
                                                <div class="text-muted"><small>{{ br.roomType.name }}</small></div>
                                                <div v-if="br.room.name != ''" class="mb-2">{{ br.room.name }}</div>
                                                <div v-else class="mb-2">Sin asignar</div>
                                                <div class="text-muted"><small>Huéspedes registrados</small></div>
                                                <div :class="{ 'text-danger': !br.num_guests }">{{ br.num_guests }}</div>
                                            </div>

                                            <div class="col-4">
                                                <span v-if="br.status == 9 || br.status == 10" class="badge badge-light-success">Completado<i class="fa-regular fa-check-double ms-2"></i></span>
                                                <span v-else-if="br.status == 6" class="badge badge rounded-pill d-block p-2 bg-dark">No Show</span>
                                               
                                                 <span v-else class="badge badge-light-warning ms-2">Pendiente<i class="fa-regular fa-bars-staggered"></i></span>
                                                <div class="text-center mt-2"><small>{{ formatDate(br.checkinRegister, true, true) }}</small></div>
                                            </div>
                                        </div>
                                    </div>
                                </router-link>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="card border-0 shadow-sm mb-3">
                    <div class="card-header bg-white">
                        <div class="d-flex align-items-center justify-content-between">
                            <div>
                                <h3>Checkout</h3>
                                <p class="lead mb-2">{{formatDate(day, false, true) }}</p>
                            </div>
                            <div>
                                <div v-if="isFloat(progressCheckout) || isInt(progressCheckout)" class="text-end">
                                    <div style="font-size: 2em;">{{ progressCheckout + "%" }}</div>
                                    <div>Check-out completados: {{ numCheckoutDone + ' de ' +  numCheckoutTotal}}</div>
                                </div>
                                <span v-else><i class="fa-regular fa-circle-info me-2"></i>No hay salidas para hoy</span>
                            </div>
                        </div>

                        <div class="progress">
                            <div class="progress-bar progress-bar-striped progress-bar-animated bg-success" role="progressbar" :aria-valuenow="progressCheckout" aria-valuemin="0" aria-valuemax="100" :style="'width: ' + progressCheckout + '%'"></div>
                        </div>
                    </div>
                    <div class="card-body" style="max-height: 500px; overflow: auto;">
                        <div v-for="item in checkoutList.bookings" :key="item">
                            <div v-for="br in item.bookingrooms" :key="br">
                                <router-link :to="{ name: 'checkin', params: {bookingroomId: br.id}}" class="bookingroom-link">
                                    <div class="bookingroom-box">
                                        <div class="row">
                                            <div class="col-4">
                                                    <div>
                                                        <div style="font-weight: 400;">{{ "Reserva: #" + item.id }}</div>
                                                        <div>{{ item.portal }}</div>
                                                        <div class="text-transform text-muted"><small>{{ item.client }}</small></div>
                                                    </div>
                                            </div>

                                            <div class="col-4">
                                                <div class="text-muted"><small>{{ br.roomType.name }}</small></div>
                                                <div v-if="br.room.name != ''" class="mb-2">{{ br.room.name }}</div>
                                                <div v-else class="mb-2">Sin asignar</div>
                                                <div class="text-muted"><small>Huéspedes registrados</small></div>
                                                <div :class="{ 'text-danger': !br.num_guests }">{{ br.num_guests }}</div>
                                            </div>

                                            <div class="col-4">
                                                <span v-if="br.status == 10" class="badge badge-light-success">Completado<i class="fa-regular fa-check-double ms-2"></i></span>
                                                <span v-else-if="br.status == 6" class="badge badge rounded-pill d-block p-2 bg-dark">No Show</span>
                                                <span v-else class="badge badge-light-warning">Pendiente<i class="fa-regular fa-bars-staggered ms-2"></i></span>
                                                <div class="text-center mt-2"><small>{{ formatDate(br.checkoutRegister, true, true) }}</small></div>
                                            </div>
                                        </div>
                                    </div>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else id="spinner-search" class="col-12 text-center py-5">
            <div  class="spinner-border text-primary" role="status" style="width: 2rem; height: 2rem; visibility: visble;">
                <span class="visually-hidden">Loading...</span>
            </div>
            <div>Cargando información...</div>
        </div>
    </div>
</template>

<style>
    .badge-soft-success {
        color: #00864e!important;
        background-color: #ccf6e4;
    }
    .badge-soft-warning {
        color: #9d5228!important;
        background-color: #fde6d8;
    }
    .bookingroom-link,
    .bookingroom-link:hover {
        text-decoration: none; color: #000;
    }
    .bookingroom-box {
        background: #f5f8fa;
        margin-bottom: 10px;
        padding: 10px;
        border-radius: 10px;
    }

    .bookingroom-box:hover {
        background: #f1f6f9;
    }
</style>
<script>

    import BookingService from "../../services/booking.service.js";

    export default {
        name: 'CheckinCheckoutList',
        components: {
            
        },
        data () {
            return {
                loading: true,
                checkinList: [],
                checkoutList: [],
                day: new Date(),
                progressCheckin: 0,
                progressCheckout: 0,
                numCheckinDone: 0,
                numCheckinTotal: 0,
                numCheckoutDone: 0,
                numCheckoutTotal: 0,
                masks: {
                    title: 'MMMM YYYY',
                    weekdays: 'W',
                    navMonths: 'MMM',
                    input: ['L', 'YYYY-MM-DD', 'YYYY/MM/DD'],
                    dayPopover: 'WWW, MMM D, YYYY',
                    data: ['L', 'YYYY-MM-DD', 'YYYY/MM/DD'],
                }
            }
        },
        computed: {
            currentUser() {
                return this.$store.state.auth.user;
            }
        },
        watch: {
            day: {
                handler: function () {
                    this.start();
                    // console.log(this.range)
                },
                deep: true
            }
        },
        methods: {   
            async start() {
                this.loading = true;
                await this.getCheckinList();
                await this.getCheckoutList();
                this.numCheckinDone = this.checkinList.num_checkin_done
                this.numCheckinTotal = this.checkinList.total_checkin_day
                this.numCheckoutDone = this.checkoutList.num_checkout_done
                this.numCheckoutTotal = this.checkoutList.total_checkout_day
                this.progressCheckin = Math.round(100 * parseFloat(this.checkinList.num_checkin_done / this.checkinList.total_checkin_day));
                this.progressCheckout = Math.round( 100 * parseFloat(this.checkoutList.num_checkout_done / this.checkoutList.total_checkout_day));
                this.loading = false;

            },

            async getCheckinList() {
                var response = await BookingService.getBookingsCheckinListDay({"day": this.formatDate(this.day, false, false, true)});

                this.checkinList = response.data;
                // console.log("ChekinList", this.checkinList);
            },

            async getCheckoutList() {
                var response = await BookingService.getBookingsCheckoutListDay({"day": this.formatDate(this.day, false, false, true)});

                this.checkoutList = response.data;
                // console.log("ChekoutList", this.checkoutList);
            },

            formatDate(date, hour=false, short=false, api=false) {
                var months = []
                if (short) {
                    months = ['ene.','feb.','mar.','abr.','may.','jun.','jul.','ago.','sep.','oct.','nov.','dic.'];
                } else {
                    months = ['enero','febrero','marzo','abril','mayo','junio','julio','agosto','septiembre','octubre','noviembre','diciembre'];
                }
                
                var result = '';
                if (date != '' && date != null) {
                    const d = new Date(date);
                    if (api) {
                        result = d.getFullYear() +'-'+ (d.getMonth() + 1) +'-'+ d.getDate();
                    }else if (hour) {
                        result = d.getDate() +  ' ' + months[d.getMonth()] +  ' ' + d.getFullYear() + ' - ' + d.getHours() + ':' + (d.getMinutes()<10?'0':'') + d.getMinutes();
                    } else {
                        result = d.getDate() +  ' ' + months[d.getMonth()] +  ' ' + d.getFullYear();
                    }
                }
                return result;
            },

            isInt(n){
                return Number(n) === n && n % 1 === 0;
            },

            isFloat(n){
                return Number(n) === n && n % 1 !== 0;
            }


        },
        created() {
            this.start()
        },
        mounted() {
            if (!this.currentUser) {
                this.$router.push('/login');
            }
        }
    };
</script>