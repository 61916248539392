<template>
    <td v-if="!loading" class="cell-invoice-charge">
        <div>{{ charge.date }}</div>
    </td>
    <td v-if="!loading && invoiceInfo.showDescriptionInInvoice" class="cell-invoice-charge text-uppercase">
        <span v-if="charge.isAdvancePayment">ANTICIPO: </span>
        {{ description }}
    </td>
    <td v-if="!loading && invoiceInfo.showRoomInInvoice" class="text-center cell-invoice-charge">
        <div v-if="charge.room_name != null && !invoice.enableGroupCharges" class="text-uppercase">
            <small>{{ charge.room_name }}</small>
        </div>
        <div v-if="charge.roomType_name != null && invoice.enableGroupCharges" class="text-uppercase">
            <small>{{ charge.roomType_name }}</small>
        </div>
    </td>
    <td v-if="!loading && invoiceInfo.showQuantityInInvoice" class="text-center cell-invoice-charge">
        {{ charge.quantity }}
    </td>
    <td v-if="!loading" class="text-center cell-invoice-charge">
        {{ formatCurrency(charge.baserate_amount, defaultCurrency) }}
    </td>
    <td v-if="!loading && invoiceInfo.showDiscountInInvoice" class="text-center cell-invoice-charge">
        {{ charge.discount + "%" }}
    </td>
    <td v-if="!loading" class="text-center cell-invoice-charge">
        <div v-if="invoice.show_taxes">
            <span v-if="charge.iva">{{ charge.iva + "%" }}</span>
            <span v-else="charge.iva">{{ "0%" }}</span>
        </div>
        <div v-else><em>Sin impuestos</em></div>
    </td>
    <td v-if="!loading" class="text-center cell-invoice-charge">
        <div v-if="invoice.show_taxes">
            <span v-if="charge.isAdvancePayment && !invoice.isAdvancePayment">
                {{ "-" + formatCurrency(charge.total_amount, defaultCurrency) }}
            </span>
            <span v-else>{{ formatCurrency(charge.total_amount, defaultCurrency) }}</span>
        </div>
        <div v-else>
            <span v-if="charge.isAdvancePayment && !invoice.isAdvancePayment">
                {{ "-" + formatCurrency(charge.baserate_amount, defaultCurrency) }}
            </span>
            <span v-else>{{ formatCurrency(charge.baserate_amount, defaultCurrency) }}</span>
        </div>

    </td>
    <td v-if="!loading" class="cell-invoice-charge">
        <div v-if="!invoice.isRectificativa &&
        invoice.invoice_rectificativa == null &&
        !invoice.enableGroupCharges
        ">
            <button class="btn btn-light btn-active-light-primary me-2" data-bs-toggle="modal" :data-bs-target="'#modalEditCharge' + charge.id">
                <i class="fa-regular fa-pen-to-square"></i>
            </button>

            <div class="modal fade" :id="'modalEditCharge' + charge.id" tabindex="-1" aria-labelledby="modalEditCharge" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content p-2">
                        <form @change="updatePrice">
                            <div class="modal-header border-0">
                                <h5 class="modal-title">Editar cargo</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>

                            <div class="modal-body">
                                <div class="form-group mb-3">
                                    <div v-if="!editCharge.isAccommodation &&
        !editCharge.isAdvancePayment
        " class="form-group mb-3">
                                        <label class="form-label">Selecciona un extra</label>
                                        <select class="form-control" v-model="editCharge.extra" required @change="updateExtra">
                                            <option :value="null" selected>
                                                Otro
                                            </option>
                                            <option :key="item" v-for="item in extras" :value="item.id">
                                                {{ item.name }}
                                            </option>
                                        </select>
                                    </div>
                                    <label class="form-label w-100">Descripción</label>
                                    <input type="text" class="form-control" v-model="editCharge.description" required />
                                </div>
                                <div class="row">
                                    <div class="form-group mb-3 col-6">
                                        <label class="form-label">Segmentación</label>
                                        <select class="form-control" v-model="editCharge.segment" required>
                                            <option :key="item" v-for="item in segments" :value="item.id">
                                                {{ item.name }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="form-group mb-3 col-6">
                                        <label class="form-label">Cantidad por día</label>
                                        <input type="number" class="form-control" v-model="editCharge.quantity" required />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group mb-3 col-6">
                                        <label class="form-label w-100">Precio unitario
                                            ({{ defaultCurrency }})</label>
                                        <input type="text" class="form-control" v-model="editCharge.price_unit" />
                                        <small v-if="invoiceInfo.prices_include_tax" class="text-muted" style="font-style: italic;">Precio
                                            con impuesto
                                            incluido</small>
                                        <small v-else class="text-muted" style="font-style: italic;">Precio SIN impuesto
                                            incluido</small>
                                    </div>
                                    <div class="form-group mb-3 col-6">
                                        <label class="form-label">IVA</label>
                                        <select class="form-control" v-model="editCharge.iva" required>
                                            <option value="0">0%</option>
                                            <option value="4">4%</option>
                                            <option value="6">6%</option>
                                            <option value="8">8%</option>
                                            <option value="10">10%</option>
                                            <option value="19">19%</option>
                                            <option value="21">21%</option>
                                        </select>
                                    </div>
                                </div>
                                <div v-if="!charge.isOld" class="form-group mb-3">
                                    <label class="form-label">Fecha</label>
                                    <DatePicker v-model="editCharge.date" is-required>
                                        <template v-slot="{ inputValue, inputEvents }">
                                            <input class="form-control" :value="inputValue" v-on="inputEvents" />
                                        </template>
                                    </DatePicker>
                                </div>
                                <div class="form-group mb-3">
                                    <h5 class="mb-1">
                                        Total:
                                        {{
        formatCurrency(
            editCharge.price,
            defaultCurrency
        )
    }}
                                    </h5>
                                    <small v-if="invoiceInfo.prices_include_tax" class="text-muted" style="font-style: italic;">Impuestos
                                        incluidos</small>
                                    <small v-else class="text-muted" style="font-style: italic;">Impuestos NO incluidos</small>
                                </div>
                                <div v-if="error_message" class="alert alert-danger" role="alert">
                                    {{ error_message }}
                                </div>
                            </div>
                            <div class="modal-footer d-flex justify-content-between border-0">
                                <div>
                                    <button class="btn btn-link text-danger px-0" @click.stop.prevent="removeChargeFromInvoice
        ">
                                        <i class="fa-regular fa-trash me-1"></i>Eliminar
                                    </button>
                                </div>
                                <div>
                                    <button :id="'submitEditCharge' + this.charge.id
        " type="submit" @click.stop.prevent="updateCharge" class="btn btn-primary">
                                        Guardar
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </td>
</template>

<style>
.cell-invoice-charge {
    vertical-align: middle;
}
</style>

<script>
import InvoiceService from "@/services/invoice.service";
import Swal from "sweetalert2";

export default {
    name: "InvoiceChargeRow",
    props: {
        charge: Object,
        booking: Object,
        invoice: Object,
        invoiceInfo: Object,
        segments: Array,
        isRectificativa: Boolean,
        extras: Array,
    },
    data() {
        return {
            loading: true,
            extra: null,
            editCharge: null,
            description: this.charge.description,
            defaultIVA: 21,
            price: 0,
            oldPrice: 0,
            baserate: 0,
            error_message: null,
            defaultCurrency: ''
        };
    },
    methods: {
        async start() {
            this.loading = true;

            this.editCharge = this.charge;
            this.oldPrice = this.charge.price;

            this.loading = false;
        },

        async updatePrice() {
            var price = this.editCharge.price_unit;
            price = price * this.editCharge.quantity;
            this.editCharge.price = Math.round(price * 100) / 100;
            this.editCharge.date = this.formatDate(
                this.editCharge.date,
                false,
                true
            );
        },

        async updateExtra() {
            var extraID = this.editCharge.extra;
            if (extraID != null) {
                for (var i = 0; i < this.extras.length; i++) {
                    if (this.extras[i].id == extraID) {
                        this.editCharge.segment = this.extras[i].segment;
                        this.editCharge.iva = this.extras[i].iva;

                        var quantity =
                            this.editCharge.price /
                            parseFloat(this.extras[i].price);
                        this.editCharge.quantity = parseInt(quantity);
                        this.updatePrice();
                        break;
                    }
                }
            }
        },

        async updateCharge() {
            this.error_message = null;
            if (this.editCharge.date.length < 1) {
                this.error_message = "Debes seleccionar al menos una fecha.";
            } else {
                window.$("#submitEditCharge" + this.charge.id).empty();
                window
                    .$("#submitEditCharge" + this.charge.id)
                    .append(
                        '<span class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>Guardando'
                    );

                this.editCharge.date = this.formatDate(
                    this.editCharge.date,
                    false,
                    true
                );

                let response = await InvoiceService.updateChargeInvoice(
                    this.invoice.id,
                    this.charge.id,
                    this.editCharge
                );

                if (response.status == 200) {
                    window.$("#modalEditCharge" + this.charge.id).hide();
                    window.$("body").removeClass("modal-open");
                    window.$(".modal-backdrop").remove();
                    Swal.fire({
                        icon: "success",
                        title: "Cargo actualizado",
                        confirmButtonColor: "#0091ff",
                    }).then((result) => {
                        this.$router.go();
                    });
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Ha ocurrido un error. Vuelve a intentarlo. Si el error persiste ponte en contacto con soporte@hospedium.com",
                        confirmButtonColor: "#0091ff",
                    });
                }
                window.$("#submitEditCharge" + this.charge.id).empty();
                window
                    .$("#submitEditCharge" + this.charge.id)
                    .append("Guardar");
            }
        },

        async removeChargeFromInvoice() {
            var response = await InvoiceService.removeChargeFromInvoice(
                this.invoice.id,
                this.charge.id
            );

            if (response.status == 200) {
                window.$("#modalEditCharge" + this.charge.id).hide();
                window.$("body").removeClass("modal-open");
                window.$(".modal-backdrop").remove();
                Swal.fire({
                    icon: "success",
                    title: "Cargo eliminado de la factura",
                    confirmButtonColor: "#0091ff",
                }).then((result) => {
                    this.$router.go();
                });
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Ha ocurrido un error. Vuelve a intentarlo. Si el error persiste ponte en contacto con soporte@hospedium.com",
                    confirmButtonColor: "#0091ff",
                });
            }
        },

        formatDate(date, hour = false, api = false) {
            var months = [
                "enero",
                "febrero",
                "marzo",
                "abril",
                "mayo",
                "junio",
                "julio",
                "agosto",
                "septiembre",
                "octubre",
                "noviembre",
                "diciembre",
            ];
            const d = new Date(date);
            var result = "";
            if (api) {
                result =
                    d.getFullYear() +
                    "-" +
                    (d.getMonth() + 1) +
                    "-" +
                    d.getDate();
            } else if (hour) {
                result =
                    d.getDate() +
                    " " +
                    months[d.getMonth()] +
                    " " +
                    d.getFullYear() +
                    " - " +
                    d.getHours() +
                    ":" +
                    (d.getMinutes() < 10 ? "0" : "") +
                    d.getMinutes() +
                    ":" +
                    (d.getSeconds() < 10 ? "0" : "") +
                    d.getSeconds();
            } else {
                result =
                    d.getDate() +
                    " " +
                    months[d.getMonth()] +
                    " " +
                    d.getFullYear();
            }

            return result;
        },


    },
    created() {
        this.defaultCurrency = localStorage.getItem('currency') || 'EUR';
        this.start();
    },
};
</script>