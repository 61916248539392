import api from "./api";
import HotelService from "./hotel.service";

class InvoiceInfoService {
	/**************************************
	 *  API HOSPEDIUM
	 **************************************/
	async addInvoiceInfo(params) {
		return await api
			.post("/invoiceinfos", params)
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return { status: error.response.status, data: error.response.data };
			});
	}

	async updateInvoiceInfo(id, params) {
		return await api
			.put("/invoiceinfos/" + id, params)
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return { status: error.response.status, data: error.response.data };
			});
	}

	async getInvoiceInfo() {
		let hotel = HotelService.getLocalHotel();
		return await api
			.get("/invoiceinfos?hotel=" + hotel.id)
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return { status: error.response.status, data: error.response.data };
			});
	}

	async getCurrentCurrency() {
		let hotel = HotelService.getLocalHotel();
		return await api
			.get("/invoiceinfos/currency/" + hotel.id)
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return { status: error.response.status, data: error.response.data };
			});
	}

	async setCurrentCurrency() {
		var response = await this.getCurrentCurrency();
		localStorage.setItem("currency", response.data.currency);
	}
}

export default new InvoiceInfoService();
