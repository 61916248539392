<template>
    <div v-if="!loading">
        <div class="searchBar">
            <!-- Filter Search -->
            <div class="input-group mb-3">
                <input type="search" class="form-control" @keyup="search" placeholder="Buscar" v-model="searchValue">
            </div>
        </div>
        <div class="table-responsive mb-3">
            <EasyDataTable :headers="headers" :items="items" :search-field="searchField" :search-value="searchValue" :rows-per-page=10 rows-per-page-message="resultados por página"
                rows-of-page-separator-message="de" :rows-items=rowsItems table-class-name="custom-client-table">
                <template #item-id="item">
                    <div class="operation-wrapper">
                        <RouterLink v-if="isClient" :to="'clients/' + item.id" class="btn btn-sm btn-primary me-2">
                            <i class="fa-solid fa-user-pen"></i>
                        </RouterLink>

                        <RouterLink v-if="isCompany" :to="'company/' + item.id" class="btn btn-sm btn-primary me-2">
                            <i class="fa-solid fa-building-circle-arrow-right"></i>
                        </RouterLink>

                        {{ "#" + item.id }}
                    </div>
                </template>
            </EasyDataTable>
        </div>
    </div>
    <div v-else id="spinner-search" class="col-12 text-center py-5">
        <div class="spinner-border text-primary" role="status" style="width: 2rem; height: 2rem; visibility: visble;">
            <span class="visually-hidden">Loading...</span>
        </div>
        <div>Cargando información...</div>
    </div>
</template>

<style>
.custom-client-table tr td {
    text-transform: uppercase;
}

.custom-client-table .easy-data-table__rows-selector ul.select-items {
    bottom: 0;
    top: auto;
}

.custom-client-table .easy-data-table__rows-selector ul.select-items li.selected[data-v-09dad912] {
    color: #fff;
    background-color: #0091ff;
}

.custom-client-table table {
    border-collapse: collapse !important;
}

.custom-client-table th {
    text-transform: uppercase;
    font-weight: 500 !important;
    font-size: 14px !important;
}

.custom-client-table table> :not(caption)>*>* {
    padding: 0.5rem 0.5rem;
    color: var(--bs-table-color-state,
            var(--bs-table-color-type, var(--bs-table-color)));
    background-color: var(--bs-table-bg);
    border-bottom-width: var(--bs-border-width);
    box-shadow: inset 0 0 0 9999px var(--bs-table-bg-state,
            var(--bs-table-bg-type, var(--bs-table-accent-bg)));
}

.custom-client-table td {
    border: none;
}

.custom-client-table table tr {
    border-bottom-width: 1px !important;
    border-bottom-style: dashed !important;
    border-bottom-color: var(--bs-border-color) !important;
}

.custom-client-table td {
    min-width: 125px;
}

.custom-client-table {
    --easy-table-border: 0;
    --easy-table-row-border: 1px solid rgba(230, 230, 230, 0.7);

    --easy-table-header-font-size: 12px;
    --easy-table-header-height: 50px;
    --easy-table-header-font-color: #000;
    --easy-table-header-background-color: #fff;

    --easy-table-header-item-padding: 10px 15px;

    --easy-table-body-even-row-font-color: #fff;
    --easy-table-body-even-row-background-color: #4c5d7a;

    --easy-table-body-row-font-color: #000;
    --easy-table-body-row-background-color: #fff;
    --easy-table-body-row-height: 50px;
    --easy-table-body-row-font-size: 12px;

    --easy-table-body-row-hover-font-color: #2d3a4f;
}
</style>
<script>
import { sortBy } from 'lodash';

import ClientService from "../../services/client.service"
import CompanyService from "../../services/company.service"
import HotelService from "../../services/hotel.service"


export default {
    name: 'TableClients',
    props: {
        id: String,
        isClient: Boolean,
        isCompany: Boolean,
        rowsItems: [10, 25, 50, 100]
    },
    components: {
    },
    data() {
        return {
            loading: true,
            searchValue: '',
            searchField: [],
            headers: [],
            items: []
        }
    },
    methods: {
        async start() {
            this.loading = true;

            if (this.isClient) {
                await this.getClients();
            } else if (this.isCompany) {
                await this.getCompanies();
            }


            this.loading = false;

        },

        async getClients() {
            let hotel = HotelService.getLocalHotel();
            var response = await ClientService.getAllClients();
            // console.log(response.data, "clients")
            var arr = []

            this.headers = [
                { text: "ID", value: "id", sortable: true },
                { text: "NOMBRE", value: "nombre", sortable: true },
                { text: "APELLIDOS", value: "apellidos", sortable: true },
                // { text: "EMAIL", value: "email", sortable: true },
                { text: "FECHA CREACIÓN", value: "fecha-creacion", sortable: true },
            ]

            for (var i = 0; i < this.headers.length; i++) {
                this.searchField.push(this.headers[i].value)
            }

            this.searchField.push()

            if (response.status == 200) {
                // console.log(response.data)
                for (var i = 0; i < response.data.length; i++) {

                    var d = new Date(response.data[i].dateCreated);
                    d = this.formatDate(d, true)

                    arr.push({
                        'id': response.data[i].id,
                        'nombre': response.data[i].firstName,
                        'apellidos': [response.data[i].lastName1, response.data[i].lastName2].join(' '),
                        // 'email': response.data[i].email,
                        'fecha-creacion': d,
                    })
                }
            }
            this.items = arr;
        },
        async getCompanies() {
            var response = await CompanyService.getAllCompanies();

            var arr = []

            this.tableHeaders = [
                'id', 'Razón Social', 'CIF', 'Email', 'Fecha Creación'
            ]

            this.headers = [
                { text: "ID", value: "id", sortable: true },
                { text: "RAZÓN SOCIAL", value: "razon-social", sortable: true },
                { text: "CIF", value: "cif", sortable: true },
                // { text: "EMAIL", value: "email", sortable: true },
                { text: "FECHA CREACIÓN", value: "fecha-creacion", sortable: true },
            ]

            for (var i = 0; i < this.headers.length; i++) {
                this.searchField.push(this.headers[i].value)
            }

            this.searchField.push()


            if (response.status == 200) {
                // console.log(response.data)
                for (var i = 0; i < response.data.length; i++) {

                    var d = new Date(response.data[i].dateCreated);
                    d = this.formatDate(d, true)

                    arr.push({
                        'id': response.data[i].id,
                        'razon-social': response.data[i].social_reason,
                        'cif': response.data[i].cif,
                        'email': response.data[i].email,
                        'fecha-creacion': d,
                    })

                }
            }
            this.items = arr;
        },

        formatDate(date, hour = false) {
            var days = ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'];
            var months = ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'];
            var currentDate = new Date(date);

            if (hour) {
                currentDate = currentDate.getDate() + ' ' + months[currentDate.getMonth()] + ' ' + currentDate.getFullYear() + ' - ' + currentDate.getHours() + ':' + (currentDate.getMinutes() < 10 ? '0' : '') + currentDate.getMinutes();
            } else {
                currentDate = currentDate.getDate() + ' ' + months[currentDate.getMonth()] + ' ' + currentDate.getFullYear();
            }

            return currentDate;
        },
    },
    created() {
        this.start();
    },
}
</script>