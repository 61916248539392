<template>
    <div class="page-app">
        <div class="col-12">
            <div class="card">
                <div class="d-flex card-header bg-white">
                    <h5 class="modal-title">{{ 'Empresa #' + company.id }}</h5>
                </div>
                <div class="card-body p-4">
                    <div v-if="!loading">
                        <form @submit="updateCompany">
                            <div class="row">
                                <div class="col-md-6">
                                    <h6>Información empresa</h6>
                                    <div class="form-group mb-3">
                                        <label class="form-label">Razón Social</label>
                                        <input type="text" name="social_reason" class="form-control" v-model="company.social_reason">
                                    </div>
                                    <div class="form-group mb-3">
                                        <label class="form-label">CIF</label>
                                        <input type="text" name="cif" class="form-control" v-model="company.cif">
                                    </div>
                                    <div class="form-group mb-3">
                                        <label class="form-label">Email</label>
                                        <input type="text" name="email" class="form-control" v-model="company.email">
                                    </div>
                                    <div class="form-group mb-3">
                                        <label class="form-label">Teléfono</label>
                                        <input type="text" name="phone" class="form-control" v-model="company.phone">
                                    </div>
                                    <div class="form-group mb-3">
                                        <label class="form-label">CIGMA</label>
                                        <input type="text" name="cigma" class="form-control" v-model="company.cigma">
                                    </div>
                                    <div class="form-group mb-3">
                                        <label class="form-label">Código Impuestos Electrónicos</label>
                                        <input type="text" name="electronic_tax_code" class="form-control" v-model="company.electronic_tax_code">
                                    </div>
                                    <div class="form-group mb-3">
                                        <label class="form-check">
                                            <input class="form-check-input" type="checkbox" v-model="company.isCompany">
                                            <span class="form-check-label">Empresa</span>
                                        </label>
                                    </div>
                                    <div class="form-group mb-3">
                                            <label class="form-check">
                                                <input class="form-check-input" type="checkbox" v-model="company.isAgency">
                                                <span class="form-check-label">Agencia</span>
                                            </label>
                                    </div>
                                    
                                </div>
                                <div class="col-md-6">
                                    <h6>Dirección de facturación</h6>
                                    <div class="form-group mb-3">
                                        <label class="form-label">Dirección</label>
                                        <input type="text" name="tax_address" class="form-control" v-model="company.tax_address">
                                    </div>
                                    <div class="form-group mb-3">
                                        <label class="form-label">Localidad</label>
                                        <input type="text" name="tax_locality" class="form-control" v-model="company.tax_locality">
                                    </div>
                                    <div class="form-group mb-3">
                                        <label class="form-label">Código Postal</label>
                                        <input type="text" name="tax_postal_code" class="form-control" v-model="company.tax_postal_code">
                                    </div>
                                    <div class="form-group mb-3" v-if="company.tax_country == 66" >
                                        <label class="form-label">Provincia</label>
                                         <select id="region" class="form-select " v-model="company.tax_region">
                                            <option :key="r" v-for="r in regions" :value="r.id">{{r.name}}</option>
                                        </select>
                                    </div>
                                    <div class="form-group mb-3">
                                        <label class="form-label">País</label>
                                        <select class="form-control" name ="country" v-model="company.tax_country">
                                            <option :key="c.id"  v-for="c in countries" :value="c.id">{{ c.name }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group mb-3">
                                        <label class="form-label">Personas de contacto</label>
                                        <textarea name="contact_people" class="form-control" v-model="company.contact_people"></textarea>
                                    </div>
                                </div>
                            </div>
                            <button type="submit" class="btn btn-primary">Guardar cambios</button>
                        </form>
                    </div>
                    <div v-else id="spinner-search" class="col-12 text-center py-5">
                        <div  class="spinner-border text-primary" role="status" style="width: 2rem; height: 2rem; visibility: visble;">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div>Cargando Información...</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>

    import CompanyService from "../../services/company.service";
    import CountryService from "../../services/country.service";
    import RegionService from "../../services/region.service";

    import Swal from 'sweetalert2'

    export default {
        name: 'company',
        data () {
            return {
                companyId: this.$route.params.companyId,
                company: [],
                contries: [],
                regions: [],
                loading: true
            }
        },
        computed: {
            currentUser() {
                return this.$store.state.auth.user;
            },
        },
        methods: {
            async start() {
                this.loading = true;
                await this.getCountries();
                await this.getRegions();
                await this.getCompany();
                this.loading = false;
            },
            async updateCompany(evt) {
                evt.preventDefault();
                var response = await CompanyService.updateCompany(this.companyId, this.company);

                if (response.status == 200) {
                    Swal.fire({
                        position: 'top',
                        icon: 'success',
                        title: 'Se ha guardado correctamente.',
                        showConfirmButton: false,
                        timer: 2000,
                    })
                }
            },
            async getCompany() {
                var response = await CompanyService.getCompany(this.companyId);
                this.company = response.data
            },
            async getCountries() {
                this.countries = await CountryService.getAllCountries();
            },

            async getRegions() {
                var response = await RegionService.getAllRegions();
                this.regions = response.data
            },
        },
        created() {
            this.start()
        },
        mounted() {
            if (!this.currentUser) {
                this.$router.push('/login');
            }
        }
    };
</script>