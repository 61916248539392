<template>
    <div class="page-app">
        <div class="row pt-2">
            <div class="col-md-4 col-lg-3 col-xl-2 px-3">
                <SidebarMenu />
            </div>
            <div class="col-md-8 col-lg-9 col-xl-10">
                <div class="d-md-flex justify-content-between align-items-center mb-3">
                    <h1>Entradas</h1>
                    <div class="d-md-flex">
                        <DatePicker v-model="range" mode="date" :masks="masks" is-range class="me-2">
                            <template v-slot="{ inputValue, inputEvents, isDragging }">
                                <div class="d-flex justify-content-center align-items-center bg-white shadow-sm btn">
                                    <div class="input-group flex-grow-0" style="width: auto">
                                        <span class="input-group-text border-0 bg-white text-primary"><i class="far fa-calendar-week"></i></span>
                                        <input class="flex-grow-0 border-0" :class="isDragging
                            ? 'text-gray-600'
                            : 'text-gray-900'
                            " :value="inputValue.start" v-on="inputEvents.start" style="
												width: 105px;
												cursor: pointer;
											" />
                                    </div>
                                    <span class="me-2 flex-grow-0">
                                        <i class="fas fa-long-arrow-alt-right stroke-current text-gray-600"></i>
                                    </span>
                                    <div class="input-group flex-grow-0" style="width: auto">
                                        <span class="input-group-text border-0 bg-white text-primary"><i class="far fa-calendar-week"></i></span>
                                        <input class="flex-grow-0 border-0" :class="isDragging
                            ? 'text-gray-600'
                            : 'text-gray-900'
                            " :value="inputValue.end" v-on="inputEvents.end" style="
												width: 105px;
												cursor: pointer;
											" />
                                    </div>
                                </div>
                            </template>
                        </DatePicker>

                        <div class="ms-3" data-bs-toggle="modal" data-bs-target="#modalReportingCheckin">
                            <button class="btn bg-white text-primary shadow-sm" data-bs-toggle="tooltip" data-bs-placement="top" title="Exportar" @click="exportReportingCheckin()">
                                <i class="fa-regular fa-arrow-down-to-line"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="card border-0 shadow-sm mb-3" style="min-height: 100px; max-height: 500px; overflow: auto">
                    <div class="card-body p-3">
                        <div v-if="!loading" class="table-resposive">
                            <div :key="d" v-for="d in checkinList">
                                <div v-if="d.data.length > 0">
                                    <h6 class="px-3">{{ d.day }}</h6>
                                    <table class="table table-hover table-bordered mb-5">
                                        <thead class="bg-white text-black">
                                            <tr>
                                                <th class="text-center" style="
														min-width: 120px;
														max-width: 120px;
													">
                                                    Reserva
                                                </th>
                                                <th class="text-center" style="
														min-width: 120px;
														max-width: 120px;
													">
                                                    Tipo hab.
                                                </th>
                                                <th class="text-center" style="
														min-width: 120px;
														max-width: 120px;
													">
                                                    Habitación
                                                </th>
                                                <th class="text-center" style="
														min-width: 120px;
														max-width: 120px;
													">
                                                    Portal
                                                </th>
                                                <th class="text-center" style="
														min-width: 200px;
														max-width: 200px;
													">
                                                    Cliente
                                                </th>
                                                <th class="text-center" style="
														min-width: 60px;
														max-width: 60px;
													">
                                                    PAX
                                                </th>
                                                <th class="text-center" style="
														min-width: 100px;
														max-width: 100px;
													">
                                                    Entrada
                                                </th>
                                                <th class="text-center" style="
														min-width: 100px;
														max-width: 100px;
													">
                                                    Salida
                                                </th>
                                                <th class="text-center" style="
														min-width: 100px;
														max-width: 100px;
													">
                                                    Régimen
                                                </th>
                                                <th class="text-center" style="
														min-width: 120px;
														max-width: 120px;
													">
                                                    Precio total
                                                </th>
                                                <th class="text-center">
                                                    Estado
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr :key="item" v-for="item in d.data">
                                                <td class="text-center" style="
														min-width: 120px;
														max-width: 120px;
													">
                                                    <router-link class="btn btn-sm btn-outline-primary" :to="{
                            name: 'ViewBooking',
                            params: {
                                bookingId:
                                    item.booking,
                            },
                        }"><i class="far fa-eye me-2"></i>{{
                            "#" + item.booking
                        }}</router-link>
                                                </td>
                                                <td class="text-center" style="
														min-width: 120px;
														max-width: 120px;
													">
                                                    {{ item.roomType.name }}
                                                </td>
                                                <td class="text-center" style="
														min-width: 120px;
														max-width: 120px;
													">
                                                    {{ item.room.name }}
                                                </td>
                                                <td class="text-center" style="
														min-width: 200px;
														max-width: 200px;
													">
                                                    {{ item.portal }}
                                                </td>
                                                <td class="text-center text-uppercase" style="
														min-width: 200px;
														max-width: 200px;
													">
                                                    {{ item.client }}
                                                </td>
                                                <td class="text-center" style="
														min-width: 60px;
														max-width: 60px;
													">
                                                    {{ item.persons }}
                                                </td>
                                                <td class="text-center" style="
														min-width: 100px;
														max-width: 100px;
													">
                                                    {{ item.checkin }}
                                                </td>
                                                <td class="text-center" style="
														min-width: 100px;
														max-width: 100px;
													">
                                                    {{ item.checkout }}
                                                </td>
                                                <td class="text-center text-uppercase" style="
														min-width: 100px;
														max-width: 100px;
													">
                                                    {{ item.rate.rate_type }}
                                                </td>
                                                <td class="text-center" style="
														min-width: 120px;
														max-width: 120px;
													">
                                                    {{
                            formatCurrency(
                                item.totalPrice,
                                defaultCurrency
                            )
                        }}
                                                </td>
                                                <td class="text-center">
                                                    <span class="badge badge-secondary">{{ item.status
                                                        }}</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div v-if="checkinList.length == 0 ||
                            checkinList == '' ||
                                checkinList == null
                                " class="text-center py-5">
                                <div>
                                    <i class="fa-regular fa-circle-info fa-2x text-primary mb-3"></i>
                                </div>
                                No hay información para la fecha seleccionada
                            </div>
                        </div>
                        <div v-else id="spinner-search" class="col-12 text-center py-5">
                            <div class="spinner-border text-primary" role="status" style="
									width: 2rem;
									height: 2rem;
									visibility: visble;
								">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                            <div>Cargando información...</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal -->
        <div class="modal fade" id="modalReportingCheckin" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Listado de entradas</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <embed v-if="pdf != null" :src="pdf" frameborder="0" width="100%" height="550px" />
                        <div v-else id="spinner-search" class="col-12 text-center py-5">
                            <div class="spinner-border text-primary" role="status" style="
									width: 2rem;
									height: 2rem;
									visibility: visble;
								">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                            <div>Cargando información...</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
.listings-menu-item {
    cursor: pointer;
}

.listings-menu-item:hover {
    background-color: #fff;
    border-radius: 4px;
}
</style>

<script>
import SidebarMenu from "../../components/reporting/SidebarMenu.vue";

import BookingService from "../../services/booking.service";

export default {
    name: "ReportingCheckin",
    components: {
        SidebarMenu: SidebarMenu,
    },
    data() {
        return {
            loading: true,
            pdf: null,
            checkinList: [],
            range: {
                start: new Date(),
                end: new Date(),
            },
            masks: {
                input: "YYYY-MM-DD",
            },
            defaultCurrency: ''
        };
    },
    watch: {
        range: {
            handler: function () {
                this.start();
            },
            deep: true,
        },
    },
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },
    },
    methods: {
        async start() {
            this.loading = true;
            await this.getBookingCheckinListDayReporting();

            this.loading = false;
        },

        async getBookingCheckinListDayReporting() {
            var response =
                await BookingService.getBookingCheckinListDayReporting({
                    startDate: this.formatDate(this.range.start),
                    endDate: this.formatDate(this.range.end),
                });
            this.checkinList = response.data;
            console.log(this.checkinList, "checkinList");
        },

        async exportReportingCheckin() {
            this.pdf = null;
            var response =
                await BookingService.getBookingCheckinListDayReportingPDF({
                    startDate: this.formatDate(this.range.start),
                    endDate: this.formatDate(this.range.end),
                });
            this.pdf = response.data;
            console.log(this.pdf, "PDF");
        },

        formatDate(date) {
            var d = new Date(date),
                month = "" + (d.getMonth() + 1),
                day = "" + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2) month = "0" + month;
            if (day.length < 2) day = "0" + day;

            return [year, month, day].join("-");
        },

    },
    created() {
        this.start();
    },
    mounted() {
        if (!this.currentUser) {
            this.$router.push("/login");
        }
    },
};
</script>