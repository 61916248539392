export default [
	{
		path: "/reporting/production",
		name: "ReportingProduction",
		component: () => import("@/views/reporting/Production/ReportingProduction.vue"),
	},
	{
		path: "/reporting/production/tags",
		name: "ReportingProdReportingProductionTagsuction",
		component: () => import("@/views/reporting/Production/ReportingProductionTags.vue"),
	},
	{
		path: "/reporting/housekeeping",
		name: "ReportingHousekeeping",
		component: () => import("@/views/reporting/Others/ReportingHousekeeping.vue"),
		beforeEnter: () => {
			localStorage.setItem("menu", "menu-reporting");
		},
	},

	{
		path: "/reporting/stays",
		name: "ReportingStays",
		component: () => import("@/views/reporting/Bookings/ReportingStays.vue"),
		beforeEnter: () => {
			localStorage.setItem("menu", "menu-reporting");
		},
	},

	{
		path: "/reporting/forecast_occupancy",
		name: "ReportingForecastOccupancy",
		component: () => import("@/views/reporting/Bookings/ReportingForecastOccupancy.vue"),
		beforeEnter: () => {
			localStorage.setItem("menu", "menu-reporting");
		},
	},

	{
		path: "/reporting/payment_links",
		name: "ReportingPaymentLink",
		component: () => import("@/views/reporting/Billing/ReportingPaymentLink.vue"),
		beforeEnter: () => {
			localStorage.setItem("menu", "menu-reporting");
		},
	},
	// Otras rutas de la aplicación
];
