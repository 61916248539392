<template>
    <div class="page-app">
        <div class="row">
            <div class="col-md-4 col-lg-3 col-xl-2 px-3">
                <SidebarMenu />
            </div>
            <div class="col-md-8 col-lg-9 col-xl-10 row">
                <div class="col-md-6">
                    <div class="card shadow-sm border-0 py-4 mb-3">
                        <div class="card-body text-center">
                            <i class="fa-regular fa-chart-mixed fa-3x mb-3 text-primary"></i>
                            <div class="col-12">
                                <h3>Previsión de ocupación</h3>
                                <div class="mb-3">Listado de previsión de ocupación.</div>
                                <router-link to="/reporting/forecast_occupancy" class="btn btn-primary"> Ver listado</router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="card shadow-sm border-0 py-4 mb-3">
                        <div class="card-body text-center">
                            <i class="fa-regular fa-bed-front fa-3x mb-3 text-primary"></i>
                            <div class="col-12">
                                <h3>Housekeeping</h3>
                                <div class="mb-3">Listado del estado de las habitaciones.</div>
                                <router-link to="/reporting/housekeeping" class="btn btn-primary"> Ver listado</router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="card shadow-sm border-0 py-4 mb-3">
                        <div class="card-body text-center">
                            <i class="fa-regular fa-credit-card fa-3x mb-3 text-primary"></i>
                            <div class="col-12">
                                <h3>Cobros</h3>
                                <div class="mb-3">Listado de cobros realizados</div>
                                <router-link to="/reporting/payments" class="btn btn-primary"> Ver listado</router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="card shadow-sm border-0 py-4 mb-3">
                        <div class="card-body text-center">
                            <i class="fa-regular fa-file-export fa-3x mb-3"></i>
                            <div class="col-12">
                                <h5>PREVISIÓN PRODUCCIÓN POR CANALES</h5>
                                <ProductionByPortals />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="card shadow-sm border-0 py-4 mb-3">
                        <div class="card-body text-center">
                            <i class="fa-solid fa-user-police fa-3x mb-3"></i>
                            <div class="col-12">
                                <h5>FICHERO GUARDIA CIVIL</h5>
                                <DownloadParteGuardiaCivil />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="card shadow-sm border-0 py-4 mb-3">
                        <div class="card-body text-center">
                            <i class="fa-solid fa-file-export fa-3x mb-3"></i>
                            <div class="col-12">
                                <h5>FACTURAS</h5>
                                <InvoicesExportExcel />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
.listings-menu-item {
    cursor: pointer;
}

.listings-menu-item:hover {
    background-color: #fff;
    border-radius: 4px;
}
</style>

<script>
import SidebarMenu from "../../components/reporting/SidebarMenu.vue"
import BookingsByDayList from "../../components/reporting/BookingsByDayList.vue"
import ProductionByPortals from "../../components/reporting/ProductionByPortals.vue"
import DownloadParteGuardiaCivil from "../../components/reporting/DownloadParteGuardiaCivil.vue"
import InvoicesExportExcel from "../../components/reporting/InvoicesExportExcel.vue"
import CheckinCheckout from "../../components/settings/CheckinCheckout.vue"

export default {
    name: 'Listings',
    components: {
        CheckinCheckout: CheckinCheckout,
        BookingsByDayList: BookingsByDayList,
        ProductionByPortals: ProductionByPortals,
        DownloadParteGuardiaCivil: DownloadParteGuardiaCivil,
        InvoicesExportExcel: InvoicesExportExcel,
        SidebarMenu: SidebarMenu
    },
    data() {
        return {
        }
    },
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },
    },
    methods: {

    },
    created() {

    },
    mounted() {
        if (!this.currentUser) {
            this.$router.push('/login');
        }
    }
};

</script>