import api from "./api";
import HotelService from "./hotel.service";

class InvoiceService {
	/**************************************
	 *  API HOSPEDIUM
	 **************************************/
	async addInvoice(data) {
		let hotel = HotelService.getLocalHotel();
		var params = {
			hotel: hotel.id,
		};
		return await api
			.post("/invoices", data, { params: params })
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async updateInvoice(id, params) {
		return await api
			.put("/invoices/" + id, params)
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async getAllInvoices() {
		let hotel = HotelService.getLocalHotel();
		return await api
			.get("/invoices?hotel=" + hotel.id)
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async getClientInvoices(params) {
		let hotel = HotelService.getLocalHotel();
		params.hotel = hotel.id;
		return await api
			.get("/invoicesclient", { params: params })
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async getCompanyInvoices(params) {
		let hotel = HotelService.getLocalHotel();
		params.hotel = hotel.id;
		return await api
			.get("/invoicescompany", { params: params })
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async getCorrectionInvoices(params) {
		let hotel = HotelService.getLocalHotel();
		params.hotel = hotel.id;
		return await api
			.get("/invoicescorrection", { params: params })
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async getInvoicesByDate(params) {
		let hotel = HotelService.getLocalHotel();
		params.hotel = hotel.id;
		return await api
			.get("/invoicesdate", { params: params })
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async getBookingInvoices(id) {
		return await api
			.get("/invoicesbooking?booking=" + id)
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	getInvoice(id) {
		return api
			.get("/invoices/" + id)
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async getInvoiceDownload(id) {
		return await api
			.get("/invoices/" + id + "/download")
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async exportInvoices(params) {
		let hotel = HotelService.getLocalHotel();
		params.hotel = hotel.id;
		// return await api.get('/invoices/export', {params: params, responseType: 'blob'})
		return await api
			.get("/invoices/export", { params: params })
			.then((response) => {
				// var date = new Date();
				// var filename = 'invoices_' + date.getFullYear() + date.getMonth() + date.getDate() + '_' + date.getHours() + date.getMinutes() + date.getSeconds()
				// const url = window.URL.createObjectURL(new Blob([response.data],{type:'application/zip'}));
				// const link = document.createElement('a');
				// link.href = url;
				// link.setAttribute('download', filename + '.zip'); //or any other extension
				// document.body.appendChild(link);
				// link.click();

				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async addChargesToInvoice(invoice_pk, data) {
		let hotel = HotelService.getLocalHotel();
		var params = {
			hotel: hotel.id,
		};
		return await api
			.post(`/invoices/${invoice_pk}/charges/add/`, data, { params: params })
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async addNewChargeToInvoice(invoice_pk, data) {
		let hotel = HotelService.getLocalHotel();
		var params = {
			hotel: hotel.id,
		};
		return await api
			.post(`/invoices/${invoice_pk}/charges/create/`, data, { params: params })
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async updateChargeInvoice(invoice_pk, charge_pk, data) {
		let hotel = HotelService.getLocalHotel();
		var params = {
			hotel: hotel.id,
		};
		return await api
			.put(`/invoices/${invoice_pk}/charges/update/${charge_pk}/`, data, { params: params })
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async updateInvoiceNotes(id, data) {
		return await api
			.put("/invoices/notes/update/" + id, data)
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async updateInvoicePaymentInfo(id, data) {
		return await api
			.put("/invoices/paymentinfo/update/" + id, data)
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async updateInvoiceTags(id, data) {
		return await api
			.put("/invoices/tags/update/" + id, data)
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async rectifyInvoice(invoice_pk, data) {
		let hotel = HotelService.getLocalHotel();
		var params = {
			hotel: hotel.id,
		};
		return await api
			.post(`/invoices/${invoice_pk}/rectify/`, data, { params: params })
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async removeChargeFromInvoice(invoice_pk, charge_pk) {
		let hotel = HotelService.getLocalHotel();
		var params = {
			hotel: hotel.id,
		};
		return await api
			.delete(`/invoices/${invoice_pk}/charges/remove/${charge_pk}/`, { params: params })
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async exportInvoicePDF(data) {
		let hotel = HotelService.getLocalHotel();
		var params = {
			hotel: hotel.id,
		};
		return api
			.post("invoices/export/pdf", data, {
				params: params,
				responseType: "blob",
			})
			.then((response) => {
				var date = new Date();
				var filename = "invoice_" + date.getFullYear() + date.getMonth() + date.getDate() + "_" + date.getHours() + date.getMinutes() + date.getSeconds();
				const url = window.URL.createObjectURL(new Blob([response.data], { type: "application/pdf" }));
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", filename + ".pdf"); //or any other extension
				document.body.appendChild(link);
				// link.click(function(){
				//     window.$("#dialog").dialog();
				// });
				return { status: response.status, data: link.href };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async exportInvoicesExcel(params) {
		let hotel = HotelService.getLocalHotel();
		params.hotel = hotel.id;
		return api
			.get("invoices/export/excel", {
				params: params,
				responseType: "blob",
			})
			.then((response) => {
				var date = new Date();
				var filename = "invoices_" + date.getFullYear() + date.getMonth() + date.getDate() + "_" + date.getHours() + date.getMinutes() + date.getSeconds();

				// create file link in browser's memory
				const url = window.URL.createObjectURL(
					new Blob([response.data], {
						type: "application/vnd.ms-excel",
					})
				);

				// create "a" HTML element with href to file & click
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", filename + ".xls"); //or any other extension
				document.body.appendChild(link);
				link.click();
				// clean up "a" element & remove ObjectURL
				document.body.removeChild(link);
				URL.revokeObjectURL(href);
			})
			.catch(function (error) {
				return { error };
			});
	}

	async updateInvoiceClient(data) {
		let hotel = HotelService.getLocalHotel();
		var params = {
			hotel: hotel.id,
		};
		return await api
			.post("invoices/client/change", data, { params: params })
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async updateInvoiceCompany(data) {
		let hotel = HotelService.getLocalHotel();
		var params = {
			hotel: hotel.id,
		};
		return await api
			.post("invoices/company/change", data, { params: params })
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}

	async senEmailInvoice(invoiceID, data) {
		return await api
			.post("invoices/send/email/" + invoiceID + "/", data)
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	}
}

export default new InvoiceService();
