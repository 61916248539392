import api from './api';
import HotelService from "./hotel.service";

class InfoService { 


    /**************************************
     *  API HOSPEDIUM
     **************************************/
    async getInfo(params) {
        let hotel = HotelService.getLocalHotel();
        params.hotel = hotel.id
        return await api.get('/info', {params:params})
        .then((response) => {
            return {status: response.status, data: response.data};
        })
        .catch(function (error) {
            return {status: error.response.status, data: error.response.data};
        });
    }

    async updateInfo(id, params) {
        return await api.put('/info/'+id, params)
        .then((response) => {
            return {status: response.status, data: response.data};
        })
        .catch(function (error) {
            return {status: error.response.status, data: error.response.data};
        });
    }

}

export default new InfoService();