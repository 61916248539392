<template>
    <div>
        <div v-if="!loading" class="d-grid gap-2 col-12 mx-auto">

            <button class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#modalSendInvoice"><i class="fa-regular fa-paper-plane me-2"></i>Enviar</button>
        </div>

        <!-- Modal Send Invoice -->
        <div class="modal fade" id="modalSendInvoice" tabindex="-1" aria-labelledby="modalSendInvoice" aria-hidden="true">
            <div class="modal-dialog modal-xl">
                <div class="modal-content p-2">
                    <div class="modal-header border-0">
                        <h5 class="modal-title">Enviar factura</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div v-if="!loading" class="modal-body">
                        <div class="form-group mb-1">
                            <MazInputTags v-model="recipient_list_to" label="Para:" color="primary" size="sm" :error="errors.recipient_list_to"
                                @update:model-value="check_recipient_list_to(recipient_list_to)" class="w-100" />
                        </div>
                        <div class="form-group mb-1">
                            <MazInputTags v-model="recipient_list_cc" label="CC:" color="primary" size="sm" @update:model-value="check_recipient_list_cc(recipient_list_cc)" class="w-100" />
                        </div>
                        <div class="form-group mb-1">
                            <MazInputTags v-model="recipient_list_bcc" label="BCC:" color="primary" size="sm" @update:model-value="check_recipient_list_bcc(recipient_list_bcc)" class="w-100" />
                        </div>
                        <div class="form-group mb-1">
                            <MazInput v-model="subject" label="Asunto:" color="primary" />
                        </div>


                        <EmailEditor ref="emailEditor" v-on:load="editorLoaded" v-on:ready="editorReady" />

                        <div class="alert alert-light" role="alert">
                            <h6>Archivos adjuntos</h6>
                            <div class="btn btn-sm badge-light-primary"><i class="fa-regular fa-file-pdf me-2"></i>{{ invoiceNumber }}</div>
                        </div>

                    </div>
                    <div class="modal-footer border-0">
                        <a type="button" class="btn btn-link text-decoration-none text-primary" data-bs-dismiss="modal">Cerrar</a>
                        <button id="submitSendEmail" type="submit" class="btn btn-primary" @click.prevent.stop="sendEmail">
                            <i class="fa-regular fa-paper-plane me-2"></i>Enviar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import HotelService from "@/services/hotel.service";
import InfoService from "@/services/info.service";
import InvoiceService from "@/services/invoice.service";

import Swal from "sweetalert2";

import { EmailEditor } from "vue-email-editor";

import { Modal } from "bootstrap";

export default {
    name: "SendInvoice",
    props: {
        booking: Object,
        invoice: Object,
    },
    components: {
        EmailEditor,
    },
    data() {
        return {
            recipient_list_to: [],
            recipient_list_cc: [],
            recipient_list_bcc: [],
            loading: true,
            subject: null,
            html_content: null,
            hotel: HotelService.getLocalHotel(),
            info: null,
            invoiceNumber: null,
            error: false,
            myModal: null,
            errors: {}

        };
    },
    methods: {
        async start() {
            this.loading = true;
            await this.getInfo();

            var content = JSON.parse(this.info.content);
            this.html_content = content["html"];

            if (this.invoice.client_hotel && this.invoice.client_hotel.email) {
                this.recipient_list_to.push(this.invoice.client_hotel.email)
            } else if (this.invoice.company_hotel && this.invoice.company_hotel.email) {
                this.recipient_list_to.push(this.invoice.company_hotel.email)
            }
            else if (this.booking && this.booking.email) {
                this.recipient_list_to.push(this.booking.email)
            }

            this.invoiceNumber = this.invoice.number.toString();
            this.invoiceNumber = this.invoiceNumber.padStart(6, '0');
            this.invoiceNumber = this.invoice.code + this.invoiceNumber

            this.subject = this.hotel.name + " ~ Factura " + this.invoiceNumber

            this.loading = false;
        },

        async getInfo() {
            var response = await InfoService.getInfo({
                key_name: "email-invoice",
            });
            this.info = response.data;
            // console.log(this.info, "Info")
        },

        editorLoaded() {
            console.log("editorLoaded");
            // Pass the template JSON here
            var content = JSON.parse(this.info.content);
            this.$refs.emailEditor.editor.loadDesign(content["design"]);


            this.$refs.emailEditor.editor.setMergeTags({
                hotel_name: {
                    name: "Hotel Name",
                    value: "{{hotel_name}}",
                },
                invoice_number: {
                    name: "Invoice Number",
                    value: "{{invoice_number}}",
                },
            })
        },
        // called when the editor has finished loading
        editorReady() {
            console.log("editorReady");
        },

        check_recipient_list_to(newEmails) {
            // Define la expresión regular para validar el formato de correo electrónico
            const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            let invalidEmailsFound = false;

            // Filtra la lista para mantener solo los elementos que cumplen con el formato de correo electrónico
            // y detecta si hay correos inválidos
            this.recipient_list_to = newEmails.filter(email => {
                const isValid = emailRegex.test(email);
                if (!isValid) invalidEmailsFound = true;
                return isValid;
            });

            // Muestra un toast si se encontró al menos un correo inválido
            if (invalidEmailsFound) {
                Swal.fire({
                    toast: true,
                    icon: 'error',
                    title: 'El email introducido no es válido.',
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                });
            }
        },

        check_recipient_list_cc(newEmails) {
            // Define la expresión regular para validar el formato de correo electrónico
            const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            let invalidEmailsFound = false;

            // Filtra la lista para mantener solo los elementos que cumplen con el formato de correo electrónico
            // y detecta si hay correos inválidos
            this.recipient_list_cc = newEmails.filter(email => {
                const isValid = emailRegex.test(email);
                if (!isValid) invalidEmailsFound = true;
                return isValid;
            });

            // Muestra un toast si se encontró al menos un correo inválido
            if (invalidEmailsFound) {
                Swal.fire({
                    toast: true,
                    icon: 'error',
                    title: 'El email introducido no es válido.',
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                });
            }
        },

        check_recipient_list_bcc(newEmails) {
            // Define la expresión regular para validar el formato de correo electrónico
            const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            let invalidEmailsFound = false;

            // Filtra la lista para mantener solo los elementos que cumplen con el formato de correo electrónico
            // y detecta si hay correos inválidos
            this.recipient_list_bcc = newEmails.filter(email => {
                const isValid = emailRegex.test(email);
                if (!isValid) invalidEmailsFound = true;
                return isValid;
            });

            // Muestra un toast si se encontró al menos un correo inválido
            if (invalidEmailsFound) {
                Swal.fire({
                    toast: true,
                    icon: 'error',
                    title: 'El email introducido no es válido.',
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                });
            }
        },


        async sendEmail() {
            this.errors = {}
            if (this.recipient_list_to.length == 0) {
                this.errors.recipient_list_to = true
                this.error = true
            }

            if (this.error == true) {
                return;
            } else {

                Swal.fire({
                    title: 'Enviando...',
                    didOpen: () => {
                        Swal.showLoading();
                    },
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    showConfirmButton: false
                });

                await this.exportHtml()


                var response = await InvoiceService.senEmailInvoice(this.invoice.id, {
                    subject: this.subject,
                    recipient_list_to: this.recipient_list_to,
                    recipient_list_cc: this.recipient_list_cc,
                    recipient_list_bcc: this.recipient_list_bcc,
                    html_content: this.html_content,
                })

                Swal.close();

                if (response.status == 200) {
                    Swal.fire({
                        icon: "success",
                        title: "Correo enviado",
                        confirmButtonColor: "#0091ff",
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.myModal.hide();
                        }
                    });
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Ha ocurrido un error. " + response.data.message,
                        confirmButtonColor: "#0091ff",
                    });
                }
            }
        },

        async exportHtml() {

            await this.$refs.emailEditor.editor.exportHtml(
                (data) => {

                    console.log('exportHtml', data);
                    this.html_content = data.html
                }
            )
        }
    },
    created() {
        this.start();
    },
    mounted() {
        this.myModal = new Modal(document.getElementById('modalSendInvoice'));
    }
};
</script>