<template>
    <div>
        <button class="d-flex align-items-center text-start" data-bs-toggle="modal" :data-bs-target="'#modalCardInfo'+card.uuid">
            <div class="pe-3">
                <i v-if="card.source_brand == 'VISA'" class="fa-brands fa-cc-visa ccard"></i>
                <i v-else-if="card.source_brand == 'MASTERCARD'" class="fa-brands fa-cc-mastercard ccard"></i>
                <i v-else class="fa-solid fa-credit-card-front ccard"></i>
            </div>
            <div>
                <div><small><span class="text-capitalize">{{ card.source_brand }}</span>{{ ' *' + card.source_last4 }}</small></div>
                <div><small>{{ card.source_expire_month + '/' + card.source_expire_year }}</small></div>
                <div v-if="card.source_validation_date == null && card.source_validation_date != ''" class="text-danger">
                    <small>
                        <i class="fa-solid fa-circle me-1"></i> Tarjeta no verificada
                    </small>
                </div>
                <div v-else class="text-success">
                    <small>
                        <i class="fa-solid fa-circle me-1"></i> Tarjeta verificada
                    </small>
                </div>
            </div>
        </button>

         <!-- Modal View Credit Card -->
        <div class="modal fade" :id="'modalCardInfo'+card.uuid" tabindex="-1" aria-labelledby="modalCardInfo" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-fullscreen-lg-down">
                <div class="modal-content">
                    <div class="modal-header">
                        <h6 class="mb-0">Detalles de tarjeta</h6>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        
                        <div class="row">
                            <div class="col-md-6 text-end">
                                <label>Método de pago</label>
                            </div>
                            <div class="col-md-6">
                                <div class="text-uppercase">{{ card.source_object }}</div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-6 text-end">
                                <label>Tipo de tarjeta</label>
                            </div>
                            <div class="col-md-6">
                                <div class="text-uppercase">{{ card.source_brand }}</div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-6 text-end">
                                <label>Titular de la tarjeta</label>
                            </div>
                            <div class="col-md-6">
                                <div class="text-uppercase">{{ card.source_holder }}</div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 text-end">
                                <label>Número de tarjeta</label>
                            </div>
                            <div class="col-md-6">
                                <div class="text-uppercase">{{ ' *' + card.source_last4 }}</div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 text-end">
                                <label>Fecha de caducidad</label>
                            </div>
                            <div class="col-md-6">
                                <div class="text-uppercase">{{ card.source_expire_month + '/' + card.source_expire_year }}</div>
                            </div>
                        </div>
                        <div class="col-12 text-center mt-5">
                            <button class="btn btn-sm btn-primary mb-2"><i class="fa-regular fa-unlock me-2"></i>Mostrar número de tarjeta</button>
                            <div class="mb-3">Visualizaciones: 0/2</div>
                            <div><small><i class="fa-regular fa-circle-info me-2"></i>Por seguridad solamente se puede visualizar el número de tarjeta hasta un máximo de 2 veces.</small></div>
                        </div>
                        <div class="mt-5 col-12 text-center">
                            <button class="btn btn-sm btn-danger" @click.stop.prevent="deleteCreditCard()"><i class="fa-regular fa-trash me-2"></i>Eliminar tarjeta</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

   

</template>

<script>
    
    import Swal from 'sweetalert2'

    import CreditCardService from "../../services/creditcard.service"

    export default {
        name: 'CreditCardInfo',
        props: {
            card: Object,
            booking: Object
        },
        data () {
            return {
                loading: true
            }
        },
        methods: {
            async start() {
                this.loading = true;
                this.loading = false;
            },

            async deleteCreditCard() {
                Swal.fire({
                    title: '¿Estás seguro que quieres eliminar la tarjeta?',
                    confirmButtonColor: 'red',
                    confirmButtonText: 'Eliminar',
                    showCancelButton: true,
                    cancelButtonText: 'Cancelar',
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        var response = await CreditCardService.deleteCreditCard({
                            bookingID: this.booking.id,
                            cardPaynopainUUID: this.card.uuid
                        })

                        if (response.status == 200) {
                            window.$('#modalAddCard').hide();
                            window.$('body').removeClass('modal-open');
                            window.$('.modal-backdrop').remove();

                            Swal.fire({
                                icon: 'success',
                                title: 'Se ha eliminado correctamente.',
                                confirmButtonColor: '#0091ff'
                            }).then(() => {
                                this.$router.go();
                            })
                                
                        } else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'Ha ocurrido un error. Vuelve a intentarlo. Si el error persiste ponte en contacto con soporte@hospedium.com',
                                confirmButtonColor: '#0091ff'
                            });
                        }
                    }
                })     
                
            }

        },
        created() {
            this.start()
        }
    }
</script>