<template>
    <div class="page-app">
        <div class="row pt-2">
            <div class="col-md-4 col-lg-3 col-xl-2 px-3">
                <SidebarMenu />
            </div>
            <div class="col-md-8 col-lg-9 col-xl-10">
                <div class="d-md-flex justify-content-between align-items-center mb-3">
                    <h1>Producción por canales</h1>
                    <div class="d-md-flex">
                        <DatePicker v-model="range" mode="date" :masks="masks" is-range class="me-2">
                            <template v-slot="{ inputValue, inputEvents, isDragging }">
                                <div class="d-flex justify-content-center align-items-center bg-white shadow-sm btn">
                                    <div class="input-group  flex-grow-0" style="width: auto;">
                                        <span class="input-group-text border-0 bg-white text-primary"><i class="far fa-calendar-week"></i></span>
                                        <input class="flex-grow-0 border-0" :class="isDragging ? 'text-gray-600' : 'text-gray-900'" :value="inputValue.start" v-on="inputEvents.start"
                                            style="width: 105px; cursor: pointer;" />
                                    </div>
                                    <span class="me-2 flex-grow-0">
                                        <i class="fas fa-long-arrow-alt-right stroke-current text-gray-600"></i>
                                    </span>
                                    <div class="input-group flex-grow-0" style="width: auto;">
                                        <span class="input-group-text border-0 bg-white text-primary"><i class="far fa-calendar-week"></i></span>
                                        <input class="flex-grow-0 border-0" :class="isDragging ? 'text-gray-600' : 'text-gray-900'" :value="inputValue.end" v-on="inputEvents.end"
                                            style="width: 105px; cursor: pointer;" />
                                    </div>
                                </div>
                            </template>
                        </DatePicker>

                        <div data-bs-toggle="modal" data-bs-target="#modalReportingCheckin">
                            <button class="btn bg-white text-primary shadow-sm" data-bs-toggle="tooltip" data-bs-placement="top" title="Exportar" @click="exportReport()"><i
                                    class="fa-regular fa-arrow-down-to-line"></i></button>
                        </div>
                    </div>
                </div>
                <div class="card border-0 shadow-sm mb-3" style="min-height: 100px; max-height: 500px; overflow: auto;">
                    <div class="card-body p-3">
                        <div v-if="!loading" class="table-responsive scrollbar">
                            <table class="table table-hover table-bordered mb-0" style="font-size: 0.8em;">
                                <thead class="bg-white text-black">
                                    <tr>
                                        <th class="text-center" style="min-width: 200px; max-width: 200px;"></th>
                                        <td class="text-center" style="min-width: 100px; max-width: 100px; font-weight: 500;">
                                            Nº Habitaciones</td>
                                        <td class="text-center" style="min-width: 100px; max-width: 100px; font-weight: 500;">
                                            Precio Medio</td>
                                        <td class="text-center" style="min-width: 100px; max-width: 100px; font-weight: 500;">
                                            Total Neto</td>
                                        <td class="text-center" style="min-width: 100px; max-width: 100px; font-weight: 500;">
                                            Total IVA</td>
                                        <td class="text-center" style="min-width: 100px; max-width: 100px; font-weight: 500;">
                                            Total Comisiones</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr :key="item" v-for="(item, index) in data.data">
                                        <td class="text-end" style="font-weight: 500;">{{ item.portal
                                            }}<i class="fa-solid fa-circle ms-2" :style="'color: ' + borderColors[index]"></i></td>
                                        <td class="text-center">{{ item.num_rooms }}</td>
                                        <td class="text-center">{{ formatCurrency(item.adr_net,
                            defaultCurrency)
                                            }}</td>
                                        <td class="text-center">{{ formatCurrency(item.total_baserate,
                            defaultCurrency) }}</td>
                                        <td class="text-center">{{ formatCurrency(item.total_iva,
                            defaultCurrency)
                                            }}</td>
                                        <td class="text-center">{{ formatCurrency(item.total_commission,
                            defaultCurrency) }}</td>
                                    </tr>
                                    <!-- <tr>
                                        <td class="text-end" style="font-weight: 500;">Total<i class="fa-solid fa-circle ms-2" :style="'color: ' + borderColors[-1]"></i></td>
                                        <td :key="p" v-for="p in data.total.data" class="text-center">{{ formatCurrency(p.total, defaultCurrency) }}</td>
                                        <td class="text-center" style="font-weight: 500;">{{  formatCurrency(data.total.total, defaultCurrency) }}</td>
                                    </tr> -->
                                </tbody>
                            </table>
                        </div>
                        <div v-else id="spinner-search" class="col-12 text-center py-5">
                            <div class="spinner-border text-primary" role="status" style="width: 2rem; height: 2rem; visibility: visble;">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                            <div>Cargando información...</div>
                        </div>
                    </div>
                </div>
                <div class="card border-0 shadow-sm mb-3" style="min-height: 100px; max-height: 500px; overflow: auto;">
                    <div class="card-body p-3">
                        <div v-if="!loading" class="table-responsive scrollbar">
                            <table class="table table-hover table-bordered mb-0" style="font-size: 0.8em;">
                                <thead class="bg-white text-black">
                                    <tr>
                                        <th class="text-center" style="min-width: 200px; max-width: 200px;"></th>
                                        <th :key="item" v-for="item in data.days" class="text-center" style="min-width: 100px; max-width: 100px;">{{
                            formatDate(item, true) }}</th>
                                        <!-- <td class="text-center" style="min-width: 100px; max-width: 100px; font-weight: 500;">Total</td> -->
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr :key="item" v-for="(item, index) in data.data">
                                        <td class="text-end" style="font-weight: 500;">{{ item.portal
                                            }}<i class="fa-solid fa-circle ms-2" :style="'color: ' + borderColors[index]"></i></td>
                                        <td :key="p" v-for="p in item.data" class="text-center">{{
                            formatCurrency(p.total, defaultCurrency) }}</td>
                                        <!-- <td class="text-center" style="font-weight: 500;">{{  formatCurrency(item.total.total, defaultCurrency) }}</td> -->
                                    </tr>
                                    <!-- <tr>
                                        <td class="text-end" style="font-weight: 500;">Total<i class="fa-solid fa-circle ms-2" :style="'color: ' + borderColors[-1]"></i></td>
                                        <td :key="p" v-for="p in data.total.data" class="text-center">{{ formatCurrency(p.total, defaultCurrency) }}</td>
                                        <td class="text-center" style="font-weight: 500;">{{  formatCurrency(data.total.total, defaultCurrency) }}</td>
                                    </tr> -->
                                </tbody>
                            </table>
                        </div>
                        <div v-else id="spinner-search" class="col-12 text-center py-5">
                            <div class="spinner-border text-primary" role="status" style="width: 2rem; height: 2rem; visibility: visble;">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                            <div>Cargando información...</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal -->
        <div class="modal fade" id="modalReportingCheckin" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Listado de entradas</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <embed v-if="pdf != null" :src="pdf" frameborder="0" width="100%" height="550px">
                        <div v-else id="spinner-search" class="col-12 text-center py-5">
                            <div class="spinner-border text-primary" role="status" style="width: 2rem; height: 2rem; visibility: visble;">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                            <div>Cargando información...</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
#sales-chart {
    display: block;
    box-sizing: unset;
    max-height: 250px;
    min-width: 100%;
    width: auto;
    aspect-ratio: auto !important;
    overflow: auto !important;
    overflow-clip-margin: unset;
    height: auto;
}

.listings-menu-item {
    cursor: pointer;
}

.listings-menu-item:hover {
    background-color: #fff;
    border-radius: 4px;
}

.table-responsive.scrollbar {
    overflow-x: auto;
}

.table-responsive.scrollbar::-webkit-scrollbar {
    -webkit-appearance: none;
}

.table-responsive.scrollbar::-webkit-scrollbar:vertical {
    width: 12px;
}

.table-responsive.scrollbar::-webkit-scrollbar:horizontal {
    height: 12px;
}

.table-responsive.scrollbar::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, .2);
    border-radius: 10px;
    border: 2px solid #ffffff;
}

.table-responsive.scrollbar::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #ffffff;
}
</style>

<script>
import SidebarMenu from "../../components/reporting/SidebarMenu.vue"
import ReportingService from "../../services/reporting.service";



export default {
    name: 'ReportingProductionByChannel',
    components: {
        SidebarMenu: SidebarMenu
    },
    data() {
        return {
            loading: true,
            pdf: null,
            data: [],
            range: {
                start: new Date(new Date().setDate(new Date().getDate() - 1)),
                end: new Date(),
            },
            masks: {
                input: 'YYYY-MM-DD',
            },
            lineChart: null,
            backgroundColors: [
                'rgba(255, 110, 107, 0.3)',
                'rgba(28, 206, 159, 0.3)',
                'rgba(23, 164, 186, 0.3)',
                'rgba(153, 205, 50, 0.3)',
                'rgba(149, 233, 211, 0.3)',
                'rgba(107, 181, 255, 0.3)',
                'rgba(15, 104, 118, 0.3)'
            ],
            borderColors: [
                'rgba(255, 110, 107, 1)',
                'rgba(28, 206, 159, 1)',
                'rgba(23, 164, 186, 1)',
                'rgba(153, 205, 50, 1)',
                'rgba(149, 233, 211, 1)',
                'rgba(107, 181, 255, 1)',
                'rgba(15, 104, 118, 1)'
            ],

            defaultCurrency: ''
        }
    },
    watch: {
        range: {
            handler: function () {
                this.start();
            },
            deep: true
        }
    },
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },
    },
    methods: {
        async start() {
            this.loading = true
            await this.getData();

            var chart_datasets = []

            for (var i = 0; i < this.data.data.length; i++) {
                var data_list = []
                for (var j = 0; j < this.data.data[i].data.length; j++) {
                    data_list.push(this.data.data[i].data[j].total)
                }
                chart_datasets.push({
                    label: this.data.data[i].paymentMethod,
                    data: data_list,
                    fill: true,
                    borderColor: this.borderColors[i],
                    tension: 0.5,
                    backgroundColor: this.backgroundColors[i]
                })
            }


            this.lineChart = {
                type: 'line',
                data: {
                    labels: this.data.days,
                    datasets: chart_datasets,
                    options: {
                        responsive: true,
                        maintainAspectRatio: false,
                        scales: {
                            yAxes: [{
                                ticks: {
                                    beginAtZero: true
                                }
                            }]
                        }
                    }
                }
            }
            this.loading = false
        },

        async getData() {
            var response = await ReportingService.getProductionByChannelReportingByDate({
                startDate: this.formatDate(this.range.start),
                endDate: this.formatDate(this.range.end)
            })
            this.data = response.data;
            console.log(this.data, "data")
        },

        async exportReport() {
            this.pdf = null;
            var response = await ReportingService.getInvoicesByPaymentMethodReportingByDatePDF({
                startDate: this.formatDate(this.range.start),
                endDate: this.formatDate(this.range.end)
            })
            this.pdf = response.data;
            console.log(this.pdf, "PDF")
        },

        formatDate(date, show) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();



            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            if (show) {
                return [day, month, year].join('/');
            }


            return [year, month, day].join('-');
        },


    },
    created() {
        this.start()
    },
    mounted() {
        if (!this.currentUser) {
            this.$router.push('/login');
        }
    }
};

</script>